import gql from 'graphql-tag';
import { Observable, mergeMap } from 'rxjs';
import { StateObservable, ofType } from 'redux-observable';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { ReduxFormInstanceServerCreate } from '../types.ts';
import { errorCreating, serverLoad } from './actions.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../../store.ts';

/**
 * Calls GraphQL to create a new form instance.
 */
export function newInstanceEpic(
  action$: Observable<ReduxFormInstanceServerCreate>,
  _: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/instance/SERVER_CREATE'),
    mergeMap(async (action) => {
      const {
        templateId,
        templateDefinitionId,
        instanceId,
        templateName,
        categoryName,
      } = action.payload;

      const response = await mutationCreateNewInstance(client, {
        instanceId,
        templateId,
        templateDefinitionId,
      });

      if (!response) {
        return errorCreating({
          instanceId,
          templateId,
          templateDefinitionId,
          templateName,
          categoryName,
        });
      }

      return serverLoad(instanceId);
    }),
  );
}

type CreateNewInstanceInputs = {
  instanceId: string;
  templateId: string;
  templateDefinitionId: string;
};

const mutationCreateNewInstance = async (
  client: IGraphQLClient,
  { instanceId, templateId, templateDefinitionId }: CreateNewInstanceInputs,
): Promise<boolean> => {
  try {
    const response = await client.mutate<IGraphQLCreateFormInstanceResponse>({
      mutation: createFormInstance,
      variables: { instanceId, templateId, templateDefinitionId },
    });

    if (!response.data?.forms.createFormInstance?.ok) {
      log.error(
        new Error(
          `Mutation failed to create form instance ${instanceId} for template ${templateId}`,
        ),
      );
      return false;
    }

    return true;
  } catch (err) {
    log.error(
      new Error(`An unexpected error occurred creating an instance ${instanceId} for template ${templateId}
  Error: ${err}`),
    );
    return false;
  }
};

interface IGraphQLCreateFormInstanceResponse {
  forms: {
    createFormInstance: {
      ok: boolean;
    };
  };
}

const createFormInstance = gql`
  mutation CreateFormInstance(
    $instanceId: ID!
    $templateId: ID!
    $templateDefinitionId: ID!
  ) {
    forms {
      createFormInstance(
        input: {
          formInstanceId: $instanceId
          formTemplateId: $templateId
          formTemplateDefinitionId: $templateDefinitionId
        }
      ) {
        ok
      }
    }
  }
`;
