import { getDistributionLists } from './distributionList.ts';
import { getPeople } from './people.ts';
import { getPeopleWithTeamMemberships } from './peopleWithTeamMemberships.ts';
import { getPermissions } from './permissions.ts';
import { getPerson } from './person.ts';
import { getPersonWithTeamMemberships } from './personWithTeamMemberships.ts';
import { getPositionTitles } from './positionTitles.ts';
import { getTeam } from './team.ts';
import { getTeamHierarchyLevels } from './teamHierarchyLevels.ts';
import { getTeamManagers } from './teamManagers.ts';
import { getTeamPersonMemberships } from './teamPersonMemberships.ts';
import { getTeams } from './teams.ts';

export const orgHierarchyQuery = {
  getDistributionLists,
  getPeople,
  getPeopleWithTeamMemberships,
  getPermissions,
  getPerson,
  getPersonWithTeamMemberships,
  getPositionTitles,
  getTeam,
  getTeamHierarchyLevels,
  getTeamManagers,
  getTeamPersonMemberships,
  getTeams,
};
