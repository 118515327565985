import { formsQuery } from '@se/data/forms/query/index.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { combineEpics, StateObservable } from 'redux-observable';
import { filter, map, mergeMap, Observable } from 'rxjs';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import { formBulkUploadClickThroughSlice } from './index.ts';

export const formBulkUploadClickThroughEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  loadSucceededClickThroughEpic,
  loadFailedClickThroughEpic,
  reloadClickThroughEpic,
);

function loadSucceededClickThroughEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(formBulkUploadClickThroughSlice.loadClickThrough.match),
    filter((action) => action.payload.type === 'Succeeded'),
    mergeMap(async (action) => {
      const createdInstancesResponse =
        await formsQuery.getFormsBulkUploadJobHistorySuccess(client, {
          jobId: action.payload.jobId,
          pageNumber: action.payload.loadNextPage
            ? state$.value.formBulkUploadClickThrough.currentPage + 1
            : 1,
          orderBys:
            state$.value.formBulkUploadClickThrough.createdInstances.orderBys,
        });

      return createdInstancesResponse.isSuccess
        ? formBulkUploadClickThroughSlice.loadedSucceededClickThrough({
            currentPage: createdInstancesResponse.data.pageInfo.currentPage,
            hasNextPage: createdInstancesResponse.data.pageInfo.hasNextPage,
            instances: createdInstancesResponse.data.instances,
          })
        : formBulkUploadClickThroughSlice.loadClickThroughFailed();
    }),
  );
}

function loadFailedClickThroughEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(formBulkUploadClickThroughSlice.loadClickThrough.match),
    filter((action) => action.payload.type === 'Failed'),
    mergeMap(async (action) => {
      const failedRowsResponse =
        await formsQuery.getFormsBulkUploadJobHistoryFailed(client, {
          jobId: action.payload.jobId,
          pageNumber: action.payload.loadNextPage
            ? state$.value.formBulkUploadClickThrough.currentPage + 1
            : 1,
          orderBys: state$.value.formBulkUploadClickThrough.failedRows.orderBys,
        });

      return failedRowsResponse.isSuccess
        ? formBulkUploadClickThroughSlice.loadedFailedClickThrough({
            currentPage: failedRowsResponse.data.pageInfo.currentPage,
            hasNextPage: failedRowsResponse.data.pageInfo.hasNextPage,
            rows: failedRowsResponse.data.rows,
          })
        : formBulkUploadClickThroughSlice.loadClickThroughFailed();
    }),
  );
}

function reloadClickThroughEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    filter(formBulkUploadClickThroughSlice.changeOrderBy.match),
    map(() =>
      formBulkUploadClickThroughSlice.loadClickThrough({
        jobId: state$.value.formBulkUploadClickThrough.jobId,
        type: state$.value.formBulkUploadClickThrough.type,
        loadNextPage: false,
      }),
    ),
  );
}
