import momentTz from 'moment-timezone';
import { FormAnswerByKey } from '../redux/form-instance/types.ts';
import {
  FormLineComponent,
  ShowOnAnswer,
  ShowOnReportingDateMonth,
} from '../types/types.ts';

export const isLineVisible = (
  line: FormLineComponent,
  answers: FormAnswerByKey,
  tenantTimezone: string,
) => {
  if (!line) return false;
  const showOnSwitch = line.showOnSwitch;
  if (showOnSwitch) return checkShowOnSwitch(answers, showOnSwitch);

  const showOnAnswer = line.showOnAnswer;
  if (showOnAnswer) return checkShowOnAnswer(answers, showOnAnswer);

  const showOnReportingDateMonth = line.showOnReportingDateMonth;
  if (showOnReportingDateMonth)
    return checkShowOnReportingDateMonth(
      answers,
      showOnReportingDateMonth,
      tenantTimezone,
    );

  return true;
};

export const checkShowOnAnswer = (
  answers?: FormAnswerByKey,
  showOnAnswer?: ShowOnAnswer,
) => {
  if (!showOnAnswer) return true;

  const answer = answers?.[showOnAnswer.id];
  if (!answer) return false;

  const conditionsArray = Array.isArray(showOnAnswer.values)
    ? showOnAnswer.values
    : [showOnAnswer.values];
  const conditions = conditionsArray.map((condition) => condition.toString());

  const isMultiCheckbox =
    answer.type === 'optionsAnswer' && answer.subType === 'CHECKBOX';

  return isMultiCheckbox
    ? matchesMultiSelectAnswer(answer.value, conditions)
    : conditions.includes(answer.value);
};

export const checkShowOnSwitch = (
  answers?: FormAnswerByKey,
  showOnSwitch?: string,
) => {
  if (!showOnSwitch) return true;
  return answers?.[showOnSwitch]?.value === 'true';
};

export const checkShowOnReportingDateMonth = (
  answers: FormAnswerByKey,
  showOnReportingDateMonth: ShowOnReportingDateMonth[],
  tenantTimezone: string,
) => {
  if (!showOnReportingDateMonth) return true;
  if (!tenantTimezone) return false;

  const reportingDate = answers?.reportingDate?.value;
  if (!reportingDate) return false;

  const reportingDateMonth = momentTz(reportingDate)
    .tz(tenantTimezone)
    .format('MMMM')
    .toLowerCase() as ShowOnReportingDateMonth;

  return showOnReportingDateMonth.includes(reportingDateMonth);
};

const matchesMultiSelectAnswer = (
  answerValue: string,
  matchAgainst: string[],
) => {
  const stripped = answerValue.startsWith('[')
    ? answerValue.substring(1, answerValue.length - 1)
    : answerValue;

  const answerValues = stripped?.split(',');
  if (!answerValues.length) return false;

  return matchAgainst.some((match) => answerValues.includes(match.toString()));
};
