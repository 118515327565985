import { ofType } from 'redux-observable';
import { Observable, map } from 'rxjs';
import { FormFileDeleted } from '../../form-file/types.ts';
import { deleteFileFromFormInstance } from './actions.ts';

/**
 * Links a newly uploaded file to an existing form instance.
 */
export function removeDeletedFileFromFormInstanceEpic(
  action$: Observable<FormFileDeleted>,
) {
  return action$.pipe(
    ofType('ui.forms/file/DELETED'),
    map((action) =>
      deleteFileFromFormInstance(
        action.payload.formInstanceId,
        action.payload.fileId,
      ),
    ),
  );
}
