import moment from 'moment';

export function toLocalDate(input: string) {
  return moment(input).local().toISOString();
}

export function formatDateString(
  date: string,
  withTime = true,
  defaultValue = '',
): string {
  const format = withTime ? 'D MMM YY, h:mm a' : 'D MMM YY';
  return date ? moment(date).format(format) : defaultValue;
}
