import { combineEpics } from 'redux-observable';
import { uploadToServer } from './epics.upload.ts';
import { copyFromFormInstanceEpic } from './epics.copyFromFormInstance.ts';
import { requestDownloadLink } from './epics.download.ts';
import { deleteDocumentFromInstance } from './epics.delete.ts';
import { FormReduxAction } from '../types.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';

export const epics = combineEpics<
  FormReduxAction,
  FormReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  uploadToServer,
  deleteDocumentFromInstance,
  copyFromFormInstanceEpic,
  requestDownloadLink,
);
