import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import { FormActionAuditEntry } from './actionAuditTypes.ts';
import { applyAuditEntryAggregations } from './actionAuditUtil.ts';

type FormActionAuditEventDto = {
  action: string;
  actionType: string;
  actionedAt: string;
  actionedBy: {
    id: string;
    name: string;
  };
  payload: Array<{ key: string; value: string }>;
};

export const getFormActionAudit = async (
  client: IGraphQLClient,
  actionId: string,
): Promise<QueryResult<FormActionAuditEntry[]>> => {
  try {
    const response = await client.query<{
      forms: { formActionAudit: FormActionAuditEventDto[] };
    }>({
      query: gql`
        query FormActionAudit($actionId: ID!) {
          forms {
            formActionAudit(id: $actionId) {
              action
              actionType
              actionedAt
              actionedBy {
                id
                name
              }
              payload {
                key
                value
              }
            }
          }
        }
      `,
      variables: { actionId },
      fetchPolicy: 'network-only',
    });

    if (!response.data.forms.formActionAudit) {
      log.error(
        `Failed to retrieve form action history for action ${actionId}`,
      );
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    const historyEntries = response.data.forms.formActionAudit.map(
      mapFormActionAuditEntry,
    );

    return {
      isSuccess: true,
      data: applyAuditEntryAggregations(historyEntries),
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query audit for action ${actionId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};

const mapFormActionAuditEntry = (
  serverEntry: FormActionAuditEventDto,
): FormActionAuditEntry => {
  const actionType =
    serverEntry.actionType as FormActionAuditEntry['actionType'];

  const payload = serverEntry.payload.reduce(
    (acc, { key, value }) => {
      acc[key] = value;
      return acc;
    },
    {} as Record<string, string>,
  );

  const verificationStatus =
    actionType === 'ActionVerificationStatusChanged'
      ? payload.status
      : undefined;

  return {
    action: serverEntry.action as FormActionAuditEntry['action'],
    actionType,
    actionedAt: serverEntry.actionedAt,
    actionedBy: serverEntry.actionedBy,
    payload,
    icon: getIcon(actionType, verificationStatus),
  } as FormActionAuditEntry;
};

const getIcon = (
  actionType: FormActionAuditEntry['actionType'],
  verificationStatus?: string,
) => {
  if (actionType === 'ActionAnswerChanged') return 'chatBubble';
  if (actionType === 'ActionAssignedToChanged') return 'person';
  if (actionType === 'ActionCancelled') return 'cancel';
  if (actionType === 'ActionCompleted') return 'check';
  if (actionType === 'ActionCreated') return 'checkCircle';
  if (actionType === 'ActionDescriptionChanged') return 'textFields';
  if (actionType === 'ActionDueByChanged') return 'notifications';
  if (actionType === 'ActionNoteAdded') return 'textFormat';
  if (actionType === 'ActionReverted') return 'refresh';
  if (actionType === 'ActionIssueChanged') return 'localLibrary';
  if (actionType === 'ActionGoalChanged') return 'myLocation';
  if (actionType === 'ActionVerificationNotesChanged') return 'textFormat';
  if (actionType !== 'ActionVerificationStatusChanged') return 'person';

  if (verificationStatus === 'Verified') return 'verifiedUser';
  if (verificationStatus === 'Returned') return 'undo';
  if (verificationStatus === 'Recheck') return 'redo';
  if (verificationStatus === 'Unverified') return 'safetyCheck';

  return 'person';
};
