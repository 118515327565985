import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';

export type ThemeType = 'DARK' | 'LIGHT';

export function textColor(theme: ThemeType) {
  switch (theme) {
    case 'DARK':
      return {
        ENABLED: COLORS.BLUE,
        DISABLED: '#888888',
      };

    case 'LIGHT':
      return {
        ENABLED: color.format(1),
        DISABLED: color.format(0.3),
      };

    default:
      throw new Error('Theme not supported');
  }
}

export function iconColor(theme: ThemeType) {
  return textColor(theme);
}
