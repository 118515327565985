import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { IOptionsAnswerLine, ISectionLine } from '../types/types.ts';
import { defaultId, getProp } from '../util/util.data.parse.ts';
import { OptionsDefinition } from './types/options.types.ts';

export function parseOptionsAnswer(
  item: OptionsDefinition,
  parentSection: ISectionLine,
  indices: number[],
): IOptionsAnswerLine | undefined {
  if (!item) return;

  const question = getProp(item, 'question');

  return {
    id: getProp(item, 'id') || defaultId(indices),
    parentId: parentSection.id,
    showOnAnswer: getProp(item, 'showOnAnswer'),
    showOnReportingDateMonth: item.showOnReportingDateMonth,
    showOnSwitch: getProp(item, 'showOnSwitch'),
    group: getProp(item, 'group')?.toString(),
    isRequired: getProp(item, 'isRequired', false),
    isEnabled: getProp(item, 'isEnabled', true),
    highlightOnHover: true,
    type: 'optionsAnswer',
    question,
    options: getProp(item, 'answers', [])?.map((option) => ({
      id: option.id?.toString(),
      label: option.label,
      issue: option?.issue
        ? {
            ...option?.issue,
            coachingRequirement: coachingRequirement(
              option?.issue?.coachingRequired,
            ),
            formQuestionText: str.removeMarkdownAndSpecialCharacters(question),
            formAnswerSelected: option.label,
            formSectionName: parentSection.title,
            notesLabel: option?.issue?.notes?.label,
            notesRequired: option?.issue.notes?.required ?? true,
            formLineId: item.id,
            parentSectionLineId: parentSection.id,
          }
        : undefined,
    })),
    direction: getProp(item, 'direction', 'vertical'),
    multiSelect: getProp(item, 'multiSelect', false),
    showIndex: false,
  };
}

const coachingRequirement = (coachingRequired: boolean) => {
  if (coachingRequired === true) return 'MANDATORY';
  if (coachingRequired === false) return 'RECOMMENDED';
  return 'NONE';
};
