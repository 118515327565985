/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  IListItemLabel,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { Switch } from '@seeeverything/ui.primitives/src/components/Switch/Switch.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { Transition } from '@seeeverything/ui.primitives/src/components/Transition/index.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { useCallback, useMemo } from 'react';
import { issueSlice } from '../../../redux/issue/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../../redux/store.ts';
import { IssueCause } from './IssueCause.tsx';

export interface IIssueCausesContainerProps {
  issueId: string;
  instanceId: string;
  lineId: string;
}

export const IssueCausesContainer: React.FC<IIssueCausesContainerProps> = ({
  issueId,
  instanceId,
  lineId,
}) => {
  const dispatch = useFormsDispatch();

  const actionPlan = useFormsSelector(
    (state) => state.formActionPlan.actionPlan[lineId],
  );

  const issueActions = useMemo(() => {
    if (!actionPlan?.plan) return [];
    if (actionPlan.isLoading) return [];
    if (actionPlan.loadError) return [];
    if (actionPlan.plan.hasSections === true) return [];
    return actionPlan.plan.actions.filter((a) => a.issueId === issueId);
  }, [actionPlan.isLoading, actionPlan.loadError, actionPlan.plan, issueId]);

  const instanceCanEdit = useFormsSelector((state) =>
    Boolean(state.formInstance.instances?.[instanceId]?.permissions.edit),
  );

  const specifyCauseAndCoachingSelected = useFormsSelector(
    (state) =>
      state.formIssue.issues.find((issue) => issue.id === issueId)?.coaching
        .specifyCauseAndCoaching.isSelected,
  );

  const canChangeSpecifyCauseAndCoaching = useFormsSelector(
    (state) =>
      state.formIssue.issues.find((issue) => issue.id === issueId)?.coaching
        .specifyCauseAndCoaching.canChange &&
      state.formInstance.instances?.[instanceId]?.permissions.edit,
  );

  const primaryCauseError = useFormsSelector(
    (state) =>
      state.formIssue.issues.find((issue) => issue.id === issueId)?.errors
        .coaching.primaryCause,
  );

  const actionsRequiredSelectionError = useFormsSelector(
    (state) =>
      state.formIssue.issues.find((issue) => issue.id === issueId)?.errors
        .coaching.actionsRequiredSelection,
  );

  const coachingIssueActionError = useFormsSelector(
    (state) =>
      state.formIssue.issues.find((issue) => issue.id === issueId)?.errors
        .coaching.coachingIssueAction,
  );

  const primaryCauseId = useFormsSelector(
    (state) =>
      state.formIssue.issues
        .find((issue) => issue.id === issueId)
        ?.issueCauses.causes.find((cause) => cause.isPrimary)?.issueCauseId,
  );

  const primaryCauseDetails = useFormsSelector(
    (state) =>
      state.formIssue.issues
        .find((issue) => issue.id === issueId)
        ?.issueCauses.causes.find((cause) => cause.isPrimary)?.notes,
  );

  const primaryCauseNotesError = useFormsSelector(
    (state) =>
      state.formIssue.issues.find((issue) => issue.id === issueId)?.errors
        .coaching.primaryCauseNotes,
  );

  const issueActionLabel = useFormsSelector(
    (state) => state.tenantState.tenant?.locale.label.issueAction,
  );

  const additionalActionsGuidanceText = useFormsSelector(
    (state) =>
      state.tenantState.tenant.locale.forms.closeTheLoop
        .issueCoachingPlanAdditionalActionsGuidanceText,
  );

  const addAction = useFormsSelector((state) => {
    const actionsRequired = state.formIssue?.issues?.find(
      (issue) => issue.id === issueId,
    )?.actionsRequired;

    if (actionsRequired === true) return 'Yes';
    if (actionsRequired === false) return 'No';
    return undefined;
  });

  const noOpenActionsWithNoActionSelectedError = useFormsSelector(
    (state) =>
      state.formIssue.issues.find((issue) => issue.id === issueId)?.errors
        .coaching.noOpenActionsWithNoActionSelected,
  );

  const tenantCauses = useFormsSelector(
    (state) => state.formIssue?.issueCauses,
  );

  const primaryCause = useMemo(
    () => tenantCauses?.find((cause) => cause.id === primaryCauseId),
    [primaryCauseId, tenantCauses],
  );

  const causes = useMemo(
    () =>
      tenantCauses
        ?.filter((cause) => cause.isActive || cause.id === primaryCauseId)
        .map((cause) => ({
          id: cause.id,
          value: cause.label,
          content: { text: cause.label },
        })),
    [primaryCauseId, tenantCauses],
  );

  const handleSelectAnswerChange = useCallback(
    (to: ISelectionListItem<IListItemLabel>) => {
      const selectedCause = causes.find((cause) => cause.id === to.id);
      if (!selectedCause) return;

      dispatch(
        issueSlice.setPrimaryCause({
          index: tenantCauses.findIndex(
            (tenantCause) => tenantCause.id === to.id,
          ),
          issueId,
          issueCauseId: selectedCause.id,
          label: selectedCause.value,
          notes: primaryCauseDetails,
          instanceId,
        }),
      );
    },
    [causes, dispatch, tenantCauses, issueId, primaryCauseDetails, instanceId],
  );

  const handlePrimaryCauseNotesChange = useCallback(
    (to: string) => {
      dispatch(
        issueSlice.setPrimaryCauseNotes({
          issueId,
          notes: to,
          instanceId,
        }),
      );
    },
    [dispatch, issueId, instanceId],
  );

  const handleCoachingConversationChange = useCallback(
    (to: string) => {
      dispatch(
        issueSlice.setCoachingConversation({
          issueId,
          coachingConversation: to,
          instanceId,
        }),
      );
    },
    [dispatch, instanceId, issueId],
  );

  const handleNoAdditionalActionReasonChange = useCallback(
    (to: string) => {
      dispatch(
        issueSlice.setPrimaryCauseNoActionsReason({
          issueId,
          noActionsReason: to,
          instanceId,
        }),
      );
    },
    [dispatch, instanceId, issueId],
  );

  const toggleActionsRequired = useCallback(
    (to: string) => {
      dispatch(
        issueSlice.setPrimaryCauseAdditionalAction({
          issueId,
          actionsRequired: to === 'Yes',
          instanceId,
        }),
      );
    },
    [dispatch, instanceId, issueId],
  );

  const handleSecondaryCausesClick = useCallback(() => {
    dispatch(issueSlice.displaySecondaryCausesDialog({ issueId, instanceId }));
  }, [dispatch, issueId, instanceId]);

  const coachingConversation = useFormsSelector(
    (state) =>
      state.formIssue.issues.find((issue) => issue.id === issueId)
        ?.coachingConversation ?? '',
  );

  const coachingConversationError = useFormsSelector(
    (state) =>
      state.formIssue.issues.find((issue) => issue.id === issueId)?.errors
        .coaching.coachingConversation,
  );

  const noActionsReason = useFormsSelector(
    (state) =>
      state.formIssue.issues.find((issue) => issue.id === issueId)
        ?.noActionsReason,
  );

  const noActionsReasonError = useFormsSelector(
    (state) =>
      state.formIssue.issues.find((issue) => issue.id === issueId)?.errors
        .coaching.noActionsReason,
  );

  const selectedSecondaryCausesLabels = useFormsSelector((state) =>
    state.formIssue.issues
      .find((issue) => issue.id === issueId)
      ?.issueCauses.causes.filter(
        (cause) => !cause.isPrimary && cause.isSelected,
      )
      ?.map((cause) => cause.label),
  );

  const issue = useFormsSelector((state) =>
    state.formIssue.issues.find((formIssue) => formIssue.id === issueId),
  );

  const toggleCauseDefined = useCallback(() => {
    dispatch(
      issueSlice.toggleDefineCauseAndCoachingSelected({ instanceId, issueId }),
    );
  }, [dispatch, instanceId, issueId]);

  if (!issue || issue.coaching.requirement === 'NONE') return null;

  const computedStyles = {
    switchDefineCause: css({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: canChangeSpecifyCauseAndCoaching ? 'pointer' : undefined,
      ':hover': {
        borderRadius: 4,
        padding: '4px 12px',
        margin: '-4px -12px',
        backgroundColor: color.format(-0.06),
      },
    }),
  };

  const elRecommendedCoaching = issue.coaching.requirement ===
    'RECOMMENDED' && (
    <div
      css={computedStyles.switchDefineCause}
      onClick={
        canChangeSpecifyCauseAndCoaching ? toggleCauseDefined : undefined
      }
    >
      <Text cursor={'inherit'}>
        {
          'Do you want to define a cause for this issue and outline coaching activity?'
        }
      </Text>
      <Switch
        isChecked={specifyCauseAndCoachingSelected}
        isEnabled={instanceCanEdit && canChangeSpecifyCauseAndCoaching}
        tooltip={
          !canChangeSpecifyCauseAndCoaching && instanceCanEdit
            ? 'This cannot be unchecked as there are cause and/or coaching activities defined.'
            : undefined
        }
      />
    </div>
  );

  return (
    <div css={styles.base}>
      {elRecommendedCoaching}
      {
        <Transition.Collapse
          in={specifyCauseAndCoachingSelected}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <IssueCause
            actions={issueActions}
            actionsRequiredSelectionError={actionsRequiredSelectionError}
            additionalActionsGuidanceText={additionalActionsGuidanceText}
            addAction={addAction}
            canEdit={instanceCanEdit}
            causes={causes}
            coachingConversation={coachingConversation}
            coachingConversationError={coachingConversationError}
            coachingIssueActionError={coachingIssueActionError}
            onCoachingConversationChange={handleCoachingConversationChange}
            onSecondaryCausesClick={handleSecondaryCausesClick}
            onPrimaryCauseNotesChange={handlePrimaryCauseNotesChange}
            onNoAdditionalActionReasonChange={
              handleNoAdditionalActionReasonChange
            }
            onSelectAnswerChange={handleSelectAnswerChange}
            instanceId={instanceId}
            issueActionLabel={issueActionLabel}
            issueId={issueId}
            noActionsReason={noActionsReason}
            noActionsReasonError={noActionsReasonError}
            noOpenActionsWithNoActionSelectedError={
              noOpenActionsWithNoActionSelectedError
            }
            primaryCauseNotes={primaryCauseDetails}
            primaryCauseNotesError={primaryCauseNotesError}
            primaryCauseId={primaryCauseId}
            primaryCauseLabel={primaryCause?.label}
            primaryCauseReasonLabel={primaryCause?.reasonLabel}
            primaryCauseError={primaryCauseError}
            selectedSecondaryCausesLabels={selectedSecondaryCausesLabels}
            toggleActionsRequired={toggleActionsRequired}
          />
        </Transition.Collapse>
      }
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    padding: '0 12px',
  }),
};
