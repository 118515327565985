/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export interface IBulletAddProps {
  style?: SerializedStyles;
}

/**
 * A bullet that renders as a [+] icon.
 */
export const BulletAdd = ({ style }: IBulletAddProps) => {
  const computedStyles = useMemo(
    () => ({
      base: css(
        {
          width: 23,
          height: 23,
        },
        style,
      ),
      iconBg: css({
        position: 'absolute',
        inset: '2px auto auto 2px',
        width: 19,
        height: 19,
        borderRadius: 23,
        backgroundColor: color.format(1),
      }),
      icon: {
        position: 'absolute',
      } as const,
    }),
    [style],
  );

  return (
    <div css={computedStyles.base}>
      <div css={computedStyles.iconBg} />
      <Icons.plus
        fill={color.format(-0.2)}
        size={23}
        style={computedStyles.icon}
      />
    </div>
  );
};
