/** @jsxImportSource @emotion/react */
import { FormListItemWrapped } from '../components/FormListItem/FormListItemWrapped.tsx';
import { SpeechBlockContainer } from '../components/SpeechBlock/SpeechBlockContainer.tsx';
import { useHandleAnswerVisibility } from '../hooks/useHandleAnswerVisibility.ts';
import { ISpeechBlockLine } from '../types/types.ts';

export interface ISpeechBlockFactoryContainerProps {
  instanceId: string;
  isPdfExport: boolean;
  line: ISpeechBlockLine;
}
export const SpeechBlockFactoryContainer: React.FC<
  ISpeechBlockFactoryContainerProps
> = ({ instanceId, isPdfExport, line }) => {
  const isVisible = useHandleAnswerVisibility({
    instanceId,
    questionKeys: [],
    showOnAnswer: line.showOnAnswer,
    showOnReportingDateMonth: line.showOnReportingDateMonth,
    showOnSwitch: line.showOnSwitch,
  });

  return (
    <FormListItemWrapped
      id={line.id}
      parentId={line.parentId}
      key={line.id}
      highlightOnHover={line.highlightOnHover}
      bullet={'person'}
      bulletTop={9}
      marginBottom={15}
      isVisible={isVisible}
      isPdfExport={isPdfExport}
    >
      <SpeechBlockContainer
        canHide={line.canHide}
        id={line.id}
        instanceId={instanceId}
        isPdfExport={isPdfExport}
        key={`speechBlock-${instanceId}-${line.id}`}
        markdown={line.text}
      />
    </FormListItemWrapped>
  );
};
