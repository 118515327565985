// eslint-disable-next-line import/extensions
import { setContext } from '@apollo/client/link/context';
import { ApolloLink } from '@apollo/client';

export type ResolveHeadersType = {
  [key: string]: () => Promise<string | number | undefined>;
};

/**
 * Executes the supplied resolver functions to resolve http headers.
 * setContext is supplied by Apollo client to attach any custom data to
 * the operation.
 */
export const resolveCustomHeaders = (resolvers?: ResolveHeadersType) =>
  setContext(async (query) =>
    resolvers
      ? Object.entries(resolvers).reduce(
          async (acc, [headerKey, resolveHeaderValue]) => {
            // Query variables should override standard headers.
            const headerFromVariables = query.variables?.[headerKey];

            const { customHeaders } = await acc;
            const headerValue =
              headerFromVariables ?? (await resolveHeaderValue())?.toString();

            return headerValue
              ? {
                  customHeaders: { ...customHeaders, [headerKey]: headerValue },
                }
              : { customHeaders };
          },
          Promise.resolve({ customHeaders: {} }),
        )
      : Promise.resolve({ customHeaders: {} }),
  );

/**
 * Apollo link for attaching custom headers to the request.
 * `setContext` is responsible for resolving these asynchronously.
 */
export const createHeadersLink = () =>
  new ApolloLink((operation, forward) => {
    const { customHeaders } = operation.getContext();
    operation.setContext({ headers: customHeaders });
    return forward(operation);
  });
