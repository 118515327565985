import fuzzy from 'fuzzy';

/**
 * Performs a fuzzy text match.
 */
export function isFuzzyMatch(
  text: string,
  matchAgainst: string[] | string | undefined | null,
) {
  if (matchAgainst === undefined || matchAgainst === null) {
    return false;
  }

  const matchAgainstArray = Array.isArray(matchAgainst)
    ? matchAgainst.filter((match) => match && match !== null) // Remove null or undefined items.
    : [matchAgainst];

  if (!matchAgainstArray.length) {
    return false;
  }

  return fuzzy.filter(text, matchAgainstArray).length > 0;
}
