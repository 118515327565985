import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import gql from 'graphql-tag';
import { ofType, StateObservable } from 'redux-observable';
import { concatAll, filter, from, map, mergeMap, Observable, of } from 'rxjs';
import { FormAnswer } from '../../types/types.ts';
import { formInstanceAnswerChange } from '../form-instance/answer/actions.ts';
import { clearErrorsForAnswerKeys } from '../form-instance/instance/actions.ts';
import { ReduxFormInstanceAnswerChange } from '../form-instance/types.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import { formScoreResultSaveSuccess, resultChangedError } from './actions.ts';
import { ReduxFormScoreResultChanged } from './types.ts';

export function saveResultEpic(
  action$: Observable<ReduxFormInstanceAnswerChange>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/instance/ANSWER/CHANGE'),
    filter(
      (action) =>
        action.payload.change.type !== 'DELETE' &&
        action.payload.change.toAnswer.lineId === 'scoreOverrule',
    ),
    mergeMap(async (action) => {
      const instanceId = action.payload.instanceId;
      const result = action.payload.change.toAnswer.value;

      try {
        const response = await client.mutate<{
          forms: { updateManualOutcome: { ok: boolean } };
        }>({
          mutation: gql`
            mutation UpdateManualOutcome(
              $instanceId: ID!
              $outcome: ScoringOutcome!
            ) {
              forms {
                updateManualOutcome(
                  input: { formInstanceId: $instanceId, outcome: $outcome }
                ) {
                  ok
                }
              }
            }
          `,
          variables: { instanceId, outcome: result },
        });
        if (response.data.forms.updateManualOutcome.ok)
          return of(formScoreResultSaveSuccess(instanceId));
      } catch (err) {
        log.error(
          new Error(
            `GraphQL error occurred while trying to save score override ${err}`,
          ),
        );
      }

      return from([resultChangedError(instanceId)]);
    }),
    concatAll(),
  );
}

export function validateAndSaveScoreResultEpic(
  action$: Observable<ReduxFormScoreResultChanged>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    ofType('ui.forms/score/RESULT_CHANGED'),
    filter(({ payload }) => Boolean(payload.instanceId)),
    map(({ payload }) => {
      const { to, instanceId } = payload;
      const existingScoreOverruleAnswer =
        state$.value.formInstance.instances[instanceId]?.answers.scoreOverrule;
      const newAnswer: FormAnswer = existingScoreOverruleAnswer
        ? {
            ...existingScoreOverruleAnswer,
            value: to,
          }
        : {
            id: uuid.generate(),
            value: to,
            type: 'inputs',
            displayValue: to,
            lineId: 'scoreOverrule',
          };

      return from([
        clearErrorsForAnswerKeys(
          instanceId,
          ['scoreOverrule'],
          'SCORE_OVERRULE',
        ),
        formInstanceAnswerChange(
          instanceId,
          existingScoreOverruleAnswer
            ? {
                type: 'UPDATE',
                fromAnswer: existingScoreOverruleAnswer,
                toAnswer: newAnswer,
              }
            : {
                type: 'CREATE',
                toAnswer: newAnswer,
                creationPreviouslyFailed: false,
              },
          true,
        ),
      ]);
    }),
    concatAll(),
  );
}
