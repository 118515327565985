import { IIssueCoachingPlanLine } from '../types/types.ts';

export function parseIssueCoachingPlan(): IIssueCoachingPlanLine[] {
  return [
    {
      id: 'issueCoachingPlan',
      type: 'issueCoachingPlan',
      highlightOnHover: false,
      subSection: false,
      showIndex: false,
    },
    {
      id: 'issueCoachingPlan-coachingRequired',
      type: 'issueCoachingPlan',
      showIndex: true,
      title: 'Coaching Required',
      highlightOnHover: false,
      subSection: true,
    },
    {
      id: 'issueCoachingPlan-coachingRecommended',
      type: 'issueCoachingPlan',
      showIndex: true,
      title: 'Coaching Recommended',
      highlightOnHover: false,
      subSection: true,
    },
  ];
}
