import { is } from 'ramda';
import {
  IDateAnswerLine,
  IDropdownAnswerLine,
  IInputsLine,
  INumberAnswerLine,
  ISectionLine,
  ISelectAnswerLine,
  ITextAnswerLine,
  InputItem,
} from '../types/types.ts';
import { defaultId, getProp } from '../util/util.data.parse.ts';
import { isSelectAnswerDefinition } from '../util/util.typeGuards.ts';
import {
  DateAnswerDefinition,
  DropdownAnswerDefinition,
  InputDefinition,
  InputFieldsDefinition,
  InputsDefinition,
  NumberAnswerDefinition,
  PreviousAnswersDefinition,
  SelectAnswerDefinition,
  TextAnswerDefinition,
} from './types/input.types.ts';

export function parseInputs(
  item: InputsDefinition,
  parentSection: ISectionLine,
  indices: number[],
): IInputsLine | undefined {
  if (!item) return;

  const inputs = (
    (<InputFieldsDefinition>item).fields ?? [<InputDefinition>item]
  ).map((input, index) => toInput(input, parentSection, [...indices, index]));

  return {
    id: getProp(item, 'id', `${indices}`),
    parentId: parentSection.id,
    type: 'inputs',
    inputs,
    showOnAnswer: getProp(item, 'showOnAnswer'),
    showOnReportingDateMonth: item.showOnReportingDateMonth,
    showOnSwitch: getProp(item, 'showOnSwitch'),
    highlightOnHover: true,
    showIndex: false,
  };
}

const toInput = (
  item: InputDefinition,
  parentSection: ISectionLine,
  indices: number[],
): InputItem => {
  if (isSelectAnswerDefinition(item))
    return parseSelectAnswer(item, parentSection, indices);
  if (item.kind === 'date')
    return parseDateAnswer(item, parentSection, indices);
  if (item.kind === 'number')
    return parseNumberAnswer(item, parentSection, indices);

  const dropdownListName = getProp(
    <DropdownAnswerDefinition>item,
    'dropdownListName',
  )?.toUpperCase();

  return dropdownListName
    ? parseDropdownAnswer(
        <DropdownAnswerDefinition>item,
        parentSection,
        indices,
      )
    : parseTextAnswer(<TextAnswerDefinition>item, parentSection, indices);
};

const parseSelectAnswer = (
  item: SelectAnswerDefinition,
  parentSection: ISectionLine,
  indices: number[],
): ISelectAnswerLine => ({
  parentId: parentSection.id,
  showOnAnswer: getProp(item, 'showOnAnswer'),
  showOnReportingDateMonth: item.showOnReportingDateMonth,
  showOnSwitch: getProp(item, 'showOnSwitch'),
  group: getProp(item, 'group')?.toString(),
  isRequired: getProp(item, 'isRequired', false),
  isEnabled: getProp(item, 'isEnabled', true),
  highlightOnHover: true,
  id: getProp(item, 'id', `select-${defaultId(indices)}`),
  type: 'selectAnswer',
  helperText: getProp(item, 'hintText'),
  floatingText: getProp(item, 'label'),
  options: parseSelectAnswerOptions(item),
  showIndex: false,
});

const parseDateAnswer = (
  item: DateAnswerDefinition,
  parentSection: ISectionLine,
  indices: number[],
): IDateAnswerLine => ({
  parentId: parentSection.id,
  showOnAnswer: getProp(item, 'showOnAnswer'),
  showOnReportingDateMonth: item.showOnReportingDateMonth,
  showOnSwitch: getProp(item, 'showOnSwitch'),
  group: getProp(item, 'group')?.toString(),
  isRequired: getProp(item, 'isRequired', false),
  isEnabled: getProp(item, 'isEnabled', true),
  highlightOnHover: true,
  id: getProp(item, 'id', `date-${defaultId(indices)}`),
  type: 'dateAnswer',
  floatingText: getProp(item, 'hintText'),
  allowPastDates: getProp(item, 'allowPastDates', true),
  showIndex: false,
});

const parseTextAnswer = (
  item: TextAnswerDefinition,
  parentSection: ISectionLine,
  indices: number[],
): ITextAnswerLine => ({
  floatingText: getProp(item, 'label'),
  group: getProp(item, 'group')?.toString(),
  helperText: getProp(item, 'hintText'),
  icon: getProp(item, 'icon'),
  id: getProp(item, 'id', `txt-${defaultId(indices)}`),
  isEnabled: getProp(item, 'isEnabled', true),
  isRequired: getProp(item, 'isRequired', false),
  parentId: parentSection.id,
  multiline: getProp(item, 'multiline', true),
  previousAnswers: parsePreviousAnswers(
    getProp(item, 'previousAnswers') || getProp(item, 'hasPreviousAnswers'),
  ),
  showIndex: false,
  showOnAnswer: getProp(item, 'showOnAnswer'),
  showOnReportingDateMonth: item.showOnReportingDateMonth,
  showOnSwitch: getProp(item, 'showOnSwitch'),
  type: 'textAnswer',
});

const parseNumberAnswer = (
  item: NumberAnswerDefinition,
  parentSection: ISectionLine,
  indices: number[],
): INumberAnswerLine => ({
  parentId: parentSection.id,
  showOnAnswer: getProp(item, 'showOnAnswer'),
  showOnReportingDateMonth: item.showOnReportingDateMonth,
  showOnSwitch: getProp(item, 'showOnSwitch'),
  group: getProp(item, 'group')?.toString(),
  isRequired: getProp(item, 'isRequired', false),
  isEnabled: getProp(item, 'isEnabled', true),
  highlightOnHover: true,
  id: getProp(item, 'id', `number-${defaultId(indices)}`),
  type: 'numberAnswer',
  helperText: getProp(item, 'hintText'),
  floatingText: getProp(item, 'label'),
  icon: getProp(item, 'icon'),
  multiline: getProp(item, 'multiline'),
  showIndex: false,
  previousAnswers: parsePreviousAnswers(
    getProp(item, 'previousAnswers') || getProp(item, 'hasPreviousAnswers'),
  ),
});

const parseSelectAnswerOptions = (item: SelectAnswerDefinition) => {
  const options = getProp(item, 'options', []);
  return options.map((option) => {
    if (is(String, option)) return { id: option, label: option };

    return {
      id: option.id,
      label: option.label,
      icon: option.icon,
      description: option.description,
    };
  });
};

const parsePreviousAnswers = (item?: PreviousAnswersDefinition | boolean) => ({
  isEnabled: Boolean(item),
  fromDifferentTemplate:
    item && !is(Boolean, item)
      ? {
          template: getProp(item, 'template'),
          inputId: getProp(item, 'field'),
        }
      : undefined,
});

const parseDropdownAnswer = (
  item: DropdownAnswerDefinition,
  parentSection: ISectionLine,
  indices: number[],
): IDropdownAnswerLine => {
  const isSubject = item.id?.toLowerCase() === 'subject';
  const isReviewer = item.id?.toLowerCase() === 'assignedTo';

  const itemId = () => {
    if (isSubject) return 'subject';
    if (isReviewer) return 'assignedTo';
    return getProp(item, 'id', `dropdown-${defaultId(indices)}`);
  };

  return {
    parentId: parentSection.id,
    showOnAnswer: getProp(item, 'showOnAnswer'),
    showOnReportingDateMonth: item.showOnReportingDateMonth,
    showOnSwitch: getProp(item, 'showOnSwitch'),
    group: getProp(item, 'group')?.toString(),
    isRequired: isSubject || isReviewer || getProp(item, 'isRequired', false),
    isEnabled: getProp(item, 'isEnabled', true),
    highlightOnHover: true,
    id: itemId(),
    type: 'dropdownAnswer',
    helperText: getProp(item, 'hintText'),
    floatingText: getProp(item, 'label'),
    showIndex: false,
    dropdownListName: getProp(item, 'dropdownListName').toUpperCase() as
      | 'PEOPLE'
      | 'TEAMS',
  };
};
