import { IAttendanceLine } from '../types/types.ts';
import { AttendanceDefinition } from './types/attendance.types.ts';

export function parseAttendance(item: AttendanceDefinition): IAttendanceLine {
  return {
    id: 'attendance',
    type: 'attendance',
    showIndex: item.isEnabled ?? true,
    title: item.title ?? 'Attendance',
    isEnabled: item.isEnabled ?? true,
    trackAttendanceHintText: item.trackAttendanceHintText,
    mandatoryFollowUp: item.mandatoryFollowUp ?? false,
    mandatoryFollowUpHintText: item.mandatoryFollowUpHintText,
    highlightOnHover: false,
  };
}
