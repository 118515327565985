/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';
import { ReactNode, useRef } from 'react';
import { useOutsideAlerter } from '../../hooks/useOutsideAlerter.ts';

export interface IOutsideAlerterProps {
  onClickedOutside?: () => void;
  style?: SerializedStyles;
  ignoreClassNames?: string[];
  children: ReactNode;
}

/**
 * Provides a wrapper for notifying on mouse clicked outside of
 * its children.
 */
export const OutsideAlerter: React.FC<IOutsideAlerterProps> = ({
  onClickedOutside,
  ignoreClassNames,
  children,
  style,
}) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onClickedOutside, ignoreClassNames);

  return (
    <div css={style} ref={wrapperRef}>
      {children}
    </div>
  );
};
