/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { OutsideAlerter } from '@seeeverything/ui.primitives/src/components/OutsideAlerter/OutsideAlerter.tsx';
import { TextLink } from '@seeeverything/ui.primitives/src/components/TextLink/TextLink.tsx';
import { useCallback, useState } from 'react';

export interface ILinkControlsProps {
  canReply: boolean;
  canDelete: boolean;
  onReplyClick: () => void;
  onDeleteClick: () => void;
}

/**
 * Controls displayed under a comment.
 */
export const LinkControls: React.FC<ILinkControlsProps> = ({
  canReply,
  canDelete,
  onReplyClick,
  onDeleteClick,
}) => {
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const handleOutsideClick = useCallback(
    () => setIsConfirmingDelete(false),
    [],
  );
  const showDeleteConfirmation = useCallback(
    () => setIsConfirmingDelete(true),
    [],
  );

  const elDelete =
    canDelete &&
    (isConfirmingDelete ? (
      <OutsideAlerter onClickedOutside={handleOutsideClick}>
        <TextLink size={14} onClick={onDeleteClick}>
          {'Click to confirm'}
        </TextLink>
      </OutsideAlerter>
    ) : (
      <TextLink size={14} onClick={showDeleteConfirmation}>
        {'Delete'}
      </TextLink>
    ));
  return (
    <div css={styles.base}>
      {canReply && (
        <TextLink size={14} onClick={onReplyClick}>
          {'Reply'}
        </TextLink>
      )}
      {elDelete}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    alignItems: 'center',
    gap: 20,
  }),
};
