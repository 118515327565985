/** @jsxImportSource @emotion/react */
import { useState } from 'react';

/**
 * Hook for assisting in handling mouseEnter/mouseLeave events.
 * @example
 * const [isMouseOver, handleMouseEnter, handleMouseLeave] = useMouseOver();
 * return (
 *  <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
 *    {`Mouse over: ${isMouseOver}`}
 *  </div>
 * );
 */
export function useMouseOver(): [boolean, () => void, () => void] {
  const [isMouseOver, setMouseOver] = useState(false);

  const handleMouseEnter = () => setMouseOver(true);
  const handleMouseLeave = () => setMouseOver(false);

  return [isMouseOver, handleMouseEnter, handleMouseLeave];
}
