import { log } from '@seeeverything/ui.util/src/log/log.ts';
import {
  CommonScoredDefinition,
  ScoredAnswerDefinition,
} from '../../parse/types/common.types.ts';
import { SelectAnswerDefinition } from '../../parse/types/input.types.ts';
import {
  NumberSliderDefinition,
  NumberSliderItemDefinition,
} from '../../parse/types/numberSlider.types.ts';
import {
  OptionsAnswerDefinition,
  OptionsDefinition,
} from '../../parse/types/options.types.ts';
import {
  SliderDefinition,
  SliderItemDefinition,
} from '../../parse/types/slider.types.ts';
import {
  FormTemplateItemDefinition,
  ScoringItem,
  SectionItem,
} from '../../parse/types/template.types.ts';
import {
  FormAnswerByKey,
  IFormInstancePermissions,
} from '../form-instance/types.ts';
import { GlobalFormsState } from '../store.ts';
import { FormResult, FormScore, IFormScoreState } from './types.ts';

const SCORABLE_QUESTION_KEYS = [
  'options',
  'numberSlider',
  'slider',
  'text',
] as const;

export const toFormScore = (
  template: FormTemplateItemDefinition[],
  answers: FormAnswerByKey,
  permissions: IFormInstancePermissions,
): FormScore | undefined => {
  if (!template?.length) return;

  const scoringDefinition = template.find(
    (item: ScoringItem) => item.scoring?.isEnabled === true,
  ) as ScoringItem;
  if (!scoringDefinition) return;

  if (
    scoringDefinition.scoring.passMark === undefined ||
    scoringDefinition.scoring.failMaximumScore === undefined
  ) {
    log.error(
      'Scoring on invalid definition, `passMark` and `failMaximumScore` must be defined',
    );
    return undefined;
  }

  const sections = template
    .filter((item: SectionItem) => Boolean(item.section))
    .map((item: SectionItem) => item.section);

  const scorableQuestions = sections.reduce((acc, section) => {
    const scoredQuestions =
      section.questions
        ?.filter((question) =>
          SCORABLE_QUESTION_KEYS.some((key) => {
            const scorableQuestion = (question as any)[key];
            return (
              scorableQuestion?.isScored === true ||
              scorableQuestion?.fields?.some(
                (field: SelectAnswerDefinition) => field.isScored === true,
              )
            );
          }),
        )
        .flatMap((question) => {
          const questionType = SCORABLE_QUESTION_KEYS.find(
            (key) => (question as any)[key] !== undefined,
          ) as string;
          const scoredQuestion = (question as any)[questionType];

          const isSingleScoredQuestion = !scoredQuestion.fields;
          if (isSingleScoredQuestion) {
            return scoredQuestion;
          }

          return scoredQuestion.fields.filter(
            (field: SelectAnswerDefinition) => field.isScored === true,
          );
        }) ?? [];

    return [...acc, ...scoredQuestions];
  }, []);

  const enrichWithScoreData = (key: string) => {
    const questionForAnswer = scorableQuestions?.find(
      (question) => question?.id === key,
    );

    const answerOnInstance = answers[key];

    if (!questionForAnswer) {
      return answerOnInstance;
    }

    const templateAnswers = ((questionForAnswer as OptionsDefinition).answers ||
      (questionForAnswer as NumberSliderDefinition).items ||
      (questionForAnswer as SliderDefinition).marks ||
      (questionForAnswer as SelectAnswerDefinition).options) as Array<
      | OptionsAnswerDefinition
      | NumberSliderItemDefinition
      | SliderItemDefinition
      | SelectAnswerDefinition
    >;

    if (!templateAnswers) {
      return answerOnInstance;
    }

    const answerDefinitionFromTemplate = templateAnswers.find(
      (templateAnswer) =>
        answerOnInstance?.value !== undefined &&
        templateAnswer.id?.toString() === answerOnInstance.value?.toString(),
    );

    const doesAnswerDefinitionHaveId = Boolean(
      answerDefinitionFromTemplate?.id,
    );
    if (
      !doesAnswerDefinitionHaveId &&
      (questionForAnswer as CommonScoredDefinition).isScored
    ) {
      log.warn(
        `No ID found for answer '${key}' on question '${questionForAnswer.id}'. Scoring will not be calculated `,
      );
      return answerOnInstance;
    }

    return {
      ...answerOnInstance,
      ...answerDefinitionFromTemplate,
    };
  };

  const answersWithScores = Object.keys(answers).reduce((acc, key) => {
    if (!scorableQuestions.some((question) => question?.id === key)) {
      return acc;
    }

    const scoredAnswer = enrichWithScoreData(key) as ScoredAnswerDefinition;
    if (!scoredAnswer) return acc;

    if (scoredAnswer.weight === undefined || scoredAnswer.score === undefined) {
      return acc;
    }

    return [...acc, scoredAnswer];
  }, []);

  const currentScorePoints = answersWithScores.reduce(
    (acc, answer) => acc + (answer.score as number),
    0,
  );

  const currentWeightPoints = answersWithScores.reduce(
    (acc, answer) => acc + (answer.weight as number),
    0,
  );

  const percentageCorrect =
    currentWeightPoints > 0 ? currentScorePoints / currentWeightPoints : -1;

  const instantFailAnswerCount = answersWithScores.reduce(
    (acc, answer) => acc + (answer.failOnSelect === true ? 1 : 0),
    0,
  );

  const passRate = scoringDefinition.scoring.passMark.value / 100.0;

  const percentageScore = Math.min(
    percentageCorrect,
    instantFailAnswerCount > 0
      ? scoringDefinition.scoring.failMaximumScore / 100.0
      : 100,
  );

  const result: FormResult =
    percentageScore >= passRate
      ? FormResult.Pass
      : percentageScore !== -1
        ? FormResult.Fail
        : FormResult.NotApplicable;

  const canViewFormScoreBreakdown =
    permissions.assignedToSignOff || permissions.assignedToRevertSignOff;

  return {
    isScorable: Boolean(scoringDefinition.scoring.isEnabled),
    isBreakdownViewable: Boolean(
      scoringDefinition.scoring.isViewingAllowed && canViewFormScoreBreakdown,
    ),
    passRate,
    failMaximumScore: scoringDefinition.scoring.failMaximumScore / 100.0,
    totalScorableQuestions: scorableQuestions.length,
    answeredQuestions: answersWithScores.length,
    currentScorePoints,
    currentWeightPoints,
    percentageScore,
    instantFailAnswerCount,
    result,
    rawPercentageScore: percentageCorrect,
  };
};

export const getScoreState = (
  state: GlobalFormsState,
): IFormScoreState | undefined =>
  state.formScore.score?.isScorable ? state.formScore : undefined;
