import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { GoalAdditionalField } from '@seeeverything/ui.util/src/redux/tenant/types.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import gql from 'graphql-tag';
import { StateObservable } from 'redux-observable';
import { Observable, concatAll, filter, from, mergeMap, of } from 'rxjs';
import { dueDateForDay } from '../../util/util.instance.ts';
import { formActionPlanSlice } from '../formActionPlan/index.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import * as formEditGoalSlice from './formEditGoalSlice.ts';

export function updateGoalOnServerEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(formEditGoalSlice.updateGoal.match),
    filter(() => Boolean(state$.value.formEditGoal.goalId)),
    mergeMap(async (action) => {
      const {
        initialGoal,
        newDescription,
        newDueBy,
        newAssignedTo,
        note,
        newAdditionalFields,
        newStatus,
        source,
      } = action.payload;
      const goalId = state$.value.formEditGoal.goalId;
      const instanceId = source.type === 'FORM' ? source.instanceId : undefined;
      const timezone = state$.value.tenantState.tenant.configuration.timezone;

      try {
        await Promise.all(
          [
            updateDescriptionIfChanged(client, goalId, newDescription),
            updateDueDateIfChanged(client, goalId, timezone, newDueBy),
            updateGoalAssignedToIfChanged(client, goalId, newAssignedTo?.id),
            addNoteIfDefined(client, goalId, note),
            ...updateAdditionFieldsIfChanged(
              client,
              goalId,
              newAdditionalFields,
            ),
          ].filter(Boolean),
        );

        if (newStatus === 'Open' || newStatus === 'Overdue')
          await reopenGoal(client, goalId);

        if (newStatus === 'Completed')
          await completeGoal(client, goalId, instanceId);

        if (newStatus === 'Cancelled')
          await cancelGoal(client, goalId, instanceId);

        return from(
          [
            formActionPlanSlice.upsertGoals({
              goals: [
                {
                  id: goalId,
                  description: newDescription ?? initialGoal.description,
                  dueBy: newDueBy ?? initialGoal.dueBy,
                  assignedTo: newAssignedTo ?? initialGoal.assignedTo,
                  status: newStatus ?? initialGoal.status.value,
                  goalCategory: initialGoal.goalCategory,
                  actions: [],
                },
              ],
              instanceId,
              timezone,
            }),
            formEditGoalSlice.updatedGoal({ isSuccess: true, goalId, source }),
          ].filter(Boolean),
        );
      } catch (err) {
        log.error('Error while updating goal data:', err);
        return of(
          formEditGoalSlice.updatedGoal({ isSuccess: false, goalId, source }),
        );
      }
    }),
    concatAll(),
  );
}

const updateDescriptionIfChanged = (
  client: IGraphQLClient,
  goalId: string,
  description?: string,
) =>
  description
    ? client.mutate({
        mutation: gql`
          mutation UpdateGoalDescription($goalId: ID!, $description: String!) {
            forms {
              updateFormGoalDescription(
                input: { id: $goalId, description: $description }
              ) {
                ok
              }
            }
          }
        `,
        variables: { goalId, description },
      })
    : undefined;

const updateDueDateIfChanged = (
  client: IGraphQLClient,
  goalId: string,
  timezone: string,
  dueBy?: string,
) =>
  dueBy
    ? client.mutate({
        mutation: gql`
          mutation UpdateGoalDueBy($goalId: ID!, $dueBy: DateTime!) {
            forms {
              updateFormGoalDueBy(input: { id: $goalId, dueBy: $dueBy }) {
                ok
              }
            }
          }
        `,
        variables: {
          goalId,
          dueBy: dueDateForDay(dueBy, timezone).toISOString(),
        },
      })
    : undefined;

const addNoteIfDefined = (
  client: IGraphQLClient,
  goalId: string,
  note?: string,
) =>
  note
    ? client.mutate({
        mutation: gql`
          mutation AddGoalNote($goalId: ID!, $note: String!) {
            forms {
              addFormGoalNote(input: { id: $goalId, note: $note }) {
                ok
              }
            }
          }
        `,
        variables: { goalId, note },
      })
    : undefined;

const updateAdditionFieldsIfChanged = (
  client: IGraphQLClient,
  goalId: string,
  fields: GoalAdditionalField[],
) =>
  fields.map(({ key, value, type, label }) =>
    client.mutate({
      mutation: gql`
        mutation UpdateGoalAdditionalField(
          $goalId: ID!
          $key: String!
          $value: String!
          $type: String!
          $label: String!
        ) {
          forms {
            updateFormGoalAdditionalField(
              input: {
                id: $goalId
                key: $key
                value: $value
                type: $type
                label: $label
              }
            ) {
              ok
            }
          }
        }
      `,
      variables: { goalId, key, value, type, label },
    }),
  );

const updateGoalAssignedToIfChanged = (
  client: IGraphQLClient,
  goalId: string,
  assignedToId: string,
) =>
  assignedToId
    ? client.mutate({
        mutation: gql`
          mutation AssignGoalToPerson($goalId: ID!, $assignedToId: ID!) {
            forms {
              assignFormGoalToPerson(
                input: { id: $goalId, personId: $assignedToId }
              ) {
                ok
              }
            }
          }
        `,
        variables: { goalId, assignedToId },
      })
    : undefined;

const reopenGoal = (client: IGraphQLClient, goalId: string) =>
  client.mutate({
    mutation: gql`
      mutation RevertGoal($goalId: ID!) {
        forms {
          revertFormGoal(input: { id: $goalId }) {
            ok
          }
        }
      }
    `,
    variables: { goalId },
  });

const completeGoal = (
  client: IGraphQLClient,
  goalId: string,
  formInstanceId?: string,
) =>
  client.mutate({
    mutation: gql`
      mutation CompleteGoal($goalId: ID!, $formInstanceId: ID) {
        forms {
          completeFormGoal(
            input: { id: $goalId, formInstanceId: $formInstanceId }
          ) {
            ok
          }
        }
      }
    `,
    variables: { goalId, formInstanceId },
  });

const cancelGoal = (
  client: IGraphQLClient,
  goalId: string,
  formInstanceId?: string,
) =>
  client.mutate({
    mutation: gql`
      mutation CancelGoal($goalId: ID!, $formInstanceId: ID) {
        forms {
          cancelFormGoal(
            input: { id: $goalId, formInstanceId: $formInstanceId }
          ) {
            ok
          }
        }
      }
    `,
    variables: { goalId, formInstanceId },
  });
