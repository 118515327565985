/**
 * Transition component wrappers provided Material UI
 *
 * @see https://material-ui.com/components/transitions/.
 */

import Collapse from '@material-ui/core/Collapse/Collapse.js';
import Fade from '@material-ui/core/Fade/Fade.js';
import Grow from '@material-ui/core/Grow/Grow.js';
import Slide from '@material-ui/core/Slide/Slide.js';
import Zoom from '@material-ui/core/Zoom/Zoom.js';

export default { Collapse, Fade, Grow, Slide, Zoom };
