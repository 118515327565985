/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';

export interface IBulletDigitalContentProps {
  style?: SerializedStyles;
}

/**
 * A bullet that contains a digital content icon.
 */
export const BulletDigitalContent: React.FC<IBulletDigitalContentProps> = ({
  style,
}) => (
  <div css={[styles.base, style]}>
    <Icons.collections fill={'#D2D2D2'} size={22} />
  </div>
);

const styles = {
  base: css({
    width: 22,
    height: 22,
  }),
};
