/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import {
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  Checkbox as MaterialCheckbox,
} from '@material-ui/core';
import { useCallback } from 'react';
import { CSSProperties } from '../../types/types.ts';

export interface ICheckboxProps {
  label?: string;
  labelPosition?: 'end' | 'start' | 'top' | 'bottom';
  labelStyle?: CSSProperties;
  checkBoxStyle?: CSSProperties;
  error?: string;
  helperText?: string;
  isChecked?: boolean;
  isEnabled?: boolean;
  width?: number | string;
  onCheck?: (to: boolean) => void;
  style?: SerializedStyles;
  classes?: FormControlLabelProps['classes'];
  indeterminate?: boolean;
}

/**
 * A Checkbox that can be ticked and unticked.
 * See:
 *  https://material-ui.com/components/checkboxes/
 *  https://material-ui.com/api/checkbox/
 */
export const Checkbox: React.FC<ICheckboxProps> = ({
  checkBoxStyle,
  classes,
  error,
  helperText,
  indeterminate = false,
  isChecked = false,
  isEnabled = true,
  label,
  labelPosition,
  labelStyle,
  onCheck,
  style,
  width,
}) => {
  const handleChecked = useCallback(
    (_: any, to: boolean) => {
      onCheck?.(to);
    },
    [onCheck],
  );

  const computedStyles = {
    base: css({
      display: helperText ? 'block' : 'inline-block',
      width,
    }),
  };

  return (
    <div css={[computedStyles.base, style]}>
      <FormControlLabel
        control={
          <MaterialCheckbox
            checked={isChecked}
            indeterminate={indeterminate}
            onChange={handleChecked}
            disabled={!isEnabled}
            style={checkBoxStyle}
          />
        }
        label={label}
        labelPlacement={labelPosition}
        style={labelStyle}
        classes={classes}
      />
      {helperText && (
        <FormHelperText error={Boolean(error)}>
          {error || helperText}
        </FormHelperText>
      )}
    </div>
  );
};
