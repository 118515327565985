import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import { Person } from '../types.ts';

export const getPerson = async (
  client: IGraphQLClient,
  id: string,
): Promise<QueryResult<Person>> => {
  try {
    const response = await client.query<{
      orgHierarchy: {
        person: {
          id: string;
          email: string;
          firstName: string;
          lastName: string;
          isActive: boolean;
          positionTitle: string;
          primaryMemberTeam?: {
            id: string;
            path: string;
          };
        };
      };
    }>({
      query: gql`
        query Person($id: ID!) {
          orgHierarchy {
            person(id: $id) {
              id
              email
              firstName
              isActive
              lastName
              positionTitle
              primaryMemberTeam {
                id
                path
              }
            }
          }
        }
      `,
      variables: { id },
      fetchPolicy: 'network-only',
    });

    const person = response.data.orgHierarchy.person;
    if (!person) {
      log.error(`Failed to retrieve person with id ${id}`);
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: {
        id: person.id,
        email: person.email,
        firstName: person.firstName,
        isActive: person.isActive,
        lastName: person.lastName,
        positionTitle: person.positionTitle,
        primaryMemberTeam: person.primaryMemberTeam && {
          id: person.primaryMemberTeam.id,
          path: person.primaryMemberTeam.path,
        },
      },
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query people - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
