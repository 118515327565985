import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { OrderBy } from '@seeeverything/ui.util/src/types.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import {
  FormsBulkUploadValidateFileError,
  FormsBulkUploadValidateFileUploadRow,
} from '../types.ts';

export type GetFormsBulkUploadJobHistoryFailedArgs = {
  jobId: string;
  pageNumber: number;
  orderBys: OrderBy[];
};

export type GetFormsBulkUploadJobHistoryFailedResponseDto = {
  pageInfo: {
    hasNextPage: boolean;
    currentPage: number;
  };
  rows: FormsBulkUploadValidateFileUploadRow[];
};

export const getFormsBulkUploadJobHistoryFailed = async (
  client: IGraphQLClient,
  args: GetFormsBulkUploadJobHistoryFailedArgs,
): Promise<QueryResult<GetFormsBulkUploadJobHistoryFailedResponseDto>> => {
  try {
    const response = await client.query<{
      forms: {
        formsBulkUploadJobHistoryFailed: {
          pageInfo: { hasNextPage: boolean; currentPage: number };
          nodes: Array<{
            rowNumber: string;
            associatedEntities?: {
              assignedTo?: string;
              subject?: string;
              automatedActionIds: string[];
            };
            errors: FormsBulkUploadValidateFileError[];
          }>;
        };
      };
    }>({
      query: gql`
        query FormsBulkUploadJobHistoryFailed(
          $jobId: ID!
          $pageNumber: Int!
          $orderBy: [OrderByInput!]
        ) {
          forms {
            formsBulkUploadJobHistoryFailed(
              jobId: $jobId
              pagination: { size: 100, pageNumber: $pageNumber }
              orderBy: $orderBy
            ) {
              pageInfo {
                hasNextPage
                totalCount
                pageSize
                currentPage
                totalPages
              }
              nodes {
                rowNumber
                associatedEntities {
                  subject
                  assignedTo
                  automatedActionIds
                }
                errors {
                  code
                  payload {
                    key
                    value
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        jobId: args.jobId,
        pageNumber: args.pageNumber,
        orderBys: args.orderBys,
      },
      fetchPolicy: 'network-only',
    });

    const { pageInfo, nodes } =
      response.data.forms.formsBulkUploadJobHistoryFailed;

    if (!nodes) {
      log.error('Failed to retrieve Bulk Upload failed rows');
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: {
        pageInfo: {
          hasNextPage: pageInfo.hasNextPage,
          currentPage: pageInfo.currentPage,
        },
        rows: nodes.map(
          (node): FormsBulkUploadValidateFileUploadRow => ({
            rowNumber: node.rowNumber,
            associatedEntities: node.associatedEntities && {
              assignedTo: node.associatedEntities.assignedTo,
              subject: node.associatedEntities.subject,
              automatedActionIds:
                node.associatedEntities.automatedActionIds ?? [],
            },
            status: 'Error',
            errors: (node.errors ?? []).map((error) => ({
              code: error.code,
              payload: (error.payload ?? []).map((payload) => ({
                key: payload.key,
                value: payload.value,
              })),
            })),
          }),
        ),
      },
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query Bulk Upload failed rows - ${error.message}`,
      error,
    );
    return { isSuccess: false, errorReason: 'UNKNOWN', error };
  }
};
