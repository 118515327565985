/** @jsxImportSource @emotion/react */
import React from 'react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { css } from '@emotion/react';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';

export interface IErrorBarProps {
  error: string;
  size?: 'large' | 'small';
}

export const ErrorBar: React.FC<IErrorBarProps> = ({
  error,
  size = 'large',
}) => {
  const computedStyles = {
    base: css({
      padding: size === 'small' ? 6 : 10,
    }),
  };

  return error ? (
    <div css={[styles.base, computedStyles.base]}>
      <div css={styles.iconContainer}>
        <Icons.errorOutline size={16} fill={'RED'} />
      </div>
      <Text color={color.format(-0.7)} size={size === 'small' ? 14 : 16}>
        {error}
      </Text>
    </div>
  ) : null; // Can't return undefined for JSX Element.
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 40,
    backgroundColor: 'rgba(255, 0, 0, 0.1)',
    border: 'solid 1px rgba(255, 0, 0, 0.05)',
    boxSizing: 'border-box',
  }),
  iconContainer: css({
    marginTop: 4,
    width: 25,
  }),
};
