/** @jsxImportSource @emotion/react */
import momentTz from 'moment-timezone';
import React from 'react';

export const DateContext = React.createContext<IDateContext | undefined>({
  format: 'MM/dd/yyyy',
  tenantTimezone: momentTz.tz.guess(),
});

export interface IDateContext {
  format: string;
  tenantTimezone: string;
}
