/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react';

export interface UseEmitDebounceOptions<T> {
  value: T;
  delay?: number;
  onDebounce: (to: T) => void;
}

/**
 * Hook for emitting an `onDebounce` event after a delay has been cleared without updates.
 *
 * @example
 * const onDebounce = (to: string) => log(to); // Logs 'DEBOUNCE' after 250ms.
 *
 * const [value, setValue] = useEmitDebounce({
 *    value: undefined,
 *    delay: 250,
 *    onDebounce,
 * });
 *
 * setValue('DEBOUNCED');
 */
export const useEmitDebounce = <T>({
  value,
  delay = 250,
  onDebounce,
}: UseEmitDebounceOptions<T>) => {
  const timeoutHandler = useRef<NodeJS.Timeout>(null);

  const [state, setState] = useState(value);

  useEffect(() => {
    clearTimeout(timeoutHandler.current);
    return () => clearTimeout(timeoutHandler.current);
  }, [value]);

  useEffect(() => {
    clearTimeout(timeoutHandler.current);

    if (value === state) return;

    timeoutHandler.current = setTimeout(() => {
      onDebounce(state);
    }, delay);
  }, [delay, onDebounce, state, value]);

  return [state, setState] as const;
};
