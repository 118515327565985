/**
 * Replaces commonly-used Windows 1252 encoded chars that do not exist in ASCII
 *  or ISO-8859-1 with ISO-8859-1 cognates.
 * These characters will typically be pasted-in from a word document.
 *
 * Based on: https://stackoverflow.com/a/6219023
 */
export const replaceWordChars = (input: string) =>
  REPLACEMENTS.reduce((acc, r) => acc.replace(r.match, r.to), input);

const REPLACEMENTS = [
  // Smart single quotes and apostrophe.
  { to: "'", match: /[\u2018|\u2019|\u201A]/g },
  // Smart double quotes.
  { to: '"', match: /[\u201C|\u201D|\u201E]/g },
  // Ellipsis. Note that this increases the length of the input string.
  { to: '...', match: /\u2026/g },
  // Dashes.
  { to: '-', match: /[\u2013|\u2014]/g },
  // Circumflex.
  { to: '^', match: /\u02C6/g },
  // Open angle bracket.
  { to: '<', match: /\u2039/g },
  // Close angle bracket.
  { to: '>', match: /\u203A/g },
  // Spaces.
  { to: ' ', match: /[\u02DC|\u00A0]/g },
];
