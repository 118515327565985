/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormActionPlanAction } from '@se/data/forms/types.ts';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import {
  IListItemLabel,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextButton } from '@seeeverything/ui.primitives/src/components/TextButton/TextButton.tsx';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import Transition from '@seeeverything/ui.primitives/src/components/Transition/Transition.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { useMemo } from 'react';
import { Action } from '../../FormActionPlan/components/Action.tsx';
import { AddAction } from '../../FormActionPlan/components/AddAction.tsx';
import { OptionsAnswer } from '../../OptionsAnswer/OptionsAnswer.tsx';
import { TextAnswer } from '../../TextAnswer/TextAnswer.tsx';

export type IssueCauseProps = {
  actions: FormActionPlanAction[];
  actionsRequiredSelectionError?: string;
  addAction?: 'Yes' | 'No';
  additionalActionsGuidanceText: string;
  canEdit: boolean;
  causes: ISelectionListItem[];
  coachingConversation: string;
  coachingConversationError?: string;
  coachingIssueActionError?: string;
  instanceId: string;
  issueActionLabel: string;
  issueId: string;
  noActionsReason: string;
  noActionsReasonError?: string;
  noOpenActionsWithNoActionSelectedError?: string;
  onCoachingConversationChange: (to: string) => void;
  onNoAdditionalActionReasonChange: (to: string) => void;
  onPrimaryCauseNotesChange: (to: string) => void;
  onSecondaryCausesClick: () => void;
  onSelectAnswerChange: (to: ISelectionListItem<IListItemLabel>) => void;
  primaryCauseError?: string;
  primaryCauseId: string;
  primaryCauseLabel: string;
  primaryCauseReasonLabel: string;
  primaryCauseNotes: string;
  primaryCauseNotesError?: string;
  selectedSecondaryCausesLabels?: string[];
  toggleActionsRequired: (to: string) => void;
};

const secondaryCausesTextEditable = (selectedCauses: string[] = []) => {
  const [first, second] = selectedCauses;
  const numCauses = selectedCauses.length ?? 0;
  switch (numCauses) {
    case 0:
      return `Add secondary causes to this issue (Nothing selected).`;
    case 1:
      return `Add or change secondary causes on this issue. ${first} is selected as a cause.`;
    case 2:
      return `Add or change secondary causes on this issue. ${first} and ${second} are selected as causes.`;
    default: {
      const remaining = numCauses - 2;
      return `Add or change secondary causes on this issue. ${first}, ${second}, and ${remaining} ${str.plural(
        'other',
        remaining,
      )} are selected as causes.`;
    }
  }
};

const secondaryCausesTextNotEditable = (selectedCauses: string[] = []) => {
  const [first, second] = selectedCauses;
  const numCauses = selectedCauses.length ?? 0;
  switch (numCauses) {
    case 0:
      return `No secondary causes were selected.`;
    case 1:
      return `${first} selected as a cause.`;
    case 2:
      return `${first} and ${second} were selected as causes.`;
    default: {
      const remaining = numCauses - 2;

      return `${first}, ${second}, and ${remaining} ${str.plural(
        'other',
        remaining,
      )} were selected as causes.`;
    }
  }
};

export const IssueCause: React.FC<IssueCauseProps> = ({
  actions,
  actionsRequiredSelectionError,
  addAction,
  additionalActionsGuidanceText,
  canEdit,
  causes,
  coachingConversation,
  coachingConversationError,
  coachingIssueActionError,
  instanceId,
  issueActionLabel,
  issueId,
  noActionsReason,
  noActionsReasonError,
  noOpenActionsWithNoActionSelectedError,
  onCoachingConversationChange,
  onNoAdditionalActionReasonChange,
  onPrimaryCauseNotesChange,
  onSecondaryCausesClick,
  onSelectAnswerChange,
  primaryCauseError,
  primaryCauseId,
  primaryCauseLabel,
  primaryCauseReasonLabel = 'Why do you think this is the primary cause? (Required)',
  primaryCauseNotes,
  primaryCauseNotesError,
  selectedSecondaryCausesLabels,
  toggleActionsRequired,
}) => {
  const hasActions = actions?.length > 0;
  const hasOpenActions =
    hasActions &&
    actions.some((action) => ['Open', 'Overdue'].includes(action.status));

  const elActions = hasActions && (
    <div css={styles.actions}>
      {actions.map((action) => (
        <Action
          key={`CoachingIssueAction-${action.id}`}
          action={action}
          source={{ type: 'FORM', instanceId }}
        />
      ))}
    </div>
  );

  const elAdditionalActions = (
    <Transition.Collapse
      in={addAction === 'Yes'}
      mountOnEnter={true}
      unmountOnExit={true}
      timeout={100}
    >
      <div css={styles.primaryCausesBase}>
        <Text>{additionalActionsGuidanceText}</Text>
        {elActions}
        <AddAction
          message={
            hasActions
              ? `Add another ${issueActionLabel.toLowerCase()}.`
              : `Add a ${issueActionLabel.toLowerCase()}.`
          }
          error={Boolean(coachingIssueActionError)}
          style={styles.addAction}
          instanceId={instanceId}
          issueId={issueId}
        />
      </div>
    </Transition.Collapse>
  );

  const elNoActionsReason = (
    <Transition.Collapse
      in={addAction === 'No'}
      mountOnEnter={true}
      unmountOnExit={true}
      timeout={100}
    >
      <div css={styles.additionalReason}>
        <div>
          <TextAnswer
            id={`no-additional-action-reason-${issueId}`}
            floatingText={'Why not?'}
            isEnabled={canEdit}
            multiline={true}
            onChange={onNoAdditionalActionReasonChange}
            value={noActionsReason ?? ''}
            error={noActionsReasonError}
            style={styles.darkenTextOnMouseover}
          />
        </div>
        {hasActions && (
          <Text
            italic={true}
            style={
              noOpenActionsWithNoActionSelectedError
                ? styles.textError
                : undefined
            }
          >
            {hasOpenActions
              ? `These actions were previously created. Cancel them if appropriate, otherwise if they're valid select Yes above.`
              : 'Previously created actions:'}
          </Text>
        )}
        {elActions}
      </div>
    </Transition.Collapse>
  );

  const elPrimaryCauseDetails = (
    <Transition.Fade
      in={Boolean(primaryCauseId)}
      mountOnEnter={true}
      unmountOnExit={true}
      timeout={100}
    >
      <TextAnswer
        id={`details-${issueId}`}
        floatingText={primaryCauseReasonLabel}
        isEnabled={canEdit}
        multiline={true}
        onChange={onPrimaryCauseNotesChange}
        value={primaryCauseNotes}
        error={primaryCauseNotesError}
        style={styles.causeText}
      />
    </Transition.Fade>
  );

  const elSecondaryCauses = (
    <>
      <Text weight={FontWeight.bold}>{'Secondary Cause (Optional)'}</Text>
      <TextButton
        onClick={onSecondaryCausesClick}
        textButtonStyle={styles.selectSecondaryCausesButton}
        textStyle={styles.selectSecondaryCausesButtonText}
        highlightOnHover={true}
        icon={Icons.animation}
      >
        {canEdit
          ? secondaryCausesTextEditable(selectedSecondaryCausesLabels)
          : secondaryCausesTextNotEditable(selectedSecondaryCausesLabels)}
      </TextButton>
    </>
  );

  const selectedValue = useMemo(() => {
    if (!primaryCauseId) return;

    return (
      causes.find((cause) => cause.id === primaryCauseId) ?? {
        id: primaryCauseId,
        value: primaryCauseLabel,
        content: { text: primaryCauseLabel },
      }
    );
  }, [causes, primaryCauseId, primaryCauseLabel]);

  return (
    <div css={styles.primaryCausesBase}>
      <Text weight={FontWeight.bold}>{'Primary Cause'}</Text>
      <div css={styles.primaryCausesOuter}>
        <div css={styles.dropdown}>
          <TextFieldDropdown
            id={`primary-cause-${issueId}`}
            InputProps={{ type: 'text', autoComplete: 'off' }}
            isEnabled={canEdit}
            label={'Select the primary cause of this issue'}
            onChange={onSelectAnswerChange}
            selections={causes}
            value={selectedValue}
            error={primaryCauseError}
          />
        </div>
        {elPrimaryCauseDetails}
      </div>
      <Text weight={FontWeight.bold}>{'Coaching Conversation'}</Text>
      <div css={styles.primaryCausesOuter}>
        <TextAnswer
          id={`coaching-conversation-summary-${issueId}`}
          floatingText={'Coaching conversation summary (Required)'}
          isEnabled={canEdit}
          multiline={true}
          onChange={onCoachingConversationChange}
          value={coachingConversation}
          error={coachingConversationError}
        />
      </div>
      <OptionsAnswer
        question={`Are additional ${str.plural(
          issueActionLabel.toLowerCase(),
        )} required to prevent this from occurring again?`}
        isEnabled={canEdit}
        onChange={toggleActionsRequired}
        answerId={issueId}
        direction={'horizontal'}
        options={[
          { id: 'Yes', label: 'Yes' },
          { id: 'No', label: 'No' },
        ]}
        value={addAction}
        error={actionsRequiredSelectionError}
        style={styles.darkenOptionsOnMouseover}
      />
      {elAdditionalActions}
      {elNoActionsReason}
      {elSecondaryCauses}
    </div>
  );
};

const styles = {
  primaryCausesOuter: css({
    display: 'flex',
    flexWrap: 'wrap',
    padding: '3px 0px',
    ':hover': {
      padding: '7px 12px',
      margin: '-4px -12px',
      backgroundColor: color.format(-0.06),
    },
  }),
  darkenOptionsOnMouseover: css({
    ':hover': {
      padding: '8px 12px',
      margin: '-8px -12px',
      backgroundColor: color.format(-0.06),
    },
  }),
  darkenTextOnMouseover: css({
    ':hover': {
      padding: '10px 27px 20px 12px',
      margin: '-10px -12px',
      backgroundColor: color.format(-0.06),
    },
  }),
  textError: css({
    color: COLORS.ERROR_RED,
  }),
  selectSecondaryCausesButton: {
    display: 'flex',
    flex: '1 1 auto',
    cursor: 'pointer',
    justifyContent: 'flex-start',
    padding: 10,
    margin: '-3px -10px',
  },
  selectSecondaryCausesButtonText: css({
    ...CommonStyles.MaterialCubicTransitions,
    marginLeft: 4,
  }),
  primaryCausesBase: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  }),
  actions: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  additionalReason: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  }),
  dropdown: css({
    flex: '1 1 225px',
    padding: '0 15px 10px 0',
  }),
  causeText: css({ flex: '4 1 375px' }),
  addAction: css({
    padding: '10px 13px',
    margin: '-10px -12px',
  }),
};
