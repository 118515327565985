import { useEffect, useState } from 'react';

/**
 * Hook for forcing a component to re-render once.
 */
export const useForceRenderOnce = () => {
  const [, set] = useState(false);
  useEffect(() => {
    set(true);
  }, []);
};
