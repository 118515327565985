/** @jsxImportSource @emotion/react */
import { useSegmentAnalytics } from '@seeeverything/ui.util/src/analytics/SegmentProvider.tsx';
import { useGraphQL } from '@seeeverything/ui.util/src/graphql/GraphQLProvider.tsx';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import { useCallback, useMemo } from 'react';
import { formInstanceAnswerChange } from '../../redux/form-instance/answer/actions.ts';
import { useFormsDispatch, useFormsSelector } from '../../redux/store.ts';
import { FormAnswer, INumberAnswerLine } from '../../types/types.ts';
import { NumberAnswer } from '../NumberAnswer/NumberAnswer.tsx';
import { PreviousResponsesDropdown } from '../PreviousAnswersDropdown/PreviousAnswersDropdown.tsx';
import { usePreviousAnswers } from './usePreviousAnswers.ts';

export interface INumberAnswerContainerProps {
  canUpdateInstance: boolean;
  instanceId: string;
  isPdfExport: boolean;
  isVisible: boolean;
  line: INumberAnswerLine;
}

export const NumberAnswerContainer: React.FC<INumberAnswerContainerProps> = ({
  canUpdateInstance,
  instanceId,
  isPdfExport,
  isVisible,
  line,
}) => {
  const { client } = useGraphQL();
  const { track } = useSegmentAnalytics();
  const dispatch = useFormsDispatch();

  const trackShowPreviousAnswers = useCallback(() => {
    track('form_view_previous_answer', { formInstanceId: instanceId });
  }, [instanceId, track]);

  const instanceSubjectId = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.subject?.id,
  );

  const instanceTemplateId = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.template?.id,
  );

  const previousAnswersDefinition = line.previousAnswers;

  const previousAnswersEnabled = Boolean(
    previousAnswersDefinition?.isEnabled && !isPdfExport,
  );

  const { previousAnswers, isFetchingPreviousAnswers } = usePreviousAnswers(
    client,
    {
      instanceId,
      subjectId: instanceSubjectId,
      templateId: instanceTemplateId,
      inputId: line.id,
      fromDifferentTemplateInputId:
        previousAnswersDefinition?.fromDifferentTemplate?.inputId,

      fromDifferentTemplateTemplateName:
        previousAnswersDefinition?.fromDifferentTemplate?.template,
      previousAnswersEnabled: previousAnswersEnabled && !isPdfExport,
    },
  );

  const newAnswerId = useMemo(() => {
    if (!isVisible) return;
    return uuid.generate();
  }, [isVisible]);

  const answer = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    return instance?.answers?.[line.id];
  });

  const answerValue = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    return instance?.answers?.[line.id]?.value?.toString();
  });

  const error = useFormsSelector(
    (state) =>
      state.formInstance.instances[instanceId]?.questionErrors?.[line.id],
  );

  const updateAnswer = useCallback(
    (to: string) => {
      if (!isVisible) return;

      const toAnswer: FormAnswer = {
        id: answer?.id ?? newAnswerId,
        value: to?.toString(),
        lineId: line.id,
        type: 'inputs',
        subType: 'numberAnswer',
        group: line.group,
      };

      dispatch(
        formInstanceAnswerChange(
          instanceId,
          answer?.id
            ? {
                type: 'UPDATE',
                toAnswer,
                fromAnswer: answer,
              }
            : {
                type: 'CREATE',
                toAnswer,
                creationPreviouslyFailed: Boolean(error),
              },
          false,
        ),
      );
    },
    [
      answer,
      dispatch,
      error,
      instanceId,
      isVisible,
      line.group,
      line.id,
      newAnswerId,
    ],
  );

  const elNumberAnswer = (
    <NumberAnswer
      error={error}
      floatingText={line.floatingText}
      helperText={line.helperText}
      icon={line.icon}
      id={line.id}
      isEnabled={canUpdateInstance}
      onChange={updateAnswer}
      renderText={isPdfExport}
      value={answerValue}
    />
  );

  return previousAnswersEnabled ? (
    <PreviousResponsesDropdown
      dropdownTitle={'Previous Responses'}
      isLoading={isFetchingPreviousAnswers}
      linkLabel={
        previousAnswersDefinition.fromDifferentTemplate?.template
          ? `Show Previous ${previousAnswersDefinition.fromDifferentTemplate?.template} Responses`
          : 'Show Previous Responses'
      }
      emptyMessage={'No previous responses to display.'}
      onAnswersDropdownShow={trackShowPreviousAnswers}
      previousResponses={previousAnswers}
    >
      {elNumberAnswer}
    </PreviousResponsesDropdown>
  ) : (
    elNumberAnswer
  );
};
