import gql from 'graphql-tag';
import { Observable, mergeMap, filter } from 'rxjs';
import { ofType, StateObservable } from 'redux-observable';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import {
  ReduxFormDigitalContentDeleteComment,
  ReduxFormDigitalContentDeleteCommentError,
} from './types.ts';
import {
  digitalContentCommentsDeleteCommentSuccess,
  digitalContentCommentsDeleteCommentError,
} from './actions.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../../store.ts';

export function deleteCommentEpic(
  action$: Observable<ReduxFormDigitalContentDeleteComment>,
  _: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/digital-content/comments/DELETE_COMMENT'),
    mergeMap(async ({ payload: { commentId } }) => {
      const response = await deleteComment(client, commentId);

      return response
        ? digitalContentCommentsDeleteCommentSuccess(commentId)
        : digitalContentCommentsDeleteCommentError(commentId, true);
    }),
  );
}

// Silent retry if a comment delete fails.
export function retryDeleteCommentEpic(
  action$: Observable<ReduxFormDigitalContentDeleteCommentError>,
  _: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/digital-content/comments/DELETE_COMMENT_ERROR'),
    filter(({ payload: { shouldRetry } }) => Boolean(shouldRetry)),
    mergeMap(async ({ payload: { commentId } }) => {
      const response = await deleteComment(client, commentId);

      return response
        ? digitalContentCommentsDeleteCommentSuccess(commentId)
        : digitalContentCommentsDeleteCommentError(commentId, false);
    }),
  );
}

const deleteComment = async (client: IGraphQLClient, id: string) => {
  try {
    const response = await client.mutate<any>({
      mutation: mutationDeleteComment,
      variables: { id },
    });
    if (!response.data?.digitalContent.deleteDigitalContentPackComment.ok) {
      throw new Error('Mutation failed to execute');
    }
    return true;
  } catch (error) {
    log.error(`GraphQL Error: Error deleting comment ${id}
     ${error}`);
    return false;
  }
};

const mutationDeleteComment = gql`
  mutation DeleteDigitalContentComment($id: ID!) {
    digitalContent {
      deleteDigitalContentPackComment(id: $id) {
        ok
      }
    }
  }
`;
