import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { GoalAdditionalField } from '@seeeverything/ui.util/src/redux/tenant/types.ts';
import { OrderBy } from '@seeeverything/ui.util/src/types.ts';
import gql from 'graphql-tag';
import { QueryResult, SimpleEntity } from '../../types.ts';
import { GoalSchedule } from './goalScheduleTypes.ts';

type GoalScheduleResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  startDate: string;
  endDate?: string;
  goalDescription: string;
  goalDueDate: string;
  goalCategory?: SimpleEntity;
  distributionList?: SimpleEntity;
  updatedBy: SimpleEntity;
  goalAdditionalFields: GoalAdditionalField[];
  status: 'NotStarted' | 'Started' | 'Inactive';
  hasStarted: boolean;
};

type GetGoalSchedulesArgs = {
  showAll: boolean;
  pageNumber?: number;
  orderBy: OrderBy;
};

export type GetGoalSchedulesResponseDto = {
  pageInfo: {
    hasNextPage: boolean;
    currentPage: number;
  };
  goalSchedules: GoalSchedule[];
};

export const getGoalSchedules = async (
  client: IGraphQLClient,
  args: GetGoalSchedulesArgs,
): Promise<QueryResult<GetGoalSchedulesResponseDto>> => {
  try {
    const response = await client.query<{
      forms: {
        goalSchedules: {
          nodes: GoalScheduleResponse[];
          pageInfo: { hasNextPage: boolean; currentPage: number };
        };
      };
    }>({
      query: gql`
        query GoalSchedules(
          $pageNumber: Int!
          $orderBy: [OrderByInput!]
          $showAll: Boolean!
        ) {
          forms {
            goalSchedules(
              showAll: $showAll
              orderBy: $orderBy
              pagination: { size: 100, pageNumber: $pageNumber }
            ) {
              pageInfo {
                currentPage
                hasNextPage
              }
              nodes {
                id
                updatedAt
                createdAt
                startDate
                endDate
                goalDescription
                goalDueDate
                goalCategory {
                  id
                  name
                }
                distributionList {
                  id
                  name
                }
                updatedBy {
                  id
                  name
                }
                goalAdditionalFields {
                  label
                  key
                  value
                  type
                }
                status
                hasStarted
              }
            }
          }
        }
      `,
      variables: {
        showAll: args.showAll,
        orderBy: args.orderBy,
        pageNumber: args.pageNumber,
      },
      fetchPolicy: 'network-only',
    });

    const { pageInfo, nodes } = response.data.forms.goalSchedules;

    if (!nodes) {
      log.error(`Failed to retrieve goal schedules`);
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: {
        pageInfo: {
          hasNextPage: pageInfo.hasNextPage,
          currentPage: pageInfo.currentPage,
        },
        goalSchedules: nodes.map((node) => ({
          id: node.id,
          applyToNewStaff: Boolean(node.endDate),
          createdAt: node.createdAt,
          distributionList: node.distributionList
            ? { id: node.distributionList.id, name: node.distributionList.name }
            : undefined,
          endDate: node.endDate ?? undefined,
          goalCategory: node.goalCategory
            ? { id: node.goalCategory.id, name: node.goalCategory.name }
            : undefined,
          goalDescription: node.goalDescription,
          goalDueDate: node.goalDueDate,
          goalAdditionalFields: (node.goalAdditionalFields ?? []).map(
            (field) => ({
              key: field.key,
              label: field.label,
              value: field.value,
              type: field.type,
            }),
          ),
          startDate: node.startDate,
          status: node.status,
          updatedAt: node.updatedAt,
          updatedBy: { id: node.updatedBy.id, name: node.updatedBy.name },
        })),
      },
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query goal schedules - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
