import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { OrderBy } from '@seeeverything/ui.util/src/types.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import { BulkUploadCreatedInstance, FormInstanceStatus } from '../types.ts';

export type GetFormsBulkUploadJobHistorySuccessArgs = {
  jobId: string;
  pageNumber: number;
  orderBys: OrderBy[];
};

export type GetFormsBulkUploadJobHistorySuccessResponseDto = {
  pageInfo: {
    hasNextPage: boolean;
    currentPage: number;
  };
  instances: BulkUploadCreatedInstance[];
};

export const getFormsBulkUploadJobHistorySuccess = async (
  client: IGraphQLClient,
  args: GetFormsBulkUploadJobHistorySuccessArgs,
): Promise<QueryResult<GetFormsBulkUploadJobHistorySuccessResponseDto>> => {
  try {
    const response = await client.query<{
      forms: {
        formsBulkUploadJobHistorySuccess: {
          pageInfo: { hasNextPage: boolean; currentPage: number };
          nodes: Array<{
            id: string;
            assignedToName: string;
            createdAt: string;
            status: FormInstanceStatus;
            subjectName: string;
            templateName: string;
            updateAt: string;
          }>;
        };
      };
    }>({
      query: gql`
        query FormsBulkUploadJobHistorySuccess(
          $jobId: ID!
          $pageNumber: Int
          $orderBys: [OrderByInput!]
        ) {
          forms {
            formsBulkUploadJobHistorySuccess(
              jobId: $jobId
              pagination: { pageNumber: $pageNumber, size: 100 }
              orderBy: $orderBys
            ) {
              pageInfo {
                hasNextPage
                totalCount
                pageSize
                currentPage
                totalPages
              }
              nodes {
                id
                createdAt
                templateName
                assignedToName
                subjectName
                updatedAt
                status
              }
            }
          }
        }
      `,
      variables: {
        jobId: args.jobId,
        pageNumber: args.pageNumber,
        orderBys: args.orderBys,
      },
      fetchPolicy: 'network-only',
    });

    const { pageInfo, nodes } =
      response.data.forms.formsBulkUploadJobHistorySuccess;

    if (!nodes) {
      log.error('Failed to retrieve Bulk Upload created instances');
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: {
        pageInfo: {
          hasNextPage: pageInfo.hasNextPage,
          currentPage: pageInfo.currentPage,
        },
        instances: nodes.map(
          (node): BulkUploadCreatedInstance => ({
            id: node.id,
            assignedToName: node.assignedToName,
            createdAt: node.createdAt,
            status: node.status,
            subjectName: node.subjectName,
            templateName: node.templateName,
            updatedAt: node.updateAt,
          }),
        ),
      },
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query Bulk Upload created instances - ${error.message}`,
      error,
    );
    return { isSuccess: false, errorReason: 'UNKNOWN', error };
  }
};
