import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import gql from 'graphql-tag';
import { MutationResult } from '../../types.ts';
import { FormsBulkUploadWorkflowType } from '../types.ts';

export type FormsBulkUploadImportFileArgs = {
  file: File;
  workflowType: FormsBulkUploadWorkflowType;
  createAutomatedActions: boolean;
};

export const formsBulkUploadImportFile = async (
  client: IGraphQLClient,
  args: FormsBulkUploadImportFileArgs,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { formsBulkUploadImportFile: { ok: boolean } };
    }>({
      mutation: gql`
        mutation FormsBulkUploadImportFile(
          $id: ID!
          $file: Upload!
          $workflowType: FormsBulkUploadWorkflowType!
          $createAutomatedActions: Boolean!
        ) {
          forms {
            formsBulkUploadImportFile(
              input: {
                id: $id
                file: $file
                workflowType: $workflowType
                createAutomatedActions: $createAutomatedActions
              }
            ) {
              ok
            }
          }
        }
      `,
      variables: {
        id: uuid.generate(),
        file: args.file,
        workflowType: args.workflowType,
        createAutomatedActions: args.createAutomatedActions,
      },
      fetchPolicy: 'network-only',
    });

    return { isSuccess: response.data.forms.formsBulkUploadImportFile.ok };
  } catch (error) {
    log.error(`Failed to import bulk upload file - ${error.message}`, error);
    return { isSuccess: false, error };
  }
};
