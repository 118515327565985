/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { Transition } from '@seeeverything/ui.primitives/src/components/Transition/index.ts';
import { TextButton } from '@seeeverything/ui.primitives/src/components/TextButton/TextButton.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export interface ICommentFieldProps {
  value: string;
  id: string;
  isEnabled?: boolean;
  onSubmit?: () => void;
  onChange?: (to: string) => void;
  onDiscard?: () => void;
  onBlur?: () => void;
  focusOnMount?: boolean;
  label?: string;
  submitLabel?: string;
  style?: SerializedStyles;
}

/**
 * Field for adding or replying to comments.
 */
export const CommentField: React.FC<ICommentFieldProps> = React.memo(
  function CommentFieldView({
    submitLabel = 'Comment',
    label = 'Add Comment',
    isEnabled = true,
    value,
    id,
    focusOnMount,
    style,
    onSubmit,
    onChange,
    onDiscard,
    onBlur,
  }) {
    const [isFocused, setIsFocused] = React.useState(false);
    const inputRef = React.createRef<HTMLInputElement>();

    React.useEffect(() => {
      if (focusOnMount) {
        inputRef.current?.focus();
      }
    }, [focusOnMount, inputRef]);

    const handleSubmitComment = () => {
      onSubmit?.();
      inputRef.current?.blur();
      setIsFocused(false);
    };

    const handleDiscardClicked = () => {
      onDiscard?.();
      setIsFocused(false);
    };

    const handleKeyDown = (key: string) => {
      if (key === 'Enter') {
        handleSubmitComment();
      }
    };

    const handleBlur = (e: React.FocusEvent) => {
      // Prevents the blur being fired if a child node has been selected under the div.
      if (e.currentTarget.contains(e.relatedTarget as Node)) {
        return;
      }

      onBlur?.();
      setIsFocused(false);
    };
    const handleFocus = () => setIsFocused(true);

    const elControls = (
      <Transition.Collapse
        in={Boolean(isFocused || value)}
        style={styles.controls}
      >
        <div css={styles.controlsInner}>
          <TextButton onClick={handleDiscardClicked}>{'Discard'}</TextButton>
          <TextButton
            icon={Icons.send}
            onClick={handleSubmitComment}
            textButtonStyle={styles.submitButton}
            color={'white'}
            iconSize={20}
            iconStyle={styles.submitIcon}
            iconSide={'RIGHT'}
          >
            {submitLabel}
          </TextButton>
        </div>
      </Transition.Collapse>
    );
    return (
      <div css={[styles.base, style]} onBlur={handleBlur}>
        <TextField
          isEnabled={isEnabled}
          id={id}
          label={label}
          value={value}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          InputProps={{
            inputRef,
            onFocus: handleFocus,
          }}
        />
        {elControls}
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.value === nextProps.value,
);

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  controls: {
    marginTop: 6,
    alignSelf: 'flex-end',
  },
  controlsInner: css({
    display: 'flex',
  }),
  submitButton: {
    marginLeft: 6,
    padding: '4px 10px',
    background: COLORS.BLUE,
    '&:hover': {
      background: color.create(COLORS.BLUE).brighten(0.2).css(),
    },
  },
  submitIcon: {
    fill: 'white',
  },
};
