/** @jsxImportSource @emotion/react */
import { useUtilSelector } from '@seeeverything/ui.util/src/redux/store.ts';
import { TenantLocale } from '@seeeverything/ui.util/src/redux/tenant/types.ts';

type TenantLocaleSelector<T> = (selector: TenantLocale) => T;
export const useTenantLocale = <T>(selector: TenantLocaleSelector<T>) => {
  const tenantLocale = useUtilSelector(
    (state) => state.tenantState.tenant.locale,
  );

  return selector(tenantLocale);
};
