import { FormInstanceActionPlanType } from '@se/data/forms/types.ts';
import { IActionPlanLine, ISectionLine } from '../types/types.ts';
import { defaultId, getProp } from '../util/util.data.parse.ts';
import {
  ActionPlanDefinition,
  ActionPlanStandaloneDefinition,
} from './types/actionPlan.types.ts';

export function parseActionPlan(item: ActionPlanDefinition): IActionPlanLine {
  return {
    id: 'actionPlan',
    type: 'actionPlan',
    actionPlanType: actionPlanType(item?.actionPlanType),
    designerHintText: item?.designerHintText,
    designerTitle: item?.title ?? 'Action Plan',
    highlightOnHover: false,
    includeCategories: item?.includeCategories ?? [],
    isEnabled: item?.isEnabled ?? true,
    isDetachable: false,
    showIndex: item?.isEnabled ?? true,
    title: item?.title ?? 'Action Plan',
  };
}

export function parseDetachableActionPlan(
  item: ActionPlanStandaloneDefinition,
  parentSection: ISectionLine,
  indices?: number[],
): IActionPlanLine {
  return {
    id: getProp(item, 'id') || defaultId(indices),
    parentId: parentSection.id,
    type: 'actionPlan',
    actionPlanType: actionPlanType(item.actionPlanType),
    designerHintText: item.designerHintText,
    designerTitle: item.title ?? 'Action Plan',
    highlightOnHover: false,
    includeCategories: item.includeCategories ?? [],
    isEnabled: item.isEnabled ?? true,
    isDetachable: true,
    showIndex: false,
    showOnAnswer: getProp(item, 'showOnAnswer'),
    showOnReportingDateMonth: item.showOnReportingDateMonth,
    showOnSwitch: getProp(item, 'showOnSwitch'),
  };
}

const actionPlanType = (
  type?: 'ONLY_NEW' | 'ONLY_EXISTING',
): FormInstanceActionPlanType => {
  switch (type) {
    case 'ONLY_EXISTING':
      return 'OnlyExisting';

    case 'ONLY_NEW':
      return 'OnlyNew';

    default:
      return;
  }
};
