/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import {
  digitalContentCommentsAddComment,
  digitalContentCommentsDeleteComment,
  digitalContentCommentsLoad,
  digitalContentCommentsRetryAddComment,
} from '../../redux/form-digital-content/comments/actions.ts';
import { useFormsDispatch, useFormsSelector } from '../../redux/store.ts';
import { DigitalContentComments } from './DigitalContentComments.tsx';

export interface IDigitalContentCommentsContainerProps {
  bannerMessage?: string;
  canAddComments?: boolean;
  canReplyToComments?: boolean;
  guidance?: string;
}

export const DigitalContentCommentsContainer: React.FC<
  IDigitalContentCommentsContainerProps
> = ({ bannerMessage, canAddComments, canReplyToComments, guidance }) => {
  const dispatch = useFormsDispatch();
  const deleteComment = useCallback(
    (commentId: string) =>
      dispatch(digitalContentCommentsDeleteComment(commentId)),
    [dispatch],
  );

  const addComment = useCallback(
    (body: string, parentId: string) => {
      const commentId = uuid.generate();
      dispatch(digitalContentCommentsAddComment(commentId, body, parentId));
    },
    [dispatch],
  );

  const loadMore = useCallback(
    () => dispatch(digitalContentCommentsLoad('LOAD_MORE')),
    [dispatch],
  );

  const reloadComments = useCallback(
    (reason: 'LOAD' | 'LOAD_MORE') =>
      dispatch(digitalContentCommentsLoad(reason)),
    [dispatch],
  );

  const retryAddComment = useCallback(
    (commentId: string) =>
      dispatch(digitalContentCommentsRetryAddComment(commentId)),
    [dispatch],
  );

  const commentState = useFormsSelector(
    (state) => state.formDigitalContent?.comments,
  );
  const commentGuidanceState = useFormsSelector(
    (state) => state.formDigitalContent?.contentMeta?.commentGuidance,
  );

  return (
    <DigitalContentComments
      bannerMessage={bannerMessage}
      canAddComments={canAddComments}
      canReplyToComments={canReplyToComments}
      comments={commentState?.comments}
      loadError={commentState?.errors?.load}
      loadMoreError={commentState?.errors?.loadMore}
      guidance={guidance ?? commentGuidanceState}
      hasNextPage={commentState?.pagination?.hasNextPage}
      isLoading={commentState?.isLoading ?? true}
      isLoadingMore={commentState?.pagination?.isLoadingMore}
      onAddComment={addComment}
      onCommentErrorClick={retryAddComment}
      onDeleteComment={deleteComment}
      onLoadMore={loadMore}
      onReloadClick={reloadComments}
    />
  );
};
