/** @jsxImportSource @emotion/react */
import React from 'react';
import { Subject, throttleTime, share } from 'rxjs';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { css } from '@emotion/react';

const COLORS = {
  MID_GREY: '#A0A0A0',
  LIGHT_GREY: '#DEDEDE',
  WHITE: '#FFFFFF',
};

interface IClickToConfirmDeleteProps {
  onConfirmClick: () => void;
}

const ClickToConfirmDelete: React.FC<IClickToConfirmDeleteProps> = ({
  onConfirmClick,
}) => {
  const [clickedOnce, setClickedOnce] = React.useState(false);

  const clickSubject = React.useRef(new Subject<void>());

  React.useEffect(() => {
    // Handles click throttling and bubbling.
    const subscription = clickSubject.current
      .pipe(share(), throttleTime(300))
      .subscribe(() => {
        setClickedOnce((currentClickedOnce) => {
          if (currentClickedOnce) {
            setTimeout(() => onConfirmClick?.(), 0);
          }

          return !currentClickedOnce;
        });
      });

    return () => subscription.unsubscribe();
  }, [onConfirmClick]);

  React.useEffect(() => {
    // Handles timing out after first click.
    let active = true;

    if (clickedOnce) {
      setTimeout(() => {
        if (active && clickedOnce) {
          setClickedOnce(false);
        }
      }, 3000);
    }

    return () => {
      active = false;
    };
  }, [clickedOnce, onConfirmClick]);

  const onClickHandler = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      clickSubject.current.next(null);
    },
    [],
  );

  const elInner = clickedOnce ? (
    <Text
      size={13}
      weight={400}
      style={styles.deleteConfirmation}
      color={COLORS.WHITE}
    >
      {'Confirm Delete'}
    </Text>
  ) : (
    <Icons.clear size={19} fill={COLORS.LIGHT_GREY} />
  );

  return (
    <div onClick={onClickHandler} css={styles.deleteOuter(clickedOnce)}>
      {elInner}
    </div>
  );
};

const styles = {
  deleteOuter: (isConfirming: boolean) =>
    css({
      position: 'absolute',
      top: 3,
      right: 3,
      height: 20,
      padding: 2,
      opacity: 0.7,
      borderRadius: isConfirming ? 13 : '100%',
      backgroundColor: COLORS.MID_GREY,
      cursor: 'pointer',
      ':hover': {
        opacity: 1,
      },
    }),
  deleteConfirmation: css({
    cursor: 'inherit',
    lineHeight: '20px',
    margin: '0px 10px',
  }),
};

export default React.memo(ClickToConfirmDelete);
