import { isAnyOf } from '@reduxjs/toolkit';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import gql from 'graphql-tag';
import { StateObservable } from 'redux-observable';
import { EMPTY, Observable, concatAll, filter, from, mergeMap, of } from 'rxjs';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import * as issueSlice from './issueSlice.ts';

const getCauses = async (
  client: IGraphQLClient,
  instanceId: string,
  issueId: string,
) => {
  const response = await client.query<{
    forms: {
      formAnswerIssues: {
        issues: Array<{
          id: string;
          answerIssueCauses?: Array<{
            issueId: string;
            isPrimary: boolean;
            notes: string;
            issueCause: {
              id: string;
              key: string;
            };
          }>;
        }>;
      };
    };
  }>({
    query: gql`
      query IssueCauses($coachingInstanceId: ID!) {
        forms {
          formAnswerIssues(coachingInstanceId: $coachingInstanceId) {
            issues {
              id
              answerIssueCauses {
                issueId
                isPrimary
                notes
                issueCause {
                  id
                  key
                }
              }
            }
          }
        }
      }
    `,
    variables: { coachingInstanceId: instanceId },
    fetchPolicy: 'network-only',
  });

  return (
    response.data.forms.formAnswerIssues.issues
      .find((issue) => issue.id === issueId)
      ?.answerIssueCauses?.map((answerIssueCause) => ({
        issueId: answerIssueCause.issueId,
        isPrimary: answerIssueCause.isPrimary,
        notes: answerIssueCause.notes,
        issueCauseId: answerIssueCause.issueCause?.id,
      })) ?? []
  );
};

const deleteCause = async (
  client: IGraphQLClient,
  instanceId: string,
  issueId: string,
  issueCauseId: string,
) => {
  const deletionResult = await client.mutate<{
    forms: { deleteFormAnswerIssueCause: { ok: boolean } };
  }>({
    mutation: gql`
      mutation DeleteIssueCause(
        $instanceId: ID!
        $issueId: ID!
        $issueCauseId: String!
      ) {
        forms {
          deleteFormAnswerIssueCause(
            input: {
              instanceId: $instanceId
              issueId: $issueId
              issueCauseId: $issueCauseId
            }
          ) {
            ok
          }
        }
      }
    `,
    variables: { instanceId, issueId, issueCauseId },
  });

  if (!deletionResult?.data?.forms?.deleteFormAnswerIssueCause.ok) {
    throw new Error(
      'Server returned error or not OK trying to delete form answer issue primary cause',
    );
  }
};

const updateCause = async (
  client: IGraphQLClient,
  instanceId: string,
  issueId: string,
  issueCauseId: string,
  notes: string,
  isPrimary: boolean,
) => {
  const response = await client.mutate<{
    forms: { updateFormAnswerIssueCause: { ok: boolean } };
  }>({
    mutation: gql`
      mutation UpdateIssueCause(
        $instanceId: ID!
        $issueId: ID!
        $issueCauseId: ID
        $isPrimary: Boolean!
        $notes: String!
      ) {
        forms {
          updateFormAnswerIssueCause(
            input: {
              instanceId: $instanceId
              answerIssueId: $issueId
              issueCauseId: $issueCauseId
              notes: $notes
              isPrimary: $isPrimary
            }
          ) {
            ok
          }
        }
      }
    `,
    variables: {
      instanceId,
      issueId,
      issueCauseId,
      notes,
      isPrimary,
    },
  });
  if (!response?.data?.forms?.updateFormAnswerIssueCause?.ok) {
    throw new Error(
      `Server returned error or not OK trying to update cause for instance '${instanceId}' and issue '${issueId}'`,
    );
  }
};

export function updatePrimaryIssueCauseEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(issueSlice.setPrimaryCause.match),
    filter((action) =>
      state$.value.formIssue.issues.some(
        (issue) => issue.id === action.payload.issueId,
      ),
    ),
    mergeMap(async (action) => {
      const { instanceId, issueId, issueCauseId } = action.payload;

      try {
        const existingCauses = await getCauses(client, instanceId, issueId);

        const previousPrimaryCauses = existingCauses.filter(
          (cause) => cause.isPrimary,
        );

        await Promise.all(
          previousPrimaryCauses.map((previousPrimaryCause) => {
            deleteCause(
              client,
              instanceId,
              issueId,
              previousPrimaryCause.issueCauseId,
            );
          }),
        );

        const notes = action.payload.notes ?? '';
        await updateCause(
          client,
          instanceId,
          issueId,
          issueCauseId,
          notes,
          true,
        );

        return EMPTY;
      } catch (error) {
        log.error(error);
      }
      return of(
        issueSlice.coachingErrors({
          issueId,
          primaryCause: 'There was a problem saving this response.',
        }),
      );
    }),
    concatAll(),
  );
}

export function updatePrimaryIssueCauseNotesEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(issueSlice.setPrimaryCauseNotes.match),
    filter((action) =>
      state$.value.formIssue.issues
        .find((issue) => issue.id === action.payload.issueId)
        ?.issueCauses.causes.some((cause) => cause.isPrimary),
    ),
    mergeMap(async (action) => {
      const { instanceId, issueId } = action.payload;

      try {
        const newPrimaryCause = state$.value.formIssue.issues
          .find((issue) => issue.id === issueId)
          .issueCauses.causes.find((cause) => cause.isPrimary);

        const notes = newPrimaryCause.notes ?? '';

        await updateCause(
          client,
          instanceId,
          issueId,
          newPrimaryCause.issueCauseId,
          notes,
          true,
        );

        return EMPTY;
      } catch (error) {
        log.error(error);
        return of(
          issueSlice.coachingErrors({
            issueId,
            primaryCauseNotes: 'There was a problem saving this response.',
          }),
        );
      }
    }),
    concatAll(),
  );
}

export function updateCoachingConversationEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(issueSlice.setCoachingConversation.match),
    filter((action) =>
      state$.value.formIssue.issues.some(
        (issue) => issue.id === action.payload.issueId,
      ),
    ),
    mergeMap(async (action) => {
      const issueId = action.payload.issueId;
      try {
        const response = await client.mutate<{
          forms: { updateFormAnswerIssueCoachingConversation: { ok: boolean } };
        }>({
          mutation: gql`
            mutation UpdateFormAnswerIssueCoachingConversation(
              $instanceId: ID!
              $issueId: ID!
              $coachingConversation: String
            ) {
              forms {
                updateFormAnswerIssueCoachingConversation(
                  input: {
                    instanceId: $instanceId
                    issueId: $issueId
                    coachingConversation: $coachingConversation
                  }
                ) {
                  ok
                }
              }
            }
          `,
          variables: {
            instanceId: action.payload.instanceId,
            issueId,
            coachingConversation: action.payload.coachingConversation,
          },
        });
        if (response.data.forms.updateFormAnswerIssueCoachingConversation.ok)
          return EMPTY;
      } catch (error) {
        log.error(error);
      }

      return of(
        issueSlice.coachingErrors({
          issueId,
          coachingConversation: 'There was a problem saving this response.',
        }),
      );
    }),
    concatAll(),
  );
}

export function updatePrimaryCauseFutureCoachingEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(
      isAnyOf(
        issueSlice.setPrimaryCauseNoActionsReason.match,
        issueSlice.setPrimaryCauseAdditionalAction.match,
      ),
    ),
    filter((action) =>
      state$.value.formIssue.issues.some(
        (issue) => issue.id === action.payload.issueId,
      ),
    ),
    mergeMap(async (action) => {
      const issueId = action.payload.issueId;
      try {
        const issue = state$.value.formIssue.issues.find(
          (formIssue) => formIssue.id === issueId,
        );
        const response = await client.mutate<{
          forms: { updateFormAnswerIssueFutureCoaching: { ok: boolean } };
        }>({
          mutation: gql`
            mutation UpdateFormAnswerIssueFutureCoaching(
              $instanceId: ID!
              $issueId: ID!
              $actionsRequired: Boolean!
              $noActionsRequiredReason: String
            ) {
              forms {
                updateFormAnswerIssueFutureCoaching(
                  input: {
                    instanceId: $instanceId
                    issueId: $issueId
                    actionsRequired: $actionsRequired
                    noActionsRequiredReason: $noActionsRequiredReason
                  }
                ) {
                  ok
                }
              }
            }
          `,
          variables: {
            instanceId: action.payload.instanceId,
            issueId,
            actionsRequired: issue.actionsRequired,
            noActionsRequiredReason: issue.noActionsReason,
          },
        });
        if (response.data.forms.updateFormAnswerIssueFutureCoaching.ok)
          return EMPTY;
      } catch (error) {
        log.error(error);
      }

      return issueSlice.setPrimaryCauseNoActionsReason.match(action)
        ? of(
            issueSlice.coachingErrors({
              issueId,
              noActionsReason: 'There was a problem saving this response.',
            }),
          )
        : of(
            issueSlice.coachingErrors({
              issueId,
              actionsRequiredSelection:
                'There was a problem saving this response.',
            }),
          );
    }),
    concatAll(),
  );
}

export function updateSecondaryCausesEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(issueSlice.saveSecondaryCauses.match),
    mergeMap(async (action) => {
      const { instanceId, issueId, updatedCauses } = action.payload;
      try {
        const existingCauses = (
          await getCauses(client, instanceId, issueId)
        ).filter(({ isPrimary }) => !isPrimary);

        const causesToDelete = existingCauses.filter((existingServerCause) =>
          updatedCauses.some(
            (updatedCause) =>
              updatedCause.issueCauseId === existingServerCause.issueCauseId &&
              updatedCause.isSelected === false,
          ),
        );

        const causesToUpdate = updatedCauses.filter(
          (updatedCause) =>
            updatedCause.isSelected &&
            !existingCauses.some(
              ({ issueCauseId, notes }) =>
                issueCauseId === updatedCause.issueCauseId &&
                notes === updatedCause.notes,
            ),
        );

        await Promise.all(
          causesToDelete.map(({ issueCauseId }) => {
            deleteCause(client, instanceId, issueId, issueCauseId);
          }),
        );

        await Promise.all(
          causesToUpdate.map((updatedCause) =>
            updateCause(
              client,
              instanceId,
              issueId,
              updatedCause.issueCauseId,
              updatedCause.notes,
              false,
            ),
          ),
        );

        return from([
          issueSlice.savedSecondaryCauses({
            updatedCauses,
            issueId,
            instanceId,
          }),
          issueSlice.hideSecondaryCausesDialog(),
        ]);
      } catch (error) {
        log.error(error);
        return of(issueSlice.saveErrorSecondaryCauses({ issueId }));
      }
    }),
    concatAll(),
  );
}
