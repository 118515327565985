/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Foo } from '@seeeverything/ui.primitives/src/components/Foo/Foo.tsx';
import { FormListItemWrapped } from '../components/FormListItem/FormListItemWrapped.tsx';
import { useHandleAnswerVisibility } from '../hooks/useHandleAnswerVisibility.ts';
import { IFooLine } from '../types/types.ts';

export interface IFooFactoryContainerProps {
  instanceId: string;
  isPdfExport: boolean;
  line: IFooLine;
}

export const FooFactoryContainer: React.FC<IFooFactoryContainerProps> = ({
  line,
  isPdfExport,
  instanceId,
}) => {
  const style = css({
    width: line.width,
    height: line.height,
  });

  const isVisible = useHandleAnswerVisibility({
    instanceId,
    questionKeys: [],
    showOnAnswer: line.showOnAnswer,
    showOnReportingDateMonth: line.showOnReportingDateMonth,
    showOnSwitch: line.showOnSwitch,
  });

  return (
    <FormListItemWrapped
      id={line.id}
      parentId={line.parentId}
      key={line.id}
      highlightOnHover={line.highlightOnHover}
      bulletTop={14}
      isVisible={isVisible}
      isPdfExport={isPdfExport}
    >
      <Foo style={style}>{line.text}</Foo>
    </FormListItemWrapped>
  );
};
