import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BulkUploadCreatedInstance,
  FormsBulkUploadValidateFileUploadRow,
} from '@se/data/forms/types.ts';
import { OrderBy } from '@seeeverything/ui.util/src/types.ts';

export type FormBulkUploadClickThroughState = {
  currentPage: number;
  error: boolean;
  hasNextPage: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  createdInstances: CreatedInstances;
  failedRows: FailedRows;
  jobId?: string;
  type?: BulkUploadClickThroughType;
};

type BulkUploadClickThroughType = 'Failed' | 'Succeeded';

type CreatedInstances = {
  instances: BulkUploadCreatedInstance[];
  orderBys: OrderBy[];
};

type FailedRows = {
  rows: FormsBulkUploadValidateFileUploadRow[];
  orderBys: OrderBy[];
};

const DEFAULT_STATE: FormBulkUploadClickThroughState = {
  currentPage: 1,
  error: false,
  hasNextPage: false,
  isLoading: false,
  isLoadingMore: false,
  createdInstances: {
    instances: [],
    orderBys: [
      {
        fieldName: 'createdAt',
        direction: 'Descending',
      },
    ],
  },
  failedRows: {
    rows: [],
    orderBys: [
      {
        fieldName: 'rowNumber',
        direction: 'Ascending',
      },
    ],
  },
};

const slice = createSlice({
  name: 'libs/forms/formBulkUploadClickThrough',
  initialState: DEFAULT_STATE,
  reducers: {
    loadFailedClickThrough(state, action: PayloadAction<{ jobId: string }>) {
      state.jobId = action.payload.jobId;
      state.type = 'Failed';
      state.failedRows = DEFAULT_STATE.failedRows;
      state.createdInstances = DEFAULT_STATE.createdInstances;
    },
    loadSucceededClickThrough(state, action: PayloadAction<{ jobId: string }>) {
      state.jobId = action.payload.jobId;
      state.type = 'Succeeded';
      state.failedRows = DEFAULT_STATE.failedRows;
      state.createdInstances = DEFAULT_STATE.createdInstances;
    },
    loadClickThrough(
      state,
      action: PayloadAction<{
        jobId: string;
        type: BulkUploadClickThroughType;
        loadNextPage: boolean;
      }>,
    ) {
      state.jobId = action.payload.jobId;
      state.type = action.payload.type;
      if (!action.payload.loadNextPage) {
        state.failedRows.rows = [];
        state.createdInstances.instances = [];
      }
      state.error = false;
      state.isLoading = !action.payload.loadNextPage;
      state.isLoadingMore = action.payload.loadNextPage;
    },
    loadedSucceededClickThrough(
      state,
      action: PayloadAction<{
        currentPage: number;
        hasNextPage: boolean;
        instances: BulkUploadCreatedInstance[];
      }>,
    ) {
      state.currentPage = action.payload.currentPage;
      state.hasNextPage = action.payload.hasNextPage;
      if (action.payload.currentPage === 1)
        state.createdInstances.instances = action.payload.instances;
      else state.createdInstances.instances.push(...action.payload.instances);
      state.isLoading = false;
      state.isLoadingMore = false;
    },
    loadedFailedClickThrough(
      state,
      action: PayloadAction<{
        currentPage: number;
        hasNextPage: boolean;
        rows: FormsBulkUploadValidateFileUploadRow[];
      }>,
    ) {
      state.currentPage = action.payload.currentPage;
      state.hasNextPage = action.payload.hasNextPage;
      if (action.payload.currentPage === 1)
        state.failedRows.rows = action.payload.rows;
      else state.failedRows.rows.push(...action.payload.rows);
      state.isLoading = false;
      state.isLoadingMore = false;
    },
    loadClickThroughFailed(state) {
      state.error = true;
      state.isLoading = false;
      state.isLoadingMore = false;
    },
    changeOrderBy(state, action: PayloadAction<{ orderBy: OrderBy }>) {
      if (state.type === 'Succeeded') {
        state.createdInstances.orderBys = [action.payload.orderBy];
      } else {
        state.failedRows.orderBys = [action.payload.orderBy];
      }
    },
  },
});

export const {
  loadFailedClickThrough,
  loadSucceededClickThrough,
  loadClickThrough,
  loadedSucceededClickThrough,
  loadedFailedClickThrough,
  loadClickThroughFailed,
  changeOrderBy,
} = slice.actions;
export const reducer = slice.reducer;
export type State = FormBulkUploadClickThroughState;
