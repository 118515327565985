/** @jsxImportSource @emotion/react */
import { makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles.js';
// eslint-disable-next-line import/extensions
import MaterialAccordion from '@mui/material/Accordion';
import { ReactNode, useCallback } from 'react';

export interface IAccordionChangeEvent {
  id?: string;
  index?: number;
  isExpanded: boolean;
}

export interface IAccordionProps {
  children: ReactNode;
  disableGutter?: boolean;
  elevation?: number;
  hideDividers?: boolean;
  id: string;
  index?: number;
  isDefaultExpanded?: boolean;
  isDisabled?: boolean;
  isExpanded?: boolean;
  isSquare?: boolean;
  onChange?: (e: IAccordionChangeEvent) => void;
  onlyMountWhenVisible?: boolean;
  style?: CSSProperties;
  variant?: 'outlined' | 'elevation';
}

/**
 * Displays `AccordionSummary` and `AccordionDetails` as a collapsible layout element.
 *
 * @see https://material-ui.com/components/accordion/
 * @see https://material-ui.com/api/accordion/
 */
export const Accordion: React.FC<IAccordionProps> = (props) => {
  const {
    children,
    disableGutter = true,
    isDefaultExpanded,
    isDisabled,
    isExpanded,
    isSquare = false,
    elevation = 1,
    id,
    onChange,
    onlyMountWhenVisible = true,
    index = 0,
    variant = 'elevation',
  } = props;

  const handleChange = useCallback(
    (_: any, toIsExpanded: boolean) => {
      onChange?.({ id, index, isExpanded: toIsExpanded });
    },
    [id, index, onChange],
  );

  const styles = useStyles(props)();

  return (
    <MaterialAccordion
      classes={{ root: styles.root }}
      defaultExpanded={isDefaultExpanded}
      disabled={isDisabled}
      expanded={isExpanded}
      square={isSquare}
      onChange={handleChange}
      disableGutters={disableGutter}
      elevation={isDisabled ? 0 : elevation}
      TransitionProps={{
        unmountOnExit: onlyMountWhenVisible,
        mountOnEnter: onlyMountWhenVisible,
      }}
      variant={variant}
    >
      {children}
    </MaterialAccordion>
  );
};

const useStyles = ({ style, hideDividers }: IAccordionProps) =>
  makeStyles({
    root: {
      transition: 'all 0.3s',
      borderRadius: 4,
      '&:hover': {
        boxShadow: `0 0 3px 0 #c0c0c0`,
      },
      ...style,
      '&:before': { height: hideDividers ? 0 : 1 },
    },
  });
