import { isAnyOf } from '@reduxjs/toolkit';
import { formsQuery } from '@se/data/forms/query/index.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { StateObservable, combineEpics } from 'redux-observable';
import { Observable, filter, map, mergeMap } from 'rxjs';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import * as goalScheduleSlice from './goalScheduleSlice.ts';

export const goalScheduleEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(loadGoalSchedulesEpic, loadGoalSchedulesOnFiltersChangedEpic);

function loadGoalSchedulesEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(goalScheduleSlice.loadGoalSchedules.match),
    mergeMap(async (action) => {
      const { loadNextPage } = action.payload;
      const { showAll, orderBy } = state$.value.goalSchedule;
      const pageNumber = loadNextPage
        ? state$.value.goalSchedule.currentPage + 1
        : 1;

      const response = await formsQuery.getGoalSchedules(client, {
        showAll,
        orderBy,
        pageNumber,
      });

      if (response.isSuccess === false)
        return goalScheduleSlice.errorLoadingGoalSchedules();

      const { pageInfo, goalSchedules } = response.data;

      return goalScheduleSlice.loadedGoalSchedules({
        schedules: goalSchedules,
        hasNextPage: pageInfo.hasNextPage,
        currentPage: pageInfo.currentPage,
      });
    }),
  );
}

function loadGoalSchedulesOnFiltersChangedEpic(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    filter(
      isAnyOf(
        goalScheduleSlice.sortBy.match,
        goalScheduleSlice.toggleShowAll.match,
      ),
    ),
    map(() => goalScheduleSlice.loadGoalSchedules({ loadNextPage: false })),
  );
}
