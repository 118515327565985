import { formsMutation } from '@se/data/forms/mutation/index.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import { StateObservable, combineEpics } from 'redux-observable';
import { Observable, concatAll, filter, map, mergeMap, of } from 'rxjs';
import { goalScheduleSlice } from '../goalSchedule/index.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import * as editGoalScheduleSlice from './editGoalScheduleSlice.ts';

export const editGoalScheduleEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  createGoalScheduleEpic,
  loadGoalSchedulesOnScheduleSavedEpic,
  reactivateGoalScheduleEpic,
  updateNotStartedGoalScheduleEpic,
  updateStartedGoalScheduleEpic,
);

function createGoalScheduleEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(editGoalScheduleSlice.save.match),
    filter(() => !state$.value.editGoalSchedule.original),
    mergeMap(async (action) => {
      try {
        const hasGoalCategories = action.payload.hasGoalCategories;
        const draft = state$.value.editGoalSchedule.draft;

        const fieldErrors = {
          startDate: draft.startDate ? undefined : 'This field is required.',
          endDate:
            draft.applyToNewStaff && !draft.endDate
              ? 'This field is required.'
              : undefined,
          distributionList: draft.distributionList
            ? undefined
            : 'This field is required.',
          goalDescription: draft.goalDescription
            ? undefined
            : 'This field is required.',
          goalDueDate: draft.goalDueDate
            ? undefined
            : 'This field is required.',
          goalCategory:
            hasGoalCategories && !draft.goalCategory
              ? 'This field is required.'
              : undefined,
        };

        const isError = Object.values(fieldErrors).some(Boolean);

        if (isError)
          return of(editGoalScheduleSlice.setFieldErrors(fieldErrors));

        const scheduleId = uuid.generate();

        const response = await formsMutation.createGoalSchedule(client, {
          scheduleId,
          updates: draft,
        });

        return response.isSuccess
          ? of(editGoalScheduleSlice.saved({ reason: 'Created' }))
          : of(
              editGoalScheduleSlice.setGlobalError({
                to: 'An error occurred while trying to save the schedule. Please try again later.',
              }),
            );
      } catch (err) {
        log.error('Unable to create goal schedule', err);
        return of(
          editGoalScheduleSlice.setGlobalError({
            to: 'An error occurred while trying to save the schedule. Please try again later.',
          }),
        );
      }
    }),
    concatAll(),
  );
}

function updateNotStartedGoalScheduleEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(editGoalScheduleSlice.save.match),
    filter(() =>
      Boolean(state$.value.editGoalSchedule.original?.status === 'NotStarted'),
    ),
    mergeMap(async (action) => {
      try {
        const hasGoalCategories = action.payload.hasGoalCategories;
        const draft = state$.value.editGoalSchedule.draft;

        const fieldErrors = {
          startDate: draft.startDate ? undefined : 'This field is required.',
          endDate:
            draft.applyToNewStaff && !draft.endDate
              ? 'This field is required.'
              : undefined,
          distributionList: draft.distributionList
            ? undefined
            : 'This field is required.',
          goalDescription: draft.goalDescription
            ? undefined
            : 'This field is required.',
          goalDueDate: draft.goalDueDate
            ? undefined
            : 'This field is required.',
          goalCategory:
            hasGoalCategories && !draft.goalCategory
              ? 'This field is required.'
              : undefined,
        };

        const isError = Object.values(fieldErrors).some(Boolean);

        if (isError)
          return of(editGoalScheduleSlice.setFieldErrors(fieldErrors));

        const updateResponse = await formsMutation.updateGoalSchedule(client, {
          scheduleId: draft.id,
          updates: draft,
        });

        if (!updateResponse.isSuccess)
          return of(
            editGoalScheduleSlice.setGlobalError({
              to: 'An error occurred while trying to save the schedule. Please try again later.',
            }),
          );

        if (draft.status !== 'Inactive')
          return of(editGoalScheduleSlice.saved({ reason: 'Updated' }));

        const deactivateResponse = await formsMutation.deactivateGoalSchedule(
          client,
          draft.id,
        );

        return deactivateResponse.isSuccess
          ? of(editGoalScheduleSlice.saved({ reason: 'Deactivated' }))
          : of(
              editGoalScheduleSlice.setGlobalError({
                to: 'There was a problem deactivating the schedule. Please try again later.',
              }),
            );
      } catch (err) {
        log.error('Unable to update goal schedule', err);
        return of(
          editGoalScheduleSlice.setGlobalError({
            to: 'An error occurred while trying to save the schedule. Please try again later.',
          }),
        );
      }
    }),
    concatAll(),
  );
}

function updateStartedGoalScheduleEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(editGoalScheduleSlice.save.match),
    filter(() =>
      Boolean(state$.value.editGoalSchedule.original?.status === 'Started'),
    ),
    mergeMap(async () => {
      const draft = state$.value.editGoalSchedule.draft;
      const original = state$.value.editGoalSchedule.original;

      try {
        if (draft.applyToNewStaff && !draft.endDate)
          return of(
            editGoalScheduleSlice.setFieldErrors({
              endDate: 'This field is required.',
            }),
          );

        if (draft.endDate !== original.endDate) {
          const updateEndDateResponse =
            await formsMutation.updateGoalScheduleEndDate(client, {
              scheduleId: draft.id,
              endDate: draft.endDate,
            });

          if (!updateEndDateResponse.isSuccess) {
            return of(
              editGoalScheduleSlice.setGlobalError({
                to: 'An error occurred while trying to save the end date on the schedule. Please try again later.',
              }),
            );
          }
        }

        if (draft.status !== 'Inactive')
          return of(editGoalScheduleSlice.saved({ reason: 'Updated' }));

        const deactivateResponse = await formsMutation.deactivateGoalSchedule(
          client,
          draft.id,
        );

        return deactivateResponse.isSuccess
          ? of(editGoalScheduleSlice.saved({ reason: 'Deactivated' }))
          : of(
              editGoalScheduleSlice.setGlobalError({
                to: 'There was a problem deactivating the schedule. Please try again later.',
              }),
            );
      } catch (err) {
        log.error(`Unable to update goal schedule ${draft.id}`, err);
        return of(
          editGoalScheduleSlice.setGlobalError({
            to: 'An error occurred while trying to save the schedule. Please try again later.',
          }),
        );
      }
    }),
    concatAll(),
  );
}

function reactivateGoalScheduleEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(editGoalScheduleSlice.save.match),
    filter(() =>
      Boolean(state$.value.editGoalSchedule.original?.status === 'Inactive'),
    ),
    mergeMap(async () => {
      const draft = state$.value.editGoalSchedule.draft;

      if (draft.status === 'Inactive')
        return of(editGoalScheduleSlice.dismiss());

      try {
        const response = await formsMutation.activateGoalSchedule(
          client,
          draft.id,
        );

        return response.isSuccess
          ? of(editGoalScheduleSlice.saved({ reason: 'Activated' }))
          : of(
              editGoalScheduleSlice.setGlobalError({
                to: 'There was a problem reactivating the schedule. Please try again later.',
              }),
            );
      } catch (err) {
        log.error(`Unable to reactivate goal schedule ${draft.id}`, err);
        return of(
          editGoalScheduleSlice.setGlobalError({
            to: 'An error occurred while trying to save the schedule. Please try again later.',
          }),
        );
      }
    }),
    concatAll(),
  );
}

function loadGoalSchedulesOnScheduleSavedEpic(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    filter(editGoalScheduleSlice.saved.match),
    map(() => goalScheduleSlice.loadGoalSchedules({ loadNextPage: false })),
  );
}
