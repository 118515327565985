// NOTE:  Add more env vars through `next.config.js`.
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import getConfig, { setConfig } from 'next/config.js';

export function updateConfig(updates?: any) {
  return setConfig(updates);
}
function config() {
  return getConfig();
}

function publicRuntimeConfig() {
  return config()?.publicRuntimeConfig || {};
}

type PublicValueOptions = {
  key: string;
  assertIsRequired: boolean | 'warn';
  defaultVal?: string;
};

function publicValue({
  key,
  assertIsRequired,
  defaultVal,
}: PublicValueOptions) {
  const value = publicRuntimeConfig()[key];

  if (assertIsRequired === true && value === undefined) {
    throw new Error(`Environment variable '${key}' is not specified`);
  }

  if (assertIsRequired === 'warn' && value === undefined) {
    log.warn(`Warning: Environment variable '${key}' is not specified`);
  }

  return (value ?? defaultVal) as string | undefined;
}

export const env = {
  /**
   * GraphQL endpoint.
   */
  GRAPHQL_ENDPOINT() {
    return publicValue({
      key: 'GRAPHQL_ENDPOINT',
      assertIsRequired: 'warn',
    }) as string;
  },

  /**
   * Image version from Datadog.
   */
  VERSION() {
    return publicValue({
      key: 'VERSION',
      assertIsRequired: false,
    });
  },

  /**
   * Datadog - global is enabled flag.
   */
  DD_RUM_ENABLED() {
    return (
      publicValue({
        key: 'DD_RUM_ENABLED',
        assertIsRequired: false,
      }) === 'true'
    );
  },

  /**
   * Datadog - Application ID.
   */
  DD_APP_ID() {
    return publicValue({
      key: 'DD_APP_ID',
      assertIsRequired:
        publicValue({
          key: 'DD_RUM_ENABLED',
          assertIsRequired: false,
        }) === 'true',
    });
  },

  /**
   * Datadog - Client token for application.
   */
  DD_CLIENT_TOKEN() {
    return publicValue({
      key: 'DD_CLIENT_TOKEN',
      assertIsRequired:
        publicValue({
          key: 'DD_RUM_ENABLED',
          assertIsRequired: false,
        }) === 'true',
    });
  },

  /**
   * Datadog - Name of the datadog service/app to log to.
   */
  DD_SERVICE() {
    return publicValue({
      key: 'DD_SERVICE',
      assertIsRequired:
        publicValue({
          key: 'DD_RUM_ENABLED',
          assertIsRequired: false,
        }) === 'true',
    });
  },

  /**
   * Datadog - Label for the deployed environment e.g. "livestage".
   */
  DD_ENVIRONMENT_LABEL() {
    return publicValue({
      key: 'DD_ENV',
      assertIsRequired:
        publicValue({
          key: 'DD_RUM_ENABLED',
          assertIsRequired: false,
        }) === 'true',
    });
  },

  /**
   * Datadog - Track user interactions e.g. app clicks.
   */
  DD_TRACK_INTERACTIONS() {
    return (
      publicValue({
        key: 'DD_TRACK_INTERACTIONS',
        assertIsRequired: false,
      }) === 'true'
    );
  },

  /**
   * Analytics - Track user actions in the app
   */
  ANALYTICS_ENABLED() {
    return Boolean(
      publicValue({
        key: 'SEGMENT_WRITE_KEY',
        assertIsRequired: false,
      }),
    );
  },

  /**
   * Reporting
   */
  REPORTING_URL() {
    return publicValue({
      key: 'REPORTING_URL',
      assertIsRequired: false,
    });
  },

  /**
   * Segment - Write key for specified source
   */
  SEGMENT_WRITE_KEY() {
    return publicValue({
      key: 'SEGMENT_WRITE_KEY',
      assertIsRequired: false,
    });
  },
};
