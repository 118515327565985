import { combineEpics } from 'redux-observable';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import { FormReduxAction } from '../types.ts';
import {
  saveResultEpic,
  validateAndSaveScoreResultEpic,
} from './epic.overruleScore.ts';
import { calculateScoreChangeEpic } from './epic.scoreChanged.ts';
import {
  updateRemediationCommentaryOnChangeEpic,
  updateRemediationResultOnChangeEpic,
} from './epic.updateRemediation.ts';
import {
  calculateScoreOnInstanceLoadEpic,
  instanceLoadedCopyScoreEpic,
} from './epics.instanceLoad.ts';

export const epics = combineEpics<
  FormReduxAction,
  FormReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  calculateScoreOnInstanceLoadEpic,
  calculateScoreChangeEpic,
  updateRemediationResultOnChangeEpic,
  updateRemediationCommentaryOnChangeEpic,
  saveResultEpic,
  validateAndSaveScoreResultEpic,
  instanceLoadedCopyScoreEpic,
);
