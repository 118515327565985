import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { StateObservable, ofType } from 'redux-observable';
import { Observable, filter, map, switchMap } from 'rxjs';
import { IDigitalContentLine } from '../../types/types.ts';
import { ReduxFormInstanceServerLoaded } from '../form-instance/types.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import {
  digitalContentLoad,
  digitalContentLoadError,
  digitalContentLoaded,
} from './actions.ts';
import { ReduxFormDigitalContentLoad } from './types.ts';

const digitalContentLine = (
  state: GlobalFormsState,
  instanceId: string,
): IDigitalContentLine | undefined => {
  const instance = state.formInstance.instances[instanceId];
  return instance?.lines.digitalContent as IDigitalContentLine;
};

export function loadOnFormInstanceEpic(
  action$: Observable<ReduxFormInstanceServerLoaded>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/SERVER_LOADED'),
    filter(({ payload: { instanceId } }) =>
      Boolean(digitalContentLine(state$.value, instanceId)?.isEnabled),
    ),
    map(({ payload: { instanceId } }) =>
      digitalContentLoad(digitalContentLine(state$.value, instanceId)?.packId),
    ),
  );
}

export function loadDigitalContentEpic(
  action$: Observable<ReduxFormDigitalContentLoad>,
  _: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/digital-content/LOAD'),
    filter(({ payload: { packId } }) => Boolean(packId)),
    switchMap(async ({ payload: { packId, dashboardComponentId } }) => {
      const contentPack = await queryDigitalContent(client, packId as string);
      return contentPack
        ? digitalContentLoaded(contentPack, dashboardComponentId)
        : digitalContentLoadError(
            'Something went wrong while trying to retrieve this content. Click here to retry.',
          );
    }),
  );
}

const queryDigitalContent = async (client: IGraphQLClient, packId: string) => {
  try {
    const response = await client.query<any>({
      query: getDigitalContentQuery,
      variables: { id: packId },
      fetchPolicy: 'network-only',
    });
    return response.data.digitalContent.contentPack;
  } catch (error) {
    log.error(`GraphQL Error: Error loading digital content ${packId}
     ${error}`);
    return;
  }
};

const getDigitalContentQuery = gql`
  query DigitalContentPack($id: ID!) {
    digitalContent {
      contentPack(id: $id) {
        commentGuidance
        commentsEnabled
        description
        files {
          id
          name
          size
          downloadLink
          type
        }
        id
        publishDate
        summary
      }
    }
  }
`;
