import { rejectedFileMetadata } from './fileMetadata.ts';
import {
  FormFileCopyFromFormInstance,
  FormFileDeleted,
  FormFileDeleteError,
  FormFileDeleteRequest,
  FormFileDownloadComplete,
  FormFileDownloadError,
  FormFileDownloadRequested,
  FormFileUpload,
  FormFileUploaded,
  FormFileUploadError,
  FormFileUploadRejected,
  IFormFile,
} from './types.ts';

export function fileUploadRejected(
  files: File[],
  maxSize: number,
): FormFileUploadRejected {
  const toRejectedFile = rejectedFileMetadata(maxSize);
  return {
    type: 'ui.forms/file/UPLOAD_REJECTED',
    payload: {
      rejections: files.map(toRejectedFile),
    },
  };
}

export function fileUpload(payload: FormFileUpload['payload']): FormFileUpload {
  return {
    type: 'ui.forms/file/UPLOAD',
    payload,
  };
}

export function fileUploaded(
  fileId: string,
  metadata: IFormFile,
): FormFileUploaded {
  return {
    type: 'ui.forms/file/UPLOADED',
    payload: { fileId, metadata },
  };
}

export function fileUploadError(
  fileId: string,
  errorMessage: string,
): FormFileUploadError {
  return {
    type: 'ui.forms/file/UPLOAD_ERROR',
    payload: { fileId, errorMessage },
  };
}

export function fileDownloadRequest(
  fileId: string,
  formInstanceId: string,
): FormFileDownloadRequested {
  return {
    type: 'ui.forms/file/DOWNLOAD_REQUEST',
    payload: { fileId, formInstanceId },
  };
}

export function fileDownloadComplete(fileId: string): FormFileDownloadComplete {
  return {
    type: 'ui.forms/file/DOWNLOAD_COMPLETE',
    payload: {
      fileId,
    },
  };
}

export function fileDownloadError(
  fileId: string,
  errorMessage: string,
): FormFileDownloadError {
  return {
    type: 'ui.forms/file/DOWNLOAD_ERROR',
    payload: { fileId, errorMessage },
  };
}

export function fileDeleteRequest(
  fileId: string,
  formInstanceId: string,
): FormFileDeleteRequest {
  return {
    type: 'ui.forms/file/DELETE_REQUEST',
    payload: { fileId, formInstanceId },
  };
}

export function fileDeleted(
  fileId: string,
  formInstanceId: string,
): FormFileDeleted {
  return {
    type: 'ui.forms/file/DELETED',
    payload: { fileId, formInstanceId },
  };
}

export function fileDeleteError(
  fileId: string,
  errorMessage: string,
): FormFileDeleteError {
  return {
    type: 'ui.forms/file/DELETE_ERROR',
    payload: { fileId, errorMessage },
  };
}

export function fileCopyFromFormInstance(
  filesMetadata: IFormFile[],
): FormFileCopyFromFormInstance {
  return {
    type: 'ui.forms/file/COPY_FROM_FORM_INSTANCE',
    payload: { filesMetadata },
  };
}
