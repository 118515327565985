import gql from 'graphql-tag';
import { Observable, switchMap, filter } from 'rxjs';
import { StateObservable, ofType } from 'redux-observable';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';
import {
  remediationChangedError,
  remediationChangedSuccess,
  remediationReasonChangedError,
  remediationReasonChangedSuccess,
} from './actions.ts';
import {
  FormResult,
  IFormScoreState,
  ReduxFormScoreRemediationChanged,
  ReduxFormScoreRemediationReasonChanged,
  RemediationOutcome,
} from './types.ts';
import { getScoreState } from './util.calculateScore.ts';
import {
  getCalculatedScore,
  updateScore,
  UpdateScoreInputs,
} from './util.saveScore.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';

export function updateRemediationResultOnChangeEpic(
  action$: Observable<ReduxFormScoreRemediationChanged>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/score/REMEDIATION_CHANGED'),
    filter((action) =>
      Boolean(action.payload.instanceId && getScoreState(state$.value)),
    ),
    switchMap(async (action) => {
      const instanceId = action.payload.instanceId;
      const scoreState = getScoreState(state$.value) as IFormScoreState;

      const updateScoreInputs = getUpdateScoreInputs(
        scoreState,
        instanceId,
        action.payload.to,
      );
      if (updateScoreInputs) {
        const updateScoreResponse = await updateScore(
          client,
          updateScoreInputs,
        );
        if (!updateScoreResponse) {
          return remediationChangedError(instanceId);
        }
      }

      const comments = state$.value.formScore.remediationReason;
      const remediationResponse = await updateRemediation(client, {
        formInstanceId: instanceId,
        comments,
        outcome: action.payload.to,
      });
      return remediationResponse
        ? remediationChangedSuccess(instanceId)
        : remediationChangedError(instanceId);
    }),
  );
}

export function updateRemediationCommentaryOnChangeEpic(
  action$: Observable<ReduxFormScoreRemediationReasonChanged>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/score/REMEDIATION_REASON_CHANGED'),
    filter((action) =>
      Boolean(action.payload.instanceId && state$.value.formScore.remediation),
    ),
    switchMap(async (action) => {
      const outcome = state$.value.formScore.remediation;
      const instanceId = action.payload.instanceId;

      const response = await updateRemediation(client, {
        formInstanceId: instanceId,
        outcome,
        comments: action.payload.to,
      });

      return response
        ? remediationReasonChangedSuccess(instanceId)
        : remediationReasonChangedError(instanceId);
    }),
  );
}

const getUpdateScoreInputs = (
  scoreState: IFormScoreState,
  formInstanceId: string,
  remediation: RemediationOutcome,
): UpdateScoreInputs | undefined => {
  if (!scoreState.score) {
    return undefined;
  }

  const { score, overruledResult } = scoreState;
  const { currentWeightPoints, result, rawPercentageScore } = score;

  if (result === FormResult.NotApplicable && !overruledResult) {
    return undefined;
  }

  const outcome = ((): UpdateScoreInputs['outcome'] => {
    switch (remediation) {
      case RemediationOutcome.No:
        return FormResult.Fail;
      case RemediationOutcome.Yes:
        return FormResult.Pass;
      case RemediationOutcome.Pending:
      default:
        return FormResult.Provisional;
    }
  })();

  const calculatedScore = getCalculatedScore(
    result,
    rawPercentageScore,
    overruledResult,
  );

  return {
    finalScore: calculatedScore,
    rawScore: calculatedScore,
    totalWeighting: currentWeightPoints,
    outcome,
    formInstanceId,
  };
};

const updateRemediation = async (
  client: IGraphQLClient,
  variables: {
    formInstanceId: string;
    comments?: string;
    outcome: RemediationOutcome;
  },
) => {
  try {
    const response = await client.mutate<{
      forms: { setRemediationResultOnFormInstance: { ok: boolean } };
    }>({
      mutation: gql`
        mutation UpdateFormRemediation(
          $formInstanceId: ID!
          $outcome: RemediationOutcome!
          $comments: String
        ) {
          forms {
            setRemediationResultOnFormInstance(
              input: {
                formInstanceId: $formInstanceId
                outcome: $outcome
                comments: $comments
              }
            ) {
              ok
            }
          }
        }
      `,
      variables,
    });

    const isSuccessful =
      response.data.forms.setRemediationResultOnFormInstance.ok;
    if (!isSuccessful) {
      throw new Error('Mutation returned a non-ok response.');
    }
    return true;
  } catch (err) {
    log.error(
      new Error(
        `Error occurred trying to update the remediation for instance ${variables.formInstanceId} ${err}`,
      ),
    );
    return false;
  }
};
