import momentTz from 'moment-timezone';

import {
  DropdownAnswerDefinition,
  InputDefinition,
  InputFieldsDefinition,
} from '@seeeverything/ui.forms/src/parse/types/input.types.ts';
import { TextSectionDefinition } from '@seeeverything/ui.forms/src/parse/types/section.types.ts';
import {
  FormTemplateItemDefinition,
  SectionItem,
} from '@seeeverything/ui.forms/src/parse/types/template.types.ts';
import { FormActionStatus, FormGoalStatus } from './types.ts';

export const getFormTemplateSubjectType = (definition: string) => {
  let items: FormTemplateItemDefinition[] = [];
  try {
    items = JSON.parse(definition);
  } catch {
    return undefined;
  }

  const sections = items
    .map((item) => (item as SectionItem).section)
    .filter(Boolean);

  const questions = sections
    .map((section) => section.questions)
    .filter(Boolean)
    .flat();

  const textQuestions = questions
    .map((question) => (question as TextSectionDefinition).text)
    .filter(Boolean);

  const inputs = textQuestions
    .map(
      (textQuestion) =>
        (textQuestion as InputFieldsDefinition).fields ?? [
          textQuestion as InputDefinition,
        ],
    )
    .flat();

  const subjectQuestion = inputs
    .map((input: DropdownAnswerDefinition) =>
      input.dropdownListName && input.id === 'subject'
        ? input.dropdownListName
        : undefined,
    )
    .filter(Boolean)?.[0]
    ?.toLowerCase();

  return subjectQuestion === 'people' ? 'Person' : 'Team';
};

/**
 * Returns supplied status or 'Overdue' when today is after end-of 'dueBy' day.
 */
export const statusWithOverdue = (
  status: FormGoalStatus | FormActionStatus,
  timezone: string,
  dueBy?: string,
) => {
  if (!dueBy || (status !== 'Open' && status !== 'Overdue')) {
    return status;
  }
  const isOverdue = momentTz()
    .tz(timezone)
    .startOf('day')
    .isAfter(momentTz(dueBy).tz(timezone).endOf('day'));

  return isOverdue ? 'Overdue' : 'Open';
};
