import {
  ApolloQueryResult,
  MutationOptions,
  MutationResult,
  QueryOptions,
  QueryResult,
  WatchQueryOptions,
} from '@apollo/client';
import { DocumentNode } from 'graphql';
import { Subject } from 'rxjs';
import { GqlRequestResponse, IGraphQLClient } from './types.ts';

const INIT_ERROR =
  'The module has not been initialized with a GraphQLClient or a corresponding mock has not been setup.';

/**
 * A mock version of the [GraphQLClient].
 */
export class GraphQLClientMock implements IGraphQLClient {
  public mock = new Mocks();

  private client: IGraphQLClient;

  constructor(client?: IGraphQLClient) {
    this.client = client;
  }

  public QueryMutation$: Subject<GqlRequestResponse>;

  public async query<T>(options: QueryOptions): Promise<ApolloQueryResult<T>> {
    const { query } = options;

    // Look for a matching mock handler.
    const name = findQueryName(query);
    const mock = this.mock.queries[name];

    // No mock found - defer to client to process request.
    if (!mock && this.client) return this.client.query(options);

    if (!mock) {
      throw new Error(`Cannot run the query: '${name}'. ${INIT_ERROR}`);
    }

    const result: any = {
      loading: false,
      networkStatus: 7,
      stale: false,
    };

    try {
      result.data = await mock.handler(options);
    } catch (error) {
      result.errors = [error];
    }
    mock.history.push({ options, result });
    return result;
  }

  public async mutate<T>(options: MutationOptions<T>) {
    const { mutation } = options;
    const name = findQueryName(mutation);
    const mock = this.mock.mutations[name];

    // No mock found - defer to client to process request.
    if (!mock && this.client) return this.client.mutate(options);

    if (!mock) {
      throw new Error(`Cannot run the mutation: '${name}'. ${INIT_ERROR}`);
    }

    let result: any;

    try {
      result = { data: await mock.handler(options) };
    } catch (error) {
      result = { errors: [error] };
    }

    mock.history.push({ options, result });
    return result;
  }
}

const findQueryName = (query: DocumentNode) => {
  const def = query.definitions[0] as any;
  if (!def) {
    return;
  }
  return def.name ? def.name.value : undefined;
};

export type MockQueryHandler = (options: WatchQueryOptions) => Promise<any>;
export interface IMockQueryLog {
  options: WatchQueryOptions;
  result: QueryResult<any>;
}
export interface IMockQuery {
  handler: MockQueryHandler;
  history: IMockQueryLog[];
}
export interface IMockQueries {
  [name: string]: IMockQuery;
}

export type MockMutationHandler = (options: MutationOptions) => Promise<any>;
export interface IMockMutationLog {
  options: MutationOptions;
  result: MutationResult<any>;
}
export interface IMockMutation {
  handler: MockMutationHandler;
  history: IMockMutationLog[];
}
export interface IMockMutations {
  [name: string]: IMockMutation;
}

export class Mocks {
  public queries: IMockQueries = {};
  public mutations: IMockMutations = {};

  public reset() {
    this.queries = {};
    this.mutations = {};
  }

  public history = {
    query: (name: string): IMockQueryLog[] =>
      (this.queries[name] && this.queries[name].history) || [],
    mutation: (name: string): IMockMutationLog[] =>
      (this.mutations[name] && this.mutations[name].history) || [],
  };

  public query(name: string, handler: MockQueryHandler) {
    this.queries[name] = { handler, history: [] };
    return this;
  }

  public mutation(name: string, handler: MockMutationHandler) {
    this.mutations[name] = { handler, history: [] };
    return this;
  }
}
