import { GlobalFormsState } from '../store.ts';
import * as issueSlice from './issueSlice.ts';

export const complianceIssueValidationErrorActions = (
  state: GlobalFormsState,
) => {
  if (!state.formIssue.issues.length) return;

  return state.formIssue.issues
    .map((issue) => {
      const notesMissing = Boolean(!issue.notes && issue.notesRequired);
      const classificationMissing = Boolean(
        issue.classificationOptions?.required &&
          issue.classificationOptions?.options?.length &&
          !issue.classifications?.length,
      );

      if (!notesMissing && !classificationMissing) return undefined;

      return issueSlice.complianceErrors({
        answerId: issue.answerId,
        classification: classificationMissing ? 'This is required.' : undefined,
        notes: notesMissing ? 'This is required.' : undefined,
      });
    })
    .filter(Boolean);
};

export const issueCoachingPlanValidationErrorActions = (
  instanceId: string,
  state: GlobalFormsState,
) => {
  if (!instanceId) return;
  if (!state) return;

  const instance = state.formInstance.instances[instanceId];
  if (!instance) return;
  if (!state.formIssue.issues.length) return;

  const issueCoachingPlanActionPlan =
    state.formActionPlan.actionPlan.issueCoaching?.plan;
  if (!issueCoachingPlanActionPlan) return;
  if (issueCoachingPlanActionPlan.hasSections === true) return;

  return state.formIssue.issues
    .filter((issue) => issue.coaching.specifyCauseAndCoaching.isSelected)
    .map((issue) => {
      const issueActions = issueCoachingPlanActionPlan.actions.filter(
        (action) => action.issueId === issue.id,
      );

      const primaryCauseRequired = !issue.issueCauses.causes.some(
        (cause) => cause.isPrimary,
      );

      const primaryCauseNotesRequired = Boolean(
        issue.issueCauses.causes.some(
          (cause) => cause.isPrimary && !cause.notes,
        ),
      );

      const coachingConversationRequired = !issue.coachingConversation;

      const actionsRequiredSelectionRequired =
        issue.actionsRequired !== true && issue.actionsRequired !== false;

      const noActionsReasonRequired =
        issue.actionsRequired === false && !issue.noActionsReason;

      const coachingIssueActionRequired =
        issue.actionsRequired === true &&
        !issueActions.some((action) => action.status !== 'Cancelled');

      const noOpenActionsWithNoActionSelectedRequired =
        issue.actionsRequired === false &&
        issueActions.some((action) =>
          ['Open', 'Overdue'].includes(action.status),
        );

      if (
        !primaryCauseRequired &&
        !primaryCauseNotesRequired &&
        !coachingConversationRequired &&
        !actionsRequiredSelectionRequired &&
        !noActionsReasonRequired &&
        !coachingIssueActionRequired &&
        !noOpenActionsWithNoActionSelectedRequired
      )
        return;

      return issueSlice.coachingErrors({
        issueId: issue.id,
        primaryCause: validationMessage(primaryCauseRequired),
        primaryCauseNotes: validationMessage(primaryCauseNotesRequired),
        coachingConversation: validationMessage(coachingConversationRequired),
        actionsRequiredSelection: validationMessage(
          actionsRequiredSelectionRequired,
        ),
        noActionsReason: validationMessage(noActionsReasonRequired),
        coachingIssueAction: validationMessage(coachingIssueActionRequired),
        noOpenActionsWithNoActionSelected: validationMessage(
          noOpenActionsWithNoActionSelectedRequired,
        ),
      });
    })
    .filter(Boolean);
};

const validationMessage = (required?: boolean) =>
  required ? 'This is required.' : undefined;
