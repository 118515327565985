/** @jsxImportSource @emotion/react */
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { IconButton } from '@seeeverything/ui.primitives/src/components/IconButton/IconButton.tsx';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { css, SerializedStyles } from '@emotion/react';
import React from 'react';
import { FormFileAction } from '../../../redux/form-file/types.ts';

export interface IStatusIconButtonProps {
  isError?: boolean;
  canRetry?: boolean;
  action?: FormFileAction;
  canDownload?: boolean;
  canView?: boolean;
  onDownloadClick: () => void;
  style?: SerializedStyles;
}
const StatusIconButton: React.FC<IStatusIconButtonProps> = (props) => {
  const {
    isError,
    action,
    onDownloadClick,
    canDownload,
    canRetry,
    canView,
    style,
  } = props;
  return isError && !canRetry && action !== 'downloading' ? (
    <div css={[styles.iconOuter, styles.iconShift, style]}>
      <Icons.errorOutline fill={'rgba(255, 0, 0, 0.5)'} />
    </div>
  ) : (
    <div css={[styles.iconOuter, style]}>
      {(canDownload || canView || isError) && (
        <IconButton
          icon={canView ? <Icons.playArrow /> : <Icons.fileDownload />}
          fill={isError ? 'rgba(255, 0, 0, 0.5)' : COLORS.BLUE}
          disabledFill={'#DEDEDE'}
          onClick={onDownloadClick}
          tooltip={canView ? 'Play' : 'Download'}
        />
      )}
    </div>
  );
};

const styles = {
  iconOuter: css({
    flex: '30px 1 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  }),
  iconShift: css({
    position: 'relative',
    top: -3,
  }),
};

export default React.memo(StatusIconButton);
