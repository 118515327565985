/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import {
  FormControl,
  FormHelperText,
  makeStyles,
  InputLabelProps as MaterialInputLabelProps,
  InputProps as MaterialInputProps,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles.js';
import { Autocomplete, AutocompleteCloseReason } from '@material-ui/lab';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { isFuzzyMatch } from '@seeeverything/ui.util/src/str/str.fuzzy.ts';
import { is } from 'ramda';
import {
  isValidElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CommonStyles } from '../../common/commonStyles.ts';
import { useViewport } from '../../hooks/useViewport.ts';
import { IconContent } from '../IconContent/IconContent.tsx';
import { IListItemLabel, ISelectionListItem } from '../SelectionList/types.ts';
import { Spinner } from '../Spinner/index.ts';
import { Tooltip } from '../Tooltip/Tooltip.tsx';

export type TextVariant = TextFieldProps['variant'];

export type TextFieldDropdownCloseHandler = (
  e: React.ChangeEvent,
  reason: AutocompleteCloseReason,
) => void;

export interface ITextFieldDropdownProps<T = any, V = any> {
  block?: boolean;
  dataTest?: string;
  defaultValue?: ISelectionListItem<T, V>;
  disableClearable?: boolean;
  emptyLabel?: string;
  emptyValueLabel?: string;
  error?: string;
  formatItem?: (item: any) => ISelectionListItem<T, V>;
  hasNextPage?: boolean;
  helperText?: string;
  id: string;
  InputLabelProps?: MaterialInputLabelProps;
  InputProps?: MaterialInputProps;
  inputValue?: string;
  isEnabled?: boolean;
  isLoading?: boolean;
  isLoadingNextPage?: boolean;
  isOpen?: boolean;
  label?: string;
  listBoxStyle?: CSSProperties;
  onChange?: (to: ISelectionListItem<T, V>) => void;
  onClose?: TextFieldDropdownCloseHandler;
  onInputChanged?: (to: string, reason: 'reset' | 'input') => void;
  onNextPageRequested?: () => void;
  onOpen?: () => void;
  openOnFocus?: boolean;
  PaperComponent?: React.FC;
  placeholder?: string;
  PopperComponent?: React.FC;
  popperStyle?: CSSProperties;
  popupIndicator?: CSSProperties;
  selectionLabel?: (selection: ISelectionListItem<T, V>) => string;
  selections?: ISelectionListItem<T, V>[];
  shouldFilter?: boolean;
  size?: 'medium' | 'small';
  style?: SerializedStyles;
  value?: ISelectionListItem<T, V> | null;
  variant?: TextVariant;
}

/**
 * See:
 *  https://material-ui.com/components/autocomplete/
 */
export const TextFieldDropdown: React.FC<ITextFieldDropdownProps> = ({
  block,
  dataTest,
  defaultValue,
  disableClearable = true,
  emptyLabel = 'Nothing to display.',
  emptyValueLabel,
  error,
  formatItem,
  hasNextPage,
  helperText,
  id,
  InputLabelProps,
  InputProps,
  inputValue,
  isEnabled = true,
  isLoading,
  isLoadingNextPage,
  isOpen,
  label,
  listBoxStyle,
  onChange,
  onClose,
  onInputChanged,
  onNextPageRequested,
  onOpen,
  openOnFocus,
  PaperComponent,
  placeholder,
  PopperComponent,
  popperStyle,
  popupIndicator,
  selections = [],
  shouldFilter = true,
  size,
  style,
  value = null, // Empty value should be set to `null` to specify this is a controlled component.
  variant = 'standard',
}) => {
  const [trackLoadMoreRef, inViewport] = useViewport({ delay: 30 });

  useEffect(() => {
    if (!onNextPageRequested) return;
    if (!inViewport) return;
    if (!hasNextPage) return;
    if (isLoadingNextPage) return;

    onNextPageRequested();
  }, [hasNextPage, inViewport, isLoadingNextPage, onNextPageRequested]);

  const selectionLabel = useCallback(
    (selection?: ISelectionListItem): string => {
      if (!selection) return '';
      if (!selection.content) return selection.value as string;
      if (is(String, selection.content)) return selection.content as string;
      if ((selection.content as IListItemLabel).text)
        return (selection.content as IListItemLabel).text as string;

      return selection.value as string;
    },
    [],
  );

  const renderSelectionItem = useCallback(
    (selection: ISelectionListItem) => {
      if (isValidElement(selection.content)) return selection.content;

      const formattedSelection = formatItem ? formatItem(selection) : selection;

      const isLast =
        selections.findIndex((s) => s.id === selection.id) ===
        selections.length - 1;

      return (
        <div css={styles.selectionItem}>
          <IconContent
            dataTest={
              selection.dataTest
                ? `primitives-textFieldDropdown-${selection.dataTest}`
                : undefined
            }
            icon={formattedSelection.icon}
            iconProps={
              formattedSelection.iconColor
                ? { fill: formattedSelection.iconColor.default.toString() }
                : undefined
            }
            content={formattedSelection.content}
            emptySelectionLabel={emptyValueLabel}
          />
          {isLast && hasNextPage && (
            <div
              ref={!isLoadingNextPage ? trackLoadMoreRef : undefined}
              css={css({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              })}
            >
              <Spinner />
            </div>
          )}
        </div>
      );
    },
    [
      emptyValueLabel,
      formatItem,
      hasNextPage,
      isLoadingNextPage,
      selections,
      trackLoadMoreRef,
    ],
  );

  const handleSelectionChanged = useCallback(
    (_: React.ChangeEvent, to: ISelectionListItem | null) => {
      if (to !== null && to.isEnabled !== false) onChange?.(to);
    },
    [onChange],
  );

  const handleInputChanged = useCallback(
    (_: React.ChangeEvent, to: string, reason: 'reset' | 'input') => {
      if (to !== undefined) onInputChanged?.(to, reason);
    },
    [onInputChanged],
  );

  const inputClasses = useInputStyles();

  const [isFocused, setIsFocused] = useState(false);
  const focus = useCallback(() => setIsFocused(true), []);
  const blur = useCallback(() => setIsFocused(false), []);

  const ellipsisPlaceholder = isFocused || Boolean(value);

  const renderTextInput = useCallback(
    (textFieldProps: TextFieldProps) => (
      <Tooltip title={label} placement={'top'}>
        <TextField
          {...textFieldProps}
          label={label}
          placeholder={placeholder}
          variant={variant}
          error={Boolean(error)}
          onBlur={blur}
          onFocus={focus}
          InputProps={{
            classes: inputClasses,
            ...textFieldProps.InputProps,
            ...InputProps,
          }}
          InputLabelProps={{
            style: {
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              width: ellipsisPlaceholder ? '133%' : '100%',
              maxWidth: ellipsisPlaceholder ? undefined : 'calc(100% - 30px)',
              height: '100%',
              minHeight: 19,
            },
            ...textFieldProps.InputLabelProps,
            ...InputLabelProps,
          }}
        />
      </Tooltip>
    ),
    [
      InputLabelProps,
      InputProps,
      blur,
      ellipsisPlaceholder,
      error,
      focus,
      inputClasses,
      label,
      placeholder,
      variant,
    ],
  );

  const computedStyles = useMemo(
    () => ({
      base: css([
        {
          width: '100%',
          position: 'relative',
          display: block ? 'block' : 'inline-block',
        },
        error ? CommonStyles.AnimationShake : undefined,
        style,
      ]),
    }),
    [block, error, style],
  );

  const elHint = Boolean(error || helperText) && (
    <FormHelperText error={Boolean(error)}>
      {error || helperText}
    </FormHelperText>
  );

  const noFilter = useCallback((options: ISelectionListItem[]) => options, []);

  const filterSelectionListItem = useCallback(
    (options: ISelectionListItem[], state: { inputValue: string }) => {
      const input = state.inputValue;

      return options.filter((option) => {
        if (is(String, option.content))
          return isFuzzyMatch(input, option.content as string);

        const text = (option.content as IListItemLabel)?.text ?? option.value;
        return isFuzzyMatch(input, text);
      });
    },
    [],
  );

  const classStyles = useStyles(listBoxStyle, popperStyle, popupIndicator)();

  const options = useMemo(() => {
    if (isLoading && !isLoadingNextPage) return [];
    if (!value) return selections;

    const selectionValue =
      selections.find((selection) => selection.id === value.id) ?? value;

    const otherSelections = selections.filter(
      (selection) => selection.id !== value?.id,
    );

    return [selectionValue ?? value, ...otherSelections];
  }, [isLoading, isLoadingNextPage, selections, value]);

  return (
    <div css={computedStyles.base} data-test={dataTest}>
      <FormControl fullWidth={true} error={Boolean(error)}>
        <Autocomplete
          autoComplete={false}
          classes={{
            option: classStyles.option,
            groupLabel: classStyles.groupLabel,
            listbox: classStyles.listbox,
            popper: classStyles.popper,
            loading: classStyles.loading,
            popupIndicator: classStyles.popupIndicator,
          }}
          defaultValue={defaultValue}
          disableClearable={disableClearable}
          disabled={!isEnabled}
          filterOptions={shouldFilter ? filterSelectionListItem : noFilter}
          filterSelectedOptions={false}
          getOptionLabel={selectionLabel}
          getOptionSelected={(o, v) => o?.id === v?.id}
          groupBy={({ category }) => category}
          id={id}
          inputValue={inputValue}
          loading={isLoading}
          loadingText={
            <div css={styles.spinner}>
              <Spinner center={true} />
            </div>
          }
          multiple={false}
          noOptionsText={emptyLabel}
          onChange={handleSelectionChanged}
          onClose={onClose}
          onInputChange={handleInputChanged}
          onOpen={onOpen}
          open={isOpen}
          openOnFocus={openOnFocus}
          options={options}
          PaperComponent={PaperComponent}
          PopperComponent={PopperComponent}
          renderInput={renderTextInput}
          renderOption={renderSelectionItem}
          selectOnFocus={true}
          size={size}
          value={value}
        />
      </FormControl>
      {elHint}
    </div>
  );
};

const useStyles = (
  listBoxStyle?: CSSProperties,
  popperStyle?: CSSProperties,
  popupIndicator?: CSSProperties,
) =>
  makeStyles({
    option: {
      // Styles the currently selected option.
      '&[aria-selected="true"]': {
        backgroundColor: color.create(COLORS.BLUE).alpha(0.1).css(),
      },
      paddingLeft: '14px !important',
    },
    groupLabel: {
      fontSize: 12,
      lineHeight: 2,
      backgroundColor: '#F4F4F4',
      textTransform: 'uppercase',
      fontWeight: 700,
      textShadow: `0px 0px ${color.format(1)}`,
      color: color.format(-0.3),
      top: 0,
    },
    listbox: {
      padding: 0,
      ...listBoxStyle,
    },
    popper: popperStyle,
    loading: { position: 'relative' },
    popupIndicator: popupIndicator,
  });

const useInputStyles = makeStyles({
  root: { '&:hover': { borderColor: COLORS.BLUE } },
  input: { cursor: 'text' },
});

const styles = {
  spinner: css({
    height: 30,
  }),
  selectionItem: css({
    flex: '1 1 auto',
    overflowX: 'hidden',
    padding: '2px 0px',
  }),
};
