import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormBulkUploadJob } from '@se/data/forms/types.ts';
import { OrderBy } from '@seeeverything/ui.util/src/types.ts';
import {
  FormTemplateColumnFilter,
  PeopleColumnFilter,
} from '../../types/types.ts';

export type FormBulkUploadJobHistoryState = {
  currentPage: number;
  error: boolean;
  hasNextPage: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  jobs: FormBulkUploadJob[];
  orderBys: OrderBy[];
  filters: {
    createdBy: PeopleColumnFilter[];
    formTemplate: FormTemplateColumnFilter[];
  };
};

const DEFAULT_STATE: FormBulkUploadJobHistoryState = {
  currentPage: 1,
  error: false,
  hasNextPage: false,
  isLoading: false,
  isLoadingMore: false,
  jobs: [],
  orderBys: [
    {
      fieldName: 'createdAt',
      direction: 'Descending',
    },
  ],
  filters: {
    createdBy: [],
    formTemplate: [],
  },
};

const slice = createSlice({
  name: 'libs/forms/formBulkUploadJobHistory',
  initialState: DEFAULT_STATE,
  reducers: {
    loadJobs(state, action: PayloadAction<{ loadNextPage: boolean }>) {
      state.error = false;
      state.isLoading = !action.payload.loadNextPage;
      state.isLoadingMore = action.payload.loadNextPage;
    },
    loadedJobs(
      state,
      action: PayloadAction<{
        currentPage: number;
        hasNextPage: boolean;
        jobs: FormBulkUploadJob[];
      }>,
    ) {
      const { jobs, hasNextPage, currentPage } = action.payload;
      state.error = false;
      state.isLoading = false;
      state.isLoadingMore = false;
      state.currentPage = currentPage;
      state.hasNextPage = hasNextPage;
      if (currentPage === 1) state.jobs = jobs;
      else state.jobs.push(...jobs);
    },
    loadJobsFailed(state) {
      state.error = true;
      state.isLoading = false;
      state.isLoadingMore = false;
    },
    sort(
      state,
      action: PayloadAction<{
        fieldName: string;
        direction: 'Ascending' | 'Descending';
      }>,
    ) {
      state.orderBys = [
        {
          fieldName: action.payload.fieldName,
          direction: action.payload.direction,
        },
      ];
    },
    toggleFilterCreatedBy(
      state,
      action: PayloadAction<{ filter: PeopleColumnFilter; to: boolean }>,
    ) {
      if (
        action.payload.to &&
        !state.filters.createdBy.some(
          (filter) => filter.value.id === action.payload.filter.value.id,
        )
      )
        state.filters.createdBy.push(action.payload.filter);

      if (!action.payload.to)
        state.filters.createdBy = state.filters.createdBy.filter(
          (filter) => filter.value.id !== action.payload.filter.value.id,
        );
    },
    toggleFilterFormTemplate(
      state,
      action: PayloadAction<{ filter: FormTemplateColumnFilter; to: boolean }>,
    ) {
      if (
        action.payload.to &&
        !state.filters.formTemplate.some(
          (filter) => filter.value.id === action.payload.filter.value.id,
        )
      )
        state.filters.formTemplate.push(action.payload.filter);

      if (!action.payload.to)
        state.filters.formTemplate = state.filters.formTemplate.filter(
          (filter) => filter.value.id !== action.payload.filter.value.id,
        );
    },
    clearAllColumnFiltersCreatedBy(state) {
      state.filters.createdBy = [];
    },
    clearAllColumnFiltersFormTemplate(state) {
      state.filters.formTemplate = [];
    },
    clearAllColumnFilters(state) {
      state.filters.createdBy = [];
      state.filters.formTemplate = [];
    },
  },
});

export const {
  loadJobs,
  loadedJobs,
  loadJobsFailed,
  sort,
  toggleFilterCreatedBy,
  toggleFilterFormTemplate,
  clearAllColumnFiltersCreatedBy,
  clearAllColumnFiltersFormTemplate,
  clearAllColumnFilters,
} = slice.actions;
export const reducer = slice.reducer;
export type State = FormBulkUploadJobHistoryState;
