/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';
import { FormBulletType } from '../../types/types.ts';
import { BulletSmall } from './components/BulletSmall.tsx';
import { BulletBlock } from './components/BulletBlock.tsx';
import { BulletPerson } from './components/BulletPerson.tsx';
import { BulletAdd } from './components/BulletAdd.tsx';
import { BulletActionPlan } from './components/BulletActionPlan.tsx';
import { BulletDigitalContent } from './components/BulletDigitalContent.tsx';
import { BulletScore } from './components/BulletScore.tsx';

export interface IBulletProps {
  bullet: FormBulletType;
  style?: SerializedStyles;
}

/**
 * The visual bullet that accompanies a question within the form.
 */
export const Bullet = ({ bullet, style }: IBulletProps) => {
  switch (bullet) {
    case 'none':
      return <div />;
    case 'small-open':
      return <BulletSmall isFilled={false} style={style} />;
    case 'small-filled':
      return <BulletSmall isFilled={true} style={style} />;
    case 'block':
      return <BulletBlock style={style} />;
    case 'person':
      return <BulletPerson style={style} />;
    case 'action-plan':
      return <BulletActionPlan style={style} />;
    case 'person-speech':
      return <BulletPerson style={style} isSpeech={true} />;
    case 'add':
      return <BulletAdd style={style} />;
    case 'digitalContent':
      return <BulletDigitalContent style={style} />;
    case 'score':
      return <BulletScore style={style} />;
    default:
      throw new Error(`Bullet type '${bullet}' not supported.`);
  }
};
