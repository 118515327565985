/** @jsxImportSource @emotion/react */
import { ReactNode, useMemo } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import {
  ActionButtons,
  IActionBarButton,
} from '../ActionButtons/ActionButtons.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export interface IActionBarProps {
  actions: IActionBarButton[];
  style?: SerializedStyles;
  leftContent?: ReactNode;
  buttonWidth?: string | number;
}

export const ActionBar = ({
  leftContent,
  style,
  actions,
  buttonWidth,
}: IActionBarProps) => {
  const computedStyles = useMemo(
    () => ({
      base: css([
        {
          backgroundColor: '#EDEDED',
          borderTop: `solid 1px ${color.format(-0.1)}`,
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'stretch',
          alignItems: 'stretch',
          width: '100%',
        },
        style,
      ]),
    }),
    [style],
  );
  return (
    <div css={computedStyles.base}>
      <div css={styles.leftOuter}>{leftContent}</div>
      <ActionButtons
        style={styles.buttonsOuter}
        actions={actions}
        buttonWidth={buttonWidth}
      />
    </div>
  );
};

const styles = {
  buttonsOuter: css({
    margin: 10,
  }),
  leftOuter: css({
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'stretch',
  }),
};
