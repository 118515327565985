/** @jsxImportSource @emotion/react */
import { safeParse } from '@seeeverything/ui.util/src/json/json.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import {
  CUSTOM_LOCAL_STORAGE_CHANGED,
  LocalStorageCustomEvent,
  storageApi,
} from '@seeeverything/ui.util/src/storage/api.ts';
import { useEffect, useState } from 'react';

type UseLocalStorageSetValue<T = string> = ((current?: T) => T) | T;

/**
 * Hook for providing a stateful integration to local storage.
 * State changes are triggered for any changes (even external) to local storage.
 * @param {string} key Local storage key.
 * @param {string} initialValue Initial/default value.
 *
 * @example
 * const [name, setName] = useLocalStorage<string>("name", "Bob");
 * return (
 *   <div>
 *     <input
 *       type={'text'}
 *       placeholder={'Enter your name'}
 *       value={name}
 *       onChange={(e) => setName(e.target.value)}
 *     />
 *   </div>
 * );
 */
export function useLocalStorage<T = string>(key: string, initialValue?: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') return initialValue;

    try {
      return storageApi.localStorageApi.get<T>(key) ?? initialValue;
    } catch (error) {
      log.error(error);
      return initialValue;
    }
  });

  const setValue = (value: UseLocalStorageSetValue<T>) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);
      storageApi.localStorageApi.set(key, valueToStore);
    } catch (error) {
      log.error(error);
    }
  };

  useEffect(() => {
    const handleStorageEvent = (e: StorageEvent) => {
      if (e.key !== key) return;
      setStoredValue(safeParse<{ value: T }>(e.newValue)?.value);
    };

    const handleCustomStorageEvent = (
      e: CustomEvent<LocalStorageCustomEvent<T>>,
    ) => {
      if (e.type !== CUSTOM_LOCAL_STORAGE_CHANGED) return;
      if (e.detail.key !== key) return;
      setStoredValue(e.detail.value);
    };

    window.addEventListener('storage', handleStorageEvent);
    window.addEventListener(
      CUSTOM_LOCAL_STORAGE_CHANGED,
      handleCustomStorageEvent,
    );
    return () => {
      window.removeEventListener('storage', handleStorageEvent);
      window.removeEventListener(
        CUSTOM_LOCAL_STORAGE_CHANGED,
        handleCustomStorageEvent,
      );
    };
  }, [key]);

  return [storedValue, setValue] as const;
}
