/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export interface IBulletSmallProps {
  isFilled: boolean;
  style?: SerializedStyles;
}

/**
 * A regular small circular bullet.
 */
export const BulletSmall = ({ isFilled, style }: IBulletSmallProps) => {
  const computedStyles = useMemo(
    () => ({
      base: css(
        {
          width: 7,
          height: 7,
          borderRadius: 5,
          backgroundColor: isFilled ? '#DDDDDD' : 'white',
          border: isFilled
            ? 'solid 1px #CDCDCD'
            : `solid 1px ${color.format(-0.2)}`,
        },
        style,
      ),
    }),
    [isFilled, style],
  );

  return <div css={computedStyles.base} />;
};
