import { IQuestionLine, ISectionLine } from '../types/types.ts';
import { defaultId, getProp } from '../util/util.data.parse.ts';
import { QuestionDefinition } from './types/question.types.ts';

export function parseQuestion(
  item: QuestionDefinition,
  parentSection: ISectionLine,
  indices: number[],
): IQuestionLine | undefined {
  if (!item) return;

  return {
    id: getProp(item, 'id') || defaultId(indices),
    parentId: parentSection.id,
    showOnAnswer: getProp(item, 'showOnAnswer'),
    showOnReportingDateMonth: item.showOnReportingDateMonth,
    showOnSwitch: getProp(item, 'showOnSwitch'),
    highlightOnHover: false,
    type: 'question',
    label: getProp(item, 'label'),
    showIndex: false,
  };
}
