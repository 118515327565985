import {
  ActionPlanAreaDefinition,
  AttendanceAreaDefinition,
  FileUploadAreaDefinition,
} from '../parse/types/parse.types.ts';
import { FormTemplateItemDefinition } from '../parse/types/template.types.ts';

export const defaultId = (indices: number[]) => indices.join('-');

/**
 * Utility function.
 *  Gets a property from an object (case-insensitive), and if not found, returns an optionally specified
 *  default return value.
 */
export function getProp<I extends object, P extends keyof I>(
  item: I,
  propName: P,
  defaultVal?: I[P],
): I[P] {
  if (!item || !propName) return defaultVal as I[P];

  const key = Object.keys(item).find(
    (itemKey) => itemKey.toLowerCase() === propName.toString().toLowerCase(),
  ) as P;
  return key ? item[key] : defaultVal;
}

export const defaultFileUploadEnabled = (
  items: FormTemplateItemDefinition[],
) => {
  const hasFileUpload = items.some(
    (item: FileUploadAreaDefinition) =>
      getProp(item, 'fileUpload') !== undefined,
  );

  return hasFileUpload
    ? items
    : [...items, { fileUpload: { isEnabled: true } }];
};

export const defaultActionPlanEnabled = (
  items: FormTemplateItemDefinition[],
) => {
  const hasActionPlan = items.some(
    (item: ActionPlanAreaDefinition) =>
      getProp(item, 'actionPlan') !== undefined,
  );

  return hasActionPlan
    ? items
    : [...items, { actionPlan: { isEnabled: true } }];
};

export const defaultAttendanceEnabled = (
  items: FormTemplateItemDefinition[],
) => {
  const hasAttendance = items.some(
    (item: AttendanceAreaDefinition) =>
      getProp(item, 'attendance') !== undefined,
  );
  return hasAttendance
    ? items
    : [...items, { attendance: { isEnabled: true } }];
};

export const defaultDigitalContentEnabled = (
  items: FormTemplateItemDefinition[],
) => [...items, { digitalContent: { isEnabled: true } }];
