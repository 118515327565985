/** @jsxImportSource @emotion/react */
import React from 'react';
import { Skeleton } from '../../Skeleton/Skeleton.tsx';
import { css, SerializedStyles } from '@emotion/react';

const NUMBER_OF_SKELETON_SENTENCES_IN_ITEM = 2;

export interface ISkeletonDetailedItemProps {
  animation?: 'wave' | 'pulse';
  style?: SerializedStyles;
}

/**
 * Provides a skeleton that resembles a typical detailed item.
 */
const SkeletonDetailedItem: React.FC<ISkeletonDetailedItemProps> = ({
  animation,
  style,
}) => {
  const generateSkeletonSentences = (numberOfSentences: number) =>
    Array.from({ length: numberOfSentences }).map((_, i) => (
      <Skeleton
        key={`skeleton-sentence-${i}`}
        width={i === numberOfSentences - 1 ? '70%' : '100%'}
        animation={animation}
        height={19}
      />
    ));

  const baseStyle = React.useMemo(() => css(styles.base, style), [style]);

  return (
    <div css={baseStyle}>
      <div css={styles.skeletonParagraph}>
        <Skeleton
          height={24}
          width={'30%'}
          animation={animation}
          key={'skeleton-title'}
        />
        {generateSkeletonSentences(NUMBER_OF_SKELETON_SENTENCES_IN_ITEM)}
      </div>
    </div>
  );
};

const styles = {
  skeletonParagraph: css({
    flex: '1 1 auto',
    marginTop: 6,
    marginBottom: 6,
  }),
  base: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '8px 16px',
  }),
};

export default React.memo(SkeletonDetailedItem);
