/** @jsxImportSource @emotion/react */
import { useCallback, useMemo } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { css, SerializedStyles } from '@emotion/react';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';
import { IFormFile, IFormRejectedFile } from '../../redux/form-file/types.ts';
import { FormFile } from '../FormFile/FormFile.tsx';
import { LabelButton } from '@seeeverything/ui.primitives/src/components/LabelButton/LabelButton.tsx';
import { EmptyFilesPanelSmall } from './components/EmptyFilesPanelSmall.tsx';
import { EmptyFilesPanelLarge } from './components/EmptyFilesPanelLarge.tsx';
import { getError } from './errors.ts';
import { ErrorBar } from './components/ErrorBar.tsx';

export interface IFormFilesPanelProps {
  allowedMimeTypes: Record<string, string[]>;
  allowedTypesReadable: string;
  canDelete: boolean;
  canUpload: boolean;
  emptySize: 'large' | 'small';
  files?: IFormFile[];
  maxSize: number;
  onDelete?: (fileId: string) => void;
  onDownload?: (fileId: string) => void;
  onFilesRejected?: (files: File[]) => void;
  onFilesUpload?: (files: File[]) => void;
  onView?: (fileId: string) => void;
  rejections?: IFormRejectedFile[];
  style?: SerializedStyles;
}

export const FormFilesPanel: React.FC<IFormFilesPanelProps> = ({
  allowedMimeTypes,
  allowedTypesReadable,
  canDelete = false,
  canUpload = false,
  emptySize = 'small',
  files = [],
  maxSize,
  onDelete,
  onDownload,
  onFilesRejected,
  onFilesUpload,
  onView,
  rejections = [],
  style,
}) => {
  const handleFileDropped = useCallback<DropzoneOptions['onDrop']>(
    (accepted, rejected) => {
      if (accepted?.length) onFilesUpload?.(accepted);
      if (rejected?.length) onFilesRejected?.(rejected.map(({ file }) => file));
    },
    [onFilesUpload, onFilesRejected],
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop: handleFileDropped,
      maxSize,
      accept: allowedMimeTypes,
    });

  const { onClick, ...rootProps } = getRootProps();

  const elInput = useMemo(
    () => <input {...getInputProps()} />,
    [getInputProps],
  );

  const error = useMemo(
    () =>
      canUpload && rejections.length > 0
        ? getError(maxSize, allowedTypesReadable, rejections)
        : undefined,
    [allowedTypesReadable, canUpload, maxSize, rejections],
  );

  const elError = useMemo(
    () =>
      error && (
        <div css={styles.error}>
          <ErrorBar error={error} />
        </div>
      ),
    [error],
  );

  const elEmpty = useMemo(() => {
    const EmptyPanel =
      emptySize === 'small' ? EmptyFilesPanelSmall : EmptyFilesPanelLarge;

    return (
      !files.length && (
        <>
          <EmptyPanel
            canUpload={canUpload}
            onSelectFile={canUpload ? onClick : undefined}
          />
          {elInput}
        </>
      )
    );
  }, [emptySize, canUpload, elInput, files.length, onClick]);

  const elFiles = useMemo(
    () =>
      Boolean(files.length) && (
        <>
          <div css={styles.filesOuter}>
            {files.map((file) => (
              <div css={styles.fileOuter} key={file.id}>
                <FormFile
                  {...file}
                  canDelete={file.canDelete && canDelete}
                  onDownload={onDownload}
                  onDelete={onDelete}
                  onView={onView}
                />
              </div>
            ))}
          </div>
          {canUpload && (
            <div css={styles.button}>
              <LabelButton label={'Upload file'} onClick={onClick} />
              {elInput}
            </div>
          )}
        </>
      ),
    [
      canUpload,
      canDelete,
      elInput,
      files,
      onClick,
      onDelete,
      onDownload,
      onView,
    ],
  );

  const elReject = useMemo(
    () => canUpload && isDragReject && <div css={styles.dragReject} />,
    [canUpload, isDragReject],
  );
  const elActive = useMemo(
    () =>
      canUpload &&
      isDragActive &&
      !isDragReject && <div css={styles.dragActive} />,
    [canUpload, isDragActive, isDragReject],
  );
  const elDropzone = useMemo(
    () =>
      canUpload &&
      !isDragActive &&
      !isDragReject && <div css={styles.dropzone} />,
    [canUpload, isDragActive, isDragReject],
  );

  return (
    <div
      css={[styles.base, style]}
      {...(canUpload ? rootProps : undefined)}
      onClick={undefined}
    >
      {elDropzone}
      {elActive}
      {elReject}
      {elEmpty}
      {elFiles}
      {elError}
    </div>
  );
};

const styles = {
  base: css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minWidth: 350,
  }),
  filesOuter: css({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    paddingBottom: 25,
  }),
  fileOuter: css({ padding: '25px 0 0 25px' }),
  dragActive: css({
    position: 'absolute',
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `dashed 2px ${COLORS.BLUE}`,
    backgroundColor: '#6399FC20',
    borderRadius: 10,
  }),
  dragReject: css({
    position: 'absolute',
    inset: 0,
    border: 'dashed 2px rgba(255, 0, 0, 0.3)',
    backgroundColor: 'rgba(255, 0, 0, 0.1)',
    borderRadius: 10,
    cursor: 'not-allowed',
  }),
  dropzone: css({
    position: 'absolute',
    inset: 0,
    border: 'dashed 2px #DEDEDE',
    borderRadius: 10,
  }),
  button: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginBottom: 25,
  }),
  error: css({ margin: '0 25px 25px 25px' }),
};
