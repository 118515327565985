import { DocumentNode, OperationVariables } from '@apollo/client';
import { FetchPolicyType } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '../../log/log.ts';
import { IGraphQLClient } from '../client/types.ts';

export interface IRecursiveFetchAllOptions {
  client: IGraphQLClient;
  query: DocumentNode;
  pageSize: number;
  variables?: OperationVariables;
}

export const recursiveFetchAll = async <T>(
  options: IRecursiveFetchAllOptions,
  resolveHasNextPage: (response: T) => boolean,
  nextPage = 1,
  fetchPolicy: FetchPolicyType = 'network-only',
  currentResults: T[] = [],
): Promise<T[]> => {
  const resultsPage = await fetchPage<T>(fetchPolicy, options, nextPage);

  if (resultsPage) currentResults.push(resultsPage);

  const hasNextPage = resolveHasNextPage(resultsPage);

  if (hasNextPage) {
    await recursiveFetchAll(
      options,
      resolveHasNextPage,
      nextPage + 1,
      fetchPolicy,
      currentResults,
    );
  }

  return currentResults;
};

const fetchPage = async <T>(
  fetchPolicy: FetchPolicyType = 'network-only',
  options: IRecursiveFetchAllOptions,
  pageNumber = 1,
): Promise<T> => {
  try {
    const response = await options.client.query<any>({
      fetchPolicy,
      query: options.query,
      variables: {
        ...options.variables,
        pageSize: options.pageSize,
        pageNumber,
      },
    });

    return response.data;
  } catch (err) {
    log.error(
      new Error(
        `Failed at page ${pageNumber}, page size ${options.pageSize} - ${err.message}`,
      ),
    );
  }
};
