/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import { Attendance } from '../components/Attendance/Attendance.tsx';
import { FormListItemWrapped } from '../components/FormListItem/FormListItemWrapped.tsx';
import { SectionTitle } from '../components/SectionTitle/SectionTitle.tsx';
import { SpeechBlockContainer } from '../components/SpeechBlock/SpeechBlockContainer.tsx';
import { useHandleAnswerVisibility } from '../hooks/useHandleAnswerVisibility.ts';
import {
  updateAllAttendeesAttendance,
  updateAttendeeAttendance,
  updateAttendeeFollowUp,
} from '../redux/form-instance/instance/actions.ts';
import { useFormsDispatch, useFormsSelector } from '../redux/store.ts';
import { IAttendanceLine } from '../types/types.ts';

export interface IAttendanceFactoryContainerProps {
  canUpdateInstance: boolean;
  instanceId: string;
  isPdfExport: boolean;
  line: IAttendanceLine;
}

export const AttendanceFactoryContainer: React.FC<
  IAttendanceFactoryContainerProps
> = ({ canUpdateInstance, instanceId, isPdfExport, line }) => {
  const dispatch = useFormsDispatch();

  const isVisible = useHandleAnswerVisibility({
    instanceId,
    questionKeys: [],
    showOnAnswer: line.showOnAnswer,
    showOnReportingDateMonth: line.showOnReportingDateMonth,
    showOnSwitch: line.showOnSwitch,
  });

  const attendees = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.attendees ?? [],
  );

  const attendanceChecked = useCallback(
    (attendeeId: string, isChecked: boolean) =>
      dispatch(updateAttendeeAttendance(instanceId, attendeeId, isChecked)),
    [dispatch, instanceId],
  );

  const allAttendanceChecked = useCallback(
    (isChecked: boolean) => {
      const updateAttendeeIds = attendees
        .filter((attendee) => attendee.isAttending !== isChecked)
        .map(({ id }) => id);

      dispatch(
        updateAllAttendeesAttendance(instanceId, updateAttendeeIds, isChecked),
      );
    },
    [attendees, dispatch, instanceId],
  );

  const attendeeFollowUpChecked = useCallback(
    (attendeeId: string, isChecked: boolean) =>
      dispatch(updateAttendeeFollowUp(instanceId, attendeeId, isChecked)),
    [dispatch, instanceId],
  );

  const speechText = line.mandatoryFollowUp
    ? `Follow-up actions for staff that were absent are mandatory for this meeting and will be automatically created.`
    : `All follow-up actions noted below will be automatically created when the meeting is signed off.`;

  return (
    <FormListItemWrapped
      id={line.id}
      parentId={line.parentId}
      key={line.id}
      highlightOnHover={line.highlightOnHover}
      marginTop={30}
      marginBottom={40}
      bullet={'block'}
      isVisible={line.isEnabled && isVisible}
      bulletTop={-13.5}
      bulletLeft={0}
      isPdfExport={isPdfExport}
    >
      <SectionTitle text={line.title} />
      <FormListItemWrapped
        id={'speechBlock'}
        highlightOnHover={line.highlightOnHover}
        bullet={'person-speech'}
        marginTop={30}
        marginBottom={40}
        paddingLeft={-32}
        paddingRight={-32}
        bulletLeft={-37}
        bulletTop={9}
        isPdfExport={isPdfExport}
      >
        <SpeechBlockContainer
          id={line.id}
          instanceId={instanceId}
          isPdfExport={isPdfExport}
          markdown={speechText}
        />
      </FormListItemWrapped>
      <Attendance
        attendees={attendees}
        canUpdate={canUpdateInstance}
        onAllAttendedChecked={allAttendanceChecked}
        onAttendeeChecked={attendanceChecked}
        onAttendeeFollowUpClick={attendeeFollowUpChecked}
        isFollowUpMandatory={line.mandatoryFollowUp}
      />
    </FormListItemWrapped>
  );
};
