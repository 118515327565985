/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { CommonStyles } from '../../common/commonStyles.ts';
import { Markdown } from '../Markdown/Markdown.tsx';

export interface IMarkdownTextFieldProps {
  error?: string;
  isEnabled?: boolean;
  label?: string;
  value?: string;
  maxHeight?: string | number;
}

export const MarkdownTextField: React.FC<IMarkdownTextFieldProps> = ({
  error,
  isEnabled,
  label,
  value,
  maxHeight,
}) => {
  const computedStyles = {
    base: css({
      display: 'flex',
      flexDirection: 'column',
      gap: 6,
      paddingBottom: 7,
      height: value ? undefined : 40,
      justifyContent: 'flex-end',
    }),
    baseEnabled: css({
      borderBottom: error
        ? `solid 2px ${COLORS.ERROR_RED}`
        : 'solid 1px #949494',
      ':hover': {
        borderBottom: error
          ? `solid 2px ${COLORS.ERROR_RED}`
          : 'solid 2px #1f1f1f',
      },
    }),
    baseDisabled: css({
      borderBottom: 'dotted 1px #949494',
    }),
    content: css({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: value ? 'space-between' : 'flex-end',
      gap: 8,
      maxHeight,
      overflowY: maxHeight !== undefined ? 'auto' : 'unset',
    }),
  };

  return (
    <>
      <div
        css={
          isEnabled
            ? [computedStyles.base, computedStyles.baseEnabled]
            : [computedStyles.base, computedStyles.baseDisabled]
        }
      >
        <Text
          size={value ? 12 : undefined}
          color={error ? COLORS.ERROR_RED : '#888888'}
        >
          {label}
        </Text>
        {value && (
          <div css={computedStyles.content}>
            <Markdown
              text={value}
              color={isEnabled ? color.format(-0.8) : '#888888'}
              style={styles.markdown}
            />
          </div>
        )}
      </div>
      {error && (
        <Text
          size={12}
          color={COLORS.ERROR_RED}
          style={CommonStyles.AnimationShake}
        >
          {error}
        </Text>
      )}
    </>
  );
};

const styles = {
  markdown: css({
    overflowWrap: 'anywhere',
    '& div': {
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
    },
  }),
};
