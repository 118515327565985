/** @jsxImportSource @emotion/react */
import { FormListItemWrapped } from '../components/FormListItem/FormListItemWrapped.tsx';
import { ScoreContainer } from '../components/Score/ScoreContainer.tsx';
import { useHandleAnswerVisibility } from '../hooks/useHandleAnswerVisibility.ts';
import { useFormsSelector } from '../redux/store.ts';
import { IScoreLine } from '../types/types.ts';

export interface IScoreFactoryContainerProps {
  line: IScoreLine;
  instanceId: string;
  isPdfExport: boolean;
}

export const ScoreFactoryContainer: React.FC<IScoreFactoryContainerProps> = ({
  line,
  instanceId,
  isPdfExport,
}) => {
  const isVisible = useHandleAnswerVisibility({
    instanceId,
    questionKeys: [],
    showOnAnswer: line.showOnAnswer,
    showOnReportingDateMonth: line.showOnReportingDateMonth,
    showOnSwitch: line.showOnSwitch,
  });

  const instancePermissions = useFormsSelector(
    (state) => state.formInstance.instances?.[instanceId]?.permissions,
  );

  const instanceStatus = useFormsSelector(
    (state) => state.formInstance.instances?.[instanceId]?.status?.status ?? '',
  );

  const isFormEnabled = Boolean(
    instancePermissions?.edit &&
      ['InProgress', 'Created'].includes(instanceStatus),
  );

  const hasInstanceSignoffs = useFormsSelector(
    (state) => state.formInstance.instances?.[instanceId]?.signOffs?.length > 0,
  );

  const isProvisionalScoreEditable =
    (instancePermissions?.assignedToSignOff ||
      instancePermissions?.assignedToRevertSignOff) &&
    hasInstanceSignoffs;

  return (
    <FormListItemWrapped
      id={line.id}
      parentId={line.parentId}
      key={line.id}
      highlightOnHover={line.highlightOnHover}
      bullet={'score'}
      bulletTop={24}
      isPdfExport={isPdfExport}
      isVisible={isVisible}
    >
      <ScoreContainer
        canMarkProvisional={line.overrules.provisional}
        canOverruleNotApplicable={line.overrules.notApplicable}
        canOverruleResult={line.overrules.result}
        canRemediateProvisional={line.canRemediateProvisional}
        failLabel={line.labels.fail}
        hasScoreLabels={line.hasScoreLabels}
        instanceId={instanceId}
        isPdfExport={isPdfExport}
        isProvisionalEditable={isProvisionalScoreEditable}
        isResultEditable={isFormEnabled}
        passLabel={line.labels.pass}
        showScoreCalculation={line.showCalculation}
        speechText={line.speechText}
      />
    </FormListItemWrapped>
  );
};
