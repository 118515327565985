import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { FormAnswer } from '../../../types/types.ts';

export const createAnswer = async (
  client: IGraphQLClient,
  instanceId: string,
  answer: FormAnswer,
) => {
  try {
    const response = await client.mutate<{
      forms: { createFormAnswerOnFormInstance: { ok: boolean } };
    }>({
      mutation: gql`
        mutation CreateFormAnswer(
          $instanceId: ID!
          $answerId: ID!
          $answerKey: String!
          $value: String!
          $displayValue: String
          $group: String
        ) {
          forms {
            createFormAnswerOnFormInstance(
              input: {
                formInstanceId: $instanceId
                formAnswerId: $answerId
                key: $answerKey
                value: $value
                displayValue: $displayValue
                group: $group
              }
            ) {
              ok
            }
          }
        }
      `,
      variables: {
        instanceId,
        answerId: answer.id,
        answerKey: answer.lineId,
        value: answer.value,
        displayValue: answer.displayValue,
        group: answer.group,
      },
    });

    if (!response.data.forms.createFormAnswerOnFormInstance.ok)
      throw new Error('Not ok');

    return true;
  } catch (err) {
    log.error(
      `Problem creating answer ${answer.id} for instance ${instanceId} - ${err.message}`,
    );
    return false;
  }
};

export const updateAnswer = async (
  client: IGraphQLClient,
  instanceId: string,
  answer: FormAnswer,
) => {
  try {
    const response = await client.mutate<{
      forms: { updateFormAnswerValueOnFormInstance: { ok: boolean } };
    }>({
      mutation: gql`
        mutation UpdateFormAnswerValue(
          $instanceId: ID!
          $answerId: ID!
          $value: String!
          $displayValue: String
        ) {
          forms {
            updateFormAnswerValueOnFormInstance(
              input: {
                formInstanceId: $instanceId
                formAnswerId: $answerId
                value: $value
                displayValue: $displayValue
              }
            ) {
              ok
            }
          }
        }
      `,
      variables: {
        instanceId,
        answerId: answer.id,
        value: answer.value,
        displayValue: answer.displayValue,
      },
    });

    if (!response.data.forms.updateFormAnswerValueOnFormInstance.ok)
      throw new Error('Not ok');

    return true;
  } catch (err) {
    log.error(
      `Problem updating answer ${answer.id} for instance ${instanceId} - ${err.message}`,
    );
    return false;
  }
};

export const deleteAnswer = async (
  client: IGraphQLClient,
  instanceId: string,
  answerId: string,
) => {
  try {
    const result = await client.mutate<{
      forms: { deleteFormAnswerFromFormInstance: { ok: boolean } };
    }>({
      mutation: gql`
        mutation DeleteFormAnswer($answerId: ID!, $instanceId: ID!) {
          forms {
            deleteFormAnswerFromFormInstance(
              input: { formAnswerId: $answerId, formInstanceId: $instanceId }
            ) {
              ok
            }
          }
        }
      `,
      variables: { instanceId, answerId },
    });

    if (!result.data.forms.deleteFormAnswerFromFormInstance.ok)
      throw new Error('Not ok');

    return true;
  } catch (err) {
    log.error(
      new Error(
        `Problem deleting answer ${answerId} for instance ${instanceId} - ${err.message}`,
      ),
    );
    return false;
  }
};
