export const IMAGES_PATH = '/ui.forms/images';

export const DOCUMENT_UPLOAD = {
  maxSize: 10506250, // 10 MB.
  allowedMimeTypes: {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
      '.docx',
    ],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xlsx',
    ],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      ['.pptx'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.ms-powerpoint': ['.ppt'],
    'application/vnd.ms-outlook': ['.msg'],
    'text/plain': ['.txt'],
    'image/gif': ['.gif'],
    'image/svg+xml': ['.svg'],
    'application/pdf': ['.pdf'],
  },
  allowedTypesReadable: `jpg, png, Office, text, gif, svg, pdf`,
};

export const STREAMABLE_MIME_TYPES = [
  'video/mp4',
  'video/x-ms-wmv',
  'video/quicktime',
  'video/x-flv',
  'application/x-mpegURL',
  'video/MP2T',
  'video/3gpp',
  'video/x-msvideo',
];
