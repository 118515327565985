import { ofType } from 'redux-observable';
import { Observable, map } from 'rxjs';
import { FormFileUpload } from '../../form-file/types.ts';
import { addFileToFormInstance } from './actions.ts';

/**
 * Links a newly uploaded file to an existing form instance.
 */
export function addNewUploadToFormInstanceEpic(
  action$: Observable<FormFileUpload>,
) {
  return action$.pipe(
    ofType('ui.forms/file/UPLOAD'),
    map((action) =>
      addFileToFormInstance(
        action.payload.formInstanceId,
        action.payload.fileId,
      ),
    ),
  );
}
