/** @jsxImportSource @emotion/react */
import { useCallback, useMemo } from 'react';
import { PreviewList } from './PreviewList.tsx';
import { hideModalDialog } from '../../redux/modalDialog/actions.ts';
import { DialogOuter } from '../DialogOuter/index.ts';
import { MaterialListItemType } from '@seeeverything/ui.primitives/src/components/MaterialList/index.ts';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';
import { DistributionListType } from '@seeeverything/ui.forms/src/redux/distributionList/types.ts';

export interface IPreviewListContainerProps {
  items: MaterialListItemType[];
  type?: DistributionListType;
}

export const PreviewListContainer = ({
  items,
  type,
}: IPreviewListContainerProps) => {
  const locale = useShellSelector((state) => state.tenantState.tenant?.locale);
  const dispatch = useShellDispatch();
  const handleHide = useCallback(() => dispatch(hideModalDialog()), [dispatch]);

  const actionButtons = useMemo(
    () => [{ id: 'OK', label: 'OK', onAction: handleHide }],
    [handleHide],
  );

  return (
    <DialogOuter actions={actionButtons} buttonWidth={110}>
      <PreviewList items={items} locale={locale} type={type} />
    </DialogOuter>
  );
};
