/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export interface IBulletBlockProps {
  style?: SerializedStyles;
}

/**
 * A bullet that is solid rectangular block.
 */
export const BulletBlock = ({ style }: IBulletBlockProps) => {
  const computedStyles = useMemo(
    () => ({
      base: css(
        {
          width: 10,
          height: 50,
          backgroundColor: color.format(-0.4),
        },
        style,
      ),
    }),
    [style],
  );

  return <div css={computedStyles.base} />;
};
