/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { INTERSECTION_OBSERVER_DEFAULTS } from '@seeeverything/ui.primitives/src/common/constants.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useViewport } from '@seeeverything/ui.primitives/src/hooks/useViewport.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useLoadFormActionPlan } from '../../hooks/useLoadFormActionPlan.ts';
import { useFormsSelector } from '../../redux/store.ts';
import { FormListItem } from '../FormListItem/FormListItem.tsx';
import { IssueContainer } from '../Issue/IssueContainer.tsx';
import { IssueGroupSkeleton } from '../Issue/IssueGroupSkeleton.tsx';
import { SectionTitle } from '../SectionTitle/SectionTitle.tsx';

export interface IIssueSummaryContainerProps {
  instanceId: string;
  isPdfExport: boolean;
  title: string;
}

export const IssueSummaryContainer: React.FC<IIssueSummaryContainerProps> = ({
  instanceId,
  isPdfExport,
  title,
}) => {
  const [viewportRef, inViewport] = useViewport(INTERSECTION_OBSERVER_DEFAULTS);

  const isLoading = useFormsSelector((state) => state.formIssue.isLoading);
  const issueIds = useFormsSelector((state) =>
    state.formIssue.issues.map(({ id }) => id),
  );
  const reviewLabel = useFormsSelector(
    (state) => state.tenantState.tenant?.locale.label.form ?? 'review',
  );

  useLoadFormActionPlan({
    instanceId,
    lineId: 'issueCoaching',
    includeCategories: ['Issue Coaching Actions'],
    hasSections: false,
    inViewport,
  });

  const instanceInProgress = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    if (!instance) return false;

    return ['Created', 'InProgress'].includes(instance.status.status);
  });

  const elLoading = isLoading && <IssueGroupSkeleton />;

  const elEmpty = instanceInProgress ? (
    <div css={styles.empty}>
      <Icons.article size={120} fill={color.format(-0.3)} />
      <Text color={color.format(-0.8)} size={18} align={'center'}>
        {`No issues on this ${reviewLabel.toLowerCase()}.`}
      </Text>
      <Text color={color.format(-0.7)} size={14} align={'center'}>
        {'Issues will show here automatically once identified.'}
      </Text>
    </div>
  ) : (
    <div css={styles.empty}>
      <Icons.clipboardTick size={120} fill={COLORS.GREEN} />
      <Text color={color.format(-0.7)} size={18} align={'center'}>
        {`Great job!`}
      </Text>
      <Text color={color.format(-0.7)} size={14} align={'center'}>
        {`There are no issues in this ${reviewLabel.toLowerCase()}.`}
      </Text>
    </div>
  );

  const isEmpty = !issueIds.length;

  return (
    <FormListItem
      id={'issueSummary'}
      key={'issueSummary'}
      bullet={isPdfExport ? 'none' : 'block'}
      marginTop={50}
      marginBottom={40}
      isVisible={true}
      bulletTop={-13.5}
      bulletLeft={0}
    >
      <SectionTitle text={title} />
      <FormListItem
        id={'issueSummary-issuesIdentified'}
        bullet={isPdfExport ? 'none' : 'small-open'}
        paddingLeft={-32}
        paddingRight={-32}
        marginTop={30}
        bulletLeft={-37}
        bulletTop={6}
      >
        <div css={styles.base} ref={viewportRef}>
          <Text weight={600} color={color.format(-0.8)}>
            {'Issues Identified'}
          </Text>
          {elLoading}
          <div css={styles.issues}>
            {isEmpty
              ? !isLoading && elEmpty
              : issueIds.map((id) => (
                  <IssueContainer
                    key={id}
                    id={id}
                    enableActions={false}
                    showReviewLink={false}
                    defaultExpanded={true}
                    instanceId={instanceId}
                    showCreatedActions={true}
                  />
                ))}
          </div>
        </div>
      </FormListItem>
    </FormListItem>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    flex: '1 1 auto',
  }),
  issues: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  empty: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 5,
  }),
};
