/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import moment from 'moment';

export interface IHeaderProps {
  completedAt?: string;
}

export const Header = ({ completedAt }: IHeaderProps) => {
  const LockIcon = completedAt ? Icons.lock : Icons.lockOpen;
  const completedAtDate = moment(completedAt);

  const statusText = completedAt
    ? `Completed at ${completedAtDate.format(
        'h:mma',
      )}, ${completedAtDate.format('D MMMM YYYY')}`
    : 'Sign-off pending';

  return (
    <div css={styles.base}>
      <div css={styles.content}>
        <LockIcon fill={color.format(-0.3)} />
        <div data-test={'forms-signoff-header'} css={styles.status}>
          <Text size={12} color={color.format(-0.4)}>
            {statusText}
          </Text>
          {completedAt && <Icons.verifiedUser fill={COLORS.GREEN} />}
        </div>
      </div>
    </div>
  );
};

const styles = {
  base: css({
    boxSizing: 'border-box',
    borderBottom: `solid 1px ${color.format(-0.1)}`,
    minHeight: 36,
    display: 'flex',
  }),
  content: css({
    boxSizing: 'border-box',
    paddingLeft: 5,
    paddingRight: 10,
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  status: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
  }),
};
