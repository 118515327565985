/** @jsxImportSource @emotion/react */
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import { useCallback, useMemo } from 'react';
import { formInstanceAnswerChange } from '../../redux/form-instance/answer/actions.ts';
import { useFormsDispatch, useFormsSelector } from '../../redux/store.ts';
import { FormAnswer, IDateAnswerLine } from '../../types/types.ts';
import { DateAnswer } from '../DateAnswer/DateAnswer.tsx';

export interface IDateAnswerContainerProps {
  canUpdateInstance: boolean;
  instanceId: string;
  isPdfExport: boolean;
  isVisible: boolean;
  line: IDateAnswerLine;
}

export const DateAnswerContainer: React.FC<IDateAnswerContainerProps> = ({
  canUpdateInstance,
  instanceId,
  isPdfExport,
  isVisible,
  line,
}) => {
  const dispatch = useFormsDispatch();

  const newAnswerId = useMemo(() => {
    if (!isVisible) return;
    return uuid.generate();
  }, [isVisible]);

  const answer = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    return instance?.answers?.[line.id];
  });

  const answerValue = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    return instance?.answers?.[line.id]?.value?.toString();
  });

  const error = useFormsSelector(
    (state) =>
      state.formInstance.instances[instanceId]?.questionErrors?.[line.id],
  );

  const updateAnswer = useCallback(
    (to: string) => {
      if (!isVisible) return;

      const toAnswer: FormAnswer = {
        id: answer?.id ?? newAnswerId,
        value: to?.toString(),
        lineId: line.id,
        type: 'inputs',
        subType: 'dateAnswer',
        group: line.group,
      };

      dispatch(
        formInstanceAnswerChange(
          instanceId,
          answer?.id
            ? {
                type: 'UPDATE',
                toAnswer,
                fromAnswer: answer,
              }
            : {
                type: 'CREATE',
                toAnswer,
                creationPreviouslyFailed: Boolean(error),
              },
          true,
        ),
      );
    },
    [
      answer,
      dispatch,
      error,
      instanceId,
      isVisible,
      line.group,
      line.id,
      newAnswerId,
    ],
  );

  return (
    <DateAnswer
      allowPastDates={line.allowPastDates}
      error={error}
      floatingText={line.floatingText}
      id={line.id}
      isEnabled={canUpdateInstance}
      onChange={updateAnswer}
      renderText={isPdfExport}
      value={answerValue}
    />
  );
};
