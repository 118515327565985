import { Observable, map } from 'rxjs';
import { file } from '@seeeverything/ui.util/src/file/index.ts';
import { ofType } from 'redux-observable';
import { fileCopyFromFormInstance } from './actions.ts';
import {
  IFormDocument,
  ReduxFormInstanceServerLoaded,
} from '../form-instance/types.ts';
import { IFormFile } from './types.ts';

/**
 * After a form instance is loaded, copy the documents out to `form-file`.
 */
export function copyFromFormInstanceEpic(
  action$: Observable<ReduxFormInstanceServerLoaded>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/SERVER_LOADED'),
    map((action) =>
      fileCopyFromFormInstance(action.payload.instance.documents.map(toFile)),
    ),
  );
}

const toFile = ({
  id,
  documentName,
  documentSize,
  createdAt,
  createdBy,
}: IFormDocument): IFormFile => ({
  id,
  canDelete: true,
  canDownload: true,
  displaySize: file.getReadableSize(documentSize),
  extension: file.getExtension(documentName),
  name: documentName,
  size: documentSize,
  uploadedByName: createdBy.name,
  uploadedDate: createdAt,
});
