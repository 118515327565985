import { IFooLine, ISectionLine } from '../types/types.ts';
import { defaultId, getProp } from '../util/util.data.parse.ts';
import { FooDefinition } from './types/foo.types.ts';

export function parseFoo(
  item: FooDefinition,
  parentSection: ISectionLine,
  indices: number[],
): IFooLine | undefined {
  if (!item) return;

  return {
    id: getProp(item, 'id') || defaultId(indices),
    parentId: parentSection.id,
    showOnAnswer: getProp(item, 'showOnAnswer'),
    showOnReportingDateMonth: item.showOnReportingDateMonth,
    showOnSwitch: getProp(item, 'showOnSwitch'),
    highlightOnHover: false,
    type: 'foo',
    text: getProp(item, 'text'),
    width: getProp(item, 'width'),
    height: getProp(item, 'height'),
    showIndex: false,
  };
}
