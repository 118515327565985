/** @jsxImportSource @emotion/react */
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Checkbox as MaterialCheckbox,
} from '@material-ui/core';
import { CSSProperties, ReactNode, useCallback } from 'react';
import { ItemLabel } from './components/ItemLabel.tsx';
import { ItemLabelValues } from './types.ts';

export type CheckboxOption = {
  id: string;
  label: string | ItemLabelValues;
  tooltip?: string;
  dataTestId?: string;
  isDimmed?: boolean;
  isEnabled?: boolean;
  style?: CSSProperties;
};

export type CheckboxValue = {
  id: string;
  checked: boolean;
  value?: string;
};

export type CheckboxGroupEvent = {
  id: string;
  index: number;
  to: boolean;
};

export type CheckboxGroupEventHandler = (e: CheckboxGroupEvent) => void;

export interface ICheckboxGroupProps {
  value?: CheckboxValue[];
  onChange?: CheckboxGroupEventHandler;
  isEnabled?: boolean;
  options?: CheckboxOption[];
  title?: string;
  direction?: 'horizontal' | 'vertical';
  error?: string;
  helperText?: string;
  style?: CSSProperties;
  renderLabelOverride?: (
    label: string | ItemLabelValues,
    tooltip?: string,
    isDimmed?: boolean,
  ) => ReactNode;
}

/**
 * Supports a group of check boxes.
 * See:
 *  https://material-ui.com/components/checkboxes/
 */
export const CheckboxGroup: React.FC<ICheckboxGroupProps> = ({
  direction = 'vertical',
  error,
  helperText,
  isEnabled = true,
  onChange,
  options = [],
  renderLabelOverride,
  style,
  title,
  value = [],
}) => {
  const isHorizontal = direction === 'horizontal';

  const handleOnChange = useCallback(
    (id: string, index: number) => (_: any, to: boolean) =>
      onChange?.({ id, index, to }),
    [onChange],
  );

  const renderLabel = useCallback(
    (label: string | ItemLabelValues, tooltip?: string, isDimmed?: boolean) =>
      renderLabelOverride ? (
        renderLabelOverride(label, tooltip, isDimmed)
      ) : (
        <ItemLabel value={label} tooltip={tooltip} center={isHorizontal} />
      ),
    [renderLabelOverride, isHorizontal],
  );

  const elOptions = options.map((option, index) => (
    <FormControlLabel
      key={option.id}
      control={
        <MaterialCheckbox
          data-test={option.dataTestId}
          checked={Boolean(value.find((v) => v.id === option.id)?.checked)}
          onChange={handleOnChange(option.id, index)}
          disabled={!isEnabled || option.isEnabled === false}
        />
      }
      label={renderLabel(option.label, option.tooltip, option.isDimmed)}
      labelPlacement={isHorizontal ? 'top' : 'end'}
      style={
        isHorizontal ? { whiteSpace: 'nowrap', ...option.style } : option.style
      }
    />
  ));

  const elHelperText = Boolean(error || helperText) && (
    <FormHelperText>{error || helperText}</FormHelperText>
  );

  return (
    <FormControl
      error={Boolean(error)}
      fullWidth={true}
      disabled={!isEnabled}
      style={style}
    >
      {title && <FormLabel>{title}</FormLabel>}
      <FormGroup
        row={isHorizontal}
        style={{
          paddingTop: isHorizontal ? 5 : undefined,
          display: 'flex',
          flexWrap: 'nowrap',
        }}
      >
        {elOptions}
      </FormGroup>
      {elHelperText}
    </FormControl>
  );
};
