/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { LabelButton } from '@seeeverything/ui.primitives/src/components/LabelButton/LabelButton.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextLink } from '@seeeverything/ui.primitives/src/components/TextLink/TextLink.tsx';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import React from 'react';
import { ErrorBar } from './ErrorBar.tsx';

export interface IEmptyFilesPanelLargeProps {
  canUpload: boolean;
  onSelectFile: () => void;
  error?: string;
}

export const EmptyFilesPanelLarge: React.FC<IEmptyFilesPanelLargeProps> = ({
  canUpload,
  onSelectFile,
  error,
}) => {
  const computedStyles = {
    textUploadLink: css({
      ':hover': { textDecoration: 'underline' },
      cursor: 'pointer',
      color: COLORS.BLUE,
    }),
    outer: css({
      paddingTop: canUpload ? 69 : 18,
      paddingBottom: canUpload ? 69 : 18,
    }),
  };

  const elUploadButton = canUpload ? (
    <div css={styles.button}>
      <LabelButton label={'Upload file'} onClick={onSelectFile} />
    </div>
  ) : undefined;

  const elUpload = canUpload ? (
    <>
      <div css={styles.textBottom}>
        <Text
          size={14}
          color={'#808080'}
          weight={FontWeight.medium}
          ellipsis={true}
        >
          {'Drag files here to upload, or'}
        </Text>
        <div css={styles.horizontalSpacer} />
        <div onClick={onSelectFile} css={computedStyles.textUploadLink}>
          <TextLink
            size={14}
            weight={FontWeight.medium}
            cursor={'inherit'}
            block={true}
            ellipsis={true}
          >
            {'click here to get started.'}
          </TextLink>
        </div>
      </div>
      <div css={styles.buttonSpacer} />
      {elUploadButton}
    </>
  ) : undefined;

  const elError = canUpload && error && (
    <div css={styles.errorOuter}>
      <div css={styles.error}>
        <ErrorBar error={error} />
      </div>
    </div>
  );

  return (
    <div css={styles.base}>
      <div css={[styles.outer, computedStyles.outer]}>
        <div css={styles.circle}>
          <div css={styles.circleIcon}>
            <Icons.document size={60} fill={'#FFFFFF'} />
          </div>
        </div>
        <div css={styles.textTop}>
          <Text
            size={14}
            color={'#808080'}
            weight={FontWeight.medium}
            ellipsis={true}
          >
            {'There are no files attached.'}
          </Text>
        </div>
        {elUpload}
        {elError}
      </div>
    </div>
  );
};

const styles = {
  base: css({
    position: 'relative',
    minWidth: 350,
    justifyContent: 'center',
  }),
  outer: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
    overflow: 'hidden',
  }),
  circle: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 99,
    width: 99,
    backgroundColor: '#DEDEDE',
    borderRadius: '50%',
  }),
  circleIcon: css({
    paddingLeft: 3,
  }),
  textTop: css({
    paddingTop: 18,
  }),
  textBottom: css({
    display: 'flex',
  }),
  horizontalSpacer: css({
    width: 3,
  }),
  buttonSpacer: css({
    marginTop: 25,
  }),
  button: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  }),
  errorOuter: css({
    width: '100%',
  }),
  error: css({
    margin: '25px 25px 0 25px',
  }),
};
