import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { ofType, StateObservable } from 'redux-observable';
import { filter, mergeMap, Observable } from 'rxjs';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../../store.ts';
import { ReduxFormInstanceAnswerChange } from '../types.ts';
import {
  formInstanceAnswerChangeError,
  reportingDateChangedSuccess,
} from './actions.ts';

export const formInstanceReportingDateChangeEpic = (
  action$: Observable<ReduxFormInstanceAnswerChange>,
  _: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) =>
  action$.pipe(
    ofType('ui.forms/instance/ANSWER/CHANGE'),
    filter((action) => {
      if (action.payload.change.type === 'DELETE') return false;
      return action.payload.change.toAnswer.lineId === 'reportingDate';
    }),
    mergeMap(async (action) => {
      const { instanceId, change } = action.payload;
      const answer = change.toAnswer;

      try {
        await updateReportingDate(client, instanceId, answer.value);
      } catch (err) {
        log.error('Error occurred while updating reporting date.', err);
        return formInstanceAnswerChangeError(instanceId, change, true);
      }

      return reportingDateChangedSuccess(instanceId, answer);
    }),
  );

const updateReportingDate = async (
  client: IGraphQLClient,
  instanceId: string,
  reportingDate: string,
) => {
  const response = await client.mutate<{
    forms: { updateFormInstanceReportingDate: { ok: boolean } };
  }>({
    mutation: gql`
      mutation UpdateFormInstanceReportingDate(
        $instanceId: ID!
        $reportingDate: DateTime!
      ) {
        forms {
          updateFormInstanceReportingDate(
            input: {
              formInstanceId: $instanceId
              reportingDate: $reportingDate
            }
          ) {
            ok
          }
        }
      }
    `,
    variables: { instanceId, reportingDate },
  });

  if (!response.data.forms.updateFormInstanceReportingDate.ok) {
    throw new Error(
      `updateFormInstanceReportingDate mutation for instance ${instanceId} was not successful.`,
    );
  }
};
