import { file } from '@seeeverything/ui.util/src/file/index.ts';
import { IFormRejectedFile } from '../../redux/form-file/types.ts';

type ErrorType =
  | 'DEFAULT'
  | 'TOO_LARGE'
  | 'TOO_LARGE_PLURAL'
  | 'BLOCKED_TYPE'
  | 'BLOCKED_TYPE_PLURAL'
  | 'BLOCKED_TYPE_AND_TOO_LARGE';

export function getError(
  maxSize: number,
  allowedTypes: string,
  rejections: IFormRejectedFile[],
) {
  const error = getErrorType(rejections);
  return message(maxSize, allowedTypes, error);
}

function getErrorType(rejections: IFormRejectedFile[]): ErrorType | undefined {
  if (!rejections.length) {
    return undefined;
  }

  const size = rejections.filter((x) => x.reason === 'size').length;
  const type = rejections.filter((x) => x.reason === 'type').length;

  if (size && !type) {
    return size === 1 ? 'TOO_LARGE' : 'TOO_LARGE_PLURAL';
  }
  if (type && !size) {
    return type === 1 ? 'BLOCKED_TYPE' : 'BLOCKED_TYPE_PLURAL';
  }
  if (type && size) {
    return 'BLOCKED_TYPE_AND_TOO_LARGE';
  }
  return 'DEFAULT';
}

const message = (
  maxSize: number,
  allowedTypes: string,
  error: ErrorType = 'DEFAULT',
) => {
  const maxSizeReadable = file.getReadableSize(maxSize, 0);

  const messages: { [key in ErrorType]: string } = {
    DEFAULT: `We couldn't upload your file. Please try again.`,
    TOO_LARGE: `Your file is too large (max ${maxSizeReadable}.)`,
    TOO_LARGE_PLURAL: `Some of your files are too large (max ${maxSizeReadable}.)`,
    BLOCKED_TYPE: `Your file is not an allowed type (${allowedTypes}.)`,
    BLOCKED_TYPE_PLURAL: `Some of your files are not allowed types (${allowedTypes}.)`,
    BLOCKED_TYPE_AND_TOO_LARGE: `Some of your files are too large (max ${maxSizeReadable}) and some are not an allowed-type (${allowedTypes}.)`,
  };

  return messages[error];
};
