import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import gql from 'graphql-tag';
import { QueryResult, SimpleEntity } from '../../types.ts';

export type GetDistributionListsResponseDto = {
  pageInfo: { hasNextPage: boolean; currentPage: number };
  distributionLists: DistributionList[];
};

export type DistributionList = {
  id: string;
  name: string;
};

type GetDistributionListsArgs = {
  fetchAllPages?: boolean;
  module: ModuleType;
  pageNumber?: number;
  typeFilter?: 'Person' | 'Team';
};

export const getDistributionLists = async (
  client: IGraphQLClient,
  args: GetDistributionListsArgs,
): Promise<QueryResult<GetDistributionListsResponseDto>> => {
  if (!args.fetchAllPages) return getDistributionListsPage(client, args);
  const allResults = await getDistributionListsRecursive(client, args);
  return {
    isSuccess: true,
    data: {
      distributionLists: allResults,
      pageInfo: { hasNextPage: false, currentPage: 1 },
    },
  };
};

const getDistributionListsRecursive = async (
  client: IGraphQLClient,
  args: GetDistributionListsArgs,
  lists: DistributionList[] = [],
  pageNumber = 1,
): Promise<DistributionList[]> => {
  const response = await getDistributionListsPage(client, {
    module: args.module,
    typeFilter: args.typeFilter,
    pageNumber,
  });

  if (!response.isSuccess)
    throw new Error('Failed to retrieve distribution lists');

  const results = lists.concat(response.data.distributionLists);

  const pageInfo = response.data.pageInfo;
  if (!pageInfo.hasNextPage) return results;

  return getDistributionListsRecursive(
    client,
    args,
    results,
    pageInfo.currentPage + 1,
  );
};

const getDistributionListsPage = async (
  client: IGraphQLClient,
  args: GetDistributionListsArgs,
): Promise<QueryResult<GetDistributionListsResponseDto>> => {
  try {
    const response = await client.query<{
      orgHierarchy: {
        distributionLists: {
          pageInfo: { hasNextPage: boolean; currentPage: number };
          nodes: SimpleEntity[];
        };
      };
    }>({
      query: gql`
        query DistributionLists($pageNumber: Int, $typeFilter: String) {
          orgHierarchy {
            distributionLists(
              pagination: { pageNumber: $pageNumber, size: 200 }
              typeFilter: $typeFilter
            ) {
              pageInfo {
                hasNextPage
                currentPage
              }
              nodes {
                id
                name
              }
            }
          }
        }
      `,
      variables: {
        typeFilter: args.typeFilter,
        pageNumber: args.pageNumber ?? 1,
        module: args.module,
      },
      fetchPolicy: 'cache-first',
    });

    const result = response.data.orgHierarchy.distributionLists.nodes;
    if (!result?.length) {
      log.error(`Failed to retrieve distribution lists`);
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: {
        pageInfo: {
          hasNextPage:
            response.data.orgHierarchy.distributionLists.pageInfo.hasNextPage,
          currentPage:
            response.data.orgHierarchy.distributionLists.pageInfo.currentPage,
        },
        distributionLists: result.map((node) => ({
          id: node.id,
          name: node.name,
        })),
      },
    };
  } catch (error) {
    log.error(`Something went wrong trying to query distribution lists`, error);
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
