import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import {
  PersonWithTeamMemberships,
  ServerMembership,
  ServerRole,
} from '../types.ts';
import { translateMemberships } from '../utils/memberships.ts';
import { translateRoles } from '../utils/roles.ts';

export type GetPeopleTeamMembershipsResponseDto = {
  pageInfo: {
    hasNextPage: boolean;
    currentPage: number;
    totalCount: number;
  };
  people: PersonWithTeamMemberships[];
};

export type GetPeopleWithTeamMembershipsArgs = {
  pageNumber?: number;
  name?: string;
  email?: string;
  includeInactive?: boolean;
};

export const getPeopleWithTeamMemberships = async (
  client: IGraphQLClient,
  args: GetPeopleWithTeamMembershipsArgs,
): Promise<QueryResult<GetPeopleTeamMembershipsResponseDto>> => {
  try {
    const response = await client.query<{
      orgHierarchy: {
        people: {
          pageInfo: {
            hasNextPage: boolean;
            currentPage: number;
            totalCount: number;
          };
          nodes: Array<{
            id: string;
            isActive: boolean;
            firstName: string;
            lastName: string;
            email: string;
            employeeId: string;
            lineManager?: {
              id: string;
              email: string;
              firstName: string;
              isActive: boolean;
              lastName: string;
              positionTitle: string;
            };
            positionTitle: string;
            memberships: { nodes: ServerMembership[] };
            roles?: ServerRole[];
          }>;
        };
      };
    }>({
      query: gql`
        query PeopleWithTeamMemberships(
          $email: String
          $includeInactive: Boolean
          $name: String
          $pageNumber: Int!
        ) {
          orgHierarchy {
            people(
              email: $email
              includeInactive: $includeInactive
              name: $name
              pagination: { size: 50, pageNumber: $pageNumber }
            ) {
              pageInfo {
                hasNextPage
                currentPage
                totalCount
              }
              nodes {
                email
                employeeId
                firstName
                id
                isActive
                lastName
                lineManager {
                  id
                  email
                  firstName
                  isActive
                  lastName
                  positionTitle
                }
                memberships(
                  isParentManager: false
                  pagination: { size: 100, pageNumber: 1 }
                ) {
                  nodes {
                    team {
                      id
                      path
                      name
                      type
                    }
                    role
                    sourceKey
                    isPrimary
                  }
                }
                positionTitle
                roles {
                  id
                  name
                  sourceKey
                  team {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        pageNumber: args.pageNumber ?? 1,
        name: args.name,
        email: args.email,
        includeInactive: args.includeInactive,
      },
      fetchPolicy: 'no-cache',
    });

    const people = response.data.orgHierarchy.people.nodes;
    if (!people) {
      log.error(`Failed to retrieve people and team memberships`);
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: {
        people: people.map(
          (person): PersonWithTeamMemberships => ({
            id: person.id,
            firstName: person.firstName,
            lastName: person.lastName,
            isActive: person.isActive,
            email: person.email,
            lineManager: person.lineManager && {
              id: person.lineManager.id,
              email: person.lineManager.email,
              firstName: person.lineManager.firstName,
              lastName: person.lineManager.lastName,
              isActive: person.lineManager.isActive,
              positionTitle: person.lineManager.positionTitle,
            },
            positionTitle: person.positionTitle,
            employeeId: person.employeeId,
            teamMemberships: translateMemberships(person.memberships.nodes),
            roles: translateRoles(person.roles ?? []),
          }),
        ),
        pageInfo: {
          currentPage: response.data.orgHierarchy.people.pageInfo.currentPage,
          hasNextPage: response.data.orgHierarchy.people.pageInfo.hasNextPage,
          totalCount: response.data.orgHierarchy.people.pageInfo.totalCount,
        },
      },
    };
  } catch (error) {
    log.error(
      `Failed to retrieve people and team memberships - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
