import { MutableRefObject, useCallback, useEffect } from 'react';

const selectPresentationMuiElement = (muiClassName: string) =>
  `div[class*="${muiClassName}"][role="presentation"]`;

/**
 * Hook that alerts clicks outside of the passed ref
 */
const DEFAULT_IGNORE_CLASS_NAMES = [
  '.see-debug',
  '.see-popup',
  '.see-dialog',
  'div[role=dialog]',
  selectPresentationMuiElement('MuiAutocomplete-option'),
  selectPresentationMuiElement('MuiAutocomplete-popper'),
  selectPresentationMuiElement('MuiPopover-root'),
  selectPresentationMuiElement('MuiDialogTitle-root'),
];

export const useOutsideAlerter = (
  ref: MutableRefObject<any>,
  onClickedOutside?: () => void,
  ignoreClassNames: string[] = DEFAULT_IGNORE_CLASS_NAMES,
) => {
  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (
        onClickedOutside &&
        ref.current &&
        !ref.current.contains(e.target) &&
        !ignoreClassNames.some((ignoreClass) =>
          document.querySelector(ignoreClass)?.contains(e.target as Node),
        )
      ) {
        onClickedOutside();
      }
    },
    [ignoreClassNames, onClickedOutside, ref],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);
};
