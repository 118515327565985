import { from, Observable, mergeMap, concatAll } from 'rxjs';
import gql from 'graphql-tag';
import { StateObservable, ofType } from 'redux-observable';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { ReduxFormInstanceUserRevertSignoff } from '../types.ts';
import {
  loadPermissions,
  revertSignoffSuccess,
  validationFailed,
} from './actions.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../../store.ts';
import { reloadInstanceAnswers } from '../answer/actions.ts';

export function revertSignoffEpic(
  action$: Observable<ReduxFormInstanceUserRevertSignoff>,
  _: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/instance/USER_REVERT_SIGNOFF'),
    mergeMap(async ({ payload }) => {
      const { instanceId, personId, signature, userReason } = payload;

      try {
        await revertSignoff(client, instanceId, personId, userReason);
      } catch (err) {
        log.error('Error occurred during signoff-revocation.', err);
        return from([validationFailed(instanceId)]);
      }

      return from([
        revertSignoffSuccess(instanceId, personId, signature),
        loadPermissions(instanceId),
        reloadInstanceAnswers(instanceId),
      ]);
    }),
    concatAll(),
  );
}

const revertSignoff = async (
  client: IGraphQLClient,
  instanceId: string,
  personId: string,
  userReason?: string,
): Promise<void> => {
  const mutation = gql`
    mutation RevertSignoff(
      $instanceId: ID!
      $personId: ID!
      $revertReason: String
    ) {
      forms {
        revertFormInstanceSignOff(
          input: {
            formInstanceId: $instanceId
            personId: $personId
            revertReason: $revertReason
          }
        ) {
          ok
        }
      }
    }
  `;

  const result = await client.mutate<any>({
    mutation,
    variables: { instanceId, personId, revertReason: userReason },
  });

  if (!result?.data?.forms?.revertFormInstanceSignOff?.ok) {
    throw new Error(
      `revertFormInstanceSignOff mutation for instance ${instanceId} was not successful.`,
    );
  }
};
