import * as R from 'ramda';

/**
 * Converts a given string to title case i.e. a title => A Title
 * @param input string to title
 */
export function titleCase(input: string): string {
  return R.isEmpty(input)
    ? input
    : input
        .split(' ')
        .map((word) =>
          word ? word[0].toUpperCase() + word.substr(1) : undefined,
        )
        .join(' ');
}
