/**
 * Adapted from:
 *  https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
 */
export function getReadableSize(fileSizeInBytes: number, precision = 1) {
  if (fileSizeInBytes <= 0) {
    return '0 KB';
  }

  let i = -1;
  const byteUnits = [' KB', ' MB', ' GB', ' TB', ' PB', ' EB', ' ZB', ' YB'];
  do {
    fileSizeInBytes = fileSizeInBytes / 1024;
    i++;
  } while (fileSizeInBytes >= 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(precision) + byteUnits[i];
}

export function getExtension(filename = '', defaultVal = '') {
  const index = filename.lastIndexOf('.');
  if (index === -1) {
    return defaultVal;
  }

  return filename.slice(index + 1).toLowerCase();
}
