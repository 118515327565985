/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Checkbox } from '@seeeverything/ui.primitives/src/components/Checkbox/Checkbox.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { Transition } from '@seeeverything/ui.primitives/src/components/Transition/index.ts';
import { makeStyles } from '@seeeverything/ui.primitives/src/hooks/makeStyles.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useCallback } from 'react';
import { Attendee } from '../../../redux/form-instance/types.ts';
import { RowRightContent } from './RowRightContent.tsx';

const SELECTED_CHROMA = color.create(COLORS.BLUE);
const SELECTED_BG = SELECTED_CHROMA.alpha(0.15).css();
const SELECTED_HOVER_BG = SELECTED_CHROMA.alpha(0.3).css();
const SELECTED_RIPPLE = SELECTED_CHROMA.alpha(0.4).css();
const TEXT_COLOR = color.format(-0.8);

export interface IAttendeeRowProps {
  attendee: Attendee;
  canUpdate: boolean;
  hasFollowUpSwitch: boolean;
  onAttendeeChecked: (attendeeId: string, isChecked: boolean) => void;
  onFollowUpActionClick: (attendeeId: string, isChecked: boolean) => void;
}

/**
 * Row to represent a single Attendee in a form instance.
 */
export const AttendeeRow: React.FC<IAttendeeRowProps> = ({
  attendee,
  canUpdate,
  hasFollowUpSwitch = true,
  onAttendeeChecked,
  onFollowUpActionClick,
}) => {
  const {
    id,
    name,
    isAttending = false,
    isFollowUpRequired = false,
    error,
    isSavingAttendance = false,
    isSavingFollowUp = false,
  } = attendee;

  const hasError = Boolean(error);

  const checkboxClasses = useCheckBoxStyles();

  const handleAttendeeClick = useCallback(() => {
    if (isSavingAttendance) return;
    onAttendeeChecked(id, !isAttending);
  }, [id, isAttending, isSavingAttendance, onAttendeeChecked]);

  const handleSwitchChanged = useCallback(
    (to: boolean) => onFollowUpActionClick(id, to),
    [id, onFollowUpActionClick],
  );

  const computedStyles = {
    button: {
      color: isAttending ? SELECTED_RIPPLE : color.format(-0.4),
      background: isAttending
        ? canUpdate
          ? SELECTED_BG
          : color.format(-0.1)
        : 'white',
      '&:hover': {
        background: canUpdate
          ? isAttending
            ? SELECTED_HOVER_BG
            : color.format(-0.08)
          : undefined,
      },
    },
  };

  const elRightContent = (
    <RowRightContent
      isSavingAttendance={isSavingAttendance}
      isSavingFollowUp={isSavingFollowUp}
      isAttending={isAttending}
      onSwitchChange={handleSwitchChanged}
      isSwitchChecked={isFollowUpRequired}
      isEnabled={canUpdate}
      isSwitchVisible={hasFollowUpSwitch}
    />
  );

  const elError = (
    <Transition.Collapse
      in={hasError}
      unmountOnExit={true}
      mountOnEnter={true}
      style={styles.errorContainer}
    >
      <Text color={COLORS.ERROR_RED} size={12}>
        {error}
      </Text>
    </Transition.Collapse>
  );

  return (
    <div css={styles.base}>
      <Button
        onClick={handleAttendeeClick}
        isEnabled={canUpdate}
        style={{ ...styles.button, ...computedStyles.button }}
      >
        <>
          <div css={styles.attendeeRow}>
            <Checkbox
              isChecked={isAttending}
              classes={checkboxClasses}
              labelPosition={'end'}
              onCheck={handleAttendeeClick}
              isEnabled={canUpdate}
            />
            <Icons.face
              style={styles.icon}
              fill={
                hasError
                  ? COLORS.ERROR_RED
                  : canUpdate
                    ? COLORS.BLUE
                    : TEXT_COLOR
              }
            />
            <Text
              color={hasError ? COLORS.ERROR_RED : TEXT_COLOR}
              cursor={'pointer'}
            >
              {name}
            </Text>
            {elRightContent}
          </div>
          {elError}
        </>
      </Button>
    </div>
  );
};

const useCheckBoxStyles = makeStyles({
  root: {
    marginRight: 0,
  },
});

const styles = {
  base: css({
    margin: '4px 0',
    position: 'relative',
    borderRadius: 4,
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
  }),
  button: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    borderRadius: 4,
    padding: 2,
  },
  errorContainer: {
    padding: '0 12px 8px 12px',
  },
  attendeeRow: css({
    display: 'flex',
    flex: '1 1 auto',
    padding: '0 12px',
    alignItems: 'center',
  }),
  icon: {
    marginRight: 8,
  },
} as const;
