import { ofType } from 'redux-observable';
import { Observable, map } from 'rxjs';
import {
  ReduxFormInstanceReviewerChanged,
  ReduxFormInstanceSubjectChanged,
} from '../types.ts';
import { loadPermissions } from './actions.ts';

/**
 * Initiates a `permissions changed` action off of the back of a `saved` action,
 * to update permissions from the service.
 */
export function loadPermissionsOnParticipantChangedEpic(
  action$: Observable<
    ReduxFormInstanceReviewerChanged | ReduxFormInstanceSubjectChanged
  >,
) {
  return action$.pipe(
    ofType(
      'ui.forms/instance/ANSWER/REVIEWER_CHANGED',
      'ui.forms/instance/ANSWER/SUBJECT_CHANGED',
    ),
    map((action) => loadPermissions(action.payload.instanceId)),
  );
}
