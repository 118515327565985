/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  RadioGroup,
  RadioGroupEvent,
  RadioGroupEventHandler,
} from '@seeeverything/ui.primitives/src/components/CheckboxRadioGroup/RadioGroup.tsx';
import { Markdown } from '@seeeverything/ui.primitives/src/components/Markdown/Markdown.tsx';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import {
  TextField,
  TextFieldChangeEventHandler,
} from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import Transition from '@seeeverything/ui.primitives/src/components/Transition/Transition.tsx';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { useCallback, useState } from 'react';
import { FormInstanceAppealState } from '../../../redux/formInstanceAppeal/formInstanceAppealSlice.ts';
import {
  AppealInputChangeEventHandler,
  AppealOutcome,
  FormInstanceAppeal,
} from '../../../redux/formInstanceAppeal/types.ts';
import { FormListItem } from '../../FormListItem/FormListItem.tsx';
import { SpeechBlockContainer } from '../../SpeechBlock/SpeechBlockContainer.tsx';

const APPEAL_OUTCOME_OPTIONS = Object.entries(AppealOutcome).map(
  ([id, label]) => ({ id, label }),
);

const APPEAL_SPEECH_TEXT = `**Appeal**

This review has been appealed. Details are outlined below.
`;

export interface IAppealInputProps {
  appeal: FormInstanceAppealState;
  appealOutcomeReasons: ISelectionListItem[];
  appealReasonCategories: ISelectionListItem[];
  assignedToLabel: string;
  canChange: boolean;
  instanceId: string;
  isPdfExport?: boolean;
  onAppealInputChange?: AppealInputChangeEventHandler;
}

export const AppealInput = ({
  appeal,
  appealOutcomeReasons,
  appealReasonCategories,
  assignedToLabel = 'Reviewer',
  canChange,
  instanceId,
  isPdfExport,
  onAppealInputChange,
}: IAppealInputProps) => {
  const [isOutcomeReasonVisible, setIsOutcomeReasonVisible] = useState(
    appeal.outcome && appeal.outcome === AppealOutcome.Declined,
  );
  const { outcome, outcomeReason, reason, request, response } = appeal;

  const [currentAppealReason, setCurrentAppealReason] =
    useState<ISelectionListItem>(
      reason?.length ? { id: reason, value: reason } : undefined,
    );

  const [currentAppealOutcomeReason, setCurrentAppealOutcomeReason] =
    useState<ISelectionListItem>(
      outcomeReason?.length
        ? {
            id: outcomeReason,
            value: outcomeReason,
          }
        : undefined,
    );

  const updateResponse = useCallback<TextFieldChangeEventHandler>(
    (to) => {
      if (canChange) onAppealInputChange?.({ field: 'response', to });
    },
    [onAppealInputChange, canChange],
  );

  const handleDropdownChange = useCallback(
    (field: keyof FormInstanceAppeal) => (to: ISelectionListItem) => {
      onAppealInputChange({ field, to: to.value });

      switch (field) {
        case 'outcomeReason':
          setCurrentAppealOutcomeReason(to);
          break;
        case 'reason':
          setCurrentAppealReason(to);
          break;
      }
    },
    [onAppealInputChange],
  );

  const handleRadioGroupChange = useCallback(
    (field: keyof FormInstanceAppeal): RadioGroupEventHandler =>
      (event: RadioGroupEvent) => {
        setIsOutcomeReasonVisible(event.id === AppealOutcome.Declined);
        onAppealInputChange({ field, to: event.id });

        if (field === 'outcome') {
          onAppealInputChange({ field: 'outcomeReason', to: undefined });
          setCurrentAppealOutcomeReason(undefined);
        }
      },
    [onAppealInputChange],
  );

  return (
    <div css={styles.base}>
      <SpeechBlockContainer
        id={'appeal-speech'}
        instanceId={instanceId}
        isPdfExport={isPdfExport}
        markdown={APPEAL_SPEECH_TEXT}
      />
      <FormListItem
        id={'form-appeal-request'}
        bullet={isPdfExport ? 'none' : 'small-open'}
        marginTop={30}
        paddingLeft={-32}
        paddingRight={-32}
        bulletTop={28}
        bulletLeft={-37}
      >
        <TextField
          id={'appeal-request'}
          multiline={true}
          label={'Appeal Request'}
          error={
            appeal.errors?.requestError ? getErrorText('request') : undefined
          }
          value={request}
          isEnabled={false}
        />
      </FormListItem>
      <FormListItem
        id={'form-appeal-response'}
        bullet={isPdfExport ? 'none' : 'small-open'}
        marginTop={10}
        paddingLeft={-32}
        paddingRight={-32}
        bulletTop={28}
        bulletLeft={-37}
      >
        <TextField
          id={'appeal-response'}
          multiline={true}
          label={`${assignedToLabel} Appeal Response`}
          isEnabled={canChange}
          error={
            appeal.errors?.responseError ? getErrorText('response') : undefined
          }
          value={response}
          onChange={updateResponse}
        />
      </FormListItem>
      <FormListItem
        id={'form-appeal-outcome'}
        bullet={isPdfExport ? 'none' : 'small-open'}
        paddingLeft={-32}
        paddingRight={-32}
        marginTop={40}
        bulletTop={-5}
        bulletLeft={-37}
      >
        <div css={styles.outcomeBase}>
          <Markdown
            style={
              !canChange
                ? styles.outcomeLabelDisabled
                : appeal.errors?.outcomeError
                  ? styles.outcomeLabelError
                  : styles.outcomeLabel
            }
            text={'#### Appeal Outcome'}
          />
          <div css={styles.options}>
            <RadioGroup
              direction={'vertical'}
              error={
                appeal.errors?.outcomeError
                  ? getErrorText('outcome')
                  : undefined
              }
              isEnabled={canChange}
              onChange={handleRadioGroupChange('outcome')}
              options={APPEAL_OUTCOME_OPTIONS}
              value={outcome}
            />
          </div>
        </div>
      </FormListItem>
      <div css={styles.categorisationHeader}>
        <Markdown
          style={
            !canChange
              ? styles.outcomeLabelDisabled
              : appeal.errors?.reasonError || appeal.errors?.outcomeReasonError
                ? styles.outcomeLabelError
                : styles.outcomeLabel
          }
          text={`#### Appeal Categorisation (for ${str.plural(
            assignedToLabel,
          )})`}
          selectable={true}
        />
      </div>
      <FormListItem
        id={'form-appeal-reason'}
        bullet={isPdfExport ? 'none' : 'small-open'}
        marginTop={20}
        paddingLeft={-32}
        paddingRight={-32}
        bulletTop={-25}
        bulletLeft={-37}
      >
        <div css={styles.categorisationRow}>
          <TextFieldDropdown
            id={'appeal-reason'}
            error={
              appeal.errors?.reasonError ? getErrorText('reason') : undefined
            }
            isEnabled={canChange}
            label={'Appeal Reason'}
            onChange={handleDropdownChange('reason')}
            selections={appealReasonCategories}
            style={styles.reasonDropdown}
            value={currentAppealReason}
          />
          <Transition.Grow
            in={isOutcomeReasonVisible}
            mountOnEnter={true}
            unmountOnExit={true}
          >
            <div css={styles.reasonDropdown}>
              <TextFieldDropdown
                id={'appeal-outcome-reason'}
                error={
                  appeal.errors?.outcomeReasonError
                    ? getErrorText('outcomeReason')
                    : undefined
                }
                isEnabled={canChange}
                label={'Appeal Outcome Reason'}
                onChange={handleDropdownChange('outcomeReason')}
                selections={appealOutcomeReasons}
                value={currentAppealOutcomeReason}
              />
            </div>
          </Transition.Grow>
        </div>
      </FormListItem>
    </div>
  );
};

const getErrorText = (field: keyof FormInstanceAppeal) =>
  `You must supply a valid ${str.humanize(field).toLowerCase()}.`;

const styles = {
  base: css({
    paddingBottom: 25,
  }),
  categorisationHeader: css({
    marginBottom: -10,
    paddingTop: 25,
  }),
  categorisationRow: css({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
  }),
  options: css({
    paddingLeft: 25,
  }),
  outcomeBase: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    paddingTop: 25,
  }),
  outcomeLabel: css({
    marginTop: -35,
  }),
  outcomeLabelDisabled: css({
    color: '#888888',
    marginTop: -35,
  }),
  outcomeLabelError: css({
    color: COLORS.ERROR_RED,
    marginTop: -35,
  }),
  reasonDropdown: css({
    flex: '1 1 auto',
    width: 300,
  }),
};
