import { OptionsAnswerDefinition } from '../parse/types/options.types.ts';
import { ANSWER_TYPE_LABELS } from './constants.ts';

const hasIssueDefinition = (answer: OptionsAnswerDefinition) =>
  Boolean(answer?.issue);

export const distinctIssueClassifications = (
  answers: OptionsAnswerDefinition[],
) => {
  if (!answers?.length) return [];

  const classifications = new Set(
    answers
      .map((answer) => answer.issue?.classification?.options)
      .filter(Boolean)
      .flat(),
  );
  return [...classifications].sort();
};

export const getIssueQuestions = (
  answers: OptionsAnswerDefinition[],
  id: string,
  label: string,
  parentHeadingId: string,
) => {
  if (!answers?.length) return [];
  if (!answers.some(hasIssueDefinition)) return [];

  const notesQuestion = {
    id: `${id} Notes`,
    label: `${label} Notes`,
    isSelected: false,
    isHiddenByDefault: true,
    parentHeadingId,
    answerOptions: [ANSWER_TYPE_LABELS.text],
  };

  const classifications = distinctIssueClassifications(answers);
  if (!classifications.length) return [notesQuestion];

  return [
    notesQuestion,
    {
      id: `${id} Classifications`,
      label: `${label} Classifications`,
      isSelected: false,
      isHiddenByDefault: true,
      parentHeadingId,
      answerOptions: [ANSWER_TYPE_LABELS.multiSelect, ...classifications],
    },
  ];
};
