/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { range } from 'ramda';
import React from 'react';
import SkeletonDetailedItem from './components/SkeletonDetailedItem.tsx';

export interface ISkeletonDetailedItemsListProps {
  style?: SerializedStyles;
  itemStyle?: SerializedStyles;
  numberOfSkeletonItems?: number;
  animation?: 'wave' | 'pulse';
}

/**
 * Renders a skeleton list for a typical list item with description.
 */
export const SkeletonDetailedItemsList: React.FC<
  ISkeletonDetailedItemsListProps
> = ({ animation = 'wave', numberOfSkeletonItems = 1, style, itemStyle }) => {
  const elSkeletonItems = range(1, numberOfSkeletonItems + 1).map((num) => (
    <SkeletonDetailedItem
      key={`list-skeleton-item-${num}`}
      animation={animation}
      style={itemStyle}
    />
  ));

  const computedStyle = React.useMemo(() => css(styles.base, style), [style]);

  return <div css={computedStyle}>{elSkeletonItems}</div>;
};

const styles = {
  base: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
  }),
};
