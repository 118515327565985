/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export interface IBulletScoreProps {
  style?: SerializedStyles;
}

/**
 * A bullet that contains a provisional score icon.
 */
export const BulletScore = ({ style }: IBulletScoreProps) => {
  const computedStyles = useMemo(
    () => ({
      base: css(
        {
          width: 30,
          height: 30,
          borderRadius: 30,
          backgroundColor: 'white',
          border: `solid 1px ${color.format(-0.2)}`,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: -11,
        },
        style,
      ),
      icon: {
        zIndex: 1,
        position: 'relative',
      } as const,
    }),
    [style],
  );

  return (
    <div css={computedStyles.base}>
      <Icons.doneAll
        style={computedStyles.icon}
        fill={color.format(-0.2)}
        size={22}
      />
    </div>
  );
};
