import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { MutationResult } from '../../types.ts';
import { InstanceScheduleDraft } from '../query/instanceScheduleTypes.ts';

export type CreateInstanceScheduleArgs = {
  scheduleId: string;
  updates: InstanceScheduleDraft;
};

export const createInstanceSchedule = async (
  client: IGraphQLClient,
  args: CreateInstanceScheduleArgs,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { createInstanceSchedule: { ok: boolean } };
    }>({
      mutation: gql`
        mutation CreateInstanceSchedule(
          $formScheduleId: ID!
          $distributionListIds: [ID]
          $name: String
          $assignedToSelector: String
          $templateId: ID
          $dueDateExpression: String
          $startDate: DateTime
          $endDate: DateTime
          $frequency: InstanceScheduleFrequency
          $reportingDateExpression: String
        ) {
          forms {
            createInstanceSchedule(
              input: {
                formScheduleId: $formScheduleId
                distributionListIds: $distributionListIds
                name: $name
                assignedToSelector: $assignedToSelector
                templateId: $templateId
                dueDateExpression: $dueDateExpression
                startDate: $startDate
                endDate: $endDate
                frequency: $frequency
                reportingDateExpression: $reportingDateExpression
              }
            ) {
              ok
            }
          }
        }
      `,
      variables: {
        formScheduleId: args.scheduleId,
        distributionListIds: [args.updates.distributionList.id],
        name: args.updates.name,
        templateId: args.updates.template.id,
        assignedToSelector: args.updates.assignedToSelector,
        dueDateExpression: args.updates.dueDateExpression,
        startDate: args.updates.startDate,
        endDate: args.updates.endDate,
        frequency: args.updates.frequency,
        reportingDateExpression: args.updates.reportingDateExpression,
      },
    });

    if (!response.data.forms.createInstanceSchedule.ok)
      throw new Error('Not ok');

    return { isSuccess: true };
  } catch (err) {
    log.error(
      `Unable to create instance schedule for id ${args.scheduleId} - ${err.message}`,
    );
    return { isSuccess: false };
  }
};

export type UpdateInstanceScheduleArgs = {
  scheduleId: string;
  updates: InstanceScheduleDraft;
};

export const updateInstanceSchedule = async (
  client: IGraphQLClient,
  args: UpdateInstanceScheduleArgs,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { updateInstanceSchedule: { ok: boolean } };
    }>({
      mutation: gql`
        mutation UpdateInstanceSchedule(
          $scheduleId: ID!
          $distributionListIds: [ID]
          $name: String
          $assignedToSelector: String
          $templateId: ID!
          $dueDateExpression: String
          $startDate: DateTime
          $endDate: DateTime
          $frequency: String
          $reportingDateExpression: String
        ) {
          forms {
            updateInstanceSchedule(
              input: {
                formScheduleId: $scheduleId
                distributionListIds: $distributionListIds
                name: $name
                assignedToSelector: $assignedToSelector
                templateId: $templateId
                dueDateExpression: $dueDateExpression
                startDate: $startDate
                endDate: $endDate
                frequency: $frequency
                reportingDateExpression: $reportingDateExpression
              }
            ) {
              ok
            }
          }
        }
      `,
      variables: {
        scheduleId: args.scheduleId,
        distributionListIds: [args.updates.distributionList.id],
        name: args.updates.name,
        assignedToSelector: args.updates.assignedToSelector,
        templateId: args.updates.template.id,
        dueDateExpression: args.updates.dueDateExpression,
        startDate: args.updates.startDate,
        endDate: args.updates.endDate,
        frequency: args.updates.frequency,
        reportingDateExpression: args.updates.reportingDateExpression,
      },
    });

    if (!response.data.forms.updateInstanceSchedule.ok)
      throw new Error('Not ok');

    return { isSuccess: true };
  } catch (err) {
    log.error(
      `Unable to update instance schedule for id ${args.scheduleId} - ${err.message}`,
    );
    return { isSuccess: false };
  }
};

export type UpdateInstanceScheduleEndDateArgs = {
  scheduleId: string;
  endDate: string;
};

export const updateInstanceScheduleEndDate = async (
  client: IGraphQLClient,
  args: UpdateInstanceScheduleEndDateArgs,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { updateInstanceScheduleEndDate: { ok: boolean } };
    }>({
      mutation: gql`
        mutation UpdateInstanceScheduleEndDate(
          $scheduleId: ID!
          $endDate: DateTime!
        ) {
          forms {
            updateInstanceScheduleEndDate(
              input: { formScheduleId: $scheduleId, endDate: $endDate }
            ) {
              ok
            }
          }
        }
      `,
      variables: {
        scheduleId: args.scheduleId,
        endDate: args.endDate,
      },
    });

    if (!response.data.forms.updateInstanceScheduleEndDate.ok)
      throw new Error('Not ok');

    return { isSuccess: true };
  } catch (err) {
    log.error(
      `Unable to update instance schedule end date for id ${args.scheduleId} - ${err.message}`,
    );
    return { isSuccess: false };
  }
};

export const deactivateInstanceScheduleStatus = async (
  client: IGraphQLClient,
  scheduleId: string,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { deactivateInstanceSchedule: { ok: boolean } };
    }>({
      mutation: gql`
        mutation DeactivateInstanceSchedule($scheduleId: ID!) {
          forms {
            deactivateInstanceSchedule(id: $scheduleId) {
              ok
            }
          }
        }
      `,
      variables: { scheduleId },
    });

    if (!response.data.forms.deactivateInstanceSchedule.ok)
      throw new Error('Not ok');

    return { isSuccess: true };
  } catch (err) {
    log.error(
      `Unable to deactivate instance schedule ${scheduleId} - ${err.message}`,
    );
    return { isSuccess: false };
  }
};
