import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import { FormActionTemplateDto } from '../types.ts';
import { mapFormActionTemplate } from './actionTemplateUtil.ts';

export const getFormActionTemplates = async (
  client: IGraphQLClient,
  module: ModuleType,
): Promise<QueryResult<FormActionTemplateDto[]>> => {
  try {
    const response = await client.query<{
      forms: { formActionTemplates: FormActionTemplateDto[] };
    }>({
      query: gql`
        query FormActionTemplates {
          forms {
            formActionTemplates {
              id
              defaultDueByExpression
              questions {
                id
                label
                type
                isRequired
                position {
                  rowIndex
                  columnIndex
                }
                options {
                  id
                  label
                }
              }
            }
          }
        }
      `,
      variables: { module },
      fetchPolicy: 'cache-first',
    });

    const formActionTemplates = response.data.forms.formActionTemplates.map(
      mapFormActionTemplate,
    );

    if (!formActionTemplates) {
      log.error(`Failed to retrieve form action template for ${module}`);
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: formActionTemplates,
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query form action templates for ${module} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
