import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { combineEpics } from 'redux-observable';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import {
  addIssueForOptionsAnswerWithIssueEpic,
  isLoadingOnExistingIssueForAnswerIdChangedEpic,
  removeIssueForOptionsAnswerWithoutIssueEpic,
  removeIssueOnOptionsAnswerHiddenEpic,
  saveCopiedIssueDetailsOnOptionsAnswerSavedEpic,
} from './epic.addRemoveIssue.ts';
import {
  updateCoachingConversationEpic,
  updatePrimaryCauseFutureCoachingEpic,
  updatePrimaryIssueCauseEpic,
  updatePrimaryIssueCauseNotesEpic,
  updateSecondaryCausesEpic,
} from './epic.issueCauses.ts';
import {
  clearCoachingPlanIssuesOnInstanceLoadEpic,
  createCoachingIssueActionCompleteEpic,
  loadCoachingPlanIssuesEpic,
  loadCoachingPlanIssuesOnInstanceLoadEpic,
  loadCoachingPlanIssuesOnSubjectChangedEpic,
  setIsLoadingOnCoachingPlanEpic,
  updateCoachingIssueActionCompleteEpic,
} from './epic.loadCoachingPlanAnswerIssues.ts';
import { loadedComplianceIssuesOnInstanceLoadedEpic } from './epic.loadComplianceReviewIssues.ts';

export const issueEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  addIssueForOptionsAnswerWithIssueEpic,
  clearCoachingPlanIssuesOnInstanceLoadEpic,
  createCoachingIssueActionCompleteEpic,
  isLoadingOnExistingIssueForAnswerIdChangedEpic,
  loadCoachingPlanIssuesEpic,
  loadCoachingPlanIssuesOnInstanceLoadEpic,
  loadCoachingPlanIssuesOnSubjectChangedEpic,
  loadedComplianceIssuesOnInstanceLoadedEpic,
  removeIssueForOptionsAnswerWithoutIssueEpic,
  removeIssueOnOptionsAnswerHiddenEpic,
  saveCopiedIssueDetailsOnOptionsAnswerSavedEpic,
  setIsLoadingOnCoachingPlanEpic,
  updateCoachingConversationEpic,
  updateCoachingIssueActionCompleteEpic,
  updatePrimaryCauseFutureCoachingEpic,
  updatePrimaryIssueCauseEpic,
  updatePrimaryIssueCauseNotesEpic,
  updateSecondaryCausesEpic,
);
