import { utils, WorkSheet, writeFile } from 'xlsx';

type ExportOptions = {
  exportFileName: string;
  sheetName: string;
  title: string;
  worksheetData: WorkSheet;
};
export const exportToXlsx = (options: ExportOptions) => {
  const { exportFileName, sheetName, title, worksheetData } = options;
  const workbook = utils.book_new();
  workbook.Props = {
    Title: title,
    Author: 'SeeEverything',
  };

  workbook.SheetNames.push(sheetName);
  workbook.Sheets[sheetName] = worksheetData;

  writeFile(workbook, exportFileName);
};
