import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import gql from 'graphql-tag';
import { MutationResult } from '../../types.ts';

type CreateAutomatedActionArgs = {
  description: string;
  matchIssueLabel: string;
  matchIssueClassification: string;
  templateId: string;
};

export const createAutomatedAction = async (
  client: IGraphQLClient,
  args: CreateAutomatedActionArgs,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { createAutomatedAction: { ok: boolean } };
    }>({
      mutation: gql`
        mutation CreateAutomatedAction(
          $id: ID!
          $templateId: ID!
          $description: String!
          $matchIssueLabel: String!
          $matchIssueClassification: String
        ) {
          forms {
            createAutomatedAction(
              input: {
                id: $id
                description: $description
                matchIssueLabel: $matchIssueLabel
                matchIssueClassification: $matchIssueClassification
                templateId: $templateId
              }
            ) {
              ok
            }
          }
        }
      `,
      variables: {
        id: uuid.generate(),
        description: args.description,
        matchIssueLabel: args.matchIssueLabel,
        matchIssueClassification: args.matchIssueClassification,
        templateId: args.templateId,
      },
      fetchPolicy: 'network-only',
    });

    return { isSuccess: response.data.forms.createAutomatedAction.ok };
  } catch (error) {
    log.error(
      `Something went wrong trying to create form automated action for template ${args.templateId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      error,
    };
  }
};

type UpdateAutomatedActionArgs = {
  id: string;
  description: string;
};

export const updateAutomatedActionDescription = async (
  client: IGraphQLClient,
  args: UpdateAutomatedActionArgs,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { updateAutomatedActionDescription: { ok: boolean } };
    }>({
      mutation: gql`
        mutation UpdateAutomatedActionDescription(
          $id: ID!
          $description: String!
        ) {
          forms {
            updateAutomatedActionDescription(
              input: { id: $id, description: $description }
            ) {
              ok
            }
          }
        }
      `,
      variables: {
        id: args.id,
        description: args.description,
      },
      fetchPolicy: 'network-only',
    });

    return {
      isSuccess: response.data.forms.updateAutomatedActionDescription.ok,
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to update form automated action description for ${args.id} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      error,
    };
  }
};

export const deleteAutomatedAction = async (
  client: IGraphQLClient,
  id: string,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { deleteAutomatedAction: { ok: boolean } };
    }>({
      mutation: gql`
        mutation DeleteAutomatedAction($id: ID!) {
          forms {
            deleteAutomatedAction(input: { id: $id }) {
              ok
            }
          }
        }
      `,
      variables: { id },
      fetchPolicy: 'network-only',
    });

    return {
      isSuccess: response.data.forms.deleteAutomatedAction.ok,
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to delete form automated action ${id} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      error,
    };
  }
};
