import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { MutationResult } from '../../types.ts';

export type UpdateFormAnswerIssueNotesArgs = {
  instanceId: string;
  answerId: string;
  notes: string;
};

export const updateFormAnswerIssueNotes = async (
  client: IGraphQLClient,
  args: UpdateFormAnswerIssueNotesArgs,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { updateFormAnswerIssueNotes: { ok: boolean } };
    }>({
      mutation: gql`
        mutation UpdateFormAnswerIssueNotes(
          $answerId: ID!
          $instanceId: ID!
          $notes: String!
        ) {
          forms {
            updateFormAnswerIssueNotes(
              input: {
                instanceId: $instanceId
                answerId: $answerId
                notes: $notes
              }
            ) {
              ok
            }
          }
        }
      `,
      variables: {
        answerId: args.answerId,
        instanceId: args.instanceId,
        notes: args.notes,
      },
    });

    return { isSuccess: response.data.forms.updateFormAnswerIssueNotes.ok };
  } catch (err) {
    log.error(
      `Unable to update form answer issue notes for instance ${args.instanceId} and answer ${args.answerId} - ${err.message}`,
    );
    return { isSuccess: false };
  }
};
