/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Text } from '../Text/Text.tsx';
import { TextButton } from '../TextButton/TextButton.tsx';
import { ChartFigure, ListFigure } from './components/index.ts';

export interface IContentPlaceholderProps {
  kind: 'CHART' | 'LIST';
  emptyText?: string | boolean;
  hintText?: string | boolean;
  onHintTextClick?: () => void;
}

/**
 * A placeholder component to replace e.g. Grid/Chart when no data is available.
 */
export const ContentPlaceholder: React.FC<IContentPlaceholderProps> = (
  props,
) => {
  const { kind, emptyText = true, hintText = true, onHintTextClick } = props;

  const elEmptyText = emptyText && (
    <Text italic={true} color={'rgba(0, 0, 0, 0.2)'} size={14} align={'center'}>
      {emptyText === true ? 'No items to display.' : emptyText}
    </Text>
  );

  const HintText = onHintTextClick ? TextButton : Text;
  const hintTextProps = onHintTextClick ? {} : { color: 'rgba(0, 0, 0, 0.8)' };
  const elHintText = hintText && (
    <HintText align={'center'} onClick={onHintTextClick} {...hintTextProps}>
      {hintText === true ? 'Create new sessions to get started.' : hintText}
    </HintText>
  );

  return (
    <div css={styles.base}>
      <div css={styles.outer}>
        <Figure kind={kind} />
        <div css={styles.line} />
        {elEmptyText}
        {elEmptyText && <div css={styles.gap} />}
        {elHintText}
      </div>
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  outer: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  line: css({
    minWidth: 200,
    borderTop: 'solid 3px rgba(0, 0, 0, 0.05)',
    overflow: 'hidden',
    paddingBottom: '10px',
  }),
  gap: css({
    height: 15,
  }),
};

interface IFigureProps {
  kind: 'CHART' | 'LIST';
}

const Figure: React.FC<IFigureProps> = ({ kind }) =>
  kind === 'CHART' ? <ChartFigure /> : <ListFigure />;
