/** @jsxImportSource @emotion/react */
import { useCallback, useMemo } from 'react';
import { CheckboxGroupEvent } from '@seeeverything/ui.primitives/src/components/CheckboxRadioGroup/CheckboxGroup.tsx';
import { useFormsDispatch, useFormsSelector } from '../../redux/store.ts';
import { OptionsAnswerIssue } from './OptionsAnswerIssue.tsx';
import { issueSlice } from '../../redux/issue/index.ts';
import { IssueAnswerDefinition } from '../../parse/types/options.types.ts';

export interface IOptionsAnswerIssueContainerProps {
  answerId: string;
  instanceId: string;
  issueDefinition: IssueAnswerDefinition;
}

export const OptionsAnswerIssueContainer: React.FC<
  IOptionsAnswerIssueContainerProps
> = ({ answerId, instanceId, issueDefinition }) => {
  const dispatch = useFormsDispatch();

  const issueClassifications = useFormsSelector(
    (state) =>
      state.formIssue.issues.find((issue) => issue.answerId === answerId)
        ?.classifications ?? [],
  );

  const selectedClassifications = useMemo(
    () =>
      issueClassifications.map((classification) => ({
        id: classification.value,
        checked: true,
      })) ?? [],
    [issueClassifications],
  );

  const notesValue = useFormsSelector(
    (state) =>
      state.formIssue.issues.find((issue) => issue.answerId === answerId)
        ?.notes,
  );

  const notesError = useFormsSelector(
    (state) =>
      state.formIssue.issues.find((issue) => issue.answerId === answerId)
        ?.errors.compliance.notes,
  );

  const classificationsError = useFormsSelector(
    (state) =>
      state.formIssue.issues.find((issue) => issue.answerId === answerId)
        ?.errors.compliance.classifications,
  );

  const instanceCanEdit = useFormsSelector((state) =>
    Boolean(state.formInstance.instances?.[instanceId]?.permissions.edit),
  );

  const questionKey = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    if (!instance?.answers) return;

    return Object.values(instance.answers).find(
      (answer) => answer?.id === answerId,
    )?.lineId;
  });

  const handleClassificationChange = useCallback(
    (e: CheckboxGroupEvent) => {
      const isAdd = Boolean(e.to);
      return isAdd
        ? dispatch(
            issueSlice.addClassification({
              answerId,
              instanceId,
              classificationValue: e.id,
              itemIndex: e.index,
              concurrencyId: questionKey,
            }),
          )
        : dispatch(
            issueSlice.deleteClassification({
              answerId,
              instanceId,
              classificationValue: e.id,
              concurrencyId: questionKey,
            }),
          );
    },
    [dispatch, answerId, instanceId, questionKey],
  );

  const handleNoteChange = useCallback(
    (to: string) => {
      dispatch(
        issueSlice.setNotes({
          answerId,
          instanceId,
          notes: to,
          concurrencyId: questionKey,
        }),
      );
    },
    [dispatch, answerId, instanceId, questionKey],
  );

  const classificationOptions = useMemo(
    () =>
      issueDefinition?.classification?.options?.map((option) => ({
        id: option,
        label: option,
      })),
    [issueDefinition?.classification?.options],
  );

  return (
    <OptionsAnswerIssue
      answerId={answerId}
      classificationOptions={classificationOptions}
      classificationsError={classificationsError}
      classificationValue={selectedClassifications}
      isEnabled={instanceCanEdit}
      notesError={notesError}
      notesLabel={issueDefinition?.notes?.label}
      notesValue={notesValue}
      onClassificationChange={handleClassificationChange}
      onNotesChange={handleNoteChange}
    />
  );
};
