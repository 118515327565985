/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

export const ListFigure: React.FC = () => (
  <div css={styles.listFigure}>
    <Item />
    <Item />
    <Item />
  </div>
);

const Item: React.FC = () => (
  <div css={styles.itemBase}>
    <div css={styles.itemLeftCell} />
    <div css={styles.itemRightCell} />
  </div>
);

const styles = {
  listFigure: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  }),
  itemBase: css({ margin: '0px 4px 4px 4px' }),
  itemLeftCell: css({
    height: 15,
    width: 25,
    display: 'inline-block',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    border: 'solid 1px rgba(0, 0, 0, 0.06)',
    borderRadius: 3,
  }),
  itemRightCell: css({
    height: 15,
    width: 120,
    display: 'inline-block',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    border: 'solid 1px rgba(0, 0, 0, 0.06)',
    borderRadius: 3,
    marginLeft: 10,
  }),
};
