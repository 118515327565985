/** @jsxImportSource @emotion/react */
import { useInView, IntersectionOptions } from 'react-intersection-observer';

/**
 * Wrapper for the `react-intersection-observer` useInView hook.
 *
 * The `forwardedRef` returned by the hook **MUST** be passed as a prop to the underlying DOM node that is being
 * monitored for viewport changes.
 *
 * @see https://www.npmjs.com/package/react-intersection-observer.
 *
 * @example
 *
 * const [forwardedRef, inViewport, entry] = useViewport({
 *   observerOptions: {
 *     triggerOnce: true,  // Only fire viewport callbacks on first enter/leave.
 *   },
 * });
 *
 * return (
 *   <div ref={forwardedRef} >
 *     {`I'm ${inViewport ? 'visible' : 'hidden'}`}
 *   </div>
 * );
 */
export const useViewport = (observerOptions?: IntersectionOptions) =>
  useInView(observerOptions);
