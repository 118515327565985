import { combineEpics } from 'redux-observable';
import { createOnServer } from './epic.createOnServer.ts';
import { loadGoalEpic } from './epic.load.ts';
import { updateGoalOnServerEpic } from './epic.modifyOnServer.ts';
import { loadPreviousGoalNotesEpic } from './epic.loadGoalNotes.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';

export const epics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  createOnServer,
  loadGoalEpic,
  updateGoalOnServerEpic,
  loadPreviousGoalNotesEpic,
);
