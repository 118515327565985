import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormGoalCategory } from '@se/data/forms/types.ts';
import { SimpleEntity } from '@se/data/types.ts';
import { GoalAdditionalField } from '@seeeverything/ui.util/src/redux/tenant/types.ts';
import { FormGoalStatus, GoalNote, IFormGoalItem } from './types.ts';

export type GoalInteractionSource =
  | { type: 'FORM'; instanceId: string }
  | { type: 'DASHBOARD'; gridKey: string }
  | { type: 'GOALS_AND_ACTIONS_GRID' };

type CreateDraftPayload = {
  additionalFields?: GoalAdditionalField[];
  assignedTo: SimpleEntity;
  formInstanceId?: string;
  goalCategory?: FormGoalCategory;
  source: GoalInteractionSource;
};

type LoadGoalPayload = {
  goalId: string;
  source: GoalInteractionSource;
};

export type UpdateGoalPayload = {
  initialGoal: IFormGoalItem;
  source: GoalInteractionSource;
  newDescription?: string;
  newDueBy?: string;
  newAdditionalFields: GoalAdditionalField[];
  newAssignedTo?: SimpleEntity;
  newStatus?: FormGoalStatus;
  note?: string;
};

type NewGoal = {
  type: 'NEW';
  status: 'Open';
  goalCategory?: FormGoalCategory;
  assignedTo: SimpleEntity;
  formInstanceId: string;
  additionalFields?: GoalAdditionalField[];
};

type ExistingGoal = {
  type: 'EXISTING';
  goal: IFormGoalItem;
};

export type FormEditGoalState = {
  goalId?: string;
  goalNotes: { isLoading: boolean; notes: GoalNote[] };
  initialValues?: NewGoal | ExistingGoal;
  isLoadError: boolean;
  isLoading: boolean;
  isSaving: boolean;
};

const DEFAULT_STATE: FormEditGoalState = {
  isLoading: false,
  isSaving: false,
  isLoadError: false,
  goalNotes: { isLoading: false, notes: [] },
};

const slice = createSlice({
  name: 'libs/forms/editGoal',
  initialState: DEFAULT_STATE,
  reducers: {
    clear: () => DEFAULT_STATE,
    createDraft: (state, action: PayloadAction<CreateDraftPayload>) => {
      const { additionalFields, assignedTo, formInstanceId, goalCategory } =
        action.payload;

      state.isSaving = false;
      state.isLoading = false;
      state.isLoadError = false;
      state.initialValues = {
        type: 'NEW',
        status: 'Open',
        additionalFields,
        assignedTo,
        formInstanceId,
        goalCategory,
      };
    },
    loadGoal: (state, action: PayloadAction<LoadGoalPayload>) => {
      state.goalId = action.payload.goalId;
      state.isSaving = false;
      state.isLoading = true;
      state.isLoadError = false;
      state.initialValues = undefined;
    },
    loadedGoal: (state, action: PayloadAction<IFormGoalItem>) => {
      state.isLoading = false;
      state.isLoadError = false;
      state.initialValues = {
        type: 'EXISTING',
        goal: action.payload,
      };
      state.goalNotes = { isLoading: true, notes: [] };
    },
    loadGoalError: (state) => {
      state.isLoading = false;
      state.isLoadError = true;
    },
    showPreviousGoalNotes: (state) => state,
    goalNotesLoaded: (state, action: PayloadAction<GoalNote[]>) => {
      state.goalNotes = { isLoading: false, notes: action.payload };
    },
    createGoal: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        goal: IFormGoalItem;
        source: GoalInteractionSource;
      }>,
    ) => {
      state.isSaving = true;
    },
    createdGoal: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        isSuccess: boolean;
        goalId: string;
        source: GoalInteractionSource;
      }>,
    ) => {
      state.isSaving = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateGoal: (state, action: PayloadAction<UpdateGoalPayload>) => {
      state.isSaving = true;
    },
    updatedGoal: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        isSuccess: boolean;
        goalId: string;
        source: GoalInteractionSource;
      }>,
    ) => {
      state.isSaving = false;
    },
  },
});

export const {
  clear,
  createDraft,
  createGoal,
  createdGoal,
  goalNotesLoaded,
  loadGoal,
  loadedGoal,
  loadGoalError,
  showPreviousGoalNotes,
  updateGoal,
  updatedGoal,
} = slice.actions;
export const reducer = slice.reducer;
export type State = FormEditGoalState;
