import { downloadFileInFrame } from '@seeeverything/ui.util/src/download/index.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { StateObservable, ofType } from 'redux-observable';
import { Observable, mergeMap } from 'rxjs';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import { fileDownloadComplete, fileDownloadError } from './actions.ts';
import { FormFileDownloadRequested } from './types.ts';

/**
 * Request a short-lived download link.
 */
export function requestDownloadLink(
  action$: Observable<FormFileDownloadRequested>,
  _: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/file/DOWNLOAD_REQUEST'),
    mergeMap(async (action) => {
      const { fileId, formInstanceId } = action.payload;

      try {
        const result = await query(client, {
          instanceId: formInstanceId,
          documentId: fileId,
        });

        if ((result.errors && result.errors.length) || !result.data) {
          throw new Error();
        }

        const downloadUrl = result.data.forms.formDocumentLink;
        if (downloadUrl) downloadFileInFrame(downloadUrl);

        return fileDownloadComplete(fileId);
      } catch (err) {
        log.error('Error occurred while trying to download file', err);
        return fileDownloadError(fileId, 'Could not download file.');
      }
    }),
  );
}

type FormDocumentLinkQueryInputs = {
  instanceId: string;
  documentId: string;
};
async function query(
  client: IGraphQLClient,
  inputs: FormDocumentLinkQueryInputs,
) {
  const gqlRequest = gql`
    query FormDocumentLink($instanceId: ID!, $documentId: ID!) {
      forms {
        formDocumentLink(formInstanceId: $instanceId, documentId: $documentId)
      }
    }
  `;

  return client.query<any>({
    query: gqlRequest,
    variables: { ...inputs },
    fetchPolicy: 'network-only',
  });
}
