/** @jsxImportSource @emotion/react */
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  RadioGroup as MaterialRadioGroup,
  Radio,
  RadioGroupProps,
} from '@material-ui/core';
import { Tooltip } from '../Tooltip/Tooltip.tsx';
import { ItemLabel } from './components/ItemLabel.tsx';

export type RadioOption = {
  id: string;
  label: string;
};

export type RadioGroupEvent<T = string> = {
  id: T;
};

export type RadioGroupEventHandler<T = string> = (
  e: RadioGroupEvent<T>,
) => void;

export interface IRadioGroupProps {
  value?: string;
  onChange?: RadioGroupEventHandler;
  isEnabled?: boolean;
  options?: RadioOption[];
  title?: string;
  direction?: 'horizontal' | 'vertical';
  error?: string;
  helperText?: string;
  isFullWidth?: boolean;
  tooltip?: string;
  optionSize?: 'small' | 'medium';
  optionStyle?: React.CSSProperties;
  groupStyle?: React.CSSProperties;
}

/**
 * See:
 *  https://material-ui.com/components/radio-buttons/
 */
export const RadioGroup: React.FC<IRadioGroupProps> = ({
  value = null,
  isFullWidth = true,
  isEnabled = true,
  direction = 'vertical',
  options = [],
  onChange,
  title,
  error,
  helperText,
  tooltip,
  groupStyle,
  optionSize = 'medium',
  optionStyle,
}) => {
  const isHorizontal = direction === 'horizontal';

  const handleOnChange: RadioGroupProps['onChange'] = (_, id) =>
    onChange?.({ id });

  const radioOptionStyle =
    optionStyle ??
    (isHorizontal ? ({ whiteSpace: 'nowrap' } as const) : undefined);

  const renderOption = (option: RadioOption) => (
    <FormControlLabel
      key={option.id}
      value={option.id}
      control={<Radio size={optionSize} />}
      label={
        <ItemLabel
          ellipsis={true}
          value={option.label}
          textSize={optionSize === 'small' ? 14 : 16}
          center={isHorizontal}
        />
      }
      labelPlacement={isHorizontal ? 'top' : 'end'}
      style={radioOptionStyle}
    />
  );

  const elHelperText = Boolean(error || helperText) && (
    <FormHelperText>{error || helperText}</FormHelperText>
  );

  const elRadioGroup = (
    <div>
      <FormControl
        error={Boolean(error)}
        fullWidth={isFullWidth}
        disabled={!isEnabled}
        style={{ alignItems: 'flex-start' }}
      >
        {title && <FormLabel>{title}</FormLabel>}
        <MaterialRadioGroup
          aria-label={title}
          name={title}
          value={value}
          onChange={handleOnChange}
          row={isHorizontal}
          style={{
            paddingTop: isHorizontal ? 5 : undefined,
            flexWrap: 'nowrap',
            ...groupStyle,
          }}
        >
          {options.map(renderOption)}
        </MaterialRadioGroup>
        {elHelperText}
      </FormControl>
    </div>
  );

  return tooltip ? (
    <Tooltip title={tooltip}>{elRadioGroup}</Tooltip>
  ) : (
    elRadioGroup
  );
};
