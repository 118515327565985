export const getCategoryOverdueLabel = (
  overdueGoals: number,
  overdueActions: number,
  actionLabel: string,
) => {
  if (overdueGoals > 0 && overdueActions > 0)
    return `Goals and ${actionLabel}s overdue`;

  if (overdueGoals > 0)
    return overdueGoals === 1
      ? '1 goal overdue'
      : `${overdueGoals} goals overdue`;

  return overdueActions === 1
    ? `1 ${actionLabel} overdue`
    : `${overdueActions} ${actionLabel}s overdue`;
};
