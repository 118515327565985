/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import { css } from '@emotion/react';
import {
  digitalContentStreamDismissPlayer,
  digitalContentDownloadFile,
  digitalContentStreamPlayerError,
  digitalContentLoad,
  digitalContentStreamRefreshPlayer,
  digitalContentStreamFile,
} from '../../redux/form-digital-content/actions.ts';
import { useFormsDispatch, useFormsSelector } from '../../redux/store.ts';
import { DigitalContent } from './DigitalContent.tsx';
import { DigitalContentCommentsContainer } from '../DigitalContentComments/DigitalContentCommentsContainer.tsx';

export interface IDigitalContentContainerProps {
  canUpdateInstance: boolean;
  packId: string;
}

export const DigitalContentContainer: React.FC<
  IDigitalContentContainerProps
> = ({ canUpdateInstance, packId }) => {
  const dispatch = useFormsDispatch();

  const reloadDigitalContent = useCallback(
    () => dispatch(digitalContentLoad(packId)),
    [dispatch, packId],
  );

  const description = useFormsSelector(
    (state) => state.formDigitalContent.contentMeta?.description,
  );

  const loadError = useFormsSelector(
    (state) => state.formDigitalContent.loadError,
  );

  const files = useFormsSelector((state) => state.formDigitalContent.files);

  const isDigitalContentLoading = useFormsSelector(
    (state) => state.formDigitalContent.isLoading,
  );

  const digitalContentTitle = useFormsSelector(
    (state) => state.formDigitalContent.contentMeta?.summary,
  );

  const streamError = useFormsSelector(
    (state) => state.formDigitalContent.stream?.error,
  );
  const streamFileId = useFormsSelector(
    (state) => state.formDigitalContent.stream?.fileId,
  );
  const streamLink = useFormsSelector(
    (state) => state.formDigitalContent.stream?.link,
  );

  const commentsEnabled = useFormsSelector(
    (state) => state.formDigitalContent.contentMeta?.commentsEnabled,
  );

  const formLabel = useFormsSelector(
    (state) => state.tenantState.tenant.locale.label.form,
  );

  const handleContentDownload = useCallback(
    (downloadPackId: string, downloadFileId: string) => {
      if (!downloadFileId) return;
      dispatch(digitalContentDownloadFile(downloadPackId, downloadFileId));
    },
    [dispatch],
  );

  const viewContentStream = useCallback(
    (fileId: string) => dispatch(digitalContentStreamFile(fileId)),
    [dispatch],
  );

  const dismissStream = useCallback(
    () => dispatch(digitalContentStreamDismissPlayer()),
    [dispatch],
  );

  const showStreamError = useCallback(
    () =>
      dispatch(
        digitalContentStreamPlayerError(
          'There was an error loading the video. Click here to retry.',
        ),
      ),
    [dispatch],
  );

  const refreshStream = useCallback(
    () => dispatch(digitalContentStreamRefreshPlayer()),
    [dispatch],
  );

  return (
    <>
      <DigitalContent
        description={description}
        error={loadError}
        files={files}
        id={packId}
        isLoading={isDigitalContentLoading}
        onContentDownload={handleContentDownload}
        onContentView={viewContentStream}
        onRefreshClick={reloadDigitalContent}
        onStreamCloseClick={dismissStream}
        onStreamMaskClick={dismissStream}
        streamError={streamError}
        streamFileId={streamFileId}
        streamLink={streamLink}
        onStreamError={showStreamError}
        onStreamRefreshClick={refreshStream}
        title={digitalContentTitle}
      />
      {Boolean(commentsEnabled) && (
        <>
          <div css={css({ paddingBottom: 40 })} />
          <DigitalContentCommentsContainer
            bannerMessage={
              !canUpdateInstance
                ? `This ${formLabel} has been completed. No more comments can be added.`
                : undefined
            }
            canAddComments={canUpdateInstance}
            canReplyToComments={canUpdateInstance}
          />
        </>
      )}
    </>
  );
};
