import {
  ReduxFormDigitalContentCommentsLoad,
  ReduxFormDigitalContentCommentsLoaded,
  ReduxFormDigitalContentCommentsLoadError,
  ReduxFormDigitalContentAddComment,
  ReduxFormDigitalContentRetryAddComment,
  ReduxFormDigitalContentAddCommentError,
  ReduxFormDigitalContentAddCommentSuccess,
  IAddCommentReadyPayload,
  ReduxFormDigitalContentAddCommentReady,
  ReduxFormDigitalContentDeleteComment,
  ReduxFormDigitalContentDeleteCommentSuccess,
  ReduxFormDigitalContentDeleteCommentError,
} from './types.ts';
import {
  IDigitalContentComment,
  CommentsLoadErrorReasonType,
} from '../../../components/DigitalContentComments/types.ts';

export const digitalContentCommentsLoad = (
  reason: CommentsLoadErrorReasonType,
): ReduxFormDigitalContentCommentsLoad => ({
  type: 'ui.forms/digital-content/comments/LOAD',
  payload: { reason },
});

export const digitalContentCommentsLoaded = (
  comments: IDigitalContentComment[],
  hasNextPage = false,
  pageNumber = 1,
): ReduxFormDigitalContentCommentsLoaded => ({
  type: 'ui.forms/digital-content/comments/LOADED',
  payload: { comments, hasNextPage, pageNumber },
});

export const digitalContentCommentsLoadError = (
  error: string,
  reason: CommentsLoadErrorReasonType,
): ReduxFormDigitalContentCommentsLoadError => ({
  type: 'ui.forms/digital-content/comments/LOAD_ERROR',
  payload: { error, reason },
});

export const digitalContentCommentsAddComment = (
  commentId: string,
  body: string,
  parentId?: string,
): ReduxFormDigitalContentAddComment => ({
  type: 'ui.forms/digital-content/comments/ADD_COMMENT',
  payload: { commentId, body, parentId },
});

export const digitalContentCommentsAddCommentReady = (
  payload: IAddCommentReadyPayload,
): ReduxFormDigitalContentAddCommentReady => ({
  type: 'ui.forms/digital-content/comments/ADD_COMMENT_READY',
  payload,
});

export const digitalContentCommentsRetryAddComment = (
  commentId: string,
): ReduxFormDigitalContentRetryAddComment => ({
  type: 'ui.forms/digital-content/comments/RETRY_ADD_COMMENT',
  payload: { commentId },
});

export const digitalContentCommentAddCommentError = (
  commentId: string,
  error: string,
): ReduxFormDigitalContentAddCommentError => ({
  type: 'ui.forms/digital-content/comments/ADD_COMMENT_ERROR',
  payload: { commentId, error },
});

export const digitalContentCommentsAddCommentSuccess = (
  commentId: string,
): ReduxFormDigitalContentAddCommentSuccess => ({
  type: 'ui.forms/digital-content/comments/ADD_COMMENT_SUCCESS',
  payload: { commentId },
});

export const digitalContentCommentsDeleteComment = (
  commentId: string,
): ReduxFormDigitalContentDeleteComment => ({
  type: 'ui.forms/digital-content/comments/DELETE_COMMENT',
  payload: { commentId },
});

export const digitalContentCommentsDeleteCommentSuccess = (
  commentId: string,
): ReduxFormDigitalContentDeleteCommentSuccess => ({
  type: 'ui.forms/digital-content/comments/DELETE_COMMENT_SUCCESS',
  payload: { commentId },
});

export const digitalContentCommentsDeleteCommentError = (
  commentId: string,
  shouldRetry?: boolean,
): ReduxFormDigitalContentDeleteCommentError => ({
  type: 'ui.forms/digital-content/comments/DELETE_COMMENT_ERROR',
  payload: { commentId, shouldRetry },
});
