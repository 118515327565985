import { FormInstanceActionPlanType } from '@se/data/forms/types.ts';
import { useCallback, useEffect } from 'react';
import { formActionPlanSlice } from '../redux/formActionPlan/index.ts';
import { useFormsDispatch, useFormsSelector } from '../redux/store.ts';

type UseLoadFormActionPlanArgs = {
  instanceId: string;
  lineId: string;
  includeCategories: string[];
  typeFilter?: FormInstanceActionPlanType;
  hasSections: boolean;
  inViewport: boolean;
};

export const useLoadFormActionPlan = ({
  instanceId,
  lineId,
  includeCategories,
  typeFilter,
  hasSections,
  inViewport,
}: UseLoadFormActionPlanArgs) => {
  const dispatch = useFormsDispatch();

  const instanceSubject = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.subject,
  );

  const actionPlan = useFormsSelector(
    (state) => state.formActionPlan.actionPlan[lineId],
  );

  const handleLoadAction = useCallback(
    () =>
      dispatch(
        formActionPlanSlice.loadActionPlan({
          instanceId,
          lineId,
          includeCategories,
          typeFilter,
          hasSections,
        }),
      ),
    [dispatch, hasSections, includeCategories, instanceId, lineId, typeFilter],
  );

  useEffect(() => {
    if (actionPlan) return;
    if (!instanceSubject) return;
    if (!inViewport) return;

    handleLoadAction();
  }, [
    actionPlan,
    dispatch,
    instanceId,
    instanceSubject,
    lineId,
    hasSections,
    handleLoadAction,
    inViewport,
  ]);

  return handleLoadAction;
};
