import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InstanceSchedule } from '@se/data/forms/query/instanceScheduleTypes.ts';
import { OrderBy } from '@seeeverything/ui.util/src/types.ts';

export type InstanceScheduleState = {
  currentPage: number;
  error: boolean;
  hasNextPage: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  schedules: InstanceSchedule[];
  showAll: boolean;
  orderBy: OrderBy;
};

const DEFAULT_STATE: InstanceScheduleState = {
  currentPage: 1,
  error: false,
  hasNextPage: false,
  isLoading: false,
  isLoadingMore: false,
  schedules: [],
  showAll: false,
  orderBy: {
    fieldName: 'createdAt',
    direction: 'Descending',
  },
};

const slice = createSlice({
  name: `libs/forms/instanceSchedule`,
  initialState: DEFAULT_STATE,
  reducers: {
    loadInstanceSchedules(
      state,
      action: PayloadAction<{ loadNextPage: boolean }>,
    ) {
      const loadNextPage = action.payload.loadNextPage;
      state.error = false;
      state.isLoading = !loadNextPage;
      state.isLoadingMore = loadNextPage;
    },
    loadedInstanceSchedules(
      state,
      action: PayloadAction<{
        currentPage: number;
        hasNextPage: boolean;
        schedules: InstanceSchedule[];
      }>,
    ) {
      const { schedules, hasNextPage, currentPage } = action.payload;

      if (currentPage === 1) {
        state.schedules = schedules;
      } else {
        state.schedules.push(...schedules);
      }

      state.hasNextPage = hasNextPage;
      state.currentPage = currentPage;
      state.isLoading = false;
      state.isLoadingMore = false;
      state.error = false;
    },
    loadInstanceSchedulesFailed(state) {
      state.isLoading = false;
      state.isLoadingMore = false;
      state.error = true;
    },
    sortByCreated(
      state,
      action: PayloadAction<{ to: 'Ascending' | 'Descending' }>,
    ) {
      state.orderBy = {
        fieldName: 'createdAt',
        direction: action.payload.to,
      };
    },
    sortByName(
      state,
      action: PayloadAction<{ to: 'Ascending' | 'Descending' }>,
    ) {
      state.orderBy = {
        fieldName: 'name',
        direction: action.payload.to,
      };
    },
    toggleFilterShowInactive(state, action: PayloadAction<{ to: boolean }>) {
      state.showAll = action.payload.to;
    },
  },
});

export const {
  loadedInstanceSchedules,
  loadInstanceSchedules,
  loadInstanceSchedulesFailed,
  sortByCreated,
  sortByName,
  toggleFilterShowInactive,
} = slice.actions;
export const reducer = slice.reducer;
export type State = InstanceScheduleState;
