/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useState } from 'react';

/**
 * Hook for outputting a debounced value after a delay has been cleared without updates.
 * @example
 * const [value, setValue] = useState('INITIAL');
 * const [debouncedValue] = useDebounce(value, 250);
 *
 * setValue('DEBOUNCED');
 *
 * log(debouncedValue); // INITIAL
 * await delay(250);
 * log(debouncedValue); // DEBOUNCED
 * @returns {[T, (nextValue: T) => void]} The debounced value and a setter for immediate updates.
 */
export function useDebounce<T>(
  value: T,
  delay: number,
): [T, (nextValue: T) => void] {
  const [debounced, setDebounced] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounced(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  const setImmediate = useCallback((nextValue: T) => {
    setDebounced(nextValue);
  }, []);

  return [debounced, setImmediate];
}
