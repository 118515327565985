import { getProp } from '../util/util.data.parse.ts';
import { SignoffDefinition } from './types/signoff.types.ts';
import { ISignoffLine } from '../types/types.ts';

export function parseSignoff(item: SignoffDefinition): ISignoffLine {
  return {
    id: 'signoff',
    type: 'signoff',
    reviewerRequired: getProp(item, 'reviewerRequired', true),
    reviewerPlaceholderName: getProp(item, 'reviewerPlaceholderName'),
    reviewerPlaceholderTitle: getProp(item, 'reviewerPlaceholderTitle'),
    reviewerRevertReasonRequired: getProp(
      item,
      'reviewerRevertReasonRequired',
      false,
    ),
    reviewerLabel: getProp(item, 'reviewerLabel'),
    subjectRequired: getProp(item, 'subjectRequired', false),
    subjectPlaceholderName: getProp(item, 'subjectPlaceholderName'),
    subjectPlaceholderTitle: getProp(item, 'subjectPlaceholderTitle'),
    subjectLabel: getProp(item, 'subjectLabel'),
    title: getProp(item, 'title', 'Sign-off'),
    canAppeal: getProp(item, 'canAppeal', false),
    preventSubjectAppeal: getProp(item, 'preventSubjectAppeal'),
    highlightOnHover: false,
    showIndex: true,
  };
}
