import { FormActionTemplateDto, FormActionTemplateQuestion } from '../types.ts';

export const mapFormActionTemplate = (template: FormActionTemplateDto) => {
  if (!template) return;

  const fields = template.questions
    .map((field) => {
      if (field.type === 'Text')
        return {
          id: field.id,
          label: field.label,
          type: field.type,
          isRequired: field.isRequired,
          position: {
            rowIndex: field.position.rowIndex,
            columnIndex: field.position.columnIndex,
          },
        };

      if (field.type === 'Dropdown')
        return {
          id: field.id,
          label: field.label,
          type: field.type,
          isRequired: field.isRequired,
          position: {
            rowIndex: field.position.rowIndex,
            columnIndex: field.position.columnIndex,
          },
          options: field.options.map(({ id, label }) => ({ id, label })),
        };
    })
    .filter(Boolean);

  return {
    id: template.id,
    defaultDueByExpression: template.defaultDueByExpression,
    questions: sortFieldsByPosition(fields),
  };
};

const sortFieldsByPosition = (fields: FormActionTemplateQuestion[]) =>
  fields.sort((a, b) => {
    if (a.position.rowIndex === b.position.rowIndex)
      return a.position.columnIndex - b.position.columnIndex;

    return a.position.rowIndex - b.position.rowIndex;
  });
