import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import { FormLineComponent, ISectionLine } from '../types/types.ts';
import { getProp } from '../util/util.data.parse.ts';
import { parseQuestions } from './parse.section.questions.ts';
import { SectionDefinition } from './types/section.types.ts';

export function parseSection(
  index: number,
  item: SectionDefinition,
): FormLineComponent[] {
  if (!item || item.visible === false) return;

  const sectionLine: ISectionLine = {
    id: item.id?.toString().replace(/'/g, '') || `section-${uuid.generate()}`,
    type: 'section',
    title: getProp(item, 'title'),
    showIndex: Boolean(getProp(item, 'index')),
    showOnAnswer: getProp(item, 'showOnAnswer'),
    showOnReportingDateMonth: item.showOnReportingDateMonth,
    showOnSwitch: getProp(item, 'showOnSwitch'),
    highlightOnHover: false,
  };

  const subQuestions = parseQuestions(
    item.questions,
    sectionLine,
    index,
  ).filter(Boolean);

  return [sectionLine, ...subQuestions].filter(Boolean);
}
