/** @jsxImportSource @emotion/react */
import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import { useUtilSelector } from '../redux/store.ts';

const SegmentContext = createContext<Analytics>(null);

export interface ISegmentProviderProps {
  children: ReactElement;
  segmentWriteKey?: string;
}
export const SegmentProvider = ({
  children,
  segmentWriteKey,
}: ISegmentProviderProps) => {
  const [segmentClient, setSegmentClient] = useState<Analytics>();

  useEffect(() => {
    if (!segmentWriteKey) return;

    (async () => {
      const [analyticsClient] = await AnalyticsBrowser.load({
        writeKey: segmentWriteKey,
      });

      setSegmentClient(analyticsClient);
    })();
  }, [segmentWriteKey]);

  return (
    <SegmentContext.Provider value={segmentClient}>
      {children}
    </SegmentContext.Provider>
  );
};

export type UseSegmentAnalyticsOutputs = {
  track: (
    eventName: string,
    payload: Record<string, string | number | boolean>,
  ) => void;
  segmentEnabled: boolean;
};

/**
 * React hook for providing methods for submitting events to Segment analytics.
 * @returns {track, segmentEnabled}
 * - **track** - Registers a track event against Segment.
 * - **segmentEnabled** - True if Segment event writing is enabled.
 *
 * @example
 * const { track, segmentEnabled } = useSegmentAnalytics();
 *
 * const trackComponentClicked = (event: any) => track('component-clicked', event.target)
 * return <Widget onClick={trackComponentClicked} />;
 */
export const useSegmentAnalytics = (): UseSegmentAnalyticsOutputs => {
  const tenant = useUtilSelector((state) => state.tenantState.tenant.id);
  const module = useUtilSelector((state) => state.tenantState.tenant.module);
  const emailDomain = useUtilSelector(
    (state) => state.tenantState.tenant.authenticatedUser?.emailDomain,
  );
  const segmentClient = useContext<Analytics>(SegmentContext);

  const track = useCallback<UseSegmentAnalyticsOutputs['track']>(
    async (eventName: string, payload: Record<string, string>) =>
      segmentClient?.track(eventName, {
        tenant,
        module,
        emailDomain,
        ...payload,
      }),
    [module, segmentClient, tenant, emailDomain],
  );

  return {
    track,
    segmentEnabled: Boolean(segmentClient),
  };
};
