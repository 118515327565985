import { ISectionLine, ISwitchAnswerLine } from '../types/types.ts';
import { defaultId, getProp } from '../util/util.data.parse.ts';
import { SwitchDefinition } from './types/switch.types.ts';

export function parseSwitchAnswer(
  item: SwitchDefinition,
  parentSection: ISectionLine,
  indices: number[],
): ISwitchAnswerLine | undefined {
  if (!item) return;

  return {
    id: getProp(item, 'id') || defaultId(indices),
    parentId: parentSection.id,
    showOnAnswer: getProp(item, 'showOnAnswer'),
    showOnReportingDateMonth: item.showOnReportingDateMonth,
    showOnSwitch: getProp(item, 'showOnSwitch'),
    group: getProp(item, 'group')?.toString(),
    isRequired: getProp(item, 'isRequired', false),
    isEnabled: getProp(item, 'isEnabled', true),
    highlightOnHover: true,
    type: 'switchAnswer',
    label: item.label,
    showIndex: false,
  };
}
