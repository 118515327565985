/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { IActionBarButton } from '@seeeverything/ui.primitives/src/components/ModalDialog/components/ActionButtons/ActionButtons.tsx';
import { ActionBar } from '@seeeverything/ui.primitives/src/components/ModalDialog/components/ActionBar/ActionBar.tsx';

interface IDialogOuterProps {
  actions: IActionBarButton[];
  children?: React.ReactNode;
  buttonWidth?: string | number;
}

/**
 * Wraps child content with an action bar for content to be used in a shell modal dialog.
 */
const DialogOuter = ({ actions, buttonWidth, children }: IDialogOuterProps) => (
  <div css={styles.base} className={'see-dialog'}>
    {children}
    <ActionBar actions={actions} buttonWidth={buttonWidth} />
  </div>
);

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }),
};

export default React.memo(DialogOuter);
