// Allowed questions that can be exported.
export const EXPORTABLE_QUESTIONS = [
  'text',
  'options',
  'switch',
  'slider',
  'score',
] as const;
export type QuestionTypes = (typeof EXPORTABLE_QUESTIONS)[number];

// Sort order for Question IDs, lower number will be sorted before higher.
// ID's not found in this will be sorted after the included ones.
export const KEY_ID_SORTS: { [key: string]: number } = {
  mandatoryFields: 0,
  assignedTo: 1,
  subject: 2,
};

export const MANDATORY_FIELDS = ['assignedTo', 'subject'];

export const ANSWER_TYPE_LABELS = {
  date: '<Date>',
  multiSelect: '<MultiSelect>',
  number: '<Number>',
  option: '<Option>',
  people: '<EmployeeID>',
  text: '<Text>',
  score: `<Outcome>`,
  team: '<TeamName|TeamPath>',
};

// Dropdown ID's that are allowed to be exported.
export const DROPDOWN_RESERVED_IDS = ['assignedTo', 'subject'];
