import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import { StatusBarType } from '../../components/StatusBar/types.ts';
import { SheetScheduleType } from '../sheetSchedules/sheetSchedulesSlice.ts';
import {
  AddSheetAction,
  ISheet,
  RemoveSheetAction,
  SheetChangeModuleAction,
  SheetHideAlertDialogAction,
  SheetHideStatusBarAction,
  SheetsChangeDateRangeAction,
  SheetsClearAction,
  SheetsClearDateRangeAction,
  SheetsDateRangeCanceledAction,
  SheetsDateRangeConfirmedAction,
  SheetsDateRangeFieldRequiredAction,
  SheetShowAlertDialogAction,
  SheetShowStatusBarAction,
  SheetToolbarClickAction,
  SheetToolbarDropdownClickAction,
  SheetToolbarTool,
} from './types.ts';

export function clearDateRange(
  context: 'DASHBOARDS',
): SheetsClearDateRangeAction {
  return {
    type: 'ui.shell/sheets/DATE_RANGE/CLEAR_DATE_RANGE',
    payload: { context },
  };
}

export function changeDateRange(
  kind: 'START' | 'END',
  to: string,
  context: 'DASHBOARDS',
): SheetsChangeDateRangeAction {
  return {
    type: 'ui.shell/sheets/DATE_RANGE/CHANGE_DATE_RANGE',
    payload: { kind, to, context },
  };
}

export function cancelDateRange(
  context: 'DASHBOARDS',
): SheetsDateRangeCanceledAction {
  return {
    type: 'ui.shell/sheets/DATE_RANGE/CANCELED',
    payload: { context },
  };
}

export function confirmDateRange(
  context: 'DASHBOARDS',
): SheetsDateRangeConfirmedAction {
  return {
    type: 'ui.shell/sheets/DATE_RANGE/CONFIRMED',
    payload: { context },
  };
}

export function dateRangeRequired(
  context: 'DASHBOARDS',
  required: 'START_DATE' | 'END_DATE',
): SheetsDateRangeFieldRequiredAction {
  return {
    type: 'ui.shell/sheets/DATE_RANGE/FIELD_REQUIRED',
    payload: { context, required },
  };
}

export function addSheet(sheet: ISheet): AddSheetAction {
  return {
    type: 'ui.shell/sheets/ADD_SHEET',
    payload: { sheet },
  };
}

export function removeSheet(): RemoveSheetAction {
  return {
    type: 'ui.shell/sheets/REMOVE_SHEET',
    payload: {},
  };
}

export function clear(): SheetsClearAction {
  return {
    type: 'ui.shell/sheets/CLEAR',
    payload: {},
  };
}

export function toolbarClick(
  toolId: string | number | SheetToolbarTool,
): SheetToolbarClickAction {
  return {
    type: 'ui.shell/sheets/TOOLBAR_CLICK',
    payload: { toolId },
  };
}

export function toolbarDropdownClick(
  sheet: ISheet,
  toolId: string | number,
  itemId: string | number,
): SheetToolbarDropdownClickAction {
  return {
    type: 'ui.shell/sheets/TOOLBAR_DROPDOWN_CLICK',
    payload: { sheet, toolId, itemId },
  };
}

export function showStatusBar(
  kind: StatusBarType,
  message: string,
  autoHideDuration = 5000,
): SheetShowStatusBarAction {
  return {
    type: 'ui.shell/sheets/SHOW_STATUS_BAR',
    payload: { kind, message, autoHideDuration },
  };
}

export function showAlertDialog(
  id: SheetScheduleType,
): SheetShowAlertDialogAction {
  return {
    type: 'ui.shell/sheets/SHOW_ALERT_DIALOG',
    payload: { id },
  };
}

export function hideAlertDialog(
  id: SheetScheduleType,
  reason: 'Confirm' | 'Cancel',
): SheetHideAlertDialogAction {
  return {
    type: 'ui.shell/sheets/HIDE_ALERT_DIALOG',
    payload: { id, reason },
  };
}

export function hideStatusBar(): SheetHideStatusBarAction {
  return {
    type: 'ui.shell/sheets/HIDE_STATUS_BAR',
    payload: {},
  };
}

export const changeModule = (
  from: ModuleType,
  to: ModuleType,
  source: SheetChangeModuleAction['payload']['source'],
): SheetChangeModuleAction => ({
  type: 'ui.shell/sheets/CHANGE_MODULE',
  payload: { from, to, source },
});
