import { FormIssueAnswerDefinition, ServerAutomatedAction } from '../types.ts';

type MapAutomatedActionArgs = {
  answerKey: string;
  answerValue: string;
  input: ServerAutomatedAction;
  issueAnswerDefinitions: Record<string, FormIssueAnswerDefinition[]>;
  issueId: string;
  questionKeys: string[];
};

export const mapAutomatedAction = ({
  answerKey,
  answerValue,
  input,
  issueAnswerDefinitions,
  issueId,
  questionKeys,
}: MapAutomatedActionArgs) => {
  const issueAnswerDefinition = issueAnswerDefinitions[answerKey].find(
    (def) => def.optionId === answerValue,
  );

  const classifications = issueAnswerDefinition.classification?.options ?? [];

  return {
    id: input.id,
    description: input.description,
    label: input.label,
    createdActionId: input.createdActionId,
    issueId,
    formQuestionIndex: questionKeys.findIndex((key) => key === answerKey),
    matchIssueClassification: input.automatedAction.matchIssueClassification,
    issueClassificationIndex: classifications.findIndex(
      (c) => c === input.automatedAction.matchIssueClassification,
    ),
  };
};
