import { SimpleEntity } from '@se/data/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import gql from 'graphql-tag';
import { StateObservable } from 'redux-observable';
import { Observable, filter, mergeMap } from 'rxjs';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import * as formEditGoalSlice from './formEditGoalSlice.ts';

export function loadPreviousGoalNotesEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(formEditGoalSlice.showPreviousGoalNotes.match),
    filter(() => Boolean(state$.value.formEditGoal.goalNotes?.isLoading)),
    mergeMap(async () => {
      const goalId = state$.value.formEditGoal.goalId;
      try {
        const response = await client.query<{
          forms: {
            formGoal: {
              notes: Array<{
                id: string;
                createdAt: string;
                createdBy: SimpleEntity;
                note: string;
              }>;
            };
          };
        }>({
          query: gql`
            query FormGoalNotes($goalId: ID!) {
              forms {
                formGoal(id: $goalId) {
                  notes {
                    id
                    createdAt
                    createdBy {
                      id
                      name
                    }
                    note
                  }
                }
              }
            }
          `,
          variables: { goalId },
          fetchPolicy: 'network-only',
        });

        const goalNotes = response.data.forms.formGoal.notes;
        return formEditGoalSlice.goalNotesLoaded(goalNotes);
      } catch (err) {
        log.error(
          `GraphQL error occurred while trying to get previous notes for goal ${goalId}.`,
          err,
        );
        return formEditGoalSlice.goalNotesLoaded([]);
      }
    }),
  );
}
