import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import { FormTemplateCategory } from '../types.ts';

type GetTemplateCategoriesArgs = {
  fetchAllPages?: boolean;
  module: ModuleType;
  pageNumber?: number;
  showAll?: boolean;
};

export type GetFormTemplateCategoriesResponseDto = {
  pageInfo: {
    hasNextPage: boolean;
    currentPage: number;
  };
  formTemplateCategories: FormTemplateCategory[];
};

export const getFormTemplateCategories = async (
  client: IGraphQLClient,
  args: GetTemplateCategoriesArgs,
): Promise<QueryResult<GetFormTemplateCategoriesResponseDto>> => {
  if (!args.fetchAllPages) return getFormTemplateCategoriesPage(client, args);

  try {
    const allFormTemplateCategories = await getFormTemplateCategoriesRecursive(
      client,
      args,
    );
    return {
      isSuccess: true,
      data: {
        formTemplateCategories: allFormTemplateCategories,
        pageInfo: { hasNextPage: false, currentPage: 1 },
      },
    };
  } catch (error) {
    log.error(error);
    return { isSuccess: false, error, errorReason: 'UNKNOWN' };
  }
};

const getFormTemplateCategoriesRecursive = async (
  client: IGraphQLClient,
  args: GetTemplateCategoriesArgs,
  templates: FormTemplateCategory[] = [],
  pageNumber = 1,
): Promise<FormTemplateCategory[]> => {
  const response = await getFormTemplateCategoriesPage(client, {
    module: args.module,
    showAll: args.showAll,
    pageNumber,
  });

  if (!response.isSuccess) {
    throw new Error(
      `Failed to retrieve form template categories for page ${pageNumber}`,
    );
  }

  const nextTemplateCategories = templates.concat(
    response.data.formTemplateCategories,
  );

  const pageInfo = response.data.pageInfo;
  if (!pageInfo.hasNextPage) return nextTemplateCategories;

  return getFormTemplateCategoriesRecursive(
    client,
    args,
    nextTemplateCategories,
    pageInfo.currentPage + 1,
  );
};

const getFormTemplateCategoriesPage = async (
  client: IGraphQLClient,
  args: GetTemplateCategoriesArgs,
): Promise<QueryResult<GetFormTemplateCategoriesResponseDto>> => {
  try {
    const response = await client.query<{
      forms: {
        formTemplateCategories: {
          nodes: FormTemplateCategory[];
          pageInfo: { hasNextPage: boolean; currentPage: number };
        };
      };
    }>({
      query: gql`
        query FormTemplateCategories($pageNumber: Int, $showAll: Boolean) {
          forms {
            formTemplateCategories(
              pagination: { pageNumber: $pageNumber, size: 100 }
              orderBy: [
                { fieldName: "rank", direction: Ascending }
                { fieldName: "name", direction: Ascending }
              ]
              showAll: $showAll
            ) {
              pageInfo {
                hasNextPage
                currentPage
              }
              nodes {
                id
                name
              }
            }
          }
        }
      `,
      variables: {
        module: args.module,
        pageNumber: args.pageNumber,
        showAll: args.showAll,
      },
      fetchPolicy: 'cache-first',
    });

    const { pageInfo, nodes } = response.data.forms.formTemplateCategories;

    if (!nodes) {
      log.error(
        `Failed to retrieve form template categories for ${args.module}`,
      );
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: {
        pageInfo: {
          hasNextPage: pageInfo.hasNextPage,
          currentPage: pageInfo.currentPage,
        },
        formTemplateCategories: nodes.map((node) => ({
          id: node.id,
          name: node.name,
        })),
      },
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query form template categories for ${args.module} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
