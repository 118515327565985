/** @jsxImportSource @emotion/react */
import { FormActionPlanAction } from '@se/data/forms/types.ts';
import { useMemo } from 'react';
import { useFormsSelector } from '../../../redux/store.ts';
import { getCategoryOverdueLabel } from '../util.ts';
import { Action } from './Action.tsx';
import { Section } from './Section.tsx';

export type IssueActionsProps = {
  instanceId: string;
  isDefaultExpanded: boolean;
  actions: FormActionPlanAction[];
};

export const IssueActions: React.FC<IssueActionsProps> = ({
  instanceId,
  isDefaultExpanded,
  actions,
}) => {
  const closeTheLoopTitle = useFormsSelector(
    (state) => state.tenantState.tenant.locale.forms.closeTheLoop.title,
  );

  const title = useMemo(
    () => `${closeTheLoopTitle} (${actions.length})`,
    [closeTheLoopTitle, actions.length],
  );

  const actionLabel = useFormsSelector(
    (state) => state.tenantState.tenant.locale.label.action,
  );

  const overdueLabel = useMemo(() => {
    const numOverdueActions = actions.filter(
      (action) => action.status === 'Overdue',
    ).length;

    return numOverdueActions
      ? getCategoryOverdueLabel(0, numOverdueActions, actionLabel)
      : undefined;
  }, [actionLabel, actions]);

  if (!actions.length) return;

  return (
    <Section
      id={'coachingIssueActions'}
      title={title}
      overdueLabel={overdueLabel}
      isDefaultExpanded={isDefaultExpanded}
      isArchived={false}
    >
      {actions.map((action) => (
        <Action
          key={`CoachingIssueAction-${action.id}`}
          action={action}
          source={{ type: 'FORM', instanceId }}
        />
      ))}
    </Section>
  );
};
