import { SEEEVERYTHING_CDN } from '@seeeverything/ui.util/src/constants/cdn.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';

export interface IFileLogo {
  url: string;
  width: number;
  height: number;
  type: 'svg' | 'png';
}

/** Resolves a file's icon from its file extension */
export const getFileLogo = (
  extension: string,
  size: 'LARGE' | 'SMALL',
): IFileLogo | undefined => {
  const isLarge = size === 'LARGE';

  switch (extension) {
    case 'pdf':
      return {
        url: `${SEEEVERYTHING_CDN}/fileLogos/pdf.svg`,
        width: isLarge ? 84.5 : 50.5,
        height: isLarge ? 110 : 65,
        type: 'svg',
      };

    case 'doc':
    case 'docx':
      return {
        url: `${SEEEVERYTHING_CDN}/fileLogos/docx.svg`,
        width: isLarge ? 120 : 71,
        height: isLarge ? 120 : 71,
        type: 'svg',
      };

    case 'xls':
    case 'xlsx':
      return {
        url: `${SEEEVERYTHING_CDN}/fileLogos/xlsx.svg`,
        width: isLarge ? 120 : 71,
        height: isLarge ? 120 : 71,
        type: 'svg',
      };

    case 'ppt':
    case 'pptx':
      return {
        url: `${SEEEVERYTHING_CDN}/fileLogos/pptx.svg`,
        width: isLarge ? 120 : 71,
        height: isLarge ? 120 : 71,
        type: 'svg',
      };

    default:
      return undefined;
  }
};

export const getFileIcon = (extension: string): keyof typeof Icons => {
  switch (extension) {
    // Standard MPEG Formats.
    case 'mpg':
    case 'mp2':
    case 'mpeg':
    case 'mpe':
    case 'mpv':
    case 'mp4':
    case 'm4p':
    case 'm4v':
    case 'm2v':
    case 'mov':
      return 'movie';

    default:
      return 'document';
  }
};
