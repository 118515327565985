/** @jsxImportSource @emotion/react */
import { useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { FormListItemWrapped } from '../components/FormListItem/FormListItemWrapped.tsx';
import { SectionTitle } from '../components/SectionTitle/SectionTitle.tsx';
import { SignoffWithAppeal } from '../components/SignoffWithAppeal/SignoffWithAppeal.tsx';
import { ISignoffLine } from '../types/types.ts';
import { useFormsDispatch, useFormsSelector } from '../redux/store.ts';
import { getUserAppealPermissions } from '../util/util.instance.ts';
import {
  AppealEventHandler,
  RevertSignoffEventHandler,
  SignoffEventHandler,
} from '../redux/form-instance/types.ts';
import { appealSlice } from '../redux/formInstanceAppeal/index.ts';
import {
  AppealInputChangeEventHandler,
  AppealInputValidationEventHandler,
} from '../redux/formInstanceAppeal/types.ts';
import {
  userSignoff,
  confirmRevertSignoff,
  userRevertedSignoff,
} from '../redux/form-instance/instance/actions.ts';

export interface ISignoffFactoryContainerProps {
  instanceId: string;
  isPdfExport: boolean;
  line: ISignoffLine;
}

export const SignoffFactoryContainer: React.FC<
  ISignoffFactoryContainerProps
> = ({ instanceId, isPdfExport, line }) => {
  const dispatch = useFormsDispatch();

  const permissionsIsLoading = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.permissionsIsLoading,
  );

  const appealState = useFormsSelector((state) => state.formInstanceAppeal);

  const subjectName = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.subject?.name,
  );

  const subjectDescription = useFormsSelector((state) => {
    const instanceSubject = state.formInstance.instances[instanceId]?.subject;
    if (!instanceSubject) return;
    return instanceSubject.kind === 'Person'
      ? instanceSubject.positionTitle
      : instanceSubject.path;
  });

  const reviewerName = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.reviewer?.name,
  );

  const reviewerDescription = useFormsSelector(
    (state) =>
      state.formInstance.instances[instanceId]?.reviewer?.positionTitle,
  );

  const subjectSignOff = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    if (!instance) return;

    const subjectId = instance.subject?.id;
    if (!subjectId) return;

    return instance.signOffs?.find(({ id }) => id === subjectId);
  });

  const subjectId = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.subject?.id,
  );

  const reviewerSignOff = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    if (!instance) return;

    const reviewerId = instance.reviewer?.id;
    if (!reviewerId) return;

    return instance.signOffs?.find(({ id }) => id === reviewerId);
  });

  const reviewerId = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.reviewer?.id,
  );

  const reviewerCanRevert = useFormsSelector((state) =>
    Boolean(
      state.formInstance.instances[instanceId]?.permissions
        .assignedToRevertSignOff,
    ),
  );

  const reviewerCanSignOff = useFormsSelector((state) =>
    Boolean(
      state.formInstance.instances[instanceId]?.permissions.assignedToSignOff,
    ),
  );

  const subjectCanRevert = useFormsSelector((state) =>
    Boolean(
      state.formInstance.instances[instanceId]?.permissions
        .subjectRevertSignOff,
    ),
  );

  const subjectCanSignOff = useFormsSelector((state) =>
    Boolean(
      state.formInstance.instances[instanceId]?.permissions.subjectSignOff,
    ),
  );

  const canChangeAppealDetails = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    if (!instance) return false;

    if (!instance.permissions.assignedToSignOff) return false;

    const instanceStatus = instance.status.status;
    return ['InProgress', 'Pending'].includes(instanceStatus);
  });

  const instanceStatus = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.status.status,
  );

  const assignedToLabel = useFormsSelector(
    (state) => state.tenantState.tenant?.locale.label.formAssignedTo,
  );

  const appealPermissions = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    if (!instance) return;

    const personId = state.tenantState.tenant?.authenticatedUser?.id;
    return getUserAppealPermissions(instance, personId);
  });

  const authenticatedUser = useFormsSelector(
    (state) => state.tenantState.tenant?.authenticatedUser,
  );

  const appealOutcomeReasons = useFormsSelector(
    (state) => state.tenantState.tenant?.configuration.appeal.outcomeReasons,
  );

  const appealOutcomeReasonSelections = useMemo(
    () =>
      appealOutcomeReasons?.map((outcome) => ({
        id: outcome,
        content: outcome,
        value: outcome,
      })),
    [appealOutcomeReasons],
  );

  const appealReasonCategories = useFormsSelector(
    (state) => state.tenantState.tenant?.configuration.appeal.reasonCategories,
  );

  const appealReasonCategorySelections = useMemo(
    () =>
      appealReasonCategories?.map((reasonCategory) => ({
        id: reasonCategory,
        content: reasonCategory,
        value: reasonCategory,
      })),
    [appealReasonCategories],
  );

  const handleAppeal = useCallback<AppealEventHandler>(
    (e) => {
      const signature = { ...authenticatedUser, type: e.personType };
      dispatch(appealSlice.validateAppeal({ instanceId, signature }));
    },
    [authenticatedUser, dispatch, instanceId],
  );

  const handleAppealInputChange = useCallback<AppealInputChangeEventHandler>(
    (e) => dispatch(appealSlice.updateAppealInput(e)),
    [dispatch],
  );

  const handleAppealInputValidation =
    useCallback<AppealInputValidationEventHandler>(
      ({ errors }) => dispatch(appealSlice.appealResponseValidation(errors)),
      [dispatch],
    );

  const handleSignOff = useCallback<SignoffEventHandler>(
    ({ personId, appealResponse, personType }) => {
      const signature = { ...authenticatedUser, type: personType };
      dispatch(userSignoff(instanceId, personId, signature, appealResponse));
    },
    [authenticatedUser, dispatch, instanceId],
  );

  const handleRevertSignoff = useCallback<RevertSignoffEventHandler>(
    ({ personId, personType }) => {
      const signature = { ...authenticatedUser, type: personType };

      if (line.reviewerRevertReasonRequired && personType === 'REVIEWER') {
        dispatch(confirmRevertSignoff(instanceId, personId, signature));
        return;
      }

      dispatch(userRevertedSignoff(instanceId, personId, signature));
    },
    [
      authenticatedUser,
      dispatch,
      instanceId,
      line.reviewerRevertReasonRequired,
    ],
  );

  const errorPreventSignOff = useFormsSelector((state) =>
    state.formIssue.loadError
      ? 'Something went wrong. Try reloading this page.'
      : undefined,
  );

  return (
    <FormListItemWrapped
      id={'signoff'}
      key={'signoff'}
      marginTop={60}
      marginBottom={40}
      highlightOnHover={false}
      bulletTop={-13.5}
      bulletLeft={0}
      bullet={'block'}
      isPdfExport={isPdfExport}
      isVisible={true}
    >
      <SectionTitle text={line.title} />
      <div css={css({ paddingBottom: 40 })} />
      <SignoffWithAppeal
        appeal={appealState}
        appealOutcomeReasons={appealOutcomeReasonSelections}
        appealReasonCategories={appealReasonCategorySelections}
        assignedToLabel={assignedToLabel}
        canAppealAsManager={appealPermissions?.canAppealAsManager}
        canAppealAsSubject={appealPermissions?.canAppealAsSubject}
        canChangeAppealDetails={canChangeAppealDetails}
        cannotAppeal={appealPermissions?.cannotAppeal}
        errorPreventSignOff={errorPreventSignOff}
        instanceId={instanceId}
        instanceStatus={instanceStatus}
        isPdfExport={isPdfExport}
        isSpinning={permissionsIsLoading}
        onAppealClick={handleAppeal}
        onAppealInputChange={handleAppealInputChange}
        onAppealInputValidation={handleAppealInputValidation}
        onlyManagerCanAppeal={appealPermissions?.onlyManagerCanAppeal}
        onRevertSignoffClick={handleRevertSignoff}
        onSignoffClick={handleSignOff}
        reviewerCanRevert={reviewerCanRevert}
        reviewerCanSignOff={reviewerCanSignOff}
        reviewerDescription={reviewerDescription}
        reviewerId={reviewerId}
        reviewerLabel={line.reviewerLabel}
        reviewerName={reviewerName}
        reviewerSignOff={reviewerSignOff}
        reviewerSignOffRequired={line.reviewerRequired}
        subjectCanRevert={subjectCanRevert}
        subjectCanSignOff={subjectCanSignOff}
        subjectDescription={subjectDescription}
        subjectId={subjectId}
        subjectLabel={line.subjectLabel}
        subjectName={subjectName}
        subjectSignOff={subjectSignOff}
        subjectSignOffRequired={line.subjectRequired}
      />
    </FormListItemWrapped>
  );
};
