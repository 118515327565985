import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import { FormGoalCategory } from '../types.ts';

export type GetFormGoalCategoriesResponseDto = {
  pageInfo: {
    hasNextPage: boolean;
    currentPage: number;
  };
  formGoalCategories: FormGoalCategory[];
};

type GetFormGoalCategoriesArgs = {
  pageNumber?: number;
  fetchAllPages?: boolean;
};

export const getFormGoalCategories = async (
  client: IGraphQLClient,
  args: GetFormGoalCategoriesArgs,
): Promise<QueryResult<GetFormGoalCategoriesResponseDto>> => {
  if (!args.fetchAllPages) return getFormGoalCategoriesPage(client, args);
  const allFormGoalCategories = await getFormGoalCategoriesRecursive(
    client,
    args,
  );
  return {
    isSuccess: true,
    data: {
      formGoalCategories: allFormGoalCategories,
      pageInfo: { hasNextPage: false, currentPage: 1 },
    },
  };
};

const getFormGoalCategoriesRecursive = async (
  client: IGraphQLClient,
  args: GetFormGoalCategoriesArgs,
  templates: FormGoalCategory[] = [],
  pageNumber = 1,
): Promise<FormGoalCategory[]> => {
  const response = await getFormGoalCategoriesPage(client, {
    pageNumber,
  });

  if (!response.isSuccess)
    throw new Error('Failed to retrieve form goal categories');

  const nextTemplates = templates.concat(response.data.formGoalCategories);

  const pageInfo = response.data.pageInfo;
  if (!pageInfo.hasNextPage) return nextTemplates;

  return getFormGoalCategoriesRecursive(
    client,
    args,
    nextTemplates,
    pageInfo.currentPage + 1,
  );
};

const getFormGoalCategoriesPage = async (
  client: IGraphQLClient,
  args: GetFormGoalCategoriesArgs,
): Promise<QueryResult<GetFormGoalCategoriesResponseDto>> => {
  try {
    const response = await client.query<{
      forms: {
        formGoalCategories: {
          pageInfo: { hasNextPage: boolean; currentPage: number };
          nodes: FormGoalCategory[];
        };
      };
    }>({
      query: gql`
        query FormGoalCategories($pageNumber: Int!) {
          forms {
            formGoalCategories(
              pagination: { pageNumber: $pageNumber, size: 100 }
              orderBy: [{ fieldName: "name", direction: Ascending }]
            ) {
              pageInfo {
                hasNextPage
                currentPage
              }
              nodes {
                id
                name
              }
            }
          }
        }
      `,
      variables: {
        pageNumber: args.pageNumber,
      },
      fetchPolicy: 'cache-first',
    });

    const { pageInfo, nodes } = response.data.forms.formGoalCategories;

    if (!nodes) {
      log.error(`Failed to retrieve form goal categories`);
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: {
        pageInfo: {
          hasNextPage: pageInfo.hasNextPage,
          currentPage: pageInfo.currentPage,
        },
        formGoalCategories: nodes.map((node) => ({
          id: node.id,
          name: node.name,
        })),
      },
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query form goal categories - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
