import { ServerMembership, TeamMembership } from '../types.ts';

export const translateMemberships = (
  memberships: ServerMembership[],
): TeamMembership[] =>
  memberships
    .filter((membership) => membership.team.type !== 'Role')
    .map(
      (membership): TeamMembership => ({
        id: membership.team.id,
        path: membership.team.path,
        role: membership.role,
        teamType: membership.team.type,
        name: membership.team.name,
        sourceKey: membership.sourceKey,
        isPrimary: membership.isPrimary,
      }),
    )
    .filter(isLowestLevelTeam)
    .sort(teamMembershipComparator);

const alphabeticalComparator = (
  first: TeamMembership,
  second: TeamMembership,
) => {
  if (first.name < second.name) return -1;
  if (first.name > second.name) return 1;
  return 0;
};

const pathDepthComparator = (first: TeamMembership, second: TeamMembership) => {
  const firstPathDepth = first.path.split('/').length;
  const secondPathDepth = second.path.split('/').length;

  return firstPathDepth === secondPathDepth
    ? alphabeticalComparator(first, second)
    : firstPathDepth - secondPathDepth;
};

const teamMembershipComparator = (
  first: TeamMembership,
  second: TeamMembership,
): number => {
  const firstTeamType = first.teamType;
  const firstRole = first.role;

  const secondTeamType = second.teamType;
  const secondRole = second.role;

  if (firstTeamType === 'Role' && secondTeamType !== 'Role') return -1;

  if (firstTeamType !== 'Role' && secondTeamType === 'Role') return 1;

  if (firstRole === 'Manager' && secondRole === 'Member') return -1;
  if (secondRole === 'Manager' && firstRole === 'Member') return 1;

  if (
    firstRole === secondRole ||
    (firstTeamType === 'Role' && secondTeamType === 'Role')
  )
    return pathDepthComparator(first, second);

  return 0;
};

const isLowestLevelTeam = (
  teamMembership: TeamMembership,
  _: number,
  allMemberships: TeamMembership[],
): boolean => {
  if (teamMembership.role === 'Manager') return true;

  const hasLowerLevelTeamMembership = allMemberships.some((membership) => {
    if (!membership.path.startsWith(teamMembership.path)) return false;

    const teamMembershipDepth = teamMembership.path.split('/').length;
    const membershipDepth = membership.path.split('/').length;

    return teamMembershipDepth < membershipDepth;
  });

  return !hasLowerLevelTeamMembership;
};
