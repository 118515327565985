/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Markdown } from '@seeeverything/ui.primitives/src/components/Markdown/Markdown.tsx';
import { FormListItemWrapped } from '../components/FormListItem/FormListItemWrapped.tsx';
import { useHandleAnswerVisibility } from '../hooks/useHandleAnswerVisibility.ts';
import { IQuestionLine } from '../types/types.ts';

export interface IQuestionFactoryContainerProps {
  instanceId: string;
  isPdfExport: boolean;
  line: IQuestionLine;
}
export const QuestionFactoryContainer: React.FC<
  IQuestionFactoryContainerProps
> = ({ instanceId, isPdfExport, line }) => {
  const isVisible = useHandleAnswerVisibility({
    instanceId,
    questionKeys: [],
    showOnAnswer: line.showOnAnswer,
    showOnReportingDateMonth: line.showOnReportingDateMonth,
    showOnSwitch: line.showOnSwitch,
  });

  return (
    <FormListItemWrapped
      id={line.id}
      parentId={line.parentId}
      key={line.id}
      highlightOnHover={line.highlightOnHover}
      bulletTop={5}
      isVisible={isVisible}
      isPdfExport={isPdfExport}
    >
      <div css={styles.base}>
        <Markdown text={line.label} />
      </div>
    </FormListItemWrapped>
  );
};

const styles = {
  base: css({
    cursor: 'default',
  }),
};
