import { CommonDefinition } from '../parse/types/common.types.ts';
import {
  InputDefinition,
  SelectAnswerDefinition,
  SelectAnswerOptionDefinition,
} from '../parse/types/input.types.ts';
import { OptionsDefinition } from '../parse/types/options.types.ts';
import {
  ScoreSectionDefinition,
  SectionQuestionDefinition,
} from '../parse/types/section.types.ts';
import {
  FormTemplateItemDefinition,
  ScoringItem,
  SectionItem,
} from '../parse/types/template.types.ts';

export const isSection = (
  item: FormTemplateItemDefinition,
): item is SectionItem => Boolean((item as SectionItem).section);

export const isRichSelectOption = (
  item: string | SelectAnswerOptionDefinition,
): item is SelectAnswerOptionDefinition =>
  Boolean(
    (item as SelectAnswerOptionDefinition).id ||
      (item as SelectAnswerOptionDefinition).label,
  );

export const isSelectAnswerDefinition = (
  item: InputDefinition,
): item is SelectAnswerDefinition =>
  Boolean((item as SelectAnswerDefinition).options);

export const isScoringItem = (
  item: FormTemplateItemDefinition,
): item is ScoringItem => Boolean((item as ScoringItem).scoring);

export const isScoringSectionDefinition = (
  item: SectionQuestionDefinition,
): item is ScoreSectionDefinition =>
  Boolean((item as ScoreSectionDefinition).score);

export const isOptionsAnswerDefinition = (
  item: CommonDefinition,
): item is OptionsDefinition => Boolean((item as OptionsDefinition).answers);
