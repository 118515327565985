import { Observable, map, filter, mergeMap } from 'rxjs';
import { StateObservable, ofType } from 'redux-observable';
import gql from 'graphql-tag';
import * as R from 'ramda';
import {
  ReduxFormDigitalContentAddComment,
  ReduxFormDigitalContentAddCommentReady,
  ReduxFormDigitalContentRetryAddComment,
  ICommentTeam,
} from './types.ts';
import {
  digitalContentCommentsAddCommentSuccess,
  digitalContentCommentAddCommentError,
  digitalContentCommentsAddCommentReady,
} from './actions.ts';
import {
  FormPerson,
  FormTeam,
  IFormInstance,
} from '../../form-instance/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { PrimaryTeam } from '@seeeverything/ui.util/src/types.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../../store.ts';

export function addCommentEpic(
  action$: Observable<ReduxFormDigitalContentAddCommentReady>,
  _: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/digital-content/comments/ADD_COMMENT_READY'),
    mergeMap(
      async ({
        payload: { body, commentId, formInstanceId, packId, team, parentId },
      }) => {
        const response = await createComment(client, {
          id: commentId,
          body,
          formInstanceId,
          packId,
          teamId: team?.id,
          parentId,
        });

        return response
          ? digitalContentCommentsAddCommentSuccess(commentId)
          : digitalContentCommentAddCommentError(
              commentId,
              'Something went wrong saving when saving this comment. Click here to retry.',
            );
      },
    ),
  );
}

export function retryAddCommentEpic(
  action$: Observable<ReduxFormDigitalContentRetryAddComment>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/digital-content/comments/RETRY_ADD_COMMENT'),
    filter(({ payload: { commentId } }) =>
      Boolean(
        getContentMeta(state$.value)?.id &&
          getExistingComment(state$.value, commentId),
      ),
    ),
    mergeMap(async ({ payload: { commentId } }) => {
      const comment = getExistingComment(state$.value, commentId);
      const instance = getFormInstance(state$.value) as IFormInstance;
      const { id: formInstanceId, subject } = instance;
      const packId = getContentMeta(state$.value)?.id as string;

      const response = await createComment(client, {
        id: commentId,
        body: comment?.body as string,
        formInstanceId,
        packId,
        teamId: subject?.id as string,
        parentId: comment?.parentId,
      });

      return response
        ? digitalContentCommentsAddCommentSuccess(commentId)
        : digitalContentCommentAddCommentError(
            commentId,
            'Something went wrong adding this comment. Click here to retry.',
          );
    }),
  );
}

export function addCommentRequestEpic(
  action$: Observable<ReduxFormDigitalContentAddComment>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    ofType('ui.forms/digital-content/comments/ADD_COMMENT'),
    filter(({ payload: { body } }) =>
      Boolean(
        body &&
          state$.value.formDigitalContent.contentMeta &&
          state$.value.tenantState.tenant.authenticatedUser,
      ),
    ),
    map(({ payload: { body, commentId, parentId } }) => {
      const user = state$.value.tenantState.tenant.authenticatedUser;
      const instance = getFormInstance(state$.value);
      const formInstanceId = instance?.id;
      const packId = getContentMeta(state$.value)?.id;
      const team = resolveTeam(instance?.subject, user.primaryTeam);

      return digitalContentCommentsAddCommentReady({
        body,
        commentId,
        formInstanceId,
        packId,
        team,
        person: `${user.firstName} ${user.lastName}`,
        parentId,
      });
    }),
  );
}

const resolveTeam = (
  instanceSubject?: FormPerson | FormTeam,
  team?: PrimaryTeam,
): ICommentTeam | undefined => {
  if (instanceSubject)
    return { id: instanceSubject.id, name: instanceSubject.name };

  if (team) return { id: team.id, name: team.name };

  return undefined;
};

const getFormInstance = (state: GlobalFormsState) =>
  R.head(Object.values(state.formInstance.instances));

const getExistingComment = (state: GlobalFormsState, commentId: string) => {
  const comments = state.formDigitalContent.comments?.comments;
  if (!comments) {
    return;
  }

  const comment = comments.find(
    ({ id, replies }) =>
      id === commentId || replies?.some((reply) => reply.id === commentId),
  );

  const isReply = comment?.id !== commentId;
  if (isReply) {
    return comment?.replies?.find((reply) => reply.id === commentId);
  }

  return comment;
};

const getContentMeta = (state: GlobalFormsState) =>
  state.formDigitalContent.contentMeta;

type CreateCommentInputs = {
  id: string;
  packId: string;
  body: string;
  formInstanceId?: string;
  teamId?: string;
  parentId?: string;
};

const createComment = async (
  client: IGraphQLClient,
  { packId, body, formInstanceId, teamId, parentId, id }: CreateCommentInputs,
) => {
  try {
    const response = await client.mutate<IGraphQLAddCommentResponse>({
      mutation: mutationCreateComment,
      variables: { packId, body, formInstanceId, parentId, teamId, id },
    });
    if (!response.data?.digitalContent.createDigitalContentPackComment.ok) {
      throw new Error('Mutation failed to execute');
    }
    return true;
  } catch (error) {
    log.error(`GraphQL Error: Error saving comment ${id} against digital content pack ${packId}
     ${error}`);
    return false;
  }
};

interface IGraphQLAddCommentResponse {
  digitalContent: {
    createDigitalContentPackComment: {
      ok: boolean;
    };
  };
}

const mutationCreateComment = gql`
  mutation CreateDigitalContentComment(
    $id: ID!
    $packId: ID!
    $body: String!
    $formInstanceId: ID
    $parentId: ID
    $teamId: ID
  ) {
    digitalContent {
      createDigitalContentPackComment(
        input: {
          id: $id
          digitalContentPackId: $packId
          body: $body
          formInstanceId: $formInstanceId
          parentId: $parentId
          teamId: $teamId
        }
      ) {
        ok
      }
    }
  }
`;
