import { recursiveFetchAll } from '@seeeverything/ui.util/src/graphql/graphql/recursiveFetchAll.ts';
import { PageInfoResponse } from '@seeeverything/ui.util/src/types.ts';
import gql from 'graphql-tag';
import { FormAction } from '../types.ts';
import { QueryOptions } from './types.ts';

type ActionsResponse = {
  pageInfo: PageInfoResponse;
  actions: FormAction[];
};

type ServerResponse = {
  forms: { formActions: { pageInfo: PageInfoResponse; nodes: FormAction[] } };
};

const createActionMapFunction =
  (actionLabel: string, issueActionLabel: string) =>
  (node: FormAction): FormAction => ({
    ...node,
    type: 'FormAction',
    typeLabel: node.issueId ? issueActionLabel : actionLabel,
    kind: node.issueId ? 'ISSUE_ACTION' : 'STANDALONE',
    statusLabel:
      node.verification?.status === 'Returned'
        ? `${node.status} (Returned)`
        : node.status,
  });

export const queryAllActionsPages = async (
  options: QueryOptions,
): Promise<ActionsResponse> => {
  const { client, variables, actionLabel, issueActionLabel } = options;
  const allPages = await recursiveFetchAll<ServerResponse>(
    {
      client,
      query,
      variables,
      pageSize: 100,
    },
    (response) => response.forms.formActions.pageInfo.hasNextPage,
  );

  const toAction = createActionMapFunction(actionLabel, issueActionLabel);

  return allPages.reduce(
    (acc: ActionsResponse, page): ActionsResponse => ({
      pageInfo: page.forms.formActions.pageInfo,
      actions: (acc?.actions ?? []).concat(
        page.forms.formActions.nodes.map(toAction),
      ),
    }),
    undefined,
  );
};

export const queryActionsPage = async (
  options: QueryOptions,
): Promise<ActionsResponse> => {
  const { client, variables, actionLabel, issueActionLabel } = options;
  const response = await client.query<ServerResponse>({
    query,
    variables: { ...variables, pageNumber: 1, pageSize: 10 },
    fetchPolicy: 'network-only',
  });

  const toAction = createActionMapFunction(actionLabel, issueActionLabel);

  return {
    pageInfo: response.data.forms.formActions.pageInfo,
    actions: response.data.forms.formActions.nodes.map(toAction),
  };
};

const query = gql`
  query FormActions(
    $orderBy: [OrderByInput!]
    $pageNumber: Int
    $pageSize: Int
    $personId: ID
    $teamId: ID
    $startDate: String
    $endDate: String
    $status: FormActionStatusFilterInput!
  ) {
    forms {
      formActions(
        assignedToPersonId: $personId
        assignedToTeamId: $teamId
        orderBy: $orderBy
        pagination: { size: $pageSize, pageNumber: $pageNumber }
        hasParentGoal: false
        startDate: $startDate
        endDate: $endDate
        status: $status
      ) {
        pageInfo {
          currentPage
          hasNextPage
          pageSize
          totalCount
          totalPages
        }
        nodes {
          id
          assignedTo {
            id
            name
          }
          description
          status
          dueBy
          issueId
          createdAt
          verification {
            status
          }
        }
      }
    }
  }
`;
