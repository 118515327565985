import gql from 'graphql-tag';
import { of, EMPTY, Observable, mergeMap, concatAll, filter } from 'rxjs';
import { StateObservable, ofType } from 'redux-observable';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';
import { ReduxFormInstanceServerCancel } from '../types.ts';
import { cancelledFormInstance } from './actions.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../../store.ts';

/**
 * Cancels a form instance.
 */
export function cancelFormInstanceEpic(
  action$: Observable<ReduxFormInstanceServerCancel>,
  _: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/instance/SERVER_CANCEL'),
    filter(() => typeof window !== 'undefined'),
    mergeMap(async ({ payload }) => {
      if (!window.confirm(payload.confirmMessage)) return EMPTY;

      await cancelInstance(client, payload.instanceId);
      return of(cancelledFormInstance(payload.instanceId));
    }),
    concatAll(),
  );
}

const cancelInstance = async (
  client: IGraphQLClient,
  instanceId: string,
): Promise<void> => {
  const result = await client.mutate<any>({
    mutation,
    variables: { instanceId },
  });

  if (result.errors || !result.data?.forms?.cancelFormInstance?.ok) {
    throw new Error(
      `Error occurred while trying to cancel form instance with id ${instanceId}: ${result.errors}`,
    );
  }
};

const mutation = gql`
  mutation CancelInstance($instanceId: ID!) {
    forms {
      cancelFormInstance(id: $instanceId) {
        ok
      }
    }
  }
`;
