import { mergeDeepRight, reduce } from 'ramda';
import { DeepPartial } from '../types.ts';

/**
 * Provides a safe and extended implementation of Ramda's mergeDeepRight functionality,
 * that handles null or undefined, and also supports any number of inputs.
 */
export const mergeDeepRightAll = <T extends object>(
  ...inputs: Array<T | DeepPartial<T>>
): T =>
  reduce(
    (acc, next: T | DeepPartial<T>) => mergeDeepRight(acc, next ?? {}),
    {} as T | DeepPartial<T>,
  )(inputs) as T;
