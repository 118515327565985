/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  CheckboxGroup,
  CheckboxGroupEventHandler,
  CheckboxOption,
  CheckboxValue,
} from '@seeeverything/ui.primitives/src/components/CheckboxRadioGroup/CheckboxGroup.tsx';
import { Markdown } from '@seeeverything/ui.primitives/src/components/Markdown/Markdown.tsx';
import { TextAnswer } from '../TextAnswer/TextAnswer.tsx';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';

export interface IOptionsAnswerIssueProps {
  answerId: string;
  classificationLabel?: string;
  classificationOptions?: CheckboxOption[];
  classificationsError?: string;
  classificationValue?: CheckboxValue[];
  isEnabled: boolean;
  notesError?: string;
  notesLabel?: string;
  notesValue?: string;
  onClassificationChange: CheckboxGroupEventHandler;
  onNotesChange: (to: string) => void;
}

export const OptionsAnswerIssue: React.FC<IOptionsAnswerIssueProps> = ({
  answerId,
  classificationLabel = 'Details',
  classificationOptions,
  classificationsError,
  classificationValue,
  isEnabled,
  notesError,
  notesLabel = 'Reviewer Notes',
  notesValue,
  onClassificationChange,
  onNotesChange,
}) => (
  <>
    {Boolean(classificationOptions?.length) && (
      <div css={styles.classificationBase}>
        <Markdown
          style={classificationsError ? styles.titleIsRequired : undefined}
          text={`${classificationLabel}:`}
        />
        <CheckboxGroup
          direction={'vertical'}
          isEnabled={isEnabled}
          onChange={onClassificationChange}
          options={classificationOptions}
          value={classificationValue}
          style={styles.classificationSelections}
          error={classificationsError}
        />
      </div>
    )}
    <TextAnswer
      id={`issue-note-${answerId}`}
      isEnabled={isEnabled}
      onChange={onNotesChange}
      floatingText={notesLabel}
      value={notesValue}
      multiline={true}
      error={notesError}
    />
  </>
);

const styles = {
  classificationBase: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  }),
  classificationSelections: {
    marginLeft: 15,
  },
  titleIsRequired: css({
    color: COLORS.ERROR_RED,
  }),
};
