import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GoalSchedule } from '@se/data/forms/query/goalScheduleTypes.ts';
import { DataGridFilter } from '@seeeverything/ui.primitives/src/components/DataGrid/index.ts';
import { OrderBy } from '@seeeverything/ui.util/src/types.ts';

export type GoalScheduleState = {
  schedules: GoalSchedule[];
  filters: DataGridFilter[];
  isLoading: boolean;
  isLoadingMore: boolean;
  error: boolean;
  hasNextPage: boolean;
  currentPage: number;
  showAll: boolean;
  orderBy: OrderBy;
};

const DEFAULT_STATE: GoalScheduleState = {
  schedules: [],
  filters: [],
  isLoading: false,
  isLoadingMore: false,
  error: false,
  hasNextPage: false,
  currentPage: 1,
  showAll: false,
  orderBy: {
    fieldName: 'createdAt',
    direction: 'Descending',
  },
};

const slice = createSlice({
  name: 'libs/forms/goalSchedule',
  initialState: DEFAULT_STATE,
  reducers: {
    loadGoalSchedules(state, action: PayloadAction<{ loadNextPage: boolean }>) {
      state.error = false;
      if (!action.payload.loadNextPage) {
        state.isLoading = true;
      } else {
        state.isLoadingMore = true;
      }
    },
    loadedGoalSchedules(
      state,
      action: PayloadAction<{
        hasNextPage: boolean;
        currentPage: number;
        schedules: GoalSchedule[];
      }>,
    ) {
      const { schedules, hasNextPage, currentPage } = action.payload;
      if (currentPage === 1) {
        state.schedules = schedules;
      } else {
        state.schedules.push(...schedules);
      }
      state.hasNextPage = hasNextPage;
      state.currentPage = currentPage;
      state.isLoading = false;
      state.isLoadingMore = false;
      state.error = false;
    },
    errorLoadingGoalSchedules(state) {
      state.isLoading = false;
      state.isLoadingMore = false;
      state.error = true;
    },
    sortBy(
      state,
      action: PayloadAction<{
        fieldName: 'createdAt' | 'goalDescription' | 'startDate';
        direction: 'Ascending' | 'Descending';
      }>,
    ) {
      state.orderBy = {
        fieldName: action.payload.fieldName,
        direction: action.payload.direction,
      };
    },
    toggleShowAll(state, action: PayloadAction<{ to: boolean }>) {
      state.showAll = action.payload.to;
    },
  },
});

export const {
  loadGoalSchedules,
  loadedGoalSchedules,
  errorLoadingGoalSchedules,
  sortBy,
  toggleShowAll,
} = slice.actions;
export const reducer = slice.reducer;
export type State = GoalScheduleState;
