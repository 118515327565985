import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ReduxDebugState = {
  isLoggingEnabled: boolean;
  loggingStartsWithFilter: string;
};

const DEFAULT_STATE: ReduxDebugState = {
  isLoggingEnabled: false,
  loggingStartsWithFilter: 'NON-MATCH',
};

const slice = createSlice({
  name: 'global/debug',
  initialState: DEFAULT_STATE,
  reducers: {
    setLoggingFlag(state, action: PayloadAction<boolean>) {
      state.isLoggingEnabled = action.payload;
    },
    setStartsWithFilter(state, action: PayloadAction<string>) {
      state.loggingStartsWithFilter = action.payload;
    },
  },
});

export const { setLoggingFlag, setStartsWithFilter } = slice.actions;
export const reducer = slice.reducer;
export type State = ReduxDebugState;
