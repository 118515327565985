import moment from 'moment';
import { omit, uniq, without } from 'ramda';
import { ISignoffLine } from '../../../types/types.ts';
import {
  IFormInstanceState,
  ReduxFormInstanceAddFileToInstance,
  ReduxFormInstanceAppealStatusSet,
  ReduxFormInstanceAttendanceAllAttendedChanged,
  ReduxFormInstanceAttendeeSaved,
  ReduxFormInstanceAttendeeUpdateAttendance,
  ReduxFormInstanceAttendeeUpdateFollowUpAction,
  ReduxFormInstanceClearErrors,
  ReduxFormInstanceDeleteFileFromInstance,
  ReduxFormInstanceErrorLoading,
  ReduxFormInstanceFlagValidationError,
  ReduxFormInstancePermissionsLoad,
  ReduxFormInstancePermissionsLoaded,
  ReduxFormInstanceServerCreate,
  ReduxFormInstanceServerCreateError,
  ReduxFormInstanceServerLoad,
  ReduxFormInstanceServerLoaded,
  ReduxFormInstanceServerRevertSignoffSuccess,
  ReduxFormInstanceServerSignoff,
  ReduxFormInstanceUserRevertSignoff,
  ReduxFormInstanceUserSignoff,
} from '../types.ts';

export function appealStatusSet(
  state: IFormInstanceState,
  action: ReduxFormInstanceAppealStatusSet,
): IFormInstanceState {
  const { instanceId } = action.payload;
  return {
    ...state,
    instances: {
      ...state.instances,
      [instanceId]: {
        ...state.instances[instanceId],
        status: { lastUpdated: moment().toISOString(), status: 'Appealed' },
      },
    },
  };
}

export function load(
  state: IFormInstanceState,
  action: ReduxFormInstanceServerLoad,
): IFormInstanceState {
  const { instanceId, clearErrors = true } = action.payload;

  const modifiedLoadErrors = { ...state.errors.load };
  if (clearErrors) {
    delete modifiedLoadErrors[instanceId];
  }

  return {
    ...state,
    errors: {
      ...state.errors,
      load: { ...modifiedLoadErrors },
    },
    instances: {},
  };
}

export function loaded(
  state: IFormInstanceState,
  action: ReduxFormInstanceServerLoaded,
): IFormInstanceState {
  const { instanceId, instance } = action.payload;
  const validationIds = without([instanceId], state.errors.validation.ids);

  const modifiedLoadErrors = { ...state.errors.load };
  delete modifiedLoadErrors[instanceId];

  return {
    ...state,
    errors: {
      ...state.errors,
      load: modifiedLoadErrors,
      validation: { ids: validationIds },
    },
    instances: {
      ...state.instances,
      [instanceId]: instance,
    },
    questionKeys: Object.keys(instance.lines),
    issueAnswerDefinitions: Object.entries(instance.lines).reduce(
      (acc, [questionKey, line]) => {
        if (line.type !== 'optionsAnswer') return acc;

        const optionsWithIssues = (line.options ?? []).filter((o) =>
          Boolean(o.issue),
        );
        if (!optionsWithIssues.length) return acc;

        return {
          ...acc,
          [questionKey]: optionsWithIssues.flatMap((optionAnswer) => ({
            ...optionAnswer.issue,
            optionId: optionAnswer.id,
          })),
        };
      },
      {},
    ),
  };
}

export function errorLoading(
  state: IFormInstanceState,
  action: ReduxFormInstanceErrorLoading,
): IFormInstanceState {
  const { instanceId, type } = action.payload;

  return {
    ...state,
    errors: {
      ...state.errors,
      load: { [instanceId]: type },
    },
  };
}

export function create(
  state: IFormInstanceState,
  action: ReduxFormInstanceServerCreate,
): IFormInstanceState {
  const { instanceId } = action.payload;

  return {
    ...state,
    errors: {
      ...state.errors,
      create: {
        ...omit([instanceId], state.errors.create),
      },
    },
  };
}

export function errorCreatingInstance(
  state: IFormInstanceState,
  action: ReduxFormInstanceServerCreateError,
): IFormInstanceState {
  const {
    instanceId,
    templateId,
    templateDefinitionId,
    templateName,
    categoryName,
  } = action.payload;

  return {
    ...state,
    errors: {
      ...state.errors,
      create: {
        ...state.errors.create,
        [instanceId]: {
          templateId,
          templateDefinitionId,
          templateName,
          categoryName,
        },
      },
    },
  };
}

export function permissionsLoading(
  state: IFormInstanceState,
  action: ReduxFormInstancePermissionsLoad,
): IFormInstanceState {
  const { instanceId } = action.payload;
  return {
    ...state,
    instances: {
      ...state.instances,
      [instanceId]: {
        ...state.instances[instanceId],
        permissionsIsLoading: true,
      },
    },
  };
}

export function permissionsLoaded(
  state: IFormInstanceState,
  action: ReduxFormInstancePermissionsLoaded,
): IFormInstanceState {
  const { instanceId, permissions, status, signOffs } = action.payload;
  return {
    ...state,
    instances: {
      ...state.instances,
      [instanceId]: {
        ...state.instances[instanceId],
        permissions,
        permissionsIsLoading: false,
        status: {
          ...state.instances[instanceId].status,
          status,
        },
        signOffs,
      },
    },
  };
}

export function flagValidationErrorOnInstance(
  state: IFormInstanceState,
  action: ReduxFormInstanceFlagValidationError,
): IFormInstanceState {
  const { instanceId } = action.payload;

  return {
    ...state,
    instances: {
      ...state.instances,
      [instanceId]: {
        ...state.instances[instanceId],
        permissionsIsLoading: false,
      },
    },
    errors: {
      ...state.errors,
      validation: {
        ids: uniq([...state.errors.validation.ids, instanceId]),
      },
    },
  };
}

export function flagGeneralErrorOnInstance(
  state: IFormInstanceState,
): IFormInstanceState {
  return {
    ...state,
    errors: {
      ...state.errors,
      showGeneralError: true,
    },
  };
}

export function setInstancePermissionsLoading(
  state: IFormInstanceState,
  action:
    | ReduxFormInstanceUserRevertSignoff
    | ReduxFormInstanceUserSignoff
    | ReduxFormInstanceServerSignoff,
): IFormInstanceState {
  const { instanceId } = action.payload;

  return {
    ...state,
    instances: {
      ...state.instances,
      [instanceId]: {
        ...state.instances[instanceId],
        permissionsIsLoading: true,
      },
    },
  };
}

export const localRevertSignoffSuccess = (
  state: IFormInstanceState,
  action: ReduxFormInstanceServerRevertSignoffSuccess,
): IFormInstanceState => {
  const { instanceId, personId } = action.payload;
  const instance = state.instances[instanceId];

  const signoffLine = instance.lines.signoff as ISignoffLine;
  const isDualSignoff = Boolean(signoffLine && signoffLine.subjectRequired);

  const isStillPending = isDualSignoff && instance.signOffs.length === 2;

  return {
    ...state,
    instances: {
      [instanceId]: {
        ...instance,
        signOffs: (instance.signOffs || []).filter(
          (signOff) => signOff.id !== personId,
        ),
        status: {
          status: isStillPending ? 'Pending' : 'InProgress',
          lastUpdated: new Date().toISOString() as any,
        },
      },
    },
  };
};

export const addFileToInstance = (
  state: IFormInstanceState,
  action: ReduxFormInstanceAddFileToInstance,
): IFormInstanceState => ({
  ...state,
  instances: {
    [action.payload.instanceId]: {
      ...state.instances[action.payload.instanceId],
      fileIds: [
        ...state.instances[action.payload.instanceId].fileIds,
        action.payload.fileId,
      ],
    },
  },
});

export const deleteFileFromInstance = (
  state: IFormInstanceState,
  action: ReduxFormInstanceDeleteFileFromInstance,
): IFormInstanceState => ({
  ...state,
  instances: {
    [action.payload.instanceId]: {
      ...state.instances[action.payload.instanceId],
      fileIds: [
        ...state.instances[action.payload.instanceId].fileIds.filter(
          (fieldId) => fieldId !== action.payload.fileId,
        ),
      ],
    },
  },
});

export const clearErrorsForAnswerKeys = (
  state: IFormInstanceState,
  action: ReduxFormInstanceClearErrors,
): IFormInstanceState => {
  const { instanceId, answerKeys } = action.payload;
  if (!answerKeys?.length) return state;

  const instance = state.instances[instanceId];

  return {
    ...state,
    errors: {
      ...state.errors,
      validation: {
        ids: state.errors.validation.ids.filter((id) => id !== instanceId),
      },
    },
    instances: {
      [instanceId]: {
        ...instance,
        questionErrors: answerKeys.reduce(
          (acc, key) => ({ ...acc, [key]: undefined }),
          instance.questionErrors,
        ),
      },
    },
  };
};

export const attendeeAttendanceUpdated = (
  state: IFormInstanceState,
  action: ReduxFormInstanceAttendeeUpdateAttendance,
): IFormInstanceState => {
  const { instanceId, isAttending, attendeeId, error } = action.payload;
  const formInstance = state.instances[instanceId];
  const attendees = formInstance.attendees;
  if (!attendees?.length) return state;

  return {
    ...state,
    instances: {
      [instanceId]: {
        ...formInstance,
        attendees: attendees.map((attendee) =>
          attendee.id === attendeeId
            ? {
                ...attendee,
                isAttending,
                isFollowUpRequired: false,
                isSavingAttendance: !error,
                error,
              }
            : attendee,
        ),
      },
    },
  };
};

export const attendeeFollowUpUpdated = (
  state: IFormInstanceState,
  action: ReduxFormInstanceAttendeeUpdateFollowUpAction,
): IFormInstanceState => {
  const { instanceId, isFollowUpRequired, attendeeId, error } = action.payload;
  const formInstance = state.instances[instanceId];
  const attendees = formInstance.attendees;
  if (!attendees?.length) return state;

  return {
    ...state,
    instances: {
      [instanceId]: {
        ...formInstance,
        attendees: attendees.map((attendee) =>
          attendee.id === attendeeId
            ? {
                ...attendee,
                isFollowUpRequired,
                isSavingFollowUp: !error,
                error,
              }
            : attendee,
        ),
      },
    },
  };
};

export const attendeeUpdateAllAttending = (
  state: IFormInstanceState,
  action: ReduxFormInstanceAttendanceAllAttendedChanged,
): IFormInstanceState => {
  const { instanceId, isAttending: allAttending } = action.payload;
  const formInstance = state.instances[instanceId];
  const attendees = formInstance.attendees;
  if (!attendees?.length) return state;

  return {
    ...state,
    instances: {
      [instanceId]: {
        ...formInstance,
        attendees: attendees.map((attendee) => ({
          ...attendee,
          isAttending: allAttending,
          isSavingAttendance: attendee.isAttending !== allAttending,
          error: undefined,
        })),
      },
    },
  };
};

export const attendeeSaved = (
  state: IFormInstanceState,
  action: ReduxFormInstanceAttendeeSaved,
): IFormInstanceState => {
  const { instanceId, attendeeId } = action.payload;
  const formInstance = state.instances[instanceId];
  const attendees = formInstance.attendees;

  if (!attendees?.find((attendee) => attendee.id === attendeeId)) return state;

  return {
    ...state,
    instances: {
      [instanceId]: {
        ...formInstance,
        attendees: attendees.map((attendee) =>
          attendee.id === attendeeId
            ? {
                ...attendee,
                isSavingAttendance: false,
                isSavingFollowUp: false,
              }
            : attendee,
        ),
      },
    },
  };
};
