export const downloadFileInFrame = (url: string, timeout = 5000) => {
  if (typeof window === 'undefined') return;

  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = url;
  document.body.appendChild(iframe);
  setTimeout(() => {
    document.body.removeChild(iframe);
  }, timeout);
};
