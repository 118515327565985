/** @jsxImportSource @emotion/react */
import React from 'react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/index.ts';
import { Switch } from '@seeeverything/ui.primitives/src/components/Switch/Switch.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { css } from '@emotion/react';

export interface IRowRightContentProps {
  isSwitchVisible?: boolean;
  isSavingAttendance?: boolean;
  isSavingFollowUp?: boolean;
  isAttending?: boolean;
  onSwitchChange?: (to: boolean) => void;
  isSwitchChecked?: boolean;
  isEnabled?: boolean;
}

/**
 * Content displayed to the right of an `AttendeeRow`.
 */
export const RowRightContent: React.FC<IRowRightContentProps> = (props) => {
  const {
    isSavingAttendance,
    isSavingFollowUp,
    isSwitchChecked,
    onSwitchChange,
    isEnabled,
    isAttending,
    isSwitchVisible = true,
  } = props;
  const handleSwitchClicked = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => e.stopPropagation(); // Prevents `onClick`s firing from parents when the switch is clicked.

  const handleSwitchChanged = (to: boolean) => {
    if (!isSavingFollowUp) {
      onSwitchChange?.(to);
    }
  };

  if (isSavingAttendance) {
    return (
      <div css={styles.base}>
        <Spinner />
      </div>
    );
  }

  if (isAttending) {
    return (
      <div css={styles.base}>
        <Text color={color.format(-0.8)} cursor={'pointer'} italic={true}>
          {'Attended'}
        </Text>
      </div>
    );
  }

  return (
    <div css={styles.base}>
      {isSavingFollowUp && <Spinner />}
      {isSwitchVisible && (
        <Switch
          label={'Follow-up Action'}
          labelPosition={'start'}
          onChange={handleSwitchChanged}
          onClick={handleSwitchClicked}
          isChecked={isSwitchChecked}
          isEnabled={isEnabled}
        />
      )}
    </div>
  );
};

const styles = {
  base: css({
    marginLeft: 'auto',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  }),
};
