import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Signature } from '../form-instance/types.ts';
import {
  AppealStateErrors,
  ConfirmAppealPayload,
  FormInstanceAppeal,
} from './types.ts';

export type FormInstanceAppealState = {
  errors?: AppealStateErrors;
  outcome?: string;
  outcomeReason?: string;
  reason?: string;
  request?: string;
  response?: string;
};

const DEFAULT_STATE: FormInstanceAppealState = {};

const slice = createSlice({
  name: 'libs/forms/instance/appeal',
  initialState: DEFAULT_STATE,
  reducers: {
    appealResponseValidation(state, action: PayloadAction<AppealStateErrors>) {
      state.errors = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    confirmAppeal(state, action: PayloadAction<ConfirmAppealPayload>) {
      return;
    },
    loadedAppeal(state, action: PayloadAction<FormInstanceAppeal>) {
      const { outcome, outcomeReason, reason, request, response } =
        action.payload;

      state.errors = undefined;
      state.outcome = outcome;
      state.outcomeReason = outcomeReason;
      state.reason = reason;
      state.request = request;
      state.response = response;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    reloadAppeal(state, action: PayloadAction<{ instanceId: string }>) {
      return state;
    },

    serverAppeal(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        instanceId: string;
        request: string;
        signature: Signature;
      }>,
    ) {
      return state;
    },
    serverAppealSuccess(
      state,
      action: PayloadAction<{
        instanceId: string;
        request: string;
        signature: Signature;
      }>,
    ) {
      state.request = action.payload.request;
    },
    updateAppealInput(
      state,
      action: PayloadAction<{
        field: 'outcome' | 'outcomeReason' | 'reason' | 'request' | 'response';
        to: string;
      }>,
    ) {
      state[action.payload.field] = action.payload.to;
    },
    validateAppeal(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        instanceId: string;
        signature: Signature;
      }>,
    ) {
      return state;
    },
  },
});

export const {
  appealResponseValidation,
  confirmAppeal,
  loadedAppeal,
  reloadAppeal,
  serverAppeal,
  serverAppealSuccess,
  updateAppealInput,
  validateAppeal,
} = slice.actions;
export const reducer = slice.reducer;
export type State = FormInstanceAppealState;
