/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import { head, is, last } from 'ramda';
import { css, SerializedStyles } from '@emotion/react';
import {
  Slider as MaterialSlider,
  FormHelperText,
  FormControl,
  FormLabel,
  makeStyles,
} from '@material-ui/core';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { Tooltip } from '../Tooltip/Tooltip.tsx';

export interface ISliderMark {
  value: number;
  label?: React.ReactNode;
  description?: string;
}

export interface ISliderProps {
  direction?: 'vertical' | 'horizontal';
  error?: string;
  errorStyle?: React.CSSProperties;
  helperText?: string;
  isEnabled?: boolean;
  label?: string;
  marks?: boolean | ISliderMark[];
  onSelectedIndexChanged?: (toIndex: number) => void;
  step?: number;
  style?: SerializedStyles;
  track?: 'normal' | false | 'inverted';
  value?: number | number[];
}

/**
 * See:
 *  https://material-ui.com/components/slider/
 *  https://material-ui.com/api/slider/
 */
export const Slider: React.FC<ISliderProps> = ({
  direction = 'horizontal',
  error,
  errorStyle,
  helperText,
  isEnabled = true,
  label,
  marks = false,
  onSelectedIndexChanged,
  step,
  style,
  track,
  value = 0,
}) => {
  const isHorizontal = direction === 'horizontal';

  const handleChange = useCallback(
    (_: any, toIndex: number) => onSelectedIndexChanged?.(toIndex),
    [onSelectedIndexChanged],
  );

  const min = (!is(Boolean, marks) && head(marks as ISliderMark[])?.value) || 0;

  const max =
    (!is(Boolean, marks) && last(marks as ISliderMark[])?.value) || 10;

  const materialMarks =
    (!is(Boolean, marks) &&
      (marks as ISliderMark[]).map((mark) => ({
        ...mark,
        label: mark.description ? (
          <Tooltip title={mark.description} arrow={true}>
            <div>{mark.label}</div>
          </Tooltip>
        ) : (
          mark.label
        ),
      }))) ||
    marks;

  const minHeight = isHorizontal
    ? undefined
    : is(Boolean, marks)
      ? 15 * max - 15 * min
      : (marks as ISliderMark[]).length * 50;

  const elHint = (error || helperText) && (
    <FormHelperText error={Boolean(error)} style={errorStyle}>
      {error || helperText}
    </FormHelperText>
  );

  const classStyles = useStyles(direction)();

  return (
    <div css={[styles.base, style]}>
      <FormControl fullWidth={true}>
        {label && <FormLabel>{label}</FormLabel>}
        {elHint}
        <MaterialSlider
          min={min}
          max={max}
          marks={materialMarks}
          orientation={direction}
          value={value}
          step={step}
          style={{
            width: '100%',
            minHeight,
            marginTop: isHorizontal ? undefined : 15,
            marginBottom: isHorizontal ? undefined : 15,
          }}
          classes={{
            markLabel: classStyles.markLabel,
            mark: classStyles.mark,
            markActive: classStyles.markActive,
          }}
          onChange={handleChange}
          disabled={!isEnabled}
          track={track}
        />
      </FormControl>
    </div>
  );
};

const styles = {
  base: css({ width: '100%' }),
};

const useStyles = (direction: 'horizontal' | 'vertical') => {
  const isHorizontal = direction === 'horizontal';
  return makeStyles({
    markLabel: {
      width: isHorizontal ? 100 : 'auto',
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
    },
    mark: {
      height: 4,
      width: 4,
      // Margins keep the dots aligned on the track.
      marginTop: -1,
      marginLeft: -1,
      borderRadius: '100%',
    },
    markActive: {
      marginTop: isHorizontal ? -2 : -1,
      marginLeft: isHorizontal ? -1 : -2,
      border: `1px solid ${COLORS.BLUE}`,
      opacity: 1,
    },
  });
};
