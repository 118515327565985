/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export interface IBulletPersonProps {
  isSpeech?: boolean;
  style?: SerializedStyles;
}

/**
 * A bullet that contains a person icon.
 */
export const BulletPerson = ({
  isSpeech = false,
  style,
}: IBulletPersonProps) => {
  const computedStyles = useMemo(
    () => ({
      base: css(
        {
          width: 29,
          height: 29,
          borderRadius: 29,
          backgroundColor: color.format(1),
          border: `solid 1px ${color.format(-0.2)}`,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: -11,
        },
        style,
      ),
      icon: css({
        width: 21,
        height: 21,
        marginTop: -2,
      }),
      speechTick: css({
        position: 'absolute',
        inset: 'auto -4px 0 auto',
      }),
    }),
    [style],
  );

  return (
    <div css={computedStyles.base}>
      <div css={computedStyles.icon}>
        <Icons.personOutline fill={color.format(-0.3)} size={21} />
        {isSpeech && <div css={computedStyles.speechTick} />}
      </div>
    </div>
  );
};
