import removeMarkdown from 'remove-markdown';
import { replaceWordChars } from './str.replaceWordChars.ts';

export interface IRemoveMarkdownAndSpecialCharactersOptions {
  removeTrailingQuestionMark?: boolean;
  shouldReplaceWordChars?: boolean;
  removeNewlineChars?: boolean;
  removeListLeaders?: boolean;
}

const STRING_REPLACEMENTS = {
  '&nbsp;': ' ',
  '#': ' ',
  '& x20;': ' ',
  '\\<': '<',
  '\\>': '>',
  '\\[': '[',
  '\\]': ']',
  '\\*': '*',
  '\\&': '&',
  '\\_': '_',
  '\\~': '~',
  '\\`': '`',
};

/**
 * Attempts to remove markdown syntax from the provided input string,
 * as well as removing special characters.
 */
export function removeMarkdownAndSpecialCharacters(
  input: string,
  options: IRemoveMarkdownAndSpecialCharactersOptions = {},
) {
  const {
    removeTrailingQuestionMark = false,
    shouldReplaceWordChars = true,
    removeListLeaders = true,
    removeNewlineChars = true,
  } = options;

  let result = removeMarkdown(input, {
    useImgAltText: true,
    stripListLeaders: removeListLeaders,
  });

  Object.entries(STRING_REPLACEMENTS).forEach(([from, to]) => {
    result = result.replaceAll(from, to);
  });

  if (removeNewlineChars) {
    result = result
      .replaceAll('\r', ' ')
      .replaceAll('\n', ' ')
      .replaceAll('\t', ' ');
  }

  if (shouldReplaceWordChars) {
    result = replaceWordChars(result);
  }

  if (removeTrailingQuestionMark && result.endsWith('?')) {
    result = result.slice(0, -1);
  }

  return result.trim();
}
