/** @jsxImportSource @emotion/react */
import { makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles.js';
// eslint-disable-next-line import/extensions
import MaterialAccordionSummary from '@mui/material/AccordionSummary';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { ReactNode } from 'react';
import { CommonStyles } from '../../common/commonStyles.ts';
import { Icons } from '../Icon/Icons.tsx';

export interface IAccordionSummaryProps {
  // Material UI Props.
  expandIcon?: React.ReactElement | null; // nulling the icon will make the accordion have no expand icon.
  disableRipple?: boolean;
  role?: string;

  style?: CSSProperties;
  expandedStyle?: CSSProperties;
  contentStyle?: CSSProperties;
  children?: ReactNode;
}

/**
 * Items rendered as the title in an `Accordion`, when expanded these will show any `AccordionDetails`.
 *
 * @see https://material-ui.com/components/accordion/
 * @see https://material-ui.com/api/accordion-summary/
 */
export const AccordionSummary = ({
  expandIcon = <Icons.arrowDropDown />,
  disableRipple = false,
  role = 'button',
  children,
  style,
  expandedStyle,
  contentStyle,
}: IAccordionSummaryProps) => {
  const styles = useStyles({ style, expandedStyle, contentStyle })();

  return (
    <MaterialAccordionSummary
      classes={{
        root: styles.root,
        expanded: styles.expanded,
        content: styles.content,
        expandIconWrapper: styles.expandIcon,
      }}
      style={style}
      expandIcon={expandIcon}
      disableRipple={disableRipple}
      role={role}
    >
      {children}
    </MaterialAccordionSummary>
  );
};

const useStyles = ({
  style,
  expandedStyle,
  contentStyle,
}: IAccordionSummaryProps) =>
  makeStyles({
    root: {
      ...CommonStyles.MaterialCubicTransitions,
      color: color.format(-0.4), // Ripple color.
      '&$expanded': {
        background: COLORS.BLUE,
        color: 'white', // Ripple color.
        '&:hover': {
          background: color.create(COLORS.BLUE).brighten(0.2).css(),
        },
        ...expandedStyle,
      },
      '&$expanded > $content': {
        color: 'white',
      },
      '&$expanded > $expandIcon div svg': {
        fill: 'white !important',
      },
      '&:hover': {
        background: color.format(-0.08),
      },
      width: '100%',
      padding: '0 16px',
      ...style,
    },
    content: {
      color: color.format(-0.8),
      ...CommonStyles.MaterialCubicTransitions,
      ...contentStyle,
    },
    expandIcon: {},
    expanded: {},
  });
