import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  InstanceSchedule,
  InstanceScheduleDraft,
  InstanceScheduleDueDateExpression,
  InstanceScheduleFrequency,
  InstanceScheduleStatus,
} from '@se/data/forms/query/instanceScheduleTypes.ts';
import { FormTemplate } from '@se/data/forms/types.ts';
import { DistributionList } from '@se/data/orgHierarchy/query/distributionList.ts';

type FieldErrors = {
  name?: string;
  template?: string;
  frequency?: string;
  dueDateExpression?: string;
  startDate?: string;
  endDate?: string;
  distributionList?: string;
};

export type FormsReduxEditInstanceScheduleState = {
  draft?: InstanceScheduleDraft;
  errors: {
    fieldErrors: FieldErrors;
    globalError?: string;
  };
  hasDraftChanged: boolean;
  isSaving: boolean;
  original?: InstanceSchedule;
  saved: boolean;
};

const DEFAULT_STATE: FormsReduxEditInstanceScheduleState = {
  draft: undefined,
  errors: { fieldErrors: {} },
  hasDraftChanged: false,
  isSaving: false,
  original: undefined,
  saved: false,
};

const slice = createSlice({
  name: `libs/forms/editInstanceSchedule`,
  initialState: DEFAULT_STATE,
  reducers: {
    createNewDraft(state) {
      state.draft = {
        status: 'NotStarted',
        assignedToSelector: 'Manager',
      };
    },
    editSchedule(state, action: PayloadAction<InstanceSchedule>) {
      state.draft = action.payload;
      state.errors = { fieldErrors: {} };
      state.hasDraftChanged = false;
      state.isSaving = false;
      state.original = action.payload;
      state.saved = false;
    },
    dismiss() {
      return DEFAULT_STATE;
    },
    updateName(state, action: PayloadAction<string>) {
      state.draft.name = action.payload;
      state.hasDraftChanged = true;
      state.errors.fieldErrors.name = undefined;
    },
    updateFormTemplate(
      state,
      action: PayloadAction<{
        template: FormTemplate;
        subjectType: 'Person' | 'Team';
      }>,
    ) {
      state.draft.template = action.payload.template;
      state.draft.subjectType = action.payload.subjectType;
      state.hasDraftChanged = true;
      state.errors.fieldErrors.template = undefined;
    },
    clearDistributionList(state) {
      state.draft.distributionList = undefined;
      state.hasDraftChanged = true;
    },
    updateDistributionList(state, action: PayloadAction<DistributionList>) {
      state.draft.distributionList = action.payload;
      state.hasDraftChanged = true;
      state.errors.fieldErrors.distributionList = undefined;
    },
    updateFacilitator(state, action: PayloadAction<'Manager' | 'LineManager'>) {
      state.draft.assignedToSelector = action.payload;
      state.hasDraftChanged = true;
    },
    updateStatus(state, action: PayloadAction<InstanceScheduleStatus>) {
      state.draft.status = action.payload;
      state.hasDraftChanged = true;
    },
    updateFrequency(state, action: PayloadAction<InstanceScheduleFrequency>) {
      state.draft.frequency = action.payload;
      state.draft.reportingDateExpression = undefined;
      state.hasDraftChanged = true;
      state.errors.fieldErrors.frequency = undefined;
    },
    updateApplyToExistingMembers(state, action: PayloadAction<boolean>) {
      state.draft.frequency = action.payload
        ? 'ContinuousIncludingExisting'
        : 'ContinuousExcludingExisting';
      state.hasDraftChanged = true;
    },
    updateStartDate(state, action: PayloadAction<string>) {
      state.draft.startDate = action.payload;
      state.hasDraftChanged = true;
      state.errors.fieldErrors.startDate = undefined;

      if (!state.draft.endDate) return;
      if (action.payload > state.draft.endDate) state.draft.endDate = undefined;
    },
    updateEndDate(state, action: PayloadAction<string>) {
      state.draft.endDate = action.payload;
      state.hasDraftChanged = true;
      state.errors.fieldErrors.endDate = undefined;
    },
    updateDueDateExpression(
      state,
      action: PayloadAction<InstanceScheduleDueDateExpression>,
    ) {
      state.draft.dueDateExpression = action.payload;
      state.hasDraftChanged = true;
      state.errors.fieldErrors.dueDateExpression = undefined;
    },
    updateReportingDateExpression(state, action: PayloadAction<string>) {
      state.draft.reportingDateExpression = action.payload;
      state.hasDraftChanged = true;
    },
    save(state) {
      state.isSaving = true;
      state.errors = { fieldErrors: {} };
      state.saved = false;
    },
    saved(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{ reason: 'Created' | 'Updated' | 'Deactivated' }>,
    ) {
      state.isSaving = false;
      state.hasDraftChanged = false;
      state.errors = { fieldErrors: {} };
      state.saved = true;
      state.draft = undefined;
      state.original = undefined;
    },
    setFieldErrors(state, action: PayloadAction<FieldErrors>) {
      state.errors.fieldErrors = action.payload;
      state.isSaving = false;
    },
    setGlobalError(state, action: PayloadAction<{ to: string }>) {
      state.isSaving = false;
      state.errors.globalError = action.payload.to;
      state.saved = false;
    },
    alertConfirmDismissChanges(state) {
      return state;
    },
  },
});

export const {
  alertConfirmDismissChanges,
  clearDistributionList,
  createNewDraft,
  dismiss,
  editSchedule,
  save,
  saved,
  setFieldErrors,
  setGlobalError,
  updateApplyToExistingMembers,
  updateDistributionList,
  updateDueDateExpression,
  updateEndDate,
  updateFacilitator,
  updateFormTemplate,
  updateFrequency,
  updateName,
  updateReportingDateExpression,
  updateStartDate,
  updateStatus,
} = slice.actions;
export const reducer = slice.reducer;
export type State = FormsReduxEditInstanceScheduleState;
