import { StateObservable, ofType } from 'redux-observable';
import { Observable, filter, map } from 'rxjs';
import {
  ReduxFormInstanceAnswerChange,
  ReduxFormInstancePermissionsLoaded,
} from '../form-instance/types.ts';
import { GlobalFormsState } from '../store.ts';
import { scoreChanged } from './actions.ts';
import { toFormScore } from './util.calculateScore.ts';

export function calculateScoreChangeEpic(
  action$: Observable<
    ReduxFormInstanceAnswerChange | ReduxFormInstancePermissionsLoaded
  >,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    ofType(
      'ui.forms/instance/PERMISSIONS_LOADED',
      'ui.forms/instance/ANSWER/CHANGE',
    ),
    filter((action) => {
      if (action.type === 'ui.forms/instance/PERMISSIONS_LOADED') return true;
      return Boolean(state$.value.formScore.score?.isScorable);
    }),
    map((action) => {
      const instanceId = action.payload.instanceId;
      const instance = state$.value.formInstance.instances[instanceId];

      const score = toFormScore(
        instance.template.definitionJson,
        instance.answers,
        instance.permissions,
      );

      return scoreChanged(instanceId, score);
    }),
  );
}
