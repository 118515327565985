import { IIssueCheckInLine } from '../types/types.ts';

export function parseIssueCheckIn(): IIssueCheckInLine {
  return {
    id: 'issueCheckIn',
    type: 'issueCheckIn',
    showIndex: true,
    highlightOnHover: false,
  };
}
