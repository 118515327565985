/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { useFormsSelector } from '../redux/store.ts';
import { checkShowOnSwitch } from './util.ts';

export type UseShowOnSwitchOptions = {
  showOnSwitch?: string;
  instanceId: string;
};

export const useShowOnSwitch = ({
  showOnSwitch,
  instanceId,
}: UseShowOnSwitchOptions) => {
  const answers = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.answers,
  );

  return useMemo(
    () => checkShowOnSwitch(answers, showOnSwitch),
    [answers, showOnSwitch],
  );
};
