/** @jsxImportSource @emotion/react */
import DateFnsUtils from '@date-io/date-fns';
import { SerializedStyles, css } from '@emotion/react';
import { InputProps, PopoverProps } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import 'date-fns';
import momentTz from 'moment-timezone';
import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDateContext } from '../../hooks/useDateContext.ts';
import { Tooltip } from '../Tooltip/Tooltip.tsx';
import { toDisplayValue } from './util.ts';

const KEYBOARD_PROPS = { 'aria-label': 'change date' };

export interface IDatePickerProps {
  dataTest?: string;
  dateFormat?: 'MM/dd/yyyy' | 'dd/MM/yyyy';
  error?: string;
  helperText?: string;
  id: string;
  isEnabled?: boolean;
  label?: string;
  maxDate?: string;
  minDate?: string;
  onChange?: (toIso: string) => void;
  style?: SerializedStyles;
  value?: string;
}

/**
 * See:
 *  https://material-ui.com/components/pickers/
 */
export const DatePicker: React.FC<IDatePickerProps> = ({
  dataTest,
  dateFormat: propDateFormat,
  error,
  helperText,
  id,
  isEnabled = true,
  label = 'Choose a date',
  maxDate,
  minDate,
  onChange,
  style,
  value: propsValue,
}) => {
  const dateContext = useDateContext();
  const dateFormat = propDateFormat ?? dateContext.format;

  const [value, setValue] = useState(
    propsValue ? toDisplayValue(propsValue, dateContext) : null,
  );

  useEffect(() => {
    if (propsValue) {
      setValue(toDisplayValue(propsValue, dateContext));
    } else {
      setValue(null);
    }
  }, [dateContext, propsValue]);

  const resetValueAndInput = useCallback((newValue: string) => {
    setValue(null);
    setTimeout(() => {
      setValue(newValue);
    }, 0);
  }, []);

  const [statefulError, setError] = useState('');

  const [isOpen, setIsOpen] = useState(false);
  const handleFocus = useCallback(() => setIsOpen(true), []);
  const handleBlur = useCallback(() => {
    setIsOpen(false);

    const repopulateValueIfCleared =
      inputRef.current?.value === '' && value !== null;
    if (repopulateValueIfCleared) resetValueAndInput(value);
  }, [resetValueAndInput, value]);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const mouseEnter = useCallback(() => {
    if (!isOpen && value) setTooltipOpen(true);
  }, [isOpen, value]);
  const mouseLeave = useCallback(() => setTooltipOpen(false), []);

  const [isTabbingAway, setIsTabbingAway] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  const handlePopperFocus = useCallback(() => {
    inputRef.current?.focus();
    inputRef.current?.select();
  }, []);

  const handlePopperBlur = useCallback(() => {
    inputRef.current?.blur();
  }, []);

  const handleDateChange = useCallback(
    (date: Date) => {
      if (isValid(date, minDate, maxDate)) {
        const newValue = momentTz(date)
          .tz(dateContext?.tenantTimezone || 'Etc/UTC', true)
          .hour(2)
          .minute(0)
          .second(0)
          .toISOString();

        onChange?.(newValue);
        resetValueAndInput(newValue);
      }

      setError(getInvalidReason(date, minDate, maxDate));
    },
    [
      dateContext?.tenantTimezone,
      maxDate,
      minDate,
      onChange,
      resetValueAndInput,
    ],
  );

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    switch (e.key) {
      case 'Enter':
        e.stopPropagation();
        setIsOpen(false);
        return;

      case 'Tab':
        setIsOpen(false);
        setIsTabbingAway(true);
        return;

      default:
        return;
    }
  }, []);

  useEffect(() => {
    if (!isOpen) {
      inputRef.current?.blur();
    }
  }, [isOpen]);

  const popoverProps: Partial<PopoverProps> = {
    anchorEl: divRef.current,
    anchorOrigin: { horizontal: 'left', vertical: statefulError ? 70 : 50 },
    transformOrigin: { horizontal: 'left', vertical: 'top' },
    disableEnforceFocus: true,
    onFocus: handlePopperFocus,
    onBlur: handlePopperBlur,
  };

  const inputProps: Partial<InputProps> = useMemo(
    () => ({
      onFocus: !isOpen && !isTabbingAway ? handleFocus : undefined,
      onBlur: !isOpen ? handleBlur : undefined,
      inputRef,
    }),
    [handleBlur, handleFocus, isOpen, isTabbingAway],
  );

  useEffect(() => {
    // Ensures a keyboard user can tab away from the field without it regaining focus.
    setTimeout(() => {
      if (isTabbingAway) setIsTabbingAway(false);
    }, 0);
  }, [isTabbingAway]);

  const elPicker = (
    <div data-test={dataTest}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          allowKeyboardControl={false}
          autoOk={true}
          autoComplete={'off'}
          disabled={!isEnabled}
          disableToolbar={false}
          error={Boolean(statefulError || error)}
          format={dateFormat}
          fullWidth={true}
          helperText={statefulError || error || helperText}
          id={id}
          initialFocusedDate={value}
          InputProps={inputProps}
          KeyboardButtonProps={KEYBOARD_PROPS}
          label={label}
          maxDate={maxDate}
          minDate={minDate}
          onChange={handleDateChange}
          onClose={handleBlur}
          onKeyDown={handleKeyDown}
          onOpen={handleFocus}
          open={isOpen}
          placeholder={dateFormat.toLowerCase()}
          PopoverProps={popoverProps}
          value={value}
          variant={'inline'}
          onMouseEnter={mouseEnter}
          onMouseLeave={mouseLeave}
        />
      </MuiPickersUtilsProvider>
    </div>
  );

  return (
    <div css={[styles.base, style]} ref={divRef}>
      <Tooltip
        open={tooltipOpen}
        title={
          value !== null
            ? toDisplayValue(propsValue, dateContext, 'dddd D MMMM, YYYY')
            : undefined
        }
      >
        {elPicker}
      </Tooltip>
    </div>
  );
};

const isBeforeMinDate = (date?: Date, minDate?: string) =>
  date && minDate && momentTz(date) < momentTz(minDate).startOf('day');

const isAfterMaxDate = (date?: Date, maxDate?: string) =>
  date && maxDate && momentTz(date) > momentTz(maxDate).endOf('day');

const isValid = (date?: Date, minDate?: string, maxDate?: string) =>
  date &&
  momentTz(date).isValid() &&
  !isBeforeMinDate(date, minDate) &&
  !isAfterMaxDate(date, maxDate);

const getInvalidReason = (date?: Date, minDate?: string, maxDate?: string) => {
  if (isBeforeMinDate(date, minDate)) {
    return 'Date is before the minimum allowed.';
  }

  if (isAfterMaxDate(date, maxDate)) {
    return 'Date is after the maximum allowed.';
  }

  if (date && !momentTz(date).isValid()) {
    return 'Not a valid date format.';
  }

  return undefined;
};

const styles = {
  base: css({
    width: '100%',
    position: 'relative',
    display: 'inline-block',
  }),
};
