import { IFormInstance, Signature } from '../form-instance/types.ts';

export enum AppealOutcome {
  Accepted = 'Accepted',
  PartiallyAccepted = 'Partially Accepted',
  Declined = 'Declined',
  Withdrawn = 'Withdrawn',
}

export type AppealStateErrors = {
  errorCount: number;
  outcomeError?: boolean;
  outcomeReasonError?: boolean;
  reasonError?: boolean;
  requestError?: boolean;
  responseError?: boolean;
};

export type AppealInputChangeEvent = {
  field: keyof FormInstanceAppeal;
  to: string;
};

export type AppealInputChangeEventHandler = (
  event: AppealInputChangeEvent,
) => void;

export type AppealInputValidationEvent = {
  errors: AppealStateErrors;
};

export type AppealInputValidationEventHandler = (
  event: AppealInputValidationEvent,
) => void;

export type ConfirmAppealPayload = {
  instance: IFormInstance;
  personId: string;
  signature: Signature;
};

export type FormInstanceAppeal = {
  outcome?: AppealOutcome;
  outcomeReason?: string;
  reason?: string;
  request?: string;
  response?: string;
};
