import { IssueSummaryDefinition } from './types/issueSummary.types.ts';
import { IIssueSummaryLine } from '../types/types.ts';

export function parseIssueSummary(
  props?: IssueSummaryDefinition,
): IIssueSummaryLine {
  return {
    id: 'issueSummary',
    type: 'issueSummary',
    showIndex: true,
    title: props?.title ?? 'Issues Identified',
    highlightOnHover: false,
  };
}
