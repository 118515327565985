/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { IFormSelectionListItem } from '../../types/types.ts';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';

export interface ISelectAnswerProps {
  error?: string;
  floatingText?: string;
  helperText?: string;
  id: string;
  isEnabled?: boolean;
  minWidth?: string | number;
  onChange: (to: IFormSelectionListItem) => void;
  renderText?: boolean;
  selections: ISelectionListItem[];
  style?: SerializedStyles;
  value?: string;
}

/**
 * A dropdown for selecting a form answer from a provided list.
 */
export const SelectAnswer: React.FC<ISelectAnswerProps> = ({
  error,
  floatingText,
  helperText,
  id,
  isEnabled,
  onChange,
  renderText,
  selections = [],
  value,
}) => {
  const computedStyles = {
    base: css({
      flex: '1 1 275px',
      padding: '0 15px 10px 0',
    }),
  };

  const selectedValue = useMemo(() => {
    if (!value) return;

    return (
      selections.find((selection) => selection.value === value) ?? {
        id: value,
        value,
        content: value,
      }
    );
  }, [selections, value]);

  return (
    <div css={computedStyles.base}>
      {renderText ? (
        <div css={styles.textContainer}>
          <Text style={styles.textLabel}>{floatingText}</Text>
          <Text>{selectedValue?.value || '-'}</Text>
        </div>
      ) : (
        <TextFieldDropdown
          error={error}
          helperText={helperText}
          id={id}
          InputProps={{ type: 'text', autoComplete: 'off' }}
          isEnabled={isEnabled}
          label={floatingText}
          onChange={onChange}
          selections={selections}
          value={selectedValue}
        />
      )}
    </div>
  );
};

const styles = {
  textContainer: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  textLabel: css({
    fontSize: 12,
    color: '#808080',
    paddingBottom: 5,
  }),
};
