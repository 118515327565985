/**
 * Converts a given string to number string
 * @param input string to number
 */
export function toNumber(input: string): string {
  if (input === undefined) return;
  if (input === '') return '';

  return input.match(/\d+/g)?.join('');
}
