import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormTemplate } from '@se/data/forms/types.ts';
import { AnalyticsEvent } from '@seeeverything/ui.util/src/redux/analytics/types.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';

export type FormBulkUploadDownloadTemplateState = {
  isLoading: boolean;
  showHiddenFields: boolean;
  exportableQuestions: ExportableQuestion[];
  selectedTemplate?: FormTemplate;
  error: boolean;
};

export type ExportableQuestion = {
  id: string;
  label?: string;
  isSelected?: boolean | 'indeterminate';
  isEnabled?: boolean;
  isHeading?: boolean;
  parentHeadingId?: string;
  isHiddenByDefault?: boolean;
  answerOptions?: string[];
  isDownloadOnly?: boolean;
};

const DEFAULT_STATE: FormBulkUploadDownloadTemplateState = {
  showHiddenFields: false,
  isLoading: false,
  error: false,
  exportableQuestions: [],
};

const slice = createSlice({
  name: 'libs/forms/formBulkUploadDownloadTemplate',
  initialState: DEFAULT_STATE,
  reducers: {
    clearTemplate: () => DEFAULT_STATE,
    selectTemplate(state, action: PayloadAction<{ template: FormTemplate }>) {
      state.selectedTemplate = action.payload.template;
      state.exportableQuestions = [];
      state.showHiddenFields = DEFAULT_STATE.showHiddenFields;
    },
    toggleShowHiddenFields(state) {
      state.showHiddenFields = !state.showHiddenFields;
    },
    templateQuestionsLoad(state) {
      state.error = false;
      state.isLoading = true;
    },
    templateQuestionsLoaded(
      state,
      action: PayloadAction<{
        exportableQuestions: ExportableQuestion[];
      }>,
    ) {
      state.error = false;
      state.isLoading = false;
      state.exportableQuestions = action.payload.exportableQuestions;
    },
    templateQuestionsLoadError(state) {
      state.error = true;
      state.isLoading = false;
      state.exportableQuestions = [];
    },
    selectQuestion(state, action: PayloadAction<{ id: string; to: boolean }>) {
      const { id, to } = action.payload;
      const exportableQuestions = state.exportableQuestions;
      if (!exportableQuestions.length) return;

      const selectedQuestion = exportableQuestions.find(
        (question) => question.id === id && question.isEnabled !== false,
      );

      if (!selectedQuestion) return;

      selectedQuestion.isSelected = to;

      const parentQuestion = exportableQuestions.find(
        (question) => question.id === selectedQuestion.parentHeadingId,
      );

      if (!parentQuestion) return;

      parentQuestion.isSelected = parentSelectionState(
        parentQuestion.id,
        exportableQuestions,
      );
    },
    selectSectionHeading(
      state,
      action: PayloadAction<{ id: string; to: boolean }>,
    ) {
      const { id, to } = action.payload;
      const exportableQuestions = state.exportableQuestions;
      if (!exportableQuestions.length) return;

      exportableQuestions
        .filter(
          (question) =>
            question.id === id ||
            (question.parentHeadingId === id && question.isEnabled !== false),
        )
        .forEach((question) => (question.isSelected = to));
    },
    cancelExport: () => DEFAULT_STATE,
    exportTemplate(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        selectedTemplate: FormTemplate;
        selectedQuestions: ExportableQuestion[];
      }>,
    ) {
      return state;
    },
  },
});

export const analytics = (
  action: ReduxAction,
  state: FormBulkUploadDownloadTemplateState,
  trackEvent: (eventIdentifier: string, payload: object) => AnalyticsEvent,
) => {
  if (slice.actions.exportTemplate.match(action))
    return [
      trackEvent('form_uploader_download_template', {
        fieldsSelected: action.payload.selectedQuestions.filter(
          (field) => !field.isHeading,
        ).length,
        fieldsAvailable: state.exportableQuestions.filter(
          (field) => !field.isHeading,
        ).length,
        formCategory: action.payload.selectedTemplate.category,
        formType: action.payload.selectedTemplate.name,
      }),
    ];
};

const parentSelectionState = (
  parentId: string,
  questions: ExportableQuestion[],
) => {
  const children = questions.filter(
    (question) =>
      question.parentHeadingId === parentId &&
      !['assignedTo', 'subject'].includes(question.id),
  );

  if (children.every(({ isSelected }) => isSelected)) return true;
  if (children.every(({ isSelected }) => !isSelected)) return false;
  return 'indeterminate';
};

export const {
  cancelExport,
  clearTemplate,
  selectTemplate,
  templateQuestionsLoad,
  templateQuestionsLoaded,
  templateQuestionsLoadError,
  toggleShowHiddenFields,
  selectQuestion,
  selectSectionHeading,
  exportTemplate,
} = slice.actions;
export const reducer = slice.reducer;
export type State = FormBulkUploadDownloadTemplateState;
