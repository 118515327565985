import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import { QueryResult } from '../../types.ts';
import { FormTemplate } from '../types.ts';
import { getFormTemplateCategories } from './formTemplateCategories.ts';
import { getFormTemplates } from './formTemplates.ts';

export type GetFormTemplatesByCategoryResponseDto = {
  formTemplates: FormTemplate[];
};

type GetFormTemplatesByCategoryArgs = {
  createInstanceOnly?: boolean;
  hasIssuesOnly?: boolean;
  includeDefinition?: boolean;
  showAll?: boolean;
  module: ModuleType;
};

export const getFormTemplatesByCategory = async (
  client: IGraphQLClient,
  args: GetFormTemplatesByCategoryArgs,
): Promise<QueryResult<FormTemplate[]>> => {
  const allFormTemplates = await getFormTemplates(client, {
    createInstanceOnly: args.createInstanceOnly,
    hasIssuesOnly: args.hasIssuesOnly,
    fetchAllPages: true,
    includeDefinition: args.includeDefinition,
    module: args.module,
    orderBy: [
      { fieldName: 'rank', direction: 'Ascending' },
      { fieldName: 'name', direction: 'Ascending' },
    ],
    showAll: args.showAll,
  });

  if (allFormTemplates.isSuccess !== true) return allFormTemplates;

  const allTemplateCategories = await getFormTemplateCategories(client, {
    fetchAllPages: true,
    module: args.module,
  });

  if (allTemplateCategories.isSuccess !== true) return allTemplateCategories;

  const categories = allTemplateCategories.data.formTemplateCategories;
  const templates = allFormTemplates.data.formTemplates;

  return {
    isSuccess: true,
    data: categories.reduce<FormTemplate[]>(
      (acc, category) =>
        acc.concat(
          templates.filter((template) => template.category.id === category.id),
        ),
      [],
    ),
  };
};
