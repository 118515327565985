import * as R from 'ramda';
import { file as fileUtil } from '@seeeverything/ui.util/src/file/index.ts';
import {
  IFormDigitalContentState,
  IFormDigitalContentAction,
} from './types.ts';
import { IFormFile } from '../form-file/types.ts';
import { IDigitalContentComment } from '../../components/DigitalContentComments/types.ts';
import { STREAMABLE_MIME_TYPES } from '../../common/constants.ts';

export const DEFAULT_STATE: IFormDigitalContentState = {
  isLoading: true,
  files: [],
};

export function reducer(
  state: IFormDigitalContentState = DEFAULT_STATE,
  action: IFormDigitalContentAction,
): IFormDigitalContentState {
  switch (action.type) {
    case 'ui.forms/digital-content/LOAD': {
      return {
        ...state,
        isLoading: true,
        contentMeta: undefined,
        comments: undefined,
      };
    }

    case 'ui.forms/digital-content/LOADED': {
      return {
        ...state,
        comments: {
          isLoading: true,
        },
        isLoading: false,
        contentMeta: action.payload.content,
        files: action.payload.content.files
          .map((file) => ({
            id: file.id,
            name: file.name,
            extension: fileUtil.getExtension(file.name),
            size: file.size,
            displaySize: fileUtil.getReadableSize(file.size),
            uploadedByName: '',
            canDownload: Boolean(file.downloadLink), // downloadLink is nullable.
            canView: canViewFile(file.type),
          }))
          .sort(
            (file1, file2) =>
              Number(file1.canView) > Number(file2.canView) ? -1 : 1, // Sorts viewable files before unviewable.
          ),
      };
    }

    case 'ui.forms/digital-content/LOAD_ERROR': {
      return {
        ...state,
        isLoading: false,
        loadError: action.payload.message,
      };
    }

    case 'ui.forms/digital-content/file/DOWNLOAD_FILE': {
      return {
        ...state,
        files: updateFile(state.files, action.payload.fileId, {
          action: 'downloading',
          error: undefined,
        }),
      };
    }

    case 'ui.forms/digital-content/file/DOWNLOAD_COMPLETE': {
      return {
        ...state,
        files: updateFile(state.files, action.payload.fileId, {
          action: undefined,
        }),
      };
    }

    case 'ui.forms/digital-content/stream/STREAM_FILE': {
      return {
        ...state,
        stream: {
          link: state.contentMeta?.files.find(
            (file) => file.id === action.payload.fileId,
          )?.downloadLink,
          fileId: action.payload.fileId,
        },
      };
    }

    case 'ui.forms/digital-content/stream/DISMISS_PLAYER': {
      return {
        ...state,
        stream: undefined,
      };
    }

    case 'ui.forms/digital-content/file/DOWNLOAD_ERROR': {
      return {
        ...state,
        files: updateFile(state.files, action.payload.fileId, {
          action: undefined,
          error: action.payload.message,
          canRetry: true,
        }),
      };
    }

    case 'ui.forms/digital-content/stream/PLAYER_ERROR': {
      return {
        ...state,
        stream: {
          ...state.stream,
          error: action.payload.error,
        },
      };
    }

    case 'ui.forms/digital-content/stream/REFRESH_PLAYER': {
      return {
        ...state,
        stream: {
          ...state.stream,
          error: undefined,
        },
      };
    }

    case 'ui.forms/digital-content/comments/LOAD': {
      return {
        ...state,
        comments: {
          ...state.comments,
          isLoading: action.payload.reason === 'LOAD',
          errors: undefined,
          pagination: {
            ...state.comments?.pagination,
            isLoadingMore: action.payload.reason === 'LOAD_MORE',
            hasNextPage: false,
          },
        },
      };
    }

    case 'ui.forms/digital-content/comments/LOADED': {
      return {
        ...state,
        comments: {
          ...state.comments,
          isLoading: false,
          pagination: {
            ...state.comments?.pagination,
            isLoadingMore: false,
            hasNextPage: action.payload.hasNextPage,
            currentPage: action.payload.pageNumber,
          },
          comments: action.payload.comments,
        },
      };
    }

    case 'ui.forms/digital-content/comments/LOAD_ERROR': {
      return {
        ...state,
        comments: {
          ...state.comments,
          isLoading: false,
          pagination: {
            ...state.comments?.pagination,
            isLoadingMore: false,
          },
          errors: {
            load:
              action.payload.reason === 'LOAD'
                ? action.payload.error
                : undefined,
            loadMore:
              action.payload.reason === 'LOAD_MORE'
                ? action.payload.error
                : undefined,
          },
        },
      };
    }

    case 'ui.forms/digital-content/comments/RETRY_ADD_COMMENT': {
      return {
        ...state,
        comments: {
          ...state.comments,
          comments: updateComment(
            state.comments?.comments,
            action.payload.commentId,
            { isSaving: true },
          ),
        },
      };
    }

    case 'ui.forms/digital-content/comments/ADD_COMMENT_READY': {
      const { body, commentId, person, team, parentId } = action.payload;
      const newComment: IDigitalContentComment = {
        id: commentId,
        body,
        commentedAt: new Date().toISOString(),
        commentedBy: person,
        team: team?.name,
        parentId,
        isSaving: true,
        canReply: true,
        canDelete: true,
      };

      const existingComments = state.comments?.comments || [];

      const newComments = (() => {
        const isReply = Boolean(parentId);
        if (!isReply) {
          return [newComment, ...existingComments];
        }

        const commentIndex =
          existingComments.findIndex((comment) => comment.id === parentId) ?? 0;

        const existingComment = existingComments[commentIndex];
        const existingReplies = existingComment.replies || [];

        const newReplies = [...existingReplies, newComment];
        return R.update(
          commentIndex,
          {
            ...existingComment,
            replies: newReplies,
          },
          existingComments,
        );
      })();

      return {
        ...state,
        comments: {
          ...state.comments,
          comments: newComments,
        },
      };
    }

    case 'ui.forms/digital-content/comments/ADD_COMMENT_ERROR': {
      return {
        ...state,
        comments: {
          ...state.comments,
          comments: updateComment(
            state.comments?.comments,
            action.payload.commentId,
            { error: action.payload.error, isSaving: false },
          ),
        },
      };
    }

    case 'ui.forms/digital-content/comments/ADD_COMMENT_SUCCESS':
      return {
        ...state,
        comments: {
          ...state.comments,
          comments: updateComment(
            state.comments?.comments,
            action.payload.commentId,
            { error: undefined, isSaving: false },
          ),
        },
      };

    case 'ui.forms/digital-content/comments/DELETE_COMMENT': {
      return {
        ...state,
        comments: {
          ...state.comments,
          comments: deleteComment(
            state.comments?.comments,
            action.payload.commentId,
          ),
        },
      };
    }

    default:
      return state;
  }
}

const deleteComment = (
  comments: IDigitalContentComment[] = [],
  commentId: string,
): IDigitalContentComment[] => {
  const commentIndex = comments.findIndex(
    (comment) => comment.id === commentId,
  );
  const isReply = commentIndex === -1;
  if (isReply) {
    return updateComment(comments, commentId, {
      canDelete: false,
      canReply: false,
      isDeleted: true,
      body: 'This comment has been deleted.',
    });
  }

  return [
    ...comments.slice(0, commentIndex),
    ...comments.slice(commentIndex + 1),
  ];
};

const updateComment = (
  comments: IDigitalContentComment[] = [],
  commentId: string,
  update: Partial<IDigitalContentComment>,
): IDigitalContentComment[] => {
  const index = comments.findIndex(
    (comment) =>
      comment.id === commentId ||
      comment.replies?.some((reply) => reply.id === commentId),
  );
  const comment = comments[index];
  if (comment.id === commentId) {
    return R.update(index, { ...comment, ...update }, comments);
  }

  const replyIndex = comment.replies?.findIndex(
    (reply) => reply.id === commentId,
  );

  if (replyIndex === undefined || replyIndex === -1) {
    return comments;
  }

  return R.update(
    index,
    {
      ...comment,
      replies:
        comment.replies &&
        R.update(
          replyIndex,
          { ...comment.replies?.[replyIndex], ...update },
          comment.replies,
        ),
    },
    comments,
  );
};

const updateFile = (
  files: IFormFile[],
  fileId: string,
  update: Partial<IFormFile>,
): IFormFile[] => {
  const index = files.findIndex((file) => file.id === fileId);
  return R.update(index, { ...files[index], ...update }, files);
};

const canViewFile = (mimeType: string) =>
  STREAMABLE_MIME_TYPES.includes(mimeType);
