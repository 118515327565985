import { isAnyOf } from '@reduxjs/toolkit';
import { formsQuery } from '@se/data/forms/query/index.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { combineEpics, StateObservable } from 'redux-observable';
import { concatAll, filter, map, mergeMap, Observable, of } from 'rxjs';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import * as instanceScheduleSlice from './instanceScheduleSlice.ts';

export const instanceScheduleEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(loadInstanceSchedulesEpic, loadInstanceSchedulesOnFiltersChangedEpic);

/**
 * Epic to load schedule items.
 */
function loadInstanceSchedulesEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(instanceScheduleSlice.loadInstanceSchedules.match),
    mergeMap(async (action) => {
      const { loadNextPage } = action.payload;
      const { showAll, orderBy } = state$.value.instanceSchedule;
      const pageNumber = loadNextPage
        ? state$.value.instanceSchedule.currentPage + 1
        : 1;

      try {
        const scheduleResponse = await formsQuery.getInstanceSchedules(client, {
          orderBy,
          pageNumber,
          showAll,
        });

        if (scheduleResponse.isSuccess !== true)
          return of(instanceScheduleSlice.loadInstanceSchedulesFailed());

        return of(
          instanceScheduleSlice.loadedInstanceSchedules({
            currentPage: scheduleResponse.data.pageInfo.currentPage,
            hasNextPage: scheduleResponse.data.pageInfo.hasNextPage,
            schedules: scheduleResponse.data.instanceSchedules,
          }),
        );
      } catch (err) {
        log.error(new Error(`Failed to get instance time schedules: ${err}`));
        return of(instanceScheduleSlice.loadInstanceSchedulesFailed());
      }
    }),
    concatAll(),
  );
}

function loadInstanceSchedulesOnFiltersChangedEpic(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    filter(
      isAnyOf(
        instanceScheduleSlice.sortByCreated.match,
        instanceScheduleSlice.sortByName.match,
        instanceScheduleSlice.toggleFilterShowInactive.match,
      ),
    ),
    map(() =>
      instanceScheduleSlice.loadInstanceSchedules({ loadNextPage: false }),
    ),
  );
}
