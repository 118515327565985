/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import {
  Switch as MaterialSwitch,
  SwitchProps,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
} from '@material-ui/core';
import { Tooltip } from '../Tooltip/Tooltip.tsx';

export type SwitchLabelPosition = FormControlLabelProps['labelPlacement'];

export interface ISwitchProps {
  dataTest?: string;
  label?: string;
  labelPosition?: SwitchLabelPosition;
  labelStyle?: React.CSSProperties;
  helperText?: string;
  error?: string;
  defaultIsChecked?: boolean;
  isChecked?: boolean;
  isEnabled?: boolean;
  onChange?: (to: boolean) => void;
  style?: SerializedStyles;
  tooltip?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

/**
 * An on/off toggle switch.
 * See:
 *  https://material-ui.com/components/switches/
 *  https://material-ui.com/api/switch/
 */
export const Switch: React.FC<ISwitchProps> = ({
  isEnabled = true,
  dataTest,
  defaultIsChecked,
  error,
  helperText,
  isChecked,
  label,
  labelPosition,
  labelStyle,
  onChange,
  onClick,
  onMouseDown,
  style,
  tooltip,
}) => {
  const handleChange: SwitchProps['onChange'] = React.useCallback(
    (_, to) => onChange?.(to),
    [onChange],
  );

  const elSwitch = (
    <MaterialSwitch
      disabled={!isEnabled}
      onChange={handleChange}
      checked={isChecked ?? defaultIsChecked}
    />
  );

  const elHint = (error || helperText) && (
    <FormHelperText error={Boolean(error)}>
      {error || helperText}
    </FormHelperText>
  );

  return (
    <div
      css={css(styles.base, style)}
      onClick={onClick}
      onMouseDown={onMouseDown}
      data-test={dataTest}
    >
      <Tooltip title={tooltip}>
        <FormControlLabel
          control={elSwitch}
          label={label}
          labelPlacement={labelPosition}
          style={labelStyle}
        />
      </Tooltip>
      {elHint}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }),
};
