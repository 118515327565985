/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export interface IBulletActionPlanProps {
  style?: SerializedStyles;
}

/**
 * A bullet that contains an action plan icon.
 */
export const BulletActionPlan = ({ style }: IBulletActionPlanProps) => {
  const computedStyles = useMemo(
    () => ({
      base: css(
        {
          width: 22,
          height: 22,
        },
        style,
      ),
      icon: {
        zIndex: 1,
        position: 'relative',
      } as const,
    }),
    [style],
  );

  return (
    <div css={computedStyles.base}>
      <Icons.playlistAddCheck
        style={computedStyles.icon}
        fill={color.format(-0.2)}
        size={22}
      />
    </div>
  );
};
