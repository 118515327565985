import { FormInstanceStatus } from '@se/data/forms/types.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { StateObservable, ofType } from 'redux-observable';
import { EMPTY, Observable, concatAll, from, mergeMap } from 'rxjs';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../../store.ts';
import { ReduxFormInstancePermissionsLoad } from '../types.ts';
import { permissionsLoaded } from './actions.ts';

export function loadPermissionsEpic(
  action$: Observable<ReduxFormInstancePermissionsLoad>,
  _: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/instance/PERMISSIONS_LOAD'),
    mergeMap(async (action) => {
      const { instanceId } = action.payload;

      try {
        const instance = await getInstanceStatusAndPermissions(
          client,
          instanceId,
        );
        if (instance)
          return from([
            permissionsLoaded(
              instanceId,
              instance.permissions,
              instance.status,
              instance.signOffs,
            ),
          ]);
      } catch (error) {
        log.error(
          `Unable to query for instance status and permissions for instance with id ${instanceId}`,
          error,
        );
      }

      return EMPTY;
    }),
    concatAll(),
  );
}

const getInstanceStatusAndPermissions = async (
  client: IGraphQLClient,
  instanceId: string,
) => {
  const query = gql`
    query FormInstancePermissions($instanceId: ID!) {
      forms {
        formInstance(id: $instanceId) {
          id
          status
          signOffs {
            id
            name
            occurredAt
            authorisedBy {
              id
              name
            }
          }
          permissions {
            edit
            archive
            cancel
            assignedToSignOff
            assignedToRevertSignOff
            subjectSignOff
            subjectRevertSignOff
            appeal
          }
        }
      }
    }
  `;

  return (
    await client.query<{
      forms: {
        formInstance: {
          id: string;
          status: FormInstanceStatus;
          signOffs?: Array<{
            id: string;
            name: string;
            occurredAt: string;
            authorisedBy?: {
              id: string;
              name: string;
            };
          }>;
          permissions: {
            edit: boolean;
            archive: boolean;
            cancel: boolean;
            revert: boolean;
            assignedToSignOff: boolean;
            assignedToRevertSignOff: boolean;
            subjectSignOff: boolean;
            subjectRevertSignOff: boolean;
            appeal: boolean;
          };
        };
      };
    }>({
      query,
      variables: { instanceId },
      fetchPolicy: 'network-only',
    })
  ).data.forms.formInstance;
};
