import { pipe } from 'ramda';
import { FormActionAuditEntry } from './actionAuditTypes.ts';

const aggregateVerificationEntries = (
  entries: FormActionAuditEntry[],
): FormActionAuditEntry[] => {
  if (!entries.length) return entries;

  let removeIndex = -1;

  return entries.reduce((acc, entry, index, allEntries) => {
    // If the previous entry was a status change, and the current entry is a note change, skip the current entry.
    if (removeIndex === index) {
      removeIndex = -1;
      return acc;
    }

    const nextIndex = index + 1;
    const nextEntry = allEntries[nextIndex];

    if (entry.actionType !== 'ActionVerificationStatusChanged') {
      acc.push(entry);
      return acc;
    }

    if (nextEntry?.actionType !== 'ActionVerificationNotesChanged') {
      acc.push(entry);
      return acc;
    }

    removeIndex = nextIndex;
    acc.push({
      action: entry.action,
      actionType: 'AggregateVerificationEntries',
      actionedAt: entry.actionedAt,
      actionedBy: entry.actionedBy,
      payload: {
        status: entry.payload.status,
        notes: nextEntry.payload.notes,
      },
      icon: entry.icon,
    });
    return acc;
  }, [] as FormActionAuditEntry[]);
};

const aggregateStatusEntries = (
  entries: FormActionAuditEntry[],
): FormActionAuditEntry[] => {
  if (!entries.length) return entries;

  let removeIndex = -1;

  return entries.reduce((acc, entry, index, allEntries) => {
    // If the previous entry was a status change, and the current entry is a note change, skip the current entry.
    if (removeIndex === index) {
      removeIndex = -1;
      return acc;
    }

    const nextIndex = index + 1;
    const nextEntry = allEntries[nextIndex];

    if (
      entry.actionType !== 'ActionCompleted' &&
      entry.actionType !== 'ActionCancelled'
    ) {
      acc.push(entry);
      return acc;
    }

    if (nextEntry?.actionType !== 'ActionNoteAdded') {
      acc.push(entry);
      return acc;
    }

    removeIndex = nextIndex;
    acc.push({
      action: entry.action,
      actionType: 'AggregateStatusEntries',
      actionedAt: entry.actionedAt,
      actionedBy: entry.actionedBy,
      payload: {
        status:
          entry.actionType === 'ActionCompleted' ? 'completed' : 'cancelled',
        note: nextEntry.payload.note,
      },
      icon: entry.icon,
    });
    return acc;
  }, [] as FormActionAuditEntry[]);
};

export const applyAuditEntryAggregations = pipe(
  aggregateVerificationEntries,
  aggregateStatusEntries,
);
