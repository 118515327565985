import gql from 'graphql-tag';
import { Observable, filter, mergeMap } from 'rxjs';
import { StateObservable, ofType } from 'redux-observable';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../../store.ts';
import {
  FormAnswerByKey,
  IFormInstance,
  ReduxFormInstanceReloadInstanceAnswers,
} from '../types.ts';
import {
  ServerAnswer,
  convertServerAnswersToInstanceAnswers,
} from '../../../util/util.instance.ts';
import { errorLoading } from '../instance/actions.ts';
import { reloadedInstanceAnswers } from './actions.ts';

export function reloadInstanceAnswersEpic(
  action$: Observable<ReduxFormInstanceReloadInstanceAnswers>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/instance/ANSWER/RELOAD_INSTANCE_ANSWERS'),
    filter(({ payload }) =>
      Boolean(state$.value.formInstance.instances[payload.instanceId]),
    ),
    mergeMap(async ({ payload }) => {
      const { instanceId } = payload;
      const instance = state$.value.formInstance.instances[instanceId];

      try {
        const answers = await reloadInstanceAnswers(client, instance);
        return reloadedInstanceAnswers(instanceId, answers);
      } catch (err) {
        log.error(
          `Error occurred while trying to reload instance answers for instance ${instanceId}`,
          err,
        );
        return errorLoading(instanceId, 'not-found');
      }
    }),
  );
}

const reloadInstanceAnswers = async (
  client: IGraphQLClient,
  instance: IFormInstance,
): Promise<FormAnswerByKey> => {
  const response = await client.query<{
    forms: {
      formInstance: {
        id: string;
        reportingDate?: string;
        answers: {
          nodes: ServerAnswer[];
        };
      };
    };
  }>({
    query: gql`
      query FormInstanceAnswers($instanceId: ID!) {
        forms {
          formInstance(id: $instanceId) {
            id
            reportingDate
            answers(
              orderBy: { fieldName: "createdAt", direction: Descending }
            ) {
              nodes {
                id
                key
                value
                createdAt
                displayValue
                group
                issue {
                  id
                  classifications
                  label
                  notes
                }
              }
            }
          }
        }
      }
    `,
    fetchPolicy: 'network-only',
    variables: { instanceId: instance.id },
  });

  return convertServerAnswersToInstanceAnswers(
    response.data.forms.formInstance.answers.nodes,
    instance.lines,
    response.data.forms.formInstance.reportingDate,
  );
};
