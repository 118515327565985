import { IScoreLine, ISectionLine } from '../types/types.ts';
import { getProp } from '../util/util.data.parse.ts';
import {
  ProvisionalOverruleDefinition,
  ScoreDefinition,
} from './types/score.types.ts';

export function parseScore(
  item: ScoreDefinition,
  parentSection: ISectionLine,
): IScoreLine | undefined {
  if (!item) return;

  const labelOverrides = item.labels !== false ? item.labels : {};

  return {
    parentId: parentSection.id,
    showOnAnswer: getProp(item, 'showOnAnswer'),
    showOnReportingDateMonth: item.showOnReportingDateMonth,
    showOnSwitch: getProp(item, 'showOnSwitch'),
    group: getProp(item, 'group')?.toString(),
    isRequired: getProp(item, 'isRequired', false),
    highlightOnHover: true,
    speechText:
      item.speechText ??
      `### Provisional Result\nThis is a provisional result. You now need to accept the review findings, and resolve the outstanding actions by the due date outlined.\n\nThe tester will review your updates. If this has been completed to expectation the result will be updated. If you do not remediate the actions in the time provided, the current provisional result will be final.`,
    showCalculation: item.showCalculation ?? false,
    id: 'scoreOverrule',
    type: 'score',
    canRemediateProvisional:
      (item.overrules?.provisional as ProvisionalOverruleDefinition)
        ?.canRemediate ?? true,
    overrules: {
      provisional: Boolean(item.overrules?.provisional),
      result: item.overrules?.result ?? false,
      notApplicable: item.overrules?.undetermined ?? false,
    },
    hasScoreLabels: item.labels !== false,
    showIndex: false,
    labels: {
      pass: 'Compliant',
      fail: 'Non-Compliant',
      ...labelOverrides,
    },
  };
}
