import { combineEpics } from 'redux-observable';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../../store.ts';
import { FormReduxAction } from '../../types.ts';
import {
  addCommentEpic,
  addCommentRequestEpic,
  retryAddCommentEpic,
} from './epic.add.ts';
import { deleteCommentEpic, retryDeleteCommentEpic } from './epic.delete.ts';
export * as commentsActions from './actions.ts';
export * as commentsTypes from './types.ts';

export const commentsEpics = combineEpics<
  FormReduxAction,
  FormReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  addCommentEpic,
  addCommentRequestEpic,
  retryAddCommentEpic,
  deleteCommentEpic,
  retryDeleteCommentEpic,
);
