import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import { FormTemplate } from '../types.ts';

export const getFormTemplate = async (
  client: IGraphQLClient,
  id: string,
): Promise<QueryResult<FormTemplate>> => {
  try {
    const response = await client.query<{
      forms: { formTemplate: FormTemplate };
    }>({
      query: gql`
        query FormTemplate($id: ID!) {
          forms {
            formTemplate(id: $id) {
              id
              name
              currentDefinitionId
              visibilityLimitations
              status
              updatedAt
              category {
                id
                name
              }
              currentDefinition {
                definition
              }
            }
          }
        }
      `,
      variables: { id },
      fetchPolicy: 'cache-first',
    });

    const formTemplate = response.data.forms.formTemplate;

    if (!formTemplate) {
      log.error(`Failed to retrieve form template for ${id}`);
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: {
        id: formTemplate.id,
        name: formTemplate.name,
        category: formTemplate.category,
        currentDefinition: formTemplate.currentDefinition,
        visibilityLimitations: formTemplate.visibilityLimitations,
        currentDefinitionId: formTemplate.currentDefinitionId,
        status: formTemplate.status,
        updatedAt: formTemplate.updatedAt,
      },
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query form template for ${id} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
