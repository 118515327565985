import { getProp } from '../util/util.data.parse.ts';
import { parseInputs } from './parse.section.questions.inputs.ts';
import { parseSpeechBlock } from './parse.section.questions.speechBlock.ts';
import { parseOptionsAnswer } from './parse.section.questions.optionsAnswer.ts';
import { parseFoo } from './parse.section.questions.foo.ts';
import { parseSwitchAnswer } from './parse.section.questions.switchAnswer.ts';
import { parseQuestion } from './parse.section.questions.question.ts';
import { parseNumberSliderAnswer } from './parse.section.questions.numberSliderAnswer.ts';
import { parseSliderAnswer } from './parse.section.questions.sliderAnswer.ts';
import { parseDetachableActionPlan } from './parse.actionPlan.ts';
import {
  ActionPlanSectionDefinition,
  FooSectionDefinition,
  NumberSliderSectionDefinition,
  OptionsSectionDefinition,
  QuestionSectionDefinition,
  ScoreSectionDefinition,
  ScriptSectionDefinition,
  SectionQuestionDefinition,
  SliderSectionDefinition,
  SwitchSectionDefinition,
  TextSectionDefinition,
} from './types/section.types.ts';
import { FormLineComponent, ISectionLine } from '../types/types.ts';
import { parseScore } from './parse.section.questions.score.ts';

const parsers: {
  [key: string]: (
    item: SectionQuestionDefinition,
    parentSection: ISectionLine,
    indices: number[],
  ) => FormLineComponent | undefined;
} = {
  text: (item: TextSectionDefinition, parentSection, indices) => {
    const props = getProp(item, 'text');
    return props && parseInputs(props, parentSection, indices);
  },
  script: (item: ScriptSectionDefinition, parentSection, indices) => {
    const props = getProp(item, 'script');
    return props && parseSpeechBlock(props, parentSection, indices);
  },
  options: (item: OptionsSectionDefinition, parentSection, indices) => {
    const props = getProp(item, 'options');
    return props && parseOptionsAnswer(props, parentSection, indices);
  },
  foo: (item: FooSectionDefinition, parentSection, indices) => {
    const props = getProp(item, 'foo');
    return props && parseFoo(props, parentSection, indices);
  },
  switch: (item: SwitchSectionDefinition, parentSection, indices) => {
    const props = getProp(item, 'switch');
    return props && parseSwitchAnswer(props, parentSection, indices);
  },
  question: (item: QuestionSectionDefinition, parentSection, indices) => {
    const props = getProp(item, 'question');
    return props && parseQuestion(props, parentSection, indices);
  },
  numberSlider: (
    item: NumberSliderSectionDefinition,
    parentSection,
    indices,
  ) => {
    const props = getProp(item, 'numberSlider');
    return props && parseNumberSliderAnswer(props, parentSection, indices);
  },
  slider: (item: SliderSectionDefinition, parentSection, indices) => {
    const props = getProp(item, 'slider');
    return props && parseSliderAnswer(props, parentSection, indices);
  },
  actionPlan: (item: ActionPlanSectionDefinition, parentSection, indices) => {
    const props = getProp(item, 'actionPlan');
    return props && parseDetachableActionPlan(props, parentSection, indices);
  },
  score: (item: ScoreSectionDefinition, parentSection) => {
    const props = getProp(item, 'score');
    return props && parseScore(props, parentSection);
  },
};

export function parseQuestions(
  questions: SectionQuestionDefinition[],
  parentSection: ISectionLine,
  index: number,
): FormLineComponent[] {
  if (!questions || !Array.isArray(questions) || questions.length === 0) {
    return [];
  }

  return questions.reduce((acc, question, childIndex) => {
    const [questionType] = Object.keys(question);
    const component = parsers[questionType]?.(question, parentSection, [
      index,
      childIndex,
    ]);
    return component ? [...acc, component] : acc;
  }, []);
}
