import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';

export const getFormsBulkUploadJobHistoryFailedDownload = async (
  client: IGraphQLClient,
  jobId: string,
): Promise<QueryResult<string>> => {
  try {
    const response = await client.query<{
      forms: {
        formsBulkUploadJobHistoryFailedDownload: string;
      };
    }>({
      query: gql`
        query FormsBulkUploadJobHistoryFailedDownload($jobId: ID!) {
          forms {
            formsBulkUploadJobHistoryFailedDownload(jobId: $jobId)
          }
        }
      `,
      variables: {
        jobId,
      },
      fetchPolicy: 'network-only',
    });

    const link = response.data.forms.formsBulkUploadJobHistoryFailedDownload;

    if (!link) {
      log.error('Failed to retrieve Bulk Upload failed load download link');
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: link,
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to retrieve Bulk Upload failed load download link - ${error.message}`,
      error,
    );
    return { isSuccess: false, errorReason: 'UNKNOWN', error };
  }
};
