/**
 * Escapes strings to suit the CSV format defined by RFC 4280 - https://tools.ietf.org/html/rfc4180
 * Based on https://www.freeformatter.com/csv-escape.html
 */

/**
 * Applies the following rules to a single field:
 * - All input strings are surrounded in double quotes whether they need it or not.
 * - A double quote must be escaped with another double quote.
 */
export function csvEscapeField(input: string) {
  if (!input) {
    return '""';
  }
  return `"${input.split('"').join('""')}"`;
}
