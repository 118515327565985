import gql from 'graphql-tag';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';
import { FormResult } from './types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';

export interface UpdateScoreInputs {
  finalScore: number;
  formInstanceId: string;
  outcome: 'Pass' | 'Fail' | 'Provisional';
  rawScore: number;
  totalWeighting: number;
}
export const getCalculatedScore = (
  calculatedResult: FormResult,
  percentageScore: number,
  overruledResult?: FormResult,
) => {
  const isFormOnlyScoredFromOverrule =
    calculatedResult === FormResult.NotApplicable && overruledResult;
  if (isFormOnlyScoredFromOverrule) {
    switch (overruledResult) {
      case FormResult.Pass:
        return 100;
      case FormResult.Provisional:
        return 50;
      case FormResult.Fail:
      default:
        return 0;
    }
  }

  return percentageScore * 100;
};

export const updateScore = async (
  client: IGraphQLClient,
  inputs: UpdateScoreInputs,
) => {
  try {
    const response = await client.mutate({
      mutation: saveScoreMutation,
      variables: inputs,
    });

    const isSuccessful = (response.data as IUpdateScoreMutationResponse).forms
      .updateScoreOnFormInstance.ok;
    if (!isSuccessful) {
      throw new Error('Mutation returned a non-ok response.');
    }
    return true;
  } catch (err) {
    log.error(
      new Error(
        `Error occurred trying to save the score for instance ${inputs.formInstanceId} ${err}`,
      ),
    );
    return false;
  }
};

const saveScoreMutation = gql`
  mutation UpdateFormScore(
    $finalScore: Float!
    $formInstanceId: ID!
    $outcome: FormInstanceScoringOutcome!
    $rawScore: Float!
    $totalWeighting: Float!
  ) {
    forms {
      updateScoreOnFormInstance(
        input: {
          finalScore: $finalScore
          formInstanceId: $formInstanceId
          outcome: $outcome
          rawScore: $rawScore
          totalWeighting: $totalWeighting
        }
      ) {
        ok
      }
    }
  }
`;

interface IUpdateScoreMutationResponse {
  forms: {
    updateScoreOnFormInstance: {
      ok: boolean;
    };
  };
}
