/** @jsxImportSource @emotion/react */
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { PreviewListContainer } from '@seeeverything/ui.shell/src/components/PreviewList/PreviewListContainer.tsx';
import { showModalDialog } from '@seeeverything/ui.shell/src/redux/modalDialog/actions.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import gql from 'graphql-tag';
import moment from 'moment';
import { StateObservable, combineEpics } from 'redux-observable';
import { Observable, concatAll, filter, from, map, mergeMap, of } from 'rxjs';
import * as distributionListSlice from '../distributionList/distributionListSlice.ts';
import {
  DistributionListItem,
  DistributionListRuleType,
  DistributionListTeamRuleDetail,
} from '../distributionList/types.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import { formatDateString } from '../utils/dates.ts';
import * as editDistributionListSlice from './editDistributionListSlice.ts';
import { createDistributionList, updateDistributionList } from './mutations.ts';
import { DistributionListRule, IDistributionListItemDraft } from './types.ts';

export const editDistributionListEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  previewDistributionListEpic,
  loadDistributionListsOnListSavedEpic,
  saveDistributionListEpic,
);

const GENERAL_ERROR =
  'An error occurred while trying to save the Distribution List.';
const VALIDATION_ERROR = 'Please check for incomplete fields and try again.';

/**
 * Epic to reload distribution list grid on save.
 */
function loadDistributionListsOnListSavedEpic(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    filter(editDistributionListSlice.saveDistributionListSuccess.match),
    map(() =>
      distributionListSlice.loadDistributionLists({ loadNextPage: false }),
    ),
  );
}

function previewDistributionListEpic(
  action$: Observable<ReduxAction>,
  _: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(editDistributionListSlice.previewDistributionList.match),
    mergeMap(async (action) => {
      const { listName, type, rules } = action.payload;

      const validationResult = validateDistributionListDraft('PREVIEW', {
        listName,
        rules,
        type,
      });

      if (!validationResult.isValid)
        return of(
          editDistributionListSlice.previewDistributionListError({
            fieldErrors: validationResult.errors,
            globalError: VALIDATION_ERROR,
          }),
        );

      const ruleIncludeTeamsBelow = rules
        .filter(
          (rule) => rule.type === DistributionListRuleType.IncludeTeamsBelow,
        )
        .map((rule) =>
          (rule.selections as DistributionListTeamRuleDetail[]).map((team) => ({
            teamId: team.id,
            hierarchyLevel: team.hierarchyLevel,
          })),
        )
        .flat();

      const ruleIncludePositionTitles = rules.find(
        (rule) => rule.type === DistributionListRuleType.IncludePositionTitles,
      )?.selections as string[];

      const ruleExcludeTeams = rules
        .find((rule) => rule.type === DistributionListRuleType.ExcludeTeams)
        ?.selections?.map(
          (selection) => (selection as DistributionListTeamRuleDetail).id,
        );

      const ruleIncludeTeams = rules
        .find((rule) => rule.type === DistributionListRuleType.IncludeTeams)
        ?.selections?.map(
          (selection) => (selection as DistributionListTeamRuleDetail).id,
        );

      const ruleExcludePositionTitles = rules.find(
        (rule) => rule.type === DistributionListRuleType.ExcludePositionTitles,
      )?.selections as string[];

      const response = await client.query<{
        orgHierarchy: {
          previewDistributionList: {
            nodes: Array<{
              type: string;
              name: string;
              path: string;
              businessUnit: string;
              positionTitle: string;
            }>;
          };
        };
      }>({
        query: gql`
          query PreviewDistributionList(
            $type: String!
            $ruleIncludeTeams: [String]
            $ruleExcludeTeams: [String]
            $ruleIncludeTeamsBelow: [RuleIncludeTeamsBelow]
            $ruleIncludePositionTitles: [String]
            $ruleExcludePositionTitles: [String]
            $ruleIncludePositionTitlesContaining: [String]
            $ruleIncludePositionTitlesNotContaining: [String]
          ) {
            orgHierarchy {
              previewDistributionList(
                type: $type
                ruleIncludeTeams: $ruleIncludeTeams
                ruleExcludeTeams: $ruleExcludeTeams
                ruleIncludeTeamsBelow: $ruleIncludeTeamsBelow
                ruleIncludePositionTitles: $ruleIncludePositionTitles
                ruleExcludePositionTitles: $ruleExcludePositionTitles
                ruleIncludePositionTitlesContaining: $ruleIncludePositionTitlesContaining
                ruleIncludePositionTitlesNotContaining: $ruleIncludePositionTitlesNotContaining
              ) {
                nodes {
                  type
                  name
                  path
                  businessUnit
                  positionTitle
                }
              }
            }
          }
        `,
        variables: {
          type,
          ruleIncludeTeamsBelow,
          ruleExcludeTeams,
          ruleIncludeTeams,
          ruleIncludePositionTitles,
          ruleExcludePositionTitles,
        },
        fetchPolicy: 'network-only',
      });

      const members = response.data.orgHierarchy.previewDistributionList.nodes;

      return of(
        showModalDialog({
          content: (
            <PreviewListContainer
              items={members.map((member) => ({
                id: member.name,
                text: member.name,
                value: member.name,
                icon: type === 'Team' ? Icons.team : Icons.face,
                description:
                  type === 'Team' ? member.path : member.positionTitle,
                isClickable: false,
              }))}
              type={type}
            />
          ),
          width: 800,
        }),
      );
    }),
    concatAll(),
  );
}

function saveDistributionListEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(editDistributionListSlice.saveDistributionList.match),
    mergeMap(async () => {
      try {
        const draft = state$.value.formEditDistributionList?.draft;

        if (draft === undefined)
          return of(
            editDistributionListSlice.saveDistributionListError({
              globalError: `${GENERAL_ERROR} - No draft selected`,
            }),
          );

        const validationResult = validateDistributionListDraft('SAVE', {
          listName: draft.listName,
          rules: draft.rules,
          type: draft.type,
        });

        if (!validationResult.isValid)
          return of(
            editDistributionListSlice.saveDistributionListError({
              fieldErrors: validationResult.errors,
              globalError: VALIDATION_ERROR,
            }),
          );

        return draft.id === undefined
          ? saveNewDistributionList$(client, draft)
          : saveExistingDistributionList$(state$.value, draft, client);
      } catch (err) {
        log.error(GENERAL_ERROR, err);

        return of(
          editDistributionListSlice.saveDistributionListError({
            globalError: GENERAL_ERROR,
          }),
        );
      }
    }),
    concatAll(),
  );
}

async function saveExistingDistributionList$(
  state: GlobalFormsState,
  draft: Partial<IDistributionListItemDraft>,
  client: IGraphQLClient,
) {
  if (!draft?.id) {
    return of(
      editDistributionListSlice.saveDistributionListError({
        globalError: `${GENERAL_ERROR} - No draft selected`,
      }),
    );
  }

  const original = state.formDistributionList.listItems.find(
    (item) => item.id === draft.id,
  );

  if (!original)
    return of(
      editDistributionListSlice.saveDistributionListError({
        globalError: `${GENERAL_ERROR} - No corresponding original`,
      }),
    );

  try {
    const success = await updateDistributionList(client, original.id, draft);
    const updated: DistributionListItem = {
      ...toDistributionListItem(draft),
    };

    return success
      ? from([
          editDistributionListSlice.saveDistributionListSuccess({
            id: draft.id,
            status: updated.status,
          }),
          distributionListSlice.updatedDistributionList({ item: updated }),
        ])
      : of(
          editDistributionListSlice.saveDistributionListError({
            globalError: GENERAL_ERROR,
          }),
        );
  } catch {
    return of(
      editDistributionListSlice.saveDistributionListError({
        globalError: GENERAL_ERROR,
      }),
    );
  }
}

async function saveNewDistributionList$(
  client: IGraphQLClient,
  draft: Partial<IDistributionListItemDraft>,
) {
  const id = uuid.generate();

  const validatedModel: IDistributionListItemDraft = {
    id,
    created: draft.created,
    lastUpdatedBy: draft.lastUpdatedBy,
    lastUpdatedOn: draft.lastUpdatedOn,
    listName: draft.listName,
    rules: draft.rules,
    type: draft.type,
  };

  const response = await createDistributionList(client, id, validatedModel);

  const defaults: DistributionListItem = {
    id,
    canSave: true,
    canEdit: true,
    created: formatDateString(moment().toISOString(), false),
    lastUpdatedBy: '-',
    lastUpdatedOn: '',
    listName: '',
    ruleExcludeTeams: [],
    ruleIncludeTeams: [],
    ruleIncludeTeamsBelow: [],
    ruleIncludePositionTitles: [],
    ruleExcludePositionTitles: [],
    ruleIncludePositionTitlesContaining: [],
    ruleIncludePositionTitlesNotContaining: [],
    status: 'Active',
    type: 'Team',
  };

  const updated = {
    ...defaults,
    ...toDistributionListItem(response),
  };

  return updated
    ? from([
        editDistributionListSlice.saveDistributionListSuccess({
          id,
          status: updated.status,
        }),
        distributionListSlice.addedDistributionList(),
      ])
    : of(
        editDistributionListSlice.saveDistributionListError({
          globalError: GENERAL_ERROR,
        }),
      );
}

function toDistributionListItem(draft?: Partial<IDistributionListItemDraft>) {
  if (!draft) return undefined;

  return {
    id: draft.id,
    canEdit: draft.canEdit,
    canSave: draft.canSave,
    created: draft.created,
    lastUpdatedBy: draft.lastUpdatedBy,
    lastUpdatedOn: draft.lastUpdatedOn,
    listName: draft.listName,
    status: draft.status,
    type: draft.type,
    ...getDistributionListRules(draft.rules),
  } as DistributionListItem;
}

const getDistributionListRules = (rules: DistributionListRule[]) => {
  const ruleExcludeTeams = (
    rules.find((rule) => rule.type === DistributionListRuleType.ExcludeTeams)
      ?.selections as DistributionListTeamRuleDetail[]
  )?.map((selection: DistributionListTeamRuleDetail) => ({
    id: selection.id,
    name: selection.name,
  }));

  const ruleIncludeTeams = (
    rules.find((rule) => rule.type === DistributionListRuleType.IncludeTeams)
      ?.selections as DistributionListTeamRuleDetail[]
  )?.map((selection: DistributionListTeamRuleDetail) => ({
    id: selection.id,
    name: selection.name,
  }));

  const ruleIncludeTeamsBelow = rules
    .filter((rule) => rule.type === DistributionListRuleType.IncludeTeamsBelow)
    ?.map((rule) => {
      const selection = rule.selections[0] as DistributionListTeamRuleDetail;
      return {
        id: selection.id,
        name: selection.name,
        hierarchyLevel: selection.hierarchyLevel,
      };
    });

  const findRulePositionTitle = (ruleType: DistributionListRuleType) =>
    rules.find((rule) => rule.type === ruleType)?.selections as string[];

  const ruleIncludePositionTitles = findRulePositionTitle(
    DistributionListRuleType.IncludePositionTitles,
  );
  const ruleExcludePositionTitles = findRulePositionTitle(
    DistributionListRuleType.ExcludePositionTitles,
  );

  return {
    ruleExcludeTeams,
    ruleIncludeTeams,
    ruleIncludeTeamsBelow,
    ruleIncludePositionTitles,
    ruleExcludePositionTitles,
  };
};

const validateDistributionListDraft = (
  type: 'SAVE' | 'PREVIEW',
  draft: Partial<IDistributionListItemDraft>,
) => {
  const invalidName =
    type === 'PREVIEW' || draft?.listName
      ? undefined
      : { nameField: 'Name is required' };
  const invalidType = draft?.type
    ? undefined
    : { typeField: 'Type is required' };
  const invalidRuleField = !draft.rules
    ? { ruleField: 'No rules created' }
    : undefined;

  const invalidRules = draft.rules
    .map((rule) => {
      if (!rule.type) return { id: rule.id, error: 'Type: not selected' };
      if (rule.selections.length === 0)
        return { id: rule.id, error: 'Selections: no selections' };
      if (rule.type === DistributionListRuleType.IncludeTeamsBelow)
        return validateRuleIncludeTeamsBelow(rule);

      return undefined;
    })
    .filter(Boolean);

  return {
    isValid: Boolean(
      !invalidName &&
        !invalidType &&
        (!invalidRules || invalidRules.length === 0),
    ),
    errors: {
      ...invalidName,
      ...invalidType,
      ...invalidRuleField,
      rules: invalidRules.filter(Boolean),
    },
  };
};

const validateRuleIncludeTeamsBelow = (
  ruleIncludeTeamsBelow?: DistributionListRule,
) => {
  const isLevelValid =
    (
      ruleIncludeTeamsBelow.selections as DistributionListTeamRuleDetail[]
    ).filter(
      (team: DistributionListTeamRuleDetail) =>
        team?.hierarchyLevel === undefined,
    ).length === 0;

  return isLevelValid
    ? undefined
    : {
        id: ruleIncludeTeamsBelow.id,
        error: 'Hierarchy Level: level not selected',
      };
};
