import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import { Person } from '../types.ts';

export const getTeamManagers = async (
  client: IGraphQLClient,
  teamId: string,
): Promise<QueryResult<Person[]>> => {
  try {
    const response = await client.query<{
      orgHierarchy: {
        team: {
          memberships: {
            nodes: Array<{ person: Person }>;
          };
        };
      };
    }>({
      query: gql`
        query TeamManagers($teamId: ID!) {
          orgHierarchy {
            team(id: $teamId) {
              memberships(isManager: true) {
                nodes {
                  person {
                    id
                    email
                    firstName
                    lastName
                    positionTitle
                    isActive
                  }
                }
              }
            }
          }
        }
      `,
      variables: { teamId },
      fetchPolicy: 'network-only',
    });

    const nodes = response.data.orgHierarchy.team.memberships.nodes;
    if (!nodes) {
      log.error(`Failed to retrieve team managers for team ${teamId}`);
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: nodes.map(
        ({ person }): Person => ({
          id: person.id,
          email: person.email,
          firstName: person.firstName,
          isActive: person.isActive,
          lastName: person.lastName,
          positionTitle: person.positionTitle,
        }),
      ),
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query team managers for team ${teamId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
