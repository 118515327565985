/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Spinner from '@seeeverything/ui.primitives/src/components/Spinner/Spinner.tsx';
import { useMouseOver } from '@seeeverything/ui.primitives/src/hooks/useMouseOver.ts';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import { useCallback, useMemo } from 'react';
import { CreateIssueActionButton } from '../components/CreateIssueActionButton/CreateIssueActionButton.tsx';
import { FormListItemWrapped } from '../components/FormListItem/FormListItemWrapped.tsx';
import { OptionsAnswer } from '../components/OptionsAnswer/OptionsAnswer.tsx';
import { useHandleAnswerVisibility } from '../hooks/useHandleAnswerVisibility.ts';
import { formInstanceAnswerChange } from '../redux/form-instance/answer/actions.ts';
import { useFormsDispatch, useFormsSelector } from '../redux/store.ts';
import { FormAnswer, IOptionsAnswerLine } from '../types/types.ts';

export interface IOptionAnswerFactoryContainerProps {
  canUpdateInstance: boolean;
  instanceId: string;
  isPdfExport: boolean;
  line: IOptionsAnswerLine;
}

export const OptionAnswerFactoryContainer: React.FC<
  IOptionAnswerFactoryContainerProps
> = ({ canUpdateInstance, instanceId, isPdfExport, line }) => {
  const dispatch = useFormsDispatch();

  const isVisible = useHandleAnswerVisibility({
    instanceId,
    questionKeys: [line.id],
    showOnAnswer: line.showOnAnswer,
    showOnReportingDateMonth: line.showOnReportingDateMonth,
    showOnSwitch: line.showOnSwitch,
  });

  const [isMouseOver, handleMouseEnter, handleMouseLeave] = useMouseOver();

  const newAnswerId = useMemo(() => {
    if (!isVisible) return;
    return uuid.generate();
  }, [isVisible]);

  const answer = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    return instance?.answers?.[line.id];
  });

  const error = useFormsSelector(
    (state) =>
      state.formInstance.instances[instanceId]?.questionErrors?.[line.id],
  );

  const issue = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    const formAnswer = instance?.answers?.[line.id];
    if (!formAnswer) return;

    return state.formIssue.issues.find((i) => i.answerId === answer.id);
  });

  const issueLoading = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    const formAnswer = instance?.answers?.[line.id];
    if (!formAnswer) return;

    const option = line.options?.find(
      (o) => o.id?.toString() === formAnswer.value,
    );
    const hasIssue = Boolean(option?.issue);
    if (!hasIssue) return false;

    const formIssue = state.formIssue.issues.find(
      (i) => i.answerId === answer.id,
    );
    if (!formIssue) return true;
    return formIssue.isLoading;
  });

  const canAddActions = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    if (!instance) return false;

    return (
      instance.status.status !== 'Completed' &&
      instance.permissions.edit === true
    );
  });

  const showCreateButton = useMemo(() => {
    if (!isMouseOver) return false;
    if (!issue) return false;
    if (issueLoading) return false;
    if (!canAddActions) return false;
    return true;
  }, [canAddActions, isMouseOver, issue, issueLoading]);

  const updateAnswer = useCallback(
    (to: string, displayValue: string) => {
      if (!isVisible) return;

      const toAnswerValue = to.toString();

      const toAnswer: FormAnswer = {
        id: answer?.id ?? newAnswerId,
        value: toAnswerValue,
        displayValue,
        lineId: line.id,
        type: 'optionsAnswer',
        group: line.group,
        subType: line.multiSelect ? 'CHECKBOX' : 'RADIO',
      };

      const option = line.options?.find(
        (o) => o.id?.toString() === toAnswerValue,
      );
      const hasIssue = Boolean(option?.issue);

      dispatch(
        formInstanceAnswerChange(
          instanceId,
          answer?.id
            ? {
                type: 'UPDATE',
                toAnswer,
                fromAnswer: answer,
                postChangeQueryIssueId: hasIssue,
              }
            : {
                type: 'CREATE',
                toAnswer,
                postChangeQueryIssueId: hasIssue,
                creationPreviouslyFailed: Boolean(error),
              },
          true,
        ),
      );
    },
    [
      answer,
      dispatch,
      error,
      instanceId,
      isVisible,
      line.group,
      line.id,
      line.multiSelect,
      line.options,
      newAnswerId,
    ],
  );

  return (
    <FormListItemWrapped
      id={line.id}
      parentId={line.parentId}
      key={line.id}
      highlightOnHover={line.highlightOnHover}
      bulletTop={line.direction === 'vertical' ? 6 : 31}
      isVisible={isVisible}
      isPdfExport={isPdfExport}
    >
      <div
        css={styles.optionsBase}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {issueLoading && (
          <div css={styles.loadingMask}>
            <Spinner center={true} />
          </div>
        )}
        <div css={issueLoading ? styles.loadingFade : undefined}>
          <OptionsAnswer
            answerId={answer?.id}
            direction={line.direction}
            error={error}
            instanceId={instanceId}
            isEnabled={canUpdateInstance}
            multiSelect={line.multiSelect}
            onChange={updateAnswer}
            options={line.options}
            question={line.question}
            value={answer?.value?.toString()}
          />
          <CreateIssueActionButton
            instanceId={instanceId}
            lineId={line.id}
            isShowing={showCreateButton}
            onHideDropdown={handleMouseLeave}
          />
        </div>
      </div>
    </FormListItemWrapped>
  );
};

const styles = {
  optionsBase: css({
    position: 'relative',
  }),
  loadingMask: css({
    position: 'absolute',
    display: 'flex',
    inset: 0,
  }),
  loadingFade: css({
    opacity: 0.5,
    pointerEvents: 'none',
  }),
};
