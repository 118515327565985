import { IScoreErrorMap } from '../../components/Score/types.ts';

export type ReduxFormScoreResultChanged = {
  type: 'ui.forms/score/RESULT_CHANGED';
  payload: { instanceId: string; to: FormResult };
};

export type ReduxFormScoreResultSavedSuccess = {
  type: 'ui.forms/score/RESULT_SAVED_SUCCESS';
  payload: { instanceId: string };
};

export type ReduxFormScoreResultSavedError = {
  type: 'ui.forms/score/RESULT_SAVED_ERROR';
  payload: { instanceId: string };
};

export type ReduxFormScoreRemediationChanged = {
  type: 'ui.forms/score/REMEDIATION_CHANGED';
  payload: { instanceId: string; to: RemediationOutcome };
};

export type ReduxFormScoreRemediationChangedSuccess = {
  type: 'ui.forms/score/REMEDIATION_CHANGED_SUCCESS';
  payload: { instanceId: string };
};

export type ReduxFormScoreRemediationChangedError = {
  type: 'ui.forms/score/REMEDIATION_CHANGED_ERROR';
  payload: { instanceId: string };
};

export type ReduxFormScoreRemediationReasonChanged = {
  type: 'ui.forms/score/REMEDIATION_REASON_CHANGED';
  payload: { instanceId: string; to: string };
};

export type ReduxFormScoreRemediationReasonChangedSuccess = {
  type: 'ui.forms/score/REMEDIATION_REASON_CHANGED_SUCCESS';
  payload: { instanceId: string };
};

export type ReduxFormScoreRemediationReasonChangedError = {
  type: 'ui.forms/score/REMEDIATION_REASON_CHANGED_ERROR';
  payload: { instanceId: string };
};

export type ReduxFormScoreScoreChanged = {
  type: 'ui.forms/score/SCORE_CHANGED';
  payload: { instanceId: string; score?: FormScore };
};

export type ReduxFormScoreLoadedFromInstance = {
  type: 'ui.forms/score/LOADED_FROM_INSTANCE';
  payload: {
    instanceId: string;
    overruledResult?: FormResult;
    remediation?: RemediationOutcome;
    remediationReason?: string;
  };
};

export type FormScoreAction =
  | ReduxFormScoreResultChanged
  | ReduxFormScoreRemediationChanged
  | ReduxFormScoreRemediationReasonChanged
  | ReduxFormScoreScoreChanged
  | ReduxFormScoreResultSavedSuccess
  | ReduxFormScoreResultSavedError
  | ReduxFormScoreRemediationChangedSuccess
  | ReduxFormScoreRemediationChangedError
  | ReduxFormScoreRemediationReasonChangedSuccess
  | ReduxFormScoreRemediationReasonChangedError
  | ReduxFormScoreLoadedFromInstance;

export interface IFormScoreState {
  overruledResult?: FormResult;
  remediation?: RemediationOutcome;
  remediationReason?: string;
  errors?: IScoreErrorMap;
  score?: FormScore;
}

export type FormScore = {
  isScorable: boolean;
  isBreakdownViewable: boolean;
  passRate: number;
  failMaximumScore: number;
  totalScorableQuestions: number;
  answeredQuestions: number;
  currentScorePoints: number;
  currentWeightPoints: number;
  percentageScore: number; // Accounts for instant fails.
  rawPercentageScore: number;
  instantFailAnswerCount: number;
  result: FormResult;
};

export enum FormResult {
  Pass = 'Pass',
  Fail = 'Fail',
  NotApplicable = 'N/A',
  Provisional = 'Provisional',
}

export enum RemediationOutcome {
  Pending = 'Pending',
  No = 'No',
  Yes = 'Yes',
}
