import { combineEpics } from 'redux-observable';
import { loadDigitalContentEpic, loadOnFormInstanceEpic } from './epic.load.ts';
import { queryDownloadLinkEpic } from './epic.download.ts';
import { commentsEpics } from './comments/index.ts';
import { FormReduxAction } from '../types.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';

export const epics = combineEpics<
  FormReduxAction,
  FormReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  loadOnFormInstanceEpic,
  loadDigitalContentEpic,
  queryDownloadLinkEpic,
  commentsEpics,
);
