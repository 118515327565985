import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import {
  PersonWithTeamMemberships,
  ServerMembership,
  ServerRole,
} from '../types.ts';
import { translateMemberships } from '../utils/memberships.ts';
import { translateRoles } from '../utils/roles.ts';

export const getPersonWithTeamMemberships = async (
  client: IGraphQLClient,
  personId: string,
): Promise<QueryResult<PersonWithTeamMemberships>> => {
  try {
    const response = await client.query<{
      orgHierarchy: {
        person: {
          id: string;
          isActive: boolean;
          firstName: string;
          lastName: string;
          email: string;
          employeeId: string;
          positionTitle: string;
          lineManager?: {
            id: string;
            email: string;
            firstName: string;
            isActive: boolean;
            lastName: string;
            positionTitle: string;
          };
          memberships: { nodes: ServerMembership[] };
          roles?: ServerRole[];
        };
      };
    }>({
      query: gql`
        query PersonWithTeamMemberships($personId: ID!) {
          orgHierarchy {
            person(id: $personId) {
              email
              employeeId
              firstName
              function
              id
              isActive
              lastName
              lineManager {
                id
                email
                firstName
                lastName
                isActive
                positionTitle
              }
              roles {
                id
                name
                sourceKey
                team {
                  id
                  name
                  path
                }
              }
              memberships(
                isParentManager: false
                pagination: { size: 100, pageNumber: 1 }
              ) {
                nodes {
                  team {
                    id
                    path
                    name
                    type
                  }
                  role
                  sourceKey
                  isPrimary
                }
              }
              positionTitle
            }
          }
        }
      `,
      variables: { personId },
      fetchPolicy: 'no-cache',
    });

    const person = response.data.orgHierarchy.person;
    if (!person) {
      log.error(
        `Failed to retrieve person and team memberships for ${personId}`,
      );
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: {
        id: person.id,
        firstName: person.firstName ?? '',
        lastName: person.lastName ?? '',
        isActive: person.isActive,
        email: person.email ?? '',
        lineManager: person.lineManager && {
          id: person.lineManager.id,
          email: person.lineManager.email ?? '',
          firstName: person.lineManager.firstName ?? '',
          lastName: person.lineManager.lastName ?? '',
          isActive: person.lineManager.isActive,
          positionTitle: person.lineManager.positionTitle ?? '',
        },
        positionTitle: person.positionTitle ?? '',
        employeeId: person.employeeId ?? '',
        teamMemberships: translateMemberships(person.memberships.nodes),
        roles: translateRoles(person.roles ?? []),
      },
    };
  } catch (error) {
    log.error(
      `Failed to retrieve person and team memberships for ${personId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
