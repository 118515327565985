import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import { FormsUserPermissions } from '../types.ts';

export const getFormPermissions = async (
  client: IGraphQLClient,
  module: ModuleType,
): Promise<QueryResult<FormsUserPermissions>> => {
  try {
    const response = await client.query<{
      forms: {
        instanceSchedulePermissions: { canView: boolean };
        formTemplatesPermissions: { canCreate: boolean };
        formPermissions: { canCreate: boolean };
        automatedActionsPermissions: { canCreate: boolean };
      };
    }>({
      query: gql`
        query FormsPermissions {
          forms {
            instanceSchedulePermissions {
              canView
            }
            formTemplatesPermissions {
              canCreate
            }
            formPermissions {
              canCreate
            }
            automatedActionsPermissions {
              canCreate
            }
          }
        }
      `,
      variables: { module },
      fetchPolicy: 'network-only',
    });

    if (!response.data.forms) {
      log.error(`Failed to retrieve form permissions for ${module}`);
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: {
        scheduleManage: response.data.forms.instanceSchedulePermissions.canView,
        templateManage: response.data.forms.formTemplatesPermissions.canCreate,
        instanceCreate: response.data.forms.formPermissions.canCreate,
        bulkFormLoadManage: response.data.forms.formPermissions.canCreate,
        automatedActionManage:
          module === 'compliance' &&
          response.data.forms.automatedActionsPermissions.canCreate,
      },
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query form permissions for ${module} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
