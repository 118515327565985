/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import { css } from '@emotion/react';
import { Markdown } from '@seeeverything/ui.primitives/src/components/Markdown/Markdown.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { useEmitDebounce } from '@seeeverything/ui.primitives/src/hooks/useEmitDebounce.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';

export interface INumberAnswerProps {
  error?: string;
  floatingText?: string;
  helperText?: string;
  icon?: string;
  id: string;
  isEnabled?: boolean;
  label?: string;
  multiline?: boolean;
  onChange: (to: string) => void;
  renderText?: boolean;
  value?: string;
}

/**
 * A single textbox for answering number based question.
 */
export const NumberAnswer: React.FC<INumberAnswerProps> = ({
  error,
  floatingText,
  helperText,
  icon,
  id,
  isEnabled,
  label,
  onChange,
  renderText,
  value,
}) => {
  const [statefulValue, setStatefulValue] = useEmitDebounce({
    value,
    delay: 5000,
    onDebounce: onChange,
  });

  const handleChange = useCallback(
    (to: string) => setStatefulValue(str.toNumber(to)),
    [setStatefulValue],
  );

  const handleBlur = useCallback(() => {
    if (value !== statefulValue) onChange?.(statefulValue);
  }, [onChange, statefulValue, value]);

  const computedStyles = {
    iconOuter: css({
      marginRight: 10,
      marginBottom: helperText ? 27 : 5,
      alignSelf: 'flex-end',
      height: 24,
    }),
    markdownOuter: css({
      marginBottom: floatingText ? 15 : 10,
      height: 31,
    }),
  };

  const Icon = icon && Icons[icon];

  const elMarkdown = label && (
    <div css={computedStyles.markdownOuter}>
      <Markdown text={label} />
    </div>
  );

  return (
    <div css={styles.base} onBlur={handleBlur}>
      {elMarkdown}
      <div css={styles.iconText}>
        {Icon && (
          <div css={computedStyles.iconOuter}>
            <Icon fill={color.format(-0.3)} size={24} />
          </div>
        )}
        {renderText ? (
          <div css={styles.textContainer}>
            <Text style={styles.textLabel}>{floatingText}</Text>
            <Text>{statefulValue || '-'}</Text>
          </div>
        ) : (
          <TextField
            id={id}
            isEnabled={isEnabled}
            label={floatingText}
            helperText={helperText}
            value={statefulValue}
            multiline={false}
            InputProps={{ type: 'text', autoComplete: 'off' }}
            onChange={handleChange}
            error={error}
          />
        )}
      </div>
    </div>
  );
};

const styles = {
  base: css({
    flex: '1 1 275px',
    padding: '0 15px 10px 0',
  }),
  iconText: css({
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
  }),
  textContainer: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  textLabel: css({
    fontSize: 12,
    color: '#808080',
    paddingBottom: 5,
  }),
};
