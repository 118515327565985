import {
  FormScoreAction,
  IFormScoreState,
  RemediationOutcome,
} from './types.ts';

const ERROR_MESSAGE =
  'Something went wrong saving your response, please retry.';
const DEFAULT_STATE: IFormScoreState = {};

export function reducer(
  state: IFormScoreState = DEFAULT_STATE,
  action: FormScoreAction,
): IFormScoreState {
  switch (action.type) {
    case 'ui.forms/score/RESULT_CHANGED': {
      const { to } = action.payload;
      return {
        ...state,
        overruledResult: to === state.score?.result ? undefined : to,
        remediation: RemediationOutcome.Pending,
        remediationReason: undefined,
        errors: {
          ...state.errors,
          overriddenResult: undefined,
          remediation: undefined,
          remediationReason: undefined,
        },
      };
    }

    case 'ui.forms/score/REMEDIATION_CHANGED': {
      const { to } = action.payload;
      return {
        ...state,
        remediation: to,
        remediationReason:
          to === RemediationOutcome.Pending
            ? undefined
            : state.remediationReason,
        errors: {
          ...state.errors,
          remediation: undefined,
          remediationReason:
            to === RemediationOutcome.Pending
              ? undefined
              : state.errors?.remediationReason,
        },
      };
    }

    case 'ui.forms/score/REMEDIATION_REASON_CHANGED': {
      return {
        ...state,
        remediationReason: action.payload.to,
        errors: {
          ...state.errors,
          remediationReason: undefined,
        },
      };
    }

    case 'ui.forms/score/SCORE_CHANGED': {
      return {
        ...state,
        score: action.payload.score,
      };
    }

    case 'ui.forms/score/REMEDIATION_REASON_CHANGED_ERROR': {
      return {
        ...state,
        errors: {
          ...state.errors,
          remediationReason: ERROR_MESSAGE,
        },
      };
    }

    case 'ui.forms/score/REMEDIATION_CHANGED_ERROR': {
      return {
        ...state,
        errors: {
          ...state.errors,
          remediation: ERROR_MESSAGE,
        },
      };
    }

    case 'ui.forms/score/RESULT_SAVED_ERROR': {
      return {
        ...state,
        errors: {
          ...state.errors,
          overriddenResult: ERROR_MESSAGE,
        },
      };
    }

    case 'ui.forms/score/LOADED_FROM_INSTANCE': {
      const { remediation, remediationReason, overruledResult } =
        action.payload;
      return {
        ...state,
        overruledResult,
        remediation,
        remediationReason,
        errors: undefined,
      };
    }

    default:
      return state;
  }
}
