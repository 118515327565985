import * as jsYaml from 'js-yaml';
import { pipe, sortBy } from 'ramda';
import { parseSignoff } from './parse.signoff.ts';
import { parseActionPlan } from './parse.actionPlan.ts';
import { parseFileUpload } from './parse.fileUpload.ts';
import { parseAttendance } from './parse.attendance.ts';
import { parseDigitalContent } from './parse.digitalContent.ts';
import { parseSection } from './parse.section.ts';
import {
  getProp,
  defaultActionPlanEnabled,
  defaultFileUploadEnabled,
} from '../util/util.data.parse.ts';
import { FormTemplateItemDefinition } from './types/template.types.ts';
import { FormLineComponent } from '../types/types.ts';
import { FormLineById } from '../redux/form-instance/types.ts';
import {
  ActionPlanAreaDefinition,
  AttendanceAreaDefinition,
  DigitalContentAreaDefinition,
  FileUploadAreaDefinition,
  IssueCheckInAreaDefinition,
  IssueCoachingPlanAreaDefinition,
  IssueSummaryAreaDefinition,
  SectionAreaDefinition,
  SignoffAreaDefinition,
} from './types/parse.types.ts';
import { parseIssueSummary } from './parse.issueSummary.ts';
import { parseIssueCoachingPlan } from './parse.issueCoachingPlan.ts';
import { parseIssueCheckIn } from './parse.issueCheckIn.ts';

/**
 * Parses the given YAML into a set of Form line definitions.
 */
export function parseYaml(yaml: string) {
  return parseItems(sanitizeAndConvertYamlToJson(yaml));
}

const MATCH_INVALID_CHARS_RANGE = /[^\x20-\x7E]/gim; //ASCII printable characters
const MATCH_TAB_CHARS = /\\t/g;

const sanitizeAndConvertYamlToJson = (yaml: string) => {
  const json = jsYaml.load(yaml);
  const jsonStringify = JSON.stringify(json)
    .replace(MATCH_INVALID_CHARS_RANGE, '')
    .replace(MATCH_TAB_CHARS, ' ');

  return JSON.parse(jsonStringify);
};

const parsers: {
  [key: string]: (
    item: FormTemplateItemDefinition,
    index: number,
  ) => FormLineComponent | FormLineComponent[] | undefined;
} = {
  section: (item: SectionAreaDefinition, index: number) => {
    const props = getProp(item, 'section');
    return props && parseSection(index, props);
  },

  actionPlan: (item: ActionPlanAreaDefinition) => {
    const props = getProp(item, 'actionPlan');
    return props && parseActionPlan(props);
  },

  fileUpload: (item: FileUploadAreaDefinition) => {
    const props = getProp(item, 'fileUpload');
    return props && parseFileUpload(props);
  },
  signoff: (item: SignoffAreaDefinition) => {
    const props = getProp(item, 'signoff');
    return props && parseSignoff(props);
  },
  attendance: (item: AttendanceAreaDefinition) => {
    const props = getProp(item, 'attendance');
    return props && parseAttendance(props);
  },
  digitalContent: (item: DigitalContentAreaDefinition) => {
    const props = getProp(item, 'digitalContent');
    return props && parseDigitalContent(props);
  },
  issueCheckIn: (item: IssueCheckInAreaDefinition) => {
    const props = getProp(item, 'issueCheckIn');
    return props && parseIssueCheckIn();
  },
  issueCoachingPlan: (item: IssueCoachingPlanAreaDefinition) => {
    const props = getProp(item, 'issueCoachingPlan');
    return props && parseIssueCoachingPlan();
  },
  issueSummary: (item: IssueSummaryAreaDefinition) => {
    const props = getProp(item, 'issueSummary');
    return props && parseIssueSummary(props);
  },
};

/**
 * Puts the form lines into a specific order, as some can be defined at any point or order
 * in the yaml (e.g. signoff), while others are also completely optional (e.g. file upload).
 */
const sortItems = (
  items: FormTemplateItemDefinition[],
): FormTemplateItemDefinition[] => {
  if (!items || !items.length) return [];

  const createItemsWithDefaults = () =>
    pipe(defaultActionPlanEnabled, defaultFileUploadEnabled)(items);

  const sortList: { [key: string]: number } = {
    digitalContent: 2,
    fileUpload: 3,
    actionPlan: 4,
    attendance: 5,
    signoff: 6,
  };

  return sortBy(
    (item: any) => item.sortOrder,
    createItemsWithDefaults().map((item: FormTemplateItemDefinition) => {
      const sortIndex = sortList[Object.keys(item)?.[0]] ?? 1;
      return { ...item, sortOrder: sortIndex };
    }),
  );
};

function parseItems(items: any[]): FormLineById {
  if (!items || !Array.isArray(items)) {
    return {};
  }

  return sortItems(items)
    .map((item, index) =>
      Object.keys(parsers)
        .map((parser) => parsers[parser](item, index))
        .flat(),
    )
    .flat()
    .filter(Boolean)
    .reduce((acc, line) => ({ ...acc, [line.id]: line }), {});
}
