import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderBy } from '@seeeverything/ui.util/src/types.ts';
import { DistributionListItem } from './types.ts';

export type DistributionListState = {
  currentPage: number;
  error: boolean;
  hasNextPage: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  listItems: DistributionListItem[];
  orderBy: OrderBy;
  showAll: boolean;
};

const DEFAULT_STATE: DistributionListState = {
  listItems: [],
  isLoading: false,
  isLoadingMore: false,
  error: false,
  hasNextPage: false,
  currentPage: 1,
  showAll: false,
  orderBy: {
    fieldName: 'createdAt',
    direction: 'Descending',
  },
};

const slice = createSlice({
  name: 'libs/forms/distributionList',
  initialState: DEFAULT_STATE,
  reducers: {
    addedDistributionList(state) {
      state.isLoading = false;
      state.error = false;
    },
    showInactiveDistributionLists(
      state,
      action: PayloadAction<{ showAll: boolean }>,
    ) {
      state.error = false;
      state.showAll = action.payload.showAll;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadDistributionList(state, action: PayloadAction<{ id: string }>) {
      state.isLoading = true;
      state.isLoadingMore = false;
      state.error = false;
    },
    loadDistributionListError(state) {
      state.isLoading = true;
      state.isLoadingMore = false;
      state.error = true;
    },
    loadedDistributionList(
      state,
      action: PayloadAction<{ item: DistributionListItem }>,
    ) {
      state.listItems = state.listItems.map((existing) =>
        existing.id === action.payload.item.id ? action.payload.item : existing,
      );
      state.isLoading = false;
      state.isLoadingMore = false;
      state.error = false;
    },
    loadDistributionLists(
      state,
      action: PayloadAction<{ loadNextPage: boolean }>,
    ) {
      const { loadNextPage } = action.payload;
      state.isLoading = !loadNextPage;
      state.isLoadingMore = loadNextPage;
      state.error = false;
    },
    loadedDistributionLists(
      state,
      action: PayloadAction<{
        listItems: DistributionListItem[];
        hasNextPage: boolean;
        currentPage: number;
      }>,
    ) {
      const { hasNextPage, currentPage, listItems } = action.payload;

      if (currentPage === 1) state.listItems = listItems;
      else state.listItems.push(...listItems);

      state.hasNextPage = hasNextPage;
      state.currentPage = currentPage;
      state.isLoading = false;
      state.isLoadingMore = false;
      state.error = false;
    },
    loadDistributionListsFailed(state) {
      state.isLoading = false;
      state.isLoadingMore = false;
      state.error = true;
    },
    sortDistributionLists(state, action: PayloadAction<{ orderBy: OrderBy }>) {
      state.orderBy = action.payload.orderBy;
    },
    updatedDistributionList(
      state,
      action: PayloadAction<{ item: DistributionListItem }>,
    ) {
      state.isLoading = false;
      state.error = false;
      state.listItems = state.listItems.map((existing) =>
        existing.id === action.payload.item.id ? action.payload.item : existing,
      );
    },
  },
});

export const {
  addedDistributionList,
  loadDistributionList,
  loadDistributionListError,
  loadDistributionLists,
  loadDistributionListsFailed,
  loadedDistributionList,
  loadedDistributionLists,
  showInactiveDistributionLists,
  sortDistributionLists,
  updatedDistributionList,
} = slice.actions;
export const reducer = slice.reducer;
export type State = DistributionListState;
