/** @jsxImportSource @emotion/react */
import { useUtilSelector } from '@seeeverything/ui.util/src/redux/store.ts';
import { TenantFeatures } from '@seeeverything/ui.util/src/redux/tenant/types.ts';

type FeatureSelector<T> = (selector: TenantFeatures) => T;
export const useTenantFeature = <T>(selector: FeatureSelector<T>) => {
  const tenantFeatures = useUtilSelector(
    (state) => state.tenantState.tenant.features,
  );

  return selector(tenantFeatures);
};
