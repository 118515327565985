/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import React from 'react';
import { CSSProperties } from '../../types/types.ts';
import {
  Button,
  ButtonClickEventHandler,
  IInnerButtonProps,
} from '../Button/Button.tsx';
import { IIcon } from '../Icon/types.ts';
import { Text } from '../Text/Text.tsx';
import { DropdownPopup } from './components/DropdownPopup.tsx';
import {
  ILabelButtonDropdown,
  LabelButtonDropdownClickEventHandler,
  LabelButtonSize,
  LabelButtonTheme,
} from './types.ts';

export interface ILabelButtonProps {
  backgroundAlways?: boolean;
  bold?: boolean;
  capitalized?: boolean;
  dataTest?: string;
  display?: CSSProperties['display'];
  dropdown?: ILabelButtonDropdown;
  dropdownMargin?: string | number;
  ellipsis?: boolean;
  emptyDropdownMessage?: string;
  highlightTheme?: LabelButtonTheme;
  isEnabled?: boolean;
  isVisible?: boolean;
  label?: React.ReactNode;
  leftIcon?: IIcon;
  margin?: string | number;
  maxWidth?: string | number;
  minWidth?: string | number;
  onClick?: ButtonClickEventHandler;
  onDropdownClick?: LabelButtonDropdownClickEventHandler;
  onDropdownHide?: () => void;
  rightIcon?: IIcon;
  size?: LabelButtonSize;
  theme?: LabelButtonTheme;
  tooltip?: string;
  visibility?: CSSProperties['visibility'];
  width?: string | number;
}

/**
 * A simple button that displays a text label.
 */
export const LabelButton = (props: ILabelButtonProps) => (
  <Button
    dataTest={props.dataTest}
    isEnabled={props.isEnabled}
    onClick={props.onClick}
    display={props.display}
    visibility={props.visibility}
    margin={props.margin}
    width={props.width}
    tooltip={props.tooltip}
  >
    <Inner data={props} />
  </Button>
);

/**
 * Internal visual representation of the button.
 */

export interface IInnerProps extends IInnerButtonProps {
  data: ILabelButtonProps;
}

function Inner(props: IInnerProps) {
  const { data, isOver } = props;
  let { isPressed } = props;
  let { theme, size, highlightTheme } = data;
  const {
    label,
    isVisible = true,
    maxWidth,
    minWidth,
    margin,
    backgroundAlways = true,
    bold = true,
    capitalized = true,
    leftIcon,
    rightIcon,
    dropdown,
    ellipsis,
    dropdownMargin,
    emptyDropdownMessage,
  } = data;

  isPressed = dropdown ? true : isPressed;

  // Set defaults.
  theme = theme || 'DARK';
  size = size || 'SMALL';
  highlightTheme = highlightTheme === undefined ? theme : highlightTheme;

  let background: string | undefined;
  let border: string | undefined;
  let borderColor: string | undefined;
  let borderTopColor: string | undefined;
  let borderBottomColor: string | undefined;
  let boxShadow: string | undefined;
  let textColor: string | undefined;
  let textSize: number;
  let paddingTop: number;
  let paddingRight: number;
  let paddingBottom: number;
  let paddingLeft: number;
  let textProps: any;
  let hasBevel: boolean | undefined;
  let iconColor: string | undefined;
  let iconSize: number;
  let iconTop: number;

  const isBgHighlighted = isOver || isPressed;

  switch (theme) {
    case 'WHITE':
      textColor = 'WHITE';
      border = 'solid 2px';
      borderColor = color.format(0.6);
      background = color.format(0.1);
      break;

    case 'DARK':
      textColor = color.format(-0.5);
      textProps = { textShadow: [1, 0.9] };
      border = 'solid 2px';
      borderColor = color.format(-0.1);
      background = color.format(-0.05);
      hasBevel = isBgHighlighted && !isPressed;
      iconColor = color.format(-0.5);
      break;

    case 'GREEN_3D':
      textColor = 'WHITE';
      textProps = { textShadow: [-1, -0.3] };
      border = 'solid 1px';
      borderColor = color.format(-0.1);
      borderTopColor = '#3AA753';
      borderBottomColor = '#318A44';
      background = 'linear-gradient(180deg, #3DCC5D 0%, #30A54A 100%)';
      boxShadow = isPressed
        ? 'inset 0px 4px 8px rgba(0, 0, 0, 0.25)'
        : undefined;
      iconColor = color.format(1);
      break;

    case 'BLUE_3D':
      textColor = 'WHITE';
      textProps = { textShadow: [-1, -0.3] };
      border = 'solid 1px';
      borderColor = color.format(-0.1);
      borderTopColor = '#527FD8';
      borderBottomColor = '#4971C8';
      background = 'linear-gradient(180deg, #659CF9 0%, #527FD8 100%)';
      boxShadow = isPressed
        ? 'inset 0px 4px 8px rgba(0, 0, 0, 0.25)'
        : undefined;
      iconColor = color.format(1);
      break;

    case 'ERROR':
      background = color.format(-0.05);
      border = 'solid 1px';
      borderColor = COLORS.ERROR_RED;
      hasBevel = isBgHighlighted && !isPressed;
      iconColor = COLORS.ERROR_RED;
      textColor = COLORS.ERROR_RED;
      textProps = { textShadow: [1, 0.9] };
      break;

    default:
      throw new Error(`Theme '${theme}' not supported.`);
  }

  if (isBgHighlighted) {
    switch (highlightTheme) {
      case 'WHITE':
        borderColor = color.format(1);
        background = color.format(0.2);
        break;

      case 'DARK':
        textColor = 'WHITE';
        textProps = { textShadow: [-1, -0.2] };
        borderColor = 'transparent';
        background = COLORS.BLUE;
        iconColor = 'WHITE';
        break;

      case 'GREEN_3D':
        textColor = 'WHITE';
        background = 'linear-gradient(180deg, #46CD64 0%, #3BAC54 100%)';
        break;

      case 'BLUE_3D':
        textColor = 'WHITE';
        background = 'linear-gradient(180deg, #70A6FA 0%, #608CDC 100%)';
        break;

      case 'ERROR':
        textColor = 'WHITE';
        textProps = { textShadow: [-1, -0.2] };
        background = COLORS.BLUE;
        break;

      default:
        throw new Error(`Theme '${theme}' not supported.`);
    }
  }

  // Clear the background color if not required.
  if (!backgroundAlways && !isOver) {
    background = undefined;
    borderColor = 'transparent';
  }

  switch (size) {
    case 'SMALL':
      textSize = 12;
      paddingTop = 5;
      paddingBottom = 4;
      paddingLeft = leftIcon ? 33 : 15;
      paddingRight = rightIcon ? 33 : 15;
      iconSize = 20;
      iconTop = -4;
      break;

    case 'MEDIUM':
      textSize = 14;
      paddingTop = 7;
      paddingBottom = 7;
      paddingLeft = leftIcon ? 45 : 30;
      paddingRight = rightIcon ? 45 : 30;
      iconSize = 22;
      iconTop = -3;
      break;

    default:
      throw new Error(`Size '${size}' not supported.`);
  }

  iconTop = isPressed ? iconTop + 1 : iconTop;
  const styles = {
    base: css({
      boxSizing: 'border-box',
      position: 'relative',
      visibility: isVisible ? 'visible' : 'hidden',
      maxWidth,
      minWidth,
      margin,
      paddingTop,
      paddingBottom,
      background,
      border: `${border} ${borderColor}`,
      borderTopColor,
      borderBottomColor,
      borderRadius: 5,
      cursor: 'pointer',
      textAlign: 'center',
      boxShadow,
    }),
    content: css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      paddingLeft,
      paddingRight,
    }),
    text: css({
      transform: isPressed ? `translateY(1px)` : undefined,
      textTransform: capitalized ? 'uppercase' : undefined,
    }),
    bevelTop: css({
      position: 'absolute',
      inset: '-1px 0 auto 0',
      height: 3,
      borderTop: `solid 1px ${color.format(0.2)}`,
      borderRadius: 4,
    }),
    iconLeft: css({
      position: 'absolute',
      top: iconTop,
      height: iconSize,
      left: 3,
    }),
    iconRight: css({
      position: 'absolute',
      top: iconTop,
      height: iconSize,
      right: 3,
    }),
  };

  const iconProps = { fill: iconColor, size: iconSize };
  const elLeftIcon = leftIcon && (
    <div css={styles.iconLeft}>{leftIcon(iconProps)}</div>
  );
  const elRightIcon = rightIcon && (
    <div css={styles.iconRight}>{rightIcon(iconProps)}</div>
  );

  const elDropdown = dropdown && (
    <DropdownPopup
      items={dropdown.items}
      width={dropdown.width}
      onClick={data.onDropdownClick}
      onHide={data.onDropdownHide}
      margin={dropdownMargin}
      emptyMessage={emptyDropdownMessage}
    />
  );
  return (
    <div css={styles.base}>
      {hasBevel && <div css={styles.bevelTop} />}
      <div css={styles.content}>
        {elLeftIcon}
        <Text
          color={textColor}
          size={textSize}
          selectable={false}
          weight={bold ? FontWeight.bold : FontWeight.normal}
          style={styles.text}
          ellipsis={ellipsis}
          {...textProps}
        >
          {label || 'Untitled'}
        </Text>
        {elRightIcon}
      </div>
      {elDropdown}
    </div>
  );
}
