import { recursiveFetchAll } from '@seeeverything/ui.util/src/graphql/graphql/recursiveFetchAll.ts';
import { PageInfoResponse } from '@seeeverything/ui.util/src/types.ts';
import gql from 'graphql-tag';
import { FormAction, FormGoal } from '../types.ts';
import { QueryOptions, QueryVariables } from './types.ts';

type GoalsResponse = {
  pageInfo: PageInfoResponse;
  goals: FormGoal[];
};

type ServerResponse = {
  forms: {
    formGoals: {
      pageInfo: PageInfoResponse;
      nodes: FormGoal[];
    };
  };
};

const createGoalMapFunction = (
  variables: QueryVariables,
  actionLabel: string,
) => {
  const orderBys = variables.orderBy;
  const excludeStatuses = variables.status.exclude ?? [];

  return (node: FormGoal): FormGoal => {
    const actions = (node.actions ?? [])
      .filter(({ status }) => !excludeStatuses.includes(status))
      .map(
        (action): FormAction => ({
          ...action,
          kind: 'GOAL_CHILD',
          type: 'FormAction',
          typeLabel: actionLabel,
          statusLabel: action.status,
        }),
      );

    const sortedActions = actions.sort((a, b) => {
      for (const field of orderBys) {
        const fieldName =
          field.fieldName === 'type'
            ? 'typeLabel'
            : (field.fieldName as keyof FormAction);

        if (a[fieldName] < b[fieldName])
          return field.direction === 'Ascending' ? -1 : 1;

        if (a[fieldName] > b[fieldName])
          return field.direction === 'Ascending' ? 1 : -1;
      }
      return 0;
    });

    return {
      ...node,
      type: 'FormGoal',
      typeLabel: node.goalCategory?.name ?? 'Goal',
      statusLabel: node.status,
      actions: sortedActions,
    };
  };
};

export const queryAllGoalsPages = async (
  options: QueryOptions,
): Promise<GoalsResponse> => {
  const { client, variables, actionLabel } = options;
  const allPages = await recursiveFetchAll<ServerResponse>(
    {
      client,
      query,
      variables,
      pageSize: 100,
    },
    (response) => response.forms.formGoals.pageInfo.hasNextPage,
  );
  const toGoal = createGoalMapFunction(variables, actionLabel);

  return allPages.reduce(
    (acc: GoalsResponse, page): GoalsResponse => ({
      pageInfo: page.forms.formGoals.pageInfo,
      goals: (acc?.goals ?? []).concat(page.forms.formGoals.nodes.map(toGoal)),
    }),
    undefined,
  );
};

export const queryGoalsPage = async (
  options: QueryOptions,
): Promise<GoalsResponse> => {
  const { client, variables, actionLabel } = options;
  const response = await client.query<ServerResponse>({
    query,
    variables: { ...variables, pageNumber: 1, pageSize: 10 },
    fetchPolicy: 'network-only',
  });

  const toGoal = createGoalMapFunction(variables, actionLabel);

  return {
    pageInfo: response.data.forms.formGoals.pageInfo,
    goals: response.data.forms.formGoals.nodes?.map(toGoal),
  };
};

const query = gql`
  query FormGoals(
    $orderBy: [OrderByInput!]
    $pageNumber: Int
    $pageSize: Int
    $personId: ID
    $teamId: ID
    $startDate: String
    $endDate: String
    $status: FormGoalStatusFilterInput!
  ) {
    forms {
      formGoals(
        assignedToPersonId: $personId
        assignedToTeamId: $teamId
        orderBy: $orderBy
        pagination: { size: $pageSize, pageNumber: $pageNumber }
        startDate: $startDate
        endDate: $endDate
        status: $status
      ) {
        pageInfo {
          currentPage
          hasNextPage
          pageSize
          totalCount
          totalPages
        }
        nodes {
          id
          actions {
            id
            assignedTo {
              id
              name
            }
            createdAt
            description
            dueBy
            status
          }
          assignedTo {
            id
            name
          }
          createdAt
          description
          dueBy
          goalCategory {
            id
            name
          }
          status
        }
      }
    }
  }
`;
