/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export interface IFileNameProps {
  name: string;
  size: string;
  style?: SerializedStyles;
}

const FileName: React.FC<IFileNameProps> = ({ name, size, style }) => (
  <div css={[styles.fileName, style]}>
    <Text
      size={13}
      weight={600}
      color={color.format(-0.6)}
      ellipsis={true}
      block={true}
      tooltip={`${name} — ${size}`}
    >
      {name}
    </Text>
  </div>
);

const styles = {
  fileName: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '30px 1 0',
    maxWidth: 135,
  }),
};

export default React.memo(FileName);
