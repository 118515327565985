import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ClassificationsDefinition,
  FormIssue,
  FormIssueCause,
  IssueCause,
  IssueClassification,
} from '@se/data/forms/types.ts';
import { ascend, prop, sortWith } from 'ramda';
import { FormAnswer } from '../../types/types.ts';

export type ClassificationValueSelection = {
  value: string;
  checked: boolean;
};

export type CoachingRequirement = 'MANDATORY' | 'RECOMMENDED' | 'NONE';

export type IssueNotes = {
  required: boolean;
  hidden: boolean;
  label: string;
};

type LoadedIssuesPayload = {
  issues: FormIssue[];
  issueCauses: IssueCause[];
  canView: boolean;
};

type CreateIssuePayload = {
  id: string;
  actionsRequired?: boolean;
  answerId: string;
  classificationOptions: ClassificationsDefinition;
  classifications: IssueClassification[];
  coachingRequirement: CoachingRequirement;
  formAnswerSelected: string;
  formLineId: string;
  formQuestionIndex: number;
  formQuestionText: string;
  formSectionName: string;
  issueCoachingRequired: boolean;
  issueNotesLabel?: string;
  label: string;
  noActionsRequiredReason?: string;
  notes?: string;
  notesLabel?: string;
  notesRequired?: boolean;
  parentSectionLineId: string;
};

type CoachingErrorsPayload = {
  actionsRequiredSelection?: string;
  coachingConversation?: string;
  coachingIssueAction?: string;
  issueId: string;
  noActionsReason?: string;
  noOpenActionsWithNoActionSelected?: string;
  primaryCause?: string;
  primaryCauseNotes?: string;
};

type ComplianceErrorsPayload = {
  answerId: string;
  classification?: string;
  notes?: string;
};

type AddClassificationPayload = {
  answerId: string;
  instanceId: string;
  classificationValue: string;
  itemIndex: number;
  concurrencyId: string;
};

type DeleteClassificationPayload = {
  answerId: string;
  instanceId: string;
  classificationValue: string;
  concurrencyId: string;
};

type SaveClassificationsPayload = {
  answerId: string;
  instanceId: string;
  concurrencyId: string;
};

type SetNotesPayload = {
  answerId: string;
  instanceId: string;
  notes: string;
  concurrencyId: string;
};

type SetCoachingConversationPayload = {
  issueId: string;
  instanceId: string;
  coachingConversation: string;
};

type AddCoachingIssueActionToIssuePayload = {
  issueId: string;
  instanceId: string;
};

type UpdateCoachingIssueActionOnIssuePayload = {
  issueId: string;
  instanceId: string;
};

type InstanceIssuePayload = {
  issueId: string;
  instanceId: string;
};

type SaveSecondaryCausesPayload = {
  updatedCauses: FormIssueCause[];
  issueId: string;
  instanceId: string;
};

type SavedSecondaryCausesPayload = SaveSecondaryCausesPayload;

type SetPrimaryIssueCausePayload = {
  issueId: string;
  index: number;
  issueCauseId: string;
  label: string;
  notes: string;
  instanceId: string;
};

type SetPrimaryIssueCauseDetailPayload = {
  issueId: string;
  notes: string;
  instanceId: string;
};

type SetPrimaryCauseAdditionalActionPayload = {
  issueId: string;
  instanceId: string;
  actionsRequired: boolean;
};

type SetPrimaryCauseNoActionsReasonPayload = {
  issueId: string;
  instanceId: string;
  noActionsReason: string;
};

const CoachingRequirementOrder = {
  MANDATORY: 1,
  RECOMMENDED: 2,
  NONE: 3,
};

const sortIssues = sortWith<FormIssue>([
  ascend((issue) => CoachingRequirementOrder[issue.coaching.requirement]),
  ascend(prop('formQuestionIndex')),
  ascend(prop('label')),
]);

const sortCauses = sortWith<FormIssueCause>([ascend((cause) => cause.index)]);

const sortClassifications = sortWith<IssueClassification>([
  ascend(prop('index')),
]);

export type IssueState = {
  isLoading: boolean;
  issues: FormIssue[];
  canView?: boolean;
  loadError?: boolean;
  issueCauses: IssueCause[];
};

const DEFAULT_STATE: IssueState = {
  isLoading: false,
  issues: [],
  issueCauses: [],
};

const slice = createSlice({
  name: 'libs/forms/issue',
  initialState: DEFAULT_STATE,
  reducers: {
    addClassification(state, action: PayloadAction<AddClassificationPayload>) {
      const issue = state.issues.find(
        ({ answerId }) => answerId === action.payload.answerId,
      );
      if (!issue) return;

      const existingClassification = issue.classifications.some(
        (existing) => existing.value === action.payload.classificationValue,
      );
      if (existingClassification) return;

      issue.classifications.push({
        index: action.payload.itemIndex,
        value: action.payload.classificationValue,
      });
      issue.classifications = sortClassifications(issue.classifications);
      issue.errors.compliance.classifications = undefined;
    },
    answerChangedWithNewIssueId(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        instanceId: string;
        toAnswer: FormAnswer;
        issueId: string;
      }>,
    ) {
      return state;
    },
    coachingErrors(state, action: PayloadAction<CoachingErrorsPayload>) {
      const {
        actionsRequiredSelection,
        coachingConversation,
        coachingIssueAction,
        issueId,
        noActionsReason,
        noOpenActionsWithNoActionSelected,
        primaryCause,
        primaryCauseNotes,
      } = action.payload;
      const issue = state.issues.find(({ id }) => id === issueId);
      if (!issue) return;

      const errors = issue.errors.coaching;

      if (actionsRequiredSelection)
        errors.actionsRequiredSelection = actionsRequiredSelection;
      if (coachingConversation)
        errors.coachingConversation = coachingConversation;
      if (coachingIssueAction) errors.coachingIssueAction = coachingIssueAction;
      if (noActionsReason) errors.noActionsReason = noActionsReason;
      if (noOpenActionsWithNoActionSelected)
        errors.noOpenActionsWithNoActionSelected =
          noOpenActionsWithNoActionSelected;
      if (primaryCause) errors.primaryCause = primaryCause;
      if (primaryCauseNotes) errors.primaryCauseNotes = primaryCauseNotes;
    },
    complianceErrors(state, action: PayloadAction<ComplianceErrorsPayload>) {
      const issue = state.issues.find(
        ({ answerId }) => answerId === action.payload.answerId,
      );
      if (!issue) return;

      if (action.payload.classification)
        issue.errors.compliance.classifications = action.payload.classification;

      if (action.payload.notes)
        issue.errors.compliance.notes = action.payload.notes;
    },
    existingIssueForAnswerIdLoading(
      state,
      action: PayloadAction<{ answerId: string }>,
    ) {
      const issue = state.issues.find(
        ({ answerId }) => answerId === action.payload.answerId,
      );
      if (!issue) return;
      issue.isLoading = true;
    },
    addIssue(state, action: PayloadAction<CreateIssuePayload>) {
      const newIssue = action.payload;
      state.issues.push({
        id: newIssue.id,
        answerId: newIssue.answerId,
        coaching: {
          requirement: newIssue.coachingRequirement,
          specifyCauseAndCoaching: {
            isSelected: newIssue.coachingRequirement === 'MANDATORY',
            canChange: newIssue.coachingRequirement === 'RECOMMENDED',
          },
        },
        classificationOptions: newIssue.classificationOptions,
        classifications: newIssue.classifications,
        errors: { coaching: {}, compliance: {} },
        formAnswerSelected: newIssue.formAnswerSelected,
        formLineId: newIssue.formLineId,
        formQuestionIndex: newIssue.formQuestionIndex,
        formQuestionText: newIssue.formQuestionText,
        formSectionName: newIssue.formSectionName,
        issueCoachingRequired: newIssue.issueCoachingRequired,
        issueCauses: { isSpinning: false, causes: [] },
        label: newIssue.label,
        notes: newIssue.notes,
        notesLabel: newIssue.notesLabel,
        notesRequired: newIssue.notesRequired,
        parentSectionLineId: newIssue.parentSectionLineId,
        isLoading: false,
      });

      state.issues = sortIssues(state.issues);
      state.isLoading = false;
    },
    addCoachingIssueActionToIssue(
      state,
      action: PayloadAction<AddCoachingIssueActionToIssuePayload>,
    ) {
      const issue = state.issues.find(
        (formIssue) => formIssue.id === action.payload.issueId,
      );
      if (!issue) return;
      issue.coaching.specifyCauseAndCoaching.canChange = false;

      issue.errors.coaching.coachingIssueAction = undefined;
    },
    updateCoachingIssueActionOnIssue(
      state,
      action: PayloadAction<UpdateCoachingIssueActionOnIssuePayload>,
    ) {
      const issue = state.issues.find(
        ({ id }) => id === action.payload.issueId,
      );
      if (!issue) return;

      issue.coaching.specifyCauseAndCoaching.canChange = false;
      issue.errors.coaching.noOpenActionsWithNoActionSelected = undefined;
      issue.errors.coaching.coachingIssueAction = undefined;
    },
    deleteClassification(
      state,
      action: PayloadAction<DeleteClassificationPayload>,
    ) {
      const issue = state.issues.find(
        ({ answerId }) => answerId === action.payload.answerId,
      );
      if (!issue) return;

      issue.classifications = issue.classifications.filter(
        (existing) => existing.value !== action.payload.classificationValue,
      );

      issue.errors.compliance.classifications = undefined;
    },
    loadError(state) {
      state.isLoading = false;
      state.loadError = true;
    },
    loadedIssues(state, action: PayloadAction<LoadedIssuesPayload>) {
      state.isLoading = false;
      state.loadError = false;
      state.issues = sortIssues(action.payload.issues ?? []);
      state.issues.forEach((issue) => {
        issue.issueCauses.causes = sortCauses(issue.issueCauses.causes);
        issue.classifications = sortClassifications(issue.classifications);
      });
      state.issueCauses = action.payload.issueCauses ?? [];
      state.canView = action.payload.canView;
    },
    clearIssues(state) {
      state.isLoading = false;
      state.issues = [];
      state.issueCauses = [];
    },
    loadIssues(state) {
      state.isLoading = true;
      state.issues = [];
      state.issueCauses = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadIssueCoachingPlan(state, _: PayloadAction<{ instanceId: string }>) {
      return state;
    },
    removeIssueByAnswerId(state, action: PayloadAction<{ answerId: string }>) {
      state.issues = state.issues.filter(
        ({ answerId }) => answerId !== action.payload.answerId,
      );
    },
    saveClassifications(
      state,
      action: PayloadAction<SaveClassificationsPayload>,
    ) {
      const issue = state.issues.find(
        ({ answerId }) => answerId === action.payload.answerId,
      );
      if (!issue) return;

      issue.errors.compliance.classifications = undefined;
    },
    setNotes(state, action: PayloadAction<SetNotesPayload>) {
      const issue = state.issues.find(
        ({ answerId }) => answerId === action.payload.answerId,
      );
      if (!issue) return;

      issue.notes = action.payload.notes;
      issue.errors.compliance.notes = undefined;
    },
    setCoachingConversation(
      state,
      action: PayloadAction<SetCoachingConversationPayload>,
    ) {
      const issue = state.issues.find(
        ({ id }) => id === action.payload.issueId,
      );
      if (!issue) return;
      issue.coachingConversation = action.payload.coachingConversation ?? '';
      issue.errors.coaching.coachingConversation = undefined;
    },
    saveSecondaryCauses(
      state,
      action: PayloadAction<SaveSecondaryCausesPayload>,
    ) {
      const issue = state.issues.find(
        ({ id }) => id === action.payload.issueId,
      );
      if (!issue) return;

      issue.issueCauses.isSpinning = true;
    },
    savedSecondaryCauses(
      state,
      action: PayloadAction<SavedSecondaryCausesPayload>,
    ) {
      const issue = state.issues.find(
        ({ id }) => id === action.payload.issueId,
      );
      if (!issue) return;

      const existingPrimaryCause = issue.issueCauses.causes.find(
        (cause) => cause.isPrimary,
      );
      issue.issueCauses.isSpinning = false;
      issue.issueCauses.causes = sortCauses(
        [...action.payload.updatedCauses, existingPrimaryCause].filter(Boolean),
      );
    },
    saveErrorSecondaryCauses(
      state,
      action: PayloadAction<{ issueId: string }>,
    ) {
      const issue = state.issues.find(
        ({ id }) => id === action.payload.issueId,
      );
      if (!issue) return;
      issue.issueCauses.isSpinning = false;
    },
    setPrimaryCause(state, action: PayloadAction<SetPrimaryIssueCausePayload>) {
      const issue = state.issues.find(
        ({ id }) => id === action.payload.issueId,
      );
      if (!issue) return;

      issue.errors.coaching.primaryCause = undefined;
      issue.coaching.specifyCauseAndCoaching.canChange = false;

      // Removes existing different primary cause.
      issue.issueCauses.causes = issue.issueCauses.causes.filter(
        (cause) => !cause.isPrimary,
      );

      // Updates existing secondary cause to be primary.
      const existingCause = issue.issueCauses.causes.find(
        (cause) => cause.issueCauseId === action.payload.issueCauseId,
      );

      if (existingCause) {
        existingCause.isPrimary = true;
        existingCause.notes = action.payload.notes;
      } else {
        issue.issueCauses.causes.push({
          index: action.payload.index,
          issueCauseId: action.payload.issueCauseId,
          label: action.payload.label,
          notes: action.payload.notes,
          isPrimary: true,
          isSelected: true,
        });
      }

      issue.issueCauses.causes = sortCauses(issue.issueCauses.causes);
    },
    setPrimaryCauseNotes(
      state,
      action: PayloadAction<SetPrimaryIssueCauseDetailPayload>,
    ) {
      const issue = state.issues.find(
        ({ id }) => id === action.payload.issueId,
      );
      if (!issue) return;
      issue.errors.coaching.primaryCauseNotes = undefined;
      issue.coaching.specifyCauseAndCoaching.canChange = false;

      const issueCause = issue.issueCauses.causes.find(
        ({ isPrimary }) => isPrimary,
      );
      if (!issueCause) return;
      issueCause.notes = action.payload.notes;
    },
    setPrimaryCauseAdditionalAction(
      state,
      action: PayloadAction<SetPrimaryCauseAdditionalActionPayload>,
    ) {
      const issue = state.issues.find(
        ({ id }) => id === action.payload.issueId,
      );
      if (!issue) return;

      const actionsRequired = action.payload.actionsRequired;

      issue.actionsRequired = actionsRequired;
      issue.coaching.specifyCauseAndCoaching.canChange = false;

      issue.errors.coaching.actionsRequiredSelection = undefined;
      issue.errors.coaching.coachingIssueAction = undefined;
      issue.errors.coaching.noActionsReason = undefined;
      issue.errors.coaching.noOpenActionsWithNoActionSelected = undefined;

      if (actionsRequired) issue.noActionsReason = undefined;
    },
    setPrimaryCauseNoActionsReason(
      state,
      action: PayloadAction<SetPrimaryCauseNoActionsReasonPayload>,
    ) {
      const issue = state.issues.find(
        ({ id }) => id === action.payload.issueId,
      );
      if (!issue) return;
      issue.noActionsReason = action.payload.noActionsReason ?? '';
      issue.errors.coaching.noActionsReason = undefined;
    },
    displaySecondaryCausesDialog(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _: PayloadAction<InstanceIssuePayload>,
    ) {
      return state;
    },
    hideSecondaryCausesDialog(state) {
      return state;
    },
    toggleDefineCauseAndCoachingSelected(
      state,
      action: PayloadAction<InstanceIssuePayload>,
    ) {
      const issue = state.issues.find(
        ({ id }) => id === action.payload.issueId,
      );
      if (!issue) return;

      if (!issue.coaching.specifyCauseAndCoaching.canChange) return;
      issue.coaching.specifyCauseAndCoaching.isSelected =
        !issue.coaching.specifyCauseAndCoaching.isSelected;
      issue.errors.coaching = {};
    },
  },
});

export const {
  addClassification,
  addCoachingIssueActionToIssue,
  addIssue,
  answerChangedWithNewIssueId,
  clearIssues,
  coachingErrors,
  complianceErrors,
  deleteClassification,
  displaySecondaryCausesDialog,
  existingIssueForAnswerIdLoading,
  hideSecondaryCausesDialog,
  loadedIssues,
  loadError,
  loadIssueCoachingPlan,
  loadIssues,
  removeIssueByAnswerId,
  saveClassifications,
  savedSecondaryCauses,
  saveErrorSecondaryCauses,
  saveSecondaryCauses,
  setCoachingConversation,
  setNotes,
  setPrimaryCause,
  setPrimaryCauseAdditionalAction,
  setPrimaryCauseNoActionsReason,
  setPrimaryCauseNotes,
  toggleDefineCauseAndCoachingSelected,
  updateCoachingIssueActionOnIssue,
} = slice.actions;
export const reducer = slice.reducer;
export type State = IssueState;
