import base64 from 'base-64';
import * as R from 'ramda';
import { replaceWordChars } from './str.replaceWordChars.ts';

const base64regex =
  /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

/**
 * Simple utility function to test if a string is base64 encoded or not.
 */
export function isBase64Encoded(str: string) {
  if (!R.is(String, str) || !str || str.length === 0) {
    return false;
  }

  return base64regex.test(str);
}

export function decodeBase64(str: string, regexTest = true) {
  if (regexTest && !isBase64Encoded(str)) return undefined;

  return base64.decode(str);
}

type Options = {
  shouldReplaceWordChars?: boolean;
  removeQuestionMarks?: boolean;
};

export function encodeBase64(
  input: string,
  { shouldReplaceWordChars = false, removeQuestionMarks = false }: Options = {},
) {
  let toEncode = shouldReplaceWordChars ? replaceWordChars(input) : input;
  if (removeQuestionMarks) toEncode = toEncode.replace(/\?/g, '');
  return base64.encode(toEncode);
}
