import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import {
  FormsBulkUploadValidateFileError,
  FormsBulkUploadValidateFileUploadRow,
  FormsBulkUploadWorkflowType,
} from '../types.ts';

type GetFormsBulkUploadValidateArgs = {
  file: File;
  workflow: FormsBulkUploadWorkflowType;
  createAutomatedActions: boolean;
};

export type GetFormsBulkUploadValidateResponseDto = {
  errors: FormsBulkUploadValidateFileError[];
  rows: FormsBulkUploadValidateFileUploadRow[];
};

export const getFormsBulkUploadValidate = async (
  client: IGraphQLClient,
  args: GetFormsBulkUploadValidateArgs,
): Promise<QueryResult<GetFormsBulkUploadValidateResponseDto>> => {
  try {
    const response = await client.query<{
      forms: {
        formsBulkUploadValidate: {
          errors: FormsBulkUploadValidateFileError[];
          rows: FormsBulkUploadValidateFileUploadRow[];
        };
      };
    }>({
      query: gql`
        query FormsBulkUploadValidate(
          $file: Upload!
          $workflowType: FormsBulkUploadWorkflowType!
          $createAutomatedActions: Boolean!
        ) {
          forms {
            formsBulkUploadValidate(
              file: $file
              jobType: Create
              workflowType: $workflowType
              createAutomatedActions: $createAutomatedActions
            ) {
              errors {
                code
                payload {
                  key
                  value
                }
              }
              rows {
                rowNumber
                associatedEntities {
                  assignedTo
                  subject
                  automatedActionIds
                }
                status
                errors {
                  code
                  payload {
                    key
                    value
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        file: args.file,
        workflowType: args.workflow,
        createAutomatedActions: args.createAutomatedActions,
      },
      fetchPolicy: 'network-only',
    });

    const { errors, rows } = response.data.forms.formsBulkUploadValidate;

    if (!rows) {
      log.error('Failed to validate bulk upload file');
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: {
        rows,
        errors,
      },
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to validate bulk upload file - ${error.message}`,
      error,
    );
    return { isSuccess: false, errorReason: 'UNKNOWN', error };
  }
};
