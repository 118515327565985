/** @jsxImportSource @emotion/react */
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { css } from '@emotion/react';

export interface ISectionTitleProps {
  text?: string;
  uppercase?: boolean;
}

/**
 * A block that displays the title of a section.
 */
export const SectionTitle = (props: ISectionTitleProps) => {
  const { text = 'Untitled', uppercase = true } = props;

  return (
    <Text
      style={styles.text}
      uppercase={uppercase}
      size={22}
      weight={FontWeight.bold}
      color={color.format(-0.7)}
    >
      {text}
    </Text>
  );
};

const styles = {
  text: css({
    cursor: 'default',
  }),
};
