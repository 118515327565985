import { FileUploadDefinition } from './types/fileUpload.types.ts';
import { IFileUploadLine } from '../types/types.ts';

export function parseFileUpload(item: FileUploadDefinition): IFileUploadLine {
  return {
    id: 'fileUpload',
    type: 'fileUpload',
    showIndex: item?.isEnabled ?? true,
    title: item?.title ?? 'Files',
    isEnabled: item?.isEnabled ?? true,
    highlightOnHover: false,
  };
}
