/** @jsxImportSource @emotion/react */
import { useDateContext } from '@seeeverything/ui.primitives/src/hooks/useDateContext.ts';
import { getLocale } from '@seeeverything/ui.util/src/redux/tenant/util.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { urlDeepLinkUtil } from '@seeeverything/ui.util/src/urlDeepLink/index.ts';
import { useCallback, useMemo } from 'react';
import { formActionSlice } from '../../redux/formAction/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../redux/store.ts';
import { formatDueBy } from '../../util/util.instance.ts';
import { Issue } from './Issue.tsx';
import { formatReviewActions } from './util.formatReviewActions.ts';

export interface IIssueContainerProps {
  id: string;
  enableActions: boolean;
  showReviewLink?: boolean;
  defaultExpanded?: boolean;
  instanceId: string;
  showCreatedActions: boolean;
}

export const IssueContainer: React.FC<IIssueContainerProps> = ({
  id,
  enableActions,
  showReviewLink,
  defaultExpanded,
  instanceId,
  showCreatedActions,
}) => {
  const dispatch = useFormsDispatch();

  const configuration = useFormsSelector(
    (state) => state.tenantState.tenant.configuration,
  );

  const complianceLocale = useMemo(
    () => getLocale(configuration, 'compliance'),
    [configuration],
  );

  const complianceFormLabel = complianceLocale.label.form;

  const reviewActions = useFormsSelector(
    (state) =>
      state.formIssue.issues.find((issue) => issue.id === id)?.instance
        ?.reviewActions,
  );

  const dateContext = useDateContext();

  const reviewActionsText = useMemo(
    () => formatReviewActions(dateContext.tenantTimezone, reviewActions),
    [dateContext.tenantTimezone, reviewActions],
  );

  const reviewDueDate = useFormsSelector((state) => {
    if (!showReviewLink) return undefined;

    const dueDate = state.formIssue.issues.find((issue) => issue.id === id)
      ?.instance?.dueDate;

    return (
      dueDate &&
      formatDueBy({
        dueBy: dueDate,
        showDistantDates: true,
        capitalized: false,
        timezone: dateContext.tenantTimezone,
      })
    );
  });

  const actionPlan = useFormsSelector(
    (state) => state.formActionPlan.actionPlan.issueCoaching,
  );

  const issueActions = useMemo(() => {
    if (!actionPlan?.plan) return [];
    if (actionPlan.isLoading) return [];
    if (actionPlan.plan.hasSections === true) return [];

    return actionPlan.plan.actions.filter(
      (a) => a.issueId === id && a.status !== 'Cancelled',
    );
  }, [actionPlan, id]);

  const reviewStatus = useFormsSelector((state) => {
    if (!showReviewLink) return undefined;

    const status = state.formIssue.issues.find((issue) => issue.id === id)
      ?.instance?.status;

    return status && str.humanize(status, false, true);
  });

  const reviewName = useFormsSelector((state) => {
    if (!showReviewLink) return undefined;

    return state.formIssue.issues.find((issue) => issue.id === id)?.instance
      ?.name;
  });

  const reviewLink = useFormsSelector((state) => {
    if (!showReviewLink) return undefined;

    const issue = state.formIssue.issues.find(
      (formIssue) => formIssue.id === id,
    );
    if (!issue) return undefined;

    const tenant = state.tenantState.tenant?.id;
    if (!tenant) return undefined;

    const tenantHasCompliance = Boolean(
      state.tenantState.tenant.configuration.modules?.compliance?.isEnabled,
    );
    if (!tenantHasCompliance) return undefined;

    const instanceDetails = issue.instance;
    if (!instanceDetails) return undefined;

    return urlDeepLinkUtil.generateUrlPath({
      tenant,
      module: 'compliance',
      chips: [
        {
          type: 'forms',
          value: instanceDetails.id,
          label: instanceDetails.name,
        },
      ],
    });
  });

  const issue = useFormsSelector((state) =>
    state.formIssue.issues.find((formIssue) => formIssue.id === id),
  );

  const issueActionLabel = useFormsSelector(
    (state) => state.tenantState.tenant?.locale.label.issueAction,
  );

  const openReviewInNewWindow = useCallback(() => {
    if (reviewLink) window.open(reviewLink, '_blank');
  }, [reviewLink]);

  const handleViewAction = useCallback(
    (actionId: string) => {
      dispatch(
        formActionSlice.load({
          actionId,
          source: { type: 'FORM', instanceId },
        }),
      );
    },
    [dispatch, instanceId],
  );

  return (
    <Issue
      actionsCreated={issueActions}
      classifications={issue.classifications}
      coachingRequirement={issue.coaching.requirement}
      complianceFormLabel={complianceFormLabel}
      defaultExpanded={defaultExpanded}
      enableActions={enableActions}
      formAnswerSelected={issue.formAnswerSelected}
      formQuestionText={issue.formQuestionText}
      formSectionName={issue.formSectionName}
      id={issue.id}
      instanceId={instanceId}
      isLoading={issue.isLoading}
      issueActions={issueActions}
      issueActionLabel={issueActionLabel}
      name={issue.label}
      notes={issue.notes}
      notesLabel={issue.notesLabel}
      onLaunchReviewClicked={openReviewInNewWindow}
      onViewAction={handleViewAction}
      reviewActionsText={reviewActionsText}
      reviewDueDate={reviewDueDate}
      reviewName={reviewName}
      reviewStatus={reviewStatus}
      showCreatedActions={showCreatedActions}
    />
  );
};
