import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { TenantConfiguration } from '@seeeverything/ui.util/src/redux/tenant/types.ts';
import { QueryResult } from '../../types.ts';
import { Team } from '../types.ts';
import { GetTeamsArgs, getTeams } from './teams.ts';

type GetTeamHierarchyLevelsArgs = {
  tenantConfig: TenantConfiguration;
  path?: string;
};

const getTeamsRecursive = async (
  client: IGraphQLClient,
  args: GetTeamsArgs,
  teams: Team[] = [],
  pageNumber = 1,
): Promise<Team[]> => {
  const response = await getTeams(client, {
    tenantConfig: args.tenantConfig,
    path: args.path,
    pageNumber,
  });

  if (!response.isSuccess) throw new Error('Failed to retrieve teams');

  const nextTeams = teams.concat(response.data.teams);

  const pageInfo = response.data.pageInfo;
  if (!pageInfo.hasNextPage) return nextTeams;

  return getTeamsRecursive(client, args, nextTeams, pageInfo.currentPage + 1);
};

export const getTeamHierarchyLevels = async (
  client: IGraphQLClient,
  args: GetTeamHierarchyLevelsArgs,
): Promise<QueryResult<number[]>> => {
  try {
    const allTeams = await getTeamsRecursive(client, {
      tenantConfig: args.tenantConfig,
      path: `${args.path}/`,
    });

    return {
      isSuccess: true,
      data: allTeams
        .reduce((levels, team) => {
          const hierarchyLevel = team.hierarchyLevel;
          if (levels.includes(hierarchyLevel)) return levels;
          return levels.concat(hierarchyLevel);
        }, [])
        .sort(),
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query teams for hierarchy levels - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
