import { IDateContext } from '@seeeverything/ui.util/src/react-context/dateContext.ts';
import momentTz from 'moment-timezone';

export const toDisplayValue = (
  value: string | null,
  dateContext: IDateContext,
  format?: string,
) => {
  const utc = momentTz(value, momentTz.ISO_8601).utc();
  if (!utc.isValid()) {
    return value;
  }

  const tenantTimezoneDate = utc.tz(
    dateContext?.tenantTimezone || 'Etc/UTC',
    false,
  );
  const local = momentTz()
    .year(tenantTimezoneDate.year())
    .month(tenantTimezoneDate.month())
    .date(tenantTimezoneDate.date())
    .hour(2)
    .minute(0)
    .second(0);

  if (format) {
    return local.format(format);
  }

  return local.format();
};
