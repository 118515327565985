import { ascend, sortWith } from 'ramda';
import { RoleMembership, ServerRole } from '../types.ts';

const sort = sortWith<ServerRole>([
  ascend((role) => (role.team ? 1 : 0)),
  ascend((role) => role.team?.name),
  ascend((role) => role.name),
  ascend((role) => (role.sourceKey ? 1 : 0)),
]);

export const translateRoles = (input: ServerRole[]) =>
  sort(input).reduce(
    (roles, nextRole) => {
      // No team, so applies to all teams
      if (!nextRole.team) {
        roles[nextRole.id] = {
          roleId: nextRole.id,
          role: {
            id: nextRole.id,
            name: nextRole.name,
            scopes: nextRole.scopes,
            description: nextRole.description,
            context: nextRole.context,
          },
          displayName: nextRole.name,
          teamContext: {
            applyToAll: true,
            teams: [],
            sourceKey: nextRole.sourceKey,
          },
        };
        return roles;
      }

      // Not in the list, add it
      if (!roles[nextRole.id]) {
        roles[nextRole.id] = {
          roleId: nextRole.id,
          role: {
            id: nextRole.id,
            name: nextRole.name,
            scopes: nextRole.scopes,
            description: nextRole.description,
            context: nextRole.context,
          },
          displayName: nextRole.name,
          teamContext: {
            applyToAll: false,
            teams: [
              {
                id: nextRole.team.id,
                name: nextRole.team.name,
                path: nextRole.team.path,
                sourceKey: nextRole.sourceKey,
              },
            ],
          },
        };
        return roles;
      }

      // Already in the list, but applies to all teams
      const teamContext = roles[nextRole.id].teamContext;
      if (teamContext.applyToAll) return roles;

      // Role exists from a previous iteration, just add this team
      teamContext.teams.push({
        id: nextRole.team.id,
        name: nextRole.team.name,
        path: nextRole.team.path,
        sourceKey: nextRole.sourceKey,
      });
      roles[nextRole.id].displayName = nextRole.name;

      return roles;
    },
    {} as Record<string, RoleMembership>,
  );
