/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';
import { useFormsSelector } from '../../../redux/store.ts';
import { Bullet } from '../../Bullet/Bullet.tsx';
import { IssueContainer } from '../../Issue/IssueContainer.tsx';
import { SectionTitle } from '../../SectionTitle/SectionTitle.tsx';
import { SpeechBlockContainer } from '../../SpeechBlock/SpeechBlockContainer.tsx';
import { IssueCausesContainer } from './IssueCausesContainer.tsx';

export interface ICoachingContentContainerProps {
  coachingRequired: boolean;
  instanceId: string;
  isPdfExport: boolean;
  issues: Array<{ issueId: string; error: boolean }>;
}

export const CoachingContentContainer: React.FC<
  ICoachingContentContainerProps
> = ({ coachingRequired, instanceId, isPdfExport, issues }) => {
  const labels = useFormsSelector(
    (state) => state.tenantState.tenant.locale.forms.closeTheLoop,
  );

  if (!issues.length) return null;

  return (
    <div css={styles.base}>
      <div css={styles.sectionTitleOuter}>
        <SectionTitle
          text={coachingRequired ? 'Coaching Required' : 'Coaching Recommended'}
        />
      </div>
      <div css={styles.speechBlockOuter}>
        <Bullet
          bullet={isPdfExport ? 'none' : 'person'}
          style={styles.speechBlockBullet}
        />
        <SpeechBlockContainer
          id={
            coachingRequired
              ? 'issueReviewRequiresCoaching'
              : 'issueReviewOptionalCoaching'
          }
          instanceId={instanceId}
          isPdfExport={isPdfExport}
          markdown={
            coachingRequired
              ? labels.issueCoachingPlanCoachingRequiredGuidanceScript
              : labels.issueCoachingPlanCoachingRecommendedGuidanceScript
          }
        />
      </div>
      {issues.map(({ issueId, error }) => (
        <div
          key={`issueCoachingPlanBase-${issueId}`}
          css={error ? styles.issueBaseError : styles.issueBase}
        >
          <div key={`issueCoachingPlanOuter-${issueId}`} css={styles.issues}>
            <IssueContainer
              key={`issueCoachingPlanContainer-${issueId}`}
              id={issueId}
              enableActions={false}
              showReviewLink={true}
              instanceId={instanceId}
              showCreatedActions={false}
            />
            <IssueCausesContainer
              key={`issueCoachingPlanCause-${issueId}`}
              issueId={issueId}
              lineId={'issueCoaching'}
              instanceId={instanceId}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 30,
  }),
  issueBase: css({
    ...CommonStyles.BoxShadowGroup,
    padding: 10,
  }),
  issueBaseError: css({
    boxShadow: `0 1px 4px 1px ${COLORS.ERROR_RED}`,
    transition: 'all 0.3s',
    borderRadius: 4,
    ':hover': {
      boxShadow: `0 1px 6px 1px ${COLORS.RED}`,
    },
    padding: 10,
  }),
  issues: css({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 4,
    gap: 20,
  }),
  sectionTitleOuter: css({
    position: 'relative',
  }),
  speechBlockOuter: css({
    position: 'relative',
  }),
  speechBlockBullet: css({
    position: 'absolute',
    inset: '9px auto auto -37px',
  }),
};
