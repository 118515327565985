import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GoalSchedule,
  GoalScheduleDraft,
  GoalScheduleStatus,
} from '@se/data/forms/query/goalScheduleTypes.ts';
import { FormGoalCategory } from '@se/data/forms/types.ts';
import { DistributionList } from '@se/data/orgHierarchy/query/distributionList.ts';
import { GoalAdditionalField } from '@seeeverything/ui.util/src/redux/tenant/types.ts';

type FieldErrors = {
  startDate?: string;
  endDate?: string;
  distributionList?: string;
  goalDescription?: string;
  goalDueDate?: string;
  goalCategory?: string;
};

export type FormsReduxEditGoalScheduleState = {
  draft?: GoalScheduleDraft;
  errors: {
    fieldErrors: FieldErrors;
    globalError?: string;
  };
  hasDraftChanged: boolean;
  isSaving: boolean;
  original?: GoalSchedule;
  saved: boolean;
};

const DEFAULT_STATE: FormsReduxEditGoalScheduleState = {
  draft: undefined,
  errors: { fieldErrors: {} },
  hasDraftChanged: false,
  isSaving: false,
  original: undefined,
  saved: false,
};

const slice = createSlice({
  name: 'libs/forms/editGoalSchedule',
  initialState: DEFAULT_STATE,
  reducers: {
    createNewDraft(
      state,
      action: PayloadAction<{
        initialGoalAdditionalFields: GoalAdditionalField[];
      }>,
    ) {
      state.draft = {
        applyToNewStaff: false,
        status: 'NotStarted',
        goalAdditionalFields: action.payload.initialGoalAdditionalFields.map(
          (f) => ({ ...f, value: '' }),
        ),
      };
      state.original = undefined;
      state.errors = { fieldErrors: {} };
      state.hasDraftChanged = false;
      state.isSaving = false;
      state.saved = false;
    },
    editSchedule(
      state,
      action: PayloadAction<{
        schedule: GoalSchedule;
        tenantGoalAdditionalFields: GoalAdditionalField[];
      }>,
    ) {
      const allGoalAdditionalFields = [
        ...(action.payload.schedule.goalAdditionalFields ?? []),
        ...(action.payload.tenantGoalAdditionalFields ?? []),
      ].map((f) => ({ ...f, value: f.value ?? '' }));

      // Merge existing schedule fields with tenant list (tenant list may have changed).
      // Existing fields take precedence.
      const goalAdditionalFields = allGoalAdditionalFields.reduce(
        (acc, field) => {
          if (!acc.some((f) => f.key === field.key)) acc.push(field);
          return acc;
        },
        new Array<GoalAdditionalField>(),
      );

      state.draft = {
        id: action.payload.schedule.id,
        applyToNewStaff: action.payload.schedule.applyToNewStaff,
        distributionList: action.payload.schedule.distributionList,
        endDate: action.payload.schedule.endDate,
        goalCategory: action.payload.schedule.goalCategory,
        goalDescription: action.payload.schedule.goalDescription,
        goalDueDate: action.payload.schedule.goalDueDate,
        goalAdditionalFields,
        startDate: action.payload.schedule.startDate,
        status: action.payload.schedule.status,
      };
      state.errors = { fieldErrors: {} };
      state.hasDraftChanged = false;
      state.isSaving = false;
      state.original = action.payload.schedule;
      state.saved = false;
    },
    dismiss() {
      return DEFAULT_STATE;
    },
    updateGoalDescription(state, action: PayloadAction<string>) {
      state.draft.goalDescription = action.payload;
      state.hasDraftChanged = true;
      state.errors.fieldErrors.goalDescription = undefined;
    },
    updateGoalDueDate(state, action: PayloadAction<string>) {
      state.draft.goalDueDate = action.payload;
      state.hasDraftChanged = true;
      state.errors.fieldErrors.goalDueDate = undefined;
    },
    updateDistributionList(state, action: PayloadAction<DistributionList>) {
      state.draft.distributionList = action.payload;
      state.hasDraftChanged = true;
      state.errors.fieldErrors.distributionList = undefined;
    },
    updateStatus(state, action: PayloadAction<GoalScheduleStatus>) {
      state.draft.status = action.payload;
      state.hasDraftChanged = true;
    },
    updateApplyToNewStaff(state, action: PayloadAction<boolean>) {
      state.draft.applyToNewStaff = action.payload;
      state.draft.endDate = undefined;
      state.errors.fieldErrors.endDate = undefined;
      state.hasDraftChanged = true;
    },
    updateStartDate(state, action: PayloadAction<string>) {
      state.draft.startDate = action.payload;
      state.hasDraftChanged = true;
      state.errors.fieldErrors.startDate = undefined;

      if (!state.draft.endDate) return;
      if (action.payload > state.draft.endDate) state.draft.endDate = undefined;
    },
    updateEndDate(state, action: PayloadAction<string>) {
      if (!state.draft.applyToNewStaff) return;

      state.draft.endDate = action.payload;
      state.hasDraftChanged = true;
      state.errors.fieldErrors.endDate = undefined;
    },
    updateGoalAdditionalField(
      state,
      action: PayloadAction<{ fieldId: string; value: string }>,
    ) {
      const field = state.draft.goalAdditionalFields.find(
        (f) => f.key === action.payload.fieldId,
      );
      if (!field) return;

      field.value = action.payload.value;
      state.hasDraftChanged = true;
    },
    updateGoalCategory(state, action: PayloadAction<FormGoalCategory>) {
      state.draft.goalCategory = action.payload;
      state.hasDraftChanged = true;
      state.errors.fieldErrors.goalCategory = undefined;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    save(state, action: PayloadAction<{ hasGoalCategories: boolean }>) {
      state.isSaving = true;
      state.errors = { fieldErrors: {} };
      state.saved = false;
    },
    saved(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        reason: 'Created' | 'Updated' | 'Activated' | 'Deactivated';
      }>,
    ) {
      state.isSaving = false;
      state.hasDraftChanged = false;
      state.errors = { fieldErrors: {} };
      state.saved = true;
      state.draft = undefined;
      state.original = undefined;
    },
    setFieldErrors(state, action: PayloadAction<FieldErrors>) {
      state.errors.fieldErrors = action.payload;
      state.isSaving = false;
    },
    setGlobalError(state, action: PayloadAction<{ to: string }>) {
      state.isSaving = false;
      state.errors.globalError = action.payload.to;
      state.saved = false;
    },
    alertConfirmDismissChanges(state) {
      return state;
    },
  },
});

export const {
  alertConfirmDismissChanges,
  createNewDraft,
  dismiss,
  editSchedule,
  save,
  saved,
  setFieldErrors,
  setGlobalError,
  updateApplyToNewStaff,
  updateDistributionList,
  updateEndDate,
  updateGoalAdditionalField,
  updateGoalCategory,
  updateGoalDescription,
  updateGoalDueDate,
  updateStartDate,
  updateStatus,
} = slice.actions;
export const reducer = slice.reducer;
export type State = FormsReduxEditGoalScheduleState;
