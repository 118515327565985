/** @jsxImportSource @emotion/react */
import { useDateContext } from '@seeeverything/ui.primitives/src/hooks/useDateContext.ts';
import { useMemo } from 'react';
import { useFormsSelector } from '../redux/store.ts';
import { ShowOnReportingDateMonth } from '../types/types.ts';
import { checkShowOnReportingDateMonth } from './util.ts';

export type UseShowOnReportingDateMonth = {
  showOnReportingDateMonth?: ShowOnReportingDateMonth[];
  instanceId: string;
};

export const useShowOnReportingDateMonth = ({
  showOnReportingDateMonth,
  instanceId,
}: UseShowOnReportingDateMonth) => {
  const dateContext = useDateContext();

  const answers = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.answers,
  );

  return useMemo(
    () =>
      checkShowOnReportingDateMonth(
        answers,
        showOnReportingDateMonth,
        dateContext.tenantTimezone,
      ),
    [answers, dateContext.tenantTimezone, showOnReportingDateMonth],
  );
};
