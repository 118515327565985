/** @jsxImportSource @emotion/react */
import { is } from 'ramda';
import { css, SerializedStyles } from '@emotion/react';

export interface IImageProps {
  path: string;
  path2x?: string;
  width: number | string;
  height: number | string;
  style?: SerializedStyles;
}

const MEDIA_QUERY_RETINA =
  '@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)';

/**
 * Renders an image using CSS.
 */
export const Image = ({
  path,
  path2x,
  width: propsWidth,
  height: propsHeight,
  style,
}: IImageProps) => {
  const width = is(Number, propsWidth)
    ? `${propsWidth}px`
    : propsWidth?.toString();

  const height = is(Number, propsHeight)
    ? `${propsHeight}px`
    : propsHeight?.toString();

  const computedStyles = {
    base: css([
      {
        width,
        height,
        backgroundImage: `url(${path})`,
        backgroundSize: `${width} ${height}`,
        backgroundRepeat: 'no-repeat',
        [MEDIA_QUERY_RETINA]: path2x
          ? { backgroundImage: `url(${path2x})` }
          : undefined,
      },
      style,
    ]),
  };

  return <div css={computedStyles.base} />;
};
