/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { Skeleton } from '@seeeverything/ui.primitives/src/components/Skeleton/Skeleton.tsx';

export interface IFilesSkeletonProps {
  numSkeletons?: number;
}

/**
 * Loading skeleton for files in a digital content pack.
 */
export const FilesSkeleton: React.FC<IFilesSkeletonProps> = ({
  numSkeletons = 2,
}) => (
  <div css={styles.base}>
    {Array.from({ length: numSkeletons }).map((_, index) => (
      <Skeleton
        key={`skeleton-file-${index}`}
        width={165}
        height={251}
        variant={'rect'}
        style={styles.file}
      />
    ))}
  </div>
);

const styles = {
  base: css({
    display: 'flex',
    padding: 25,
    background: '#fcfcfc',
    borderRadius: 4,
    border: `1px solid ${color.format(-0.1)}`,
    flexWrap: 'wrap',
    gap: 25,
  }),
  file: { borderRadius: 10 },
};
