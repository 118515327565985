import gql from 'graphql-tag';
import { Observable, filter, mergeMap } from 'rxjs';
import { StateObservable, ofType } from 'redux-observable';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { ReduxFormInstanceAttendeeUpdateFollowUpAction } from '../types.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../../store.ts';
import { attendeeSaved, updateAttendeeFollowUp } from './actions.ts';

export function updateAttendeeFollowUpEpic(
  action$: Observable<ReduxFormInstanceAttendeeUpdateFollowUpAction>,
  _: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/instance/attendance/UPDATE_ATTENDEE_FOLLOW_UP_ACTION'),
    filter(({ payload: { error } }) => !error),
    mergeMap(async (action) => {
      const { attendeeId, instanceId, isFollowUpRequired } = action.payload;
      const resultAction = await attendeeFollowUpMutation(client, {
        isFollowUpRequired,
        instanceId,
        personId: attendeeId,
      });
      return resultAction;
    }),
  );
}

const attendeeFollowUpMutation = async (
  client: IGraphQLClient,
  { personId, instanceId, isFollowUpRequired }: IUpdateAttendeeFollowUpInputs,
) => {
  const errorAction = updateAttendeeFollowUp(
    instanceId,
    personId,
    !isFollowUpRequired,
    `Unable to update the attendee's follow up action. Please retry.`,
  );

  try {
    const result = await client.mutate<IUpdateAttendeeFollowUpResponse>({
      mutation: mutationUpdateInstanceAttendeeFollowUp,
      variables: { personId, instanceId, isFollowUpRequired },
    });

    if (
      result.errors ||
      !result.data?.forms?.updateInstanceAttendeeFollowUp?.ok
    ) {
      return errorAction;
    }

    return attendeeSaved(instanceId, personId);
  } catch (err) {
    log.error(
      new Error(
        `An unexpected error occurred trying to update follow-up for ${personId} against instance ${instanceId} ${err}`,
      ),
    );
    return errorAction;
  }
};

interface IUpdateAttendeeFollowUpInputs {
  personId: string;
  instanceId: string;
  isFollowUpRequired: boolean;
}

interface IUpdateAttendeeFollowUpResponse {
  forms: {
    updateInstanceAttendeeFollowUp: {
      ok: boolean;
    };
  };
}

const mutationUpdateInstanceAttendeeFollowUp = gql`
  mutation UpdateInstanceAttendeeFollowUp(
    $personId: ID!
    $instanceId: ID!
    $isFollowUpRequired: Boolean!
  ) {
    forms {
      updateInstanceAttendeeFollowUp(
        input: {
          personId: $personId
          instanceId: $instanceId
          isFollowUpRequired: $isFollowUpRequired
        }
      ) {
        ok
      }
    }
  }
`;
