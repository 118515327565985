/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormInstanceActionPlanType } from '@se/data/forms/types.ts';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { INTERSECTION_OBSERVER_DEFAULTS } from '@seeeverything/ui.primitives/src/common/constants.ts';
import { ContentPlaceholder } from '@seeeverything/ui.primitives/src/components/ContentPlaceholder/ContentPlaceholder.tsx';
import { ErrorRetry } from '@seeeverything/ui.primitives/src/components/ErrorRetry/ErrorRetry.tsx';
import { SkeletonDetailedItemsList } from '@seeeverything/ui.primitives/src/components/SkeletonDetailedItemsList/SkeletonDetailedItemsList.tsx';
import { useViewport } from '@seeeverything/ui.primitives/src/hooks/useViewport.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useLoadFormActionPlan } from '../../hooks/useLoadFormActionPlan.ts';
import { useFormsSelector } from '../../redux/store.ts';
import { ActionPlanWithSections } from './components/ActionPlanWithSections.tsx';
import { ActionPlanWithoutSections } from './components/ActionPlanWithoutSections.tsx';

export type FormActionPlanContainerProps = {
  includeCategories: string[];
  instanceId: string;
  isPdfExport: boolean;
  lineId: string;
  typeFilter?: FormInstanceActionPlanType;
};

export const FormActionPlanContainer: React.FC<
  FormActionPlanContainerProps
> = ({
  includeCategories = [],
  instanceId,
  isPdfExport,
  lineId,
  typeFilter,
}) => {
  const [viewportRef, inViewport] = useViewport(INTERSECTION_OBSERVER_DEFAULTS);

  const hasSections = useFormsSelector(
    (state) => state.tenantState.tenant.module === 'coaching',
  );

  const handleLoadAction = useLoadFormActionPlan({
    instanceId,
    lineId,
    includeCategories,
    typeFilter,
    hasSections,
    inViewport,
  });

  const actionPlan = useFormsSelector(
    (state) => state.formActionPlan.actionPlan[lineId],
  );

  const instanceSubject = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.subject,
  );

  if (!instanceSubject)
    return (
      <div css={styles.contentPlaceholder}>
        <ContentPlaceholder
          kind={'LIST'}
          emptyText={''}
          hintText={'Start by assigning this to a team or member.'}
        />
      </div>
    );

  if (!actionPlan || actionPlan.isLoading)
    return (
      <div
        css={styles.base}
        data-test={'forms-actionPlan-loading-placeholder'}
        ref={viewportRef}
      >
        <SkeletonDetailedItemsList numberOfSkeletonItems={3} />
      </div>
    );

  if (actionPlan.loadError)
    return (
      <ErrorRetry
        onClick={handleLoadAction}
        message={
          'There was a problem loading your action plan. Click here to try again.'
        }
        style={styles.error}
      />
    );

  return hasSections ? (
    <div css={styles.base}>
      <ActionPlanWithSections
        includeCategories={includeCategories}
        instanceId={instanceId}
        isPdfExport={isPdfExport}
        lineId={lineId}
        typeFilter={typeFilter}
      />
    </div>
  ) : (
    <ActionPlanWithoutSections
      includeCategories={includeCategories}
      instanceId={instanceId}
      lineId={lineId}
      typeFilter={typeFilter}
    />
  );
};

const styles = {
  base: css({
    ...CommonStyles.BoxShadowGroup,
    padding: 12,
  }),
  contentPlaceholder: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    height: 200,
    width: '100%',
  }),
  error: {
    border: `dashed 1px ${COLORS.ORANGE_DARK}`,
    borderRadius: 3,
    backgroundColor: COLORS.ORANGE_TRANSPARENT,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0',
  },
};
