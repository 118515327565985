import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FormsBulkUploadValidateFileError,
  FormsBulkUploadValidateFileUploadRow,
  FormsBulkUploadValidationRowStatus,
  FormsBulkUploadWorkflowType,
} from '@se/data/forms/types.ts';
import { AnalyticsEvent } from '@seeeverything/ui.util/src/redux/analytics/types.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';

export type FormBulkUploadImportFileState = {
  selectedTabId: TabId;
  statusFilters: FormsBulkUploadValidationRowStatus[];
  validatedFile?: File;
  validationResult?: FormsBulkUploadFileValidationResult;
  isLoading: boolean;
  error: boolean;
  importSettings: {
    workflow?: FormsBulkUploadWorkflowType;
    createAutomatedActions: boolean;
  };
};

export type FormsBulkUploadFileValidationResult = {
  errors: FormsBulkUploadValidateFileError[];
  rows: FormsBulkUploadValidateFileUploadRow[];
};

type TabId = 'UPLOAD' | 'WORKFLOW' | 'REVIEW';

const DEFAULT_STATE: FormBulkUploadImportFileState = {
  selectedTabId: 'UPLOAD',
  statusFilters: ['Error', 'Success', 'Warning'],
  isLoading: false,
  error: false,
  importSettings: {
    createAutomatedActions: true,
  },
};

const slice = createSlice({
  name: 'libs/forms/formBulkUploadImportFile',
  initialState: DEFAULT_STATE,
  reducers: {
    clear: () => DEFAULT_STATE,
    changeTab(state, action: PayloadAction<{ tabId: TabId }>) {
      state.selectedTabId = action.payload.tabId;
    },
    cancelDuplicateRowImport() {},
    fileUpload(state, action: PayloadAction<{ file: File }>) {
      state.validatedFile = action.payload.file;
    },
    fileRejected(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{ file: File; maxSize: number }>,
    ) {
      return state;
    },
    selectWorkflow(
      state,
      action: PayloadAction<{ workflow: FormsBulkUploadWorkflowType }>,
    ) {
      state.importSettings.workflow = action.payload.workflow;
    },
    toggleCreateAutomatedActions(
      state,
      action: PayloadAction<{
        to: boolean;
      }>,
    ) {
      state.importSettings.createAutomatedActions = action.payload.to;
    },
    fileValidated(
      state,
      action: PayloadAction<{
        errors: FormsBulkUploadValidateFileError[];
        rows: FormsBulkUploadValidateFileUploadRow[];
      }>,
    ) {
      state.validationResult = {
        errors: action.payload.errors,
        rows: action.payload.rows,
      };
    },
    fileValidatedError(state) {
      state.error = true;
    },
    statusFilterChange(
      state,
      action: PayloadAction<{
        filterValue: FormsBulkUploadValidationRowStatus;
      }>,
    ) {
      const index = state.statusFilters.indexOf(action.payload.filterValue);
      if (index === -1) {
        state.statusFilters.push(action.payload.filterValue);
      } else {
        state.statusFilters.splice(index, 1);
      }
    },
    importFile(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        file: File;
        workflow: FormsBulkUploadWorkflowType;
        createAutomatedActions: boolean;
      }>,
    ) {
      state.isLoading = true;
    },
    importFileStarted(state) {
      state.isLoading = false;
    },
    showDuplicateRowImportDialog() {},
  },
});

export const analytics = (
  action: ReduxAction,
  trackEvent: (eventIdentifier: string, payload?: object) => AnalyticsEvent,
) => {
  if (slice.actions.fileUpload.match(action))
    return [trackEvent('form_uploader_upload_template_start')];

  if (slice.actions.importFile.match(action))
    return [
      trackEvent('form_uploader_upload_template_complete', {
        workflowType: action.payload.workflow,
        createAutomatedActions: action.payload.createAutomatedActions,
      }),
    ];
};

export const {
  clear,
  changeTab,
  cancelDuplicateRowImport,
  fileUpload,
  fileRejected,
  selectWorkflow,
  fileValidated,
  fileValidatedError,
  statusFilterChange,
  toggleCreateAutomatedActions,
  importFile,
  importFileStarted,
  showDuplicateRowImportDialog,
} = slice.actions;
export const reducer = slice.reducer;
export type State = FormBulkUploadImportFileState;
