/** @jsxImportSource @emotion/react */
import React from 'react';
import { Skeleton as MaterialSkeleton } from '@material-ui/lab';

export interface ISkeletonProps {
  // Material UI Props
  animation?: 'pulse' | 'wave' | false;
  height?: number | string;
  variant?: 'text' | 'rect' | 'circle';
  width?: number | string;
  style?: React.CSSProperties;
}

/**
 * Placeholder for data that is not yet visible.
 *
 * @see https://material-ui.com/components/skeleton/
 * @see https://material-ui.com/api/skeleton/
 */
export const Skeleton: React.FC<ISkeletonProps> = ({
  animation = 'wave',
  height,
  variant,
  width,
  style,
}) => (
  <MaterialSkeleton
    animation={animation}
    height={height}
    variant={variant}
    width={width}
    style={style}
  />
);
