import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FormBulkUploadDownloadFailedRowsState = {
  jobId?: string;
};

const DEFAULT_STATE: FormBulkUploadDownloadFailedRowsState = {};

const slice = createSlice({
  name: 'libs/forms/formBulkUploadDownloadFailedRows',
  initialState: DEFAULT_STATE,
  reducers: {
    requestDownloadLink(state, action: PayloadAction<{ jobId: string }>) {
      state.jobId = action.payload.jobId;
    },
    downloadFailed(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{ jobId: string }>,
    ) {
      return state;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    downloadComplete(state, action: PayloadAction<{ jobId: string }>) {
      return state;
    },
  },
});

export const { requestDownloadLink, downloadFailed, downloadComplete } =
  slice.actions;
export const reducer = slice.reducer;
export type State = FormBulkUploadDownloadFailedRowsState;
