/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { IconButton } from '@seeeverything/ui.primitives/src/components/IconButton/IconButton.tsx';
import { InputAdornment } from '@seeeverything/ui.primitives/src/components/InputAdornment/InputAdornment.tsx';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { useCallback, useRef } from 'react';

export interface IFilterFieldProps {
  filter?: string;
  onFilterChange: (to: string) => void;
}

/**
 * `TextField` for providing filtering against a `PreviousAnswerDropdown`.
 */
export const FilterField: React.FC<IFilterFieldProps> = ({
  filter,
  onFilterChange,
}) => {
  const inputRef = useRef<HTMLInputElement>();

  const handleFilterIconButtonClicked = useCallback(() => {
    onFilterChange('');
    inputRef.current?.focus();
  }, [onFilterChange]);

  return (
    <TextField
      placeholder={'Filter'}
      id={'filter'}
      value={filter}
      onChange={onFilterChange}
      style={styles.base}
      InputProps={{
        inputRef,
        endAdornment: (
          <InputAdornment position={'end'}>
            <IconButton
              icon={filter ? <Icons.clear /> : <Icons.search />}
              onClick={handleFilterIconButtonClicked}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

const styles = {
  base: css({
    width: '25%',
  }),
};
