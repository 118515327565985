import { ofType } from 'redux-observable';
import { Observable, filter, map } from 'rxjs';
import { ReduxFormInstanceServerLoaded } from '../form-instance/types.ts';
import { scoreChanged, scoreLoadedFromInstance } from './actions.ts';
import { FormResult, RemediationOutcome } from './types.ts';
import { toFormScore } from './util.calculateScore.ts';

export function calculateScoreOnInstanceLoadEpic(
  action$: Observable<ReduxFormInstanceServerLoaded>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/SERVER_LOADED'),
    filter((action) => Boolean(action.payload.instance.template)),
    map(({ payload: { instance } }) => {
      const score = toFormScore(
        instance.template.definitionJson,
        instance.answers,
        instance.permissions,
      );
      return scoreChanged(instance.id, score);
    }),
  );
}

export function instanceLoadedCopyScoreEpic(
  action$: Observable<ReduxFormInstanceServerLoaded>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/SERVER_LOADED'),
    map(({ payload: { instance } }) => {
      const score = instance.score;
      const overruleResult = instance.answers.scoreOverrule
        ?.value as FormResult;

      const remediationOutcome =
        overruleResult === FormResult.Provisional
          ? (score?.remediationResult?.outcome ?? RemediationOutcome.Pending)
          : undefined;
      const remediationReason =
        overruleResult === FormResult.Provisional
          ? (score?.remediationResult?.comments ?? undefined)
          : undefined;

      return scoreLoadedFromInstance(
        instance.id,
        overruleResult,
        remediationOutcome,
        remediationReason,
      );
    }),
  );
}
