import { sprintf } from 'sprintf-js';

/**
 * String format methods from module `sprintf-js`.
 * https://github.com/alexei/sprintf.js#readme
 */

/**
 * Returns a formatted string.
 * @param input The placeholders in the format string are marked by % and are
 * followed by optional elements, then a type specifier e.g. 's' for string.
 * @param args Arguments for insertion into format string. Arrays should be
 * spread, e.g. format('%s', ...myArray)
 */
export function format(input: string, ...args: any[]) {
  return sprintf(input, ...args);
}
