/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import {
  CheckboxGroup,
  CheckboxGroupEvent,
  CheckboxValue,
  RadioGroup,
  RadioGroupEvent,
  RadioOption,
} from '@seeeverything/ui.primitives/src/components/CheckboxRadioGroup/index.ts';
import { Markdown } from '@seeeverything/ui.primitives/src/components/Markdown/Markdown.tsx';
import { Transition } from '@seeeverything/ui.primitives/src/components/Transition/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';
import { useCallback, useMemo } from 'react';
import { CheckboxIssueOption, OptionAnswer } from '../../types/types.ts';
import { OptionsAnswerIssueContainer } from './OptionsAnswerIssueContainer.tsx';

export interface IOptionsAnswerProps {
  answerId: string;
  direction?: 'horizontal' | 'vertical';
  error?: string;
  group?: string;
  instanceId?: string;
  isEnabled?: boolean;
  multiSelect?: boolean;
  onChange: (to: string, toDisplayValue: string) => void;
  options?: OptionAnswer[];
  question?: string;
  style?: SerializedStyles;
  value?: string;
}

/**
 * An answer to a question that provides several multi-choice options.
 *  multiSelect switches between radio- and multiple-options modes.
 */
export const OptionsAnswer: React.FC<IOptionsAnswerProps> = ({
  answerId,
  direction = 'vertical',
  error,
  instanceId,
  isEnabled = true,
  multiSelect = false,
  onChange,
  options = [],
  question,
  style,
  value,
}) => {
  const radioOptions = options as RadioOption[];

  const selectedOption = (options as CheckboxIssueOption[]).find(
    (option) => option.id === value,
  );

  const handleRadioChange = useCallback(
    (e: RadioGroupEvent) => {
      const toDisplayValue = radioOptions.find(
        (option) => e.id === option.id,
      )?.label;
      const to = e.id;
      onChange?.(to, toDisplayValue);
    },
    [onChange, radioOptions],
  );

  const valueToCheckbox = useCallback(
    (answerValue?: string): CheckboxValue[] => {
      const selectedOptions = answerValue?.startsWith('[')
        ? answerValue.substring(1, answerValue.length - 1)
        : answerValue;
      return (
        selectedOptions
          ?.split(',')
          .map((option) => ({ id: option, checked: true })) || []
      );
    },
    [],
  );

  const checkboxToAnswer = useCallback(
    (e: CheckboxGroupEvent): string => {
      const to = { id: e.id, checked: e.to };
      const mergedWithExisting = value ? [...valueToCheckbox(value), to] : [to];

      return mergedWithExisting
        .map((option) =>
          option.id === e.id ? { ...option, checked: e.to } : option,
        )
        .filter((option) => option.checked)
        .reduce((acc, option, index, all) => {
          const isFirst = index === 0;
          const isLast = index === all.length - 1;

          if (isFirst && isLast) return `[${option.id}]`;
          if (isFirst) return `[${option.id}`;

          return isLast ? `${acc},${option.id}]` : `${acc},${option.id}`;
        }, '');
    },
    [value, valueToCheckbox],
  );

  const checkboxDisplayValue = useCallback(
    (selectedAnswers: string): string => {
      const checkedIds = valueToCheckbox(selectedAnswers)
        .filter((cb) => cb.checked)
        .map((cb) => cb.id);

      return radioOptions
        .filter((option) => checkedIds.includes(option.id))
        .map((option) => option.label)
        .join(`; `);
    },
    [radioOptions, valueToCheckbox],
  );

  const handleCheckboxChange = useCallback(
    (e: CheckboxGroupEvent) => {
      const to = checkboxToAnswer(e);
      onChange?.(to, checkboxDisplayValue(to));
    },
    [checkboxDisplayValue, checkboxToAnswer, onChange],
  );

  const isHorizontal = direction === 'horizontal';

  const styles = useMemo(
    () => ({
      base: css(
        {
          display: 'flex',
          flexDirection: 'column',
          gap: 15,
        },
        style,
      ),
      optionsBase: css({
        display: 'flex',
        flexDirection: isHorizontal ? 'row' : 'column',
        alignItems: isHorizontal ? 'center' : undefined,
        justifyContent: isHorizontal ? 'space-between' : undefined,
        cursor: 'default',
        padding: error ? 3 : undefined,
        gap: '0 10px',
      }),
      options: css({
        paddingLeft: isHorizontal ? undefined : 10,
        flex: '0 1 auto',
      }),
      question: css({
        color: error ? COLORS.ERROR_RED : undefined,
        flex: '0 5000 auto',
      }),
    }),
    [error, isHorizontal, style],
  );

  const elOptions = multiSelect ? (
    <CheckboxGroup
      direction={direction}
      value={valueToCheckbox(value)}
      options={radioOptions}
      onChange={handleCheckboxChange}
      isEnabled={isEnabled}
      error={error}
    />
  ) : (
    <RadioGroup
      direction={direction}
      value={value}
      options={radioOptions}
      onChange={handleRadioChange}
      isEnabled={isEnabled}
      error={error}
    />
  );

  return (
    <div css={styles.base}>
      <div css={styles.optionsBase}>
        {question && <Markdown style={styles.question} text={question} />}
        <div css={styles.options}>{elOptions}</div>
      </div>
      <Transition.Collapse
        in={Boolean(selectedOption?.issue)}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <OptionsAnswerIssueContainer
          answerId={answerId}
          instanceId={instanceId}
          issueDefinition={selectedOption?.issue}
        />
      </Transition.Collapse>
    </div>
  );
};
