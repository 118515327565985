/** @jsxImportSource @emotion/react */
import { useCallback, useRef } from 'react';

/**
 * Hook for preventing double clicks and other rapid event firing.
 * @param {Function} func Function to be called.
 * @param {number} delayMs _Optional_ Delay in milliseconds (default 300ms).
 * @example const handleClick = usePreventDoubleClick(() => {
 *  log('Clicked!');
 * });
 * return <button onClick={handleClick}>Click me</button>;
 */
export function usePreventDoubleClick<T extends (...args: any) => void>(
  func: T,
  delayMs = 300,
): T {
  const preventHandlerExecution = useRef(false);
  const startTimer = useCallback(() => {
    preventHandlerExecution.current = true;
    setTimeout(() => {
      preventHandlerExecution.current = false;
    }, delayMs);
  }, [delayMs]);

  return useCallback(
    (...args: any) => {
      if (preventHandlerExecution.current) return;
      startTimer();

      return func(...args);
    },
    [func, startTimer],
  ) as T;
}
