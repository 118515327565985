/** @jsxImportSource @emotion/react */
import React from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useViewport } from '../../../hooks/useViewport.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { MaterialListItemType, MaterialListItemClickEvent } from '../types.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';

export interface IMaterialListItemProps {
  item: MaterialListItemType;
  index: number;
  isSelected: boolean;
  onEnterViewport?: () => void;
  onClick?: (e: MaterialListItemClickEvent) => void;
}

/**
 * Item rendered in a material list
 */
const MaterialListItem = (props: IMaterialListItemProps) => {
  const { item, index, onClick, isSelected, onEnterViewport } =
    props as IMaterialListItemProps;

  const {
    isClickable = true,
    type = 'ITEM',
    icon: Icon,
    text,
    description,
    id,
    isDimmed,
    value,
    iconFill,
    isSelected: isItemSelected,
  } = item;

  const [forwardedRef, inViewport] = useViewport();

  React.useEffect(() => {
    if (inViewport) onEnterViewport?.();
  }, [inViewport, onEnterViewport]);

  const [isHovered, setIsHovered] = React.useState<boolean>(false);
  const isDisabled = isDimmed && ((isHovered && !isClickable) || !isHovered);
  const classStyles = useStyles(isDisabled, isClickable, isDimmed)();

  if (type === 'HEADER') {
    return (
      <ListSubheader
        key={`material-list-subheader-${id}`}
        id={id}
        className={classStyles.header}
      >
        {text}
      </ListSubheader>
    );
  }

  const handleMouseHover = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const clickEvent: MaterialListItemClickEvent = {
    item,
    value,
    index,
  };
  const handleListItemClick = () => onClick?.(clickEvent);
  const selectableProps = isClickable && {
    button: true,
    onClick: handleListItemClick,
    selected: isSelected || isItemSelected,
  };

  const elHeadingLabel = (
    <Typography key={'list-item-heading'} className={classStyles.itemHeading}>
      {text}
    </Typography>
  );

  const elDescriptionLabel = description && (
    <Typography
      key={'list-item-description'}
      className={classStyles.itemDescription}
    >
      {description}
    </Typography>
  );

  const elIcon = Icon && (
    <ListItemIcon key={`material-list-item-icon`}>
      <Icon fill={isDisabled ? color.format(-0.3) : iconFill} />
    </ListItemIcon>
  );

  return (
    <ListItem
      key={`material-list-item-${id}`}
      id={id}
      onMouseOver={handleMouseHover}
      onMouseLeave={handleMouseLeave}
      innerRef={forwardedRef}
      {...(selectableProps as any)} // Typing to any here to get around MUI's strict typing that button always has to be true when it doesn't have to be.
      classes={{
        root: classStyles.root,
        selected: classStyles.selected,
      }}
    >
      {elIcon}
      <ListItemText
        key={`material-list-item-text`}
        primary={elHeadingLabel}
        secondary={elDescriptionLabel}
      />
    </ListItem>
  );
};

const useStyles = (
  isDisabled?: boolean,
  isClickable?: boolean,
  isDimmed?: boolean,
) => {
  const itemTextCommonStyles = {
    cursor: isClickable ? 'cursor' : undefined,
    fontStyle: isDimmed ? 'italic' : 'normal',
    userSelect: (isDisabled ? 'none' : 'auto') as 'none' | 'auto', // For typescript.
    display: 'block',
  };

  return makeStyles({
    header: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      lineHeight: '36px',
    },
    itemHeading: {
      ...itemTextCommonStyles,
      color: isDisabled ? color.format(-0.3) : color.format(-1),
    },
    itemDescription: {
      ...itemTextCommonStyles,
      color: isDisabled ? color.format(-0.2) : color.format(-0.6),
      fontSize: 13,
    },
    root: {
      '&$selected': {
        background: color.create(COLORS.BLUE).alpha(0.1).css(),
      },
    },
    selected: {},
  });
};

export default React.memo(MaterialListItem);
