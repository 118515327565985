import { FormInstanceStatus } from '@se/data/forms/types.ts';
import moment from 'moment';
import { formatDueBy } from '../../util/util.instance.ts';

export const toDatesDisplayValue = (
  instanceStatus: FormInstanceStatus,
  timezone: string,
  reportingDate?: string,
  dueBy?: string,
) => {
  const hasReportingDate = Boolean(reportingDate);

  const hasDueDate =
    Boolean(dueBy) &&
    !['Completed', 'Cancelled', 'Archived'].includes(instanceStatus);

  if (!hasReportingDate && !hasDueDate) return undefined;

  const reportingDateDisplayValue = hasReportingDate
    ? `Dated ${moment(reportingDate).format('D MMM YY')}`
    : undefined;

  const dueByDisplayValue = hasDueDate
    ? formatDueBy({
        dueBy,
        showDistantDates: true,
        capitalized: !hasReportingDate,
        timezone,
      })
    : undefined;

  return [reportingDateDisplayValue, dueByDisplayValue]
    .filter(Boolean)
    .join(', ');
};

export const toGoalDatesDisplayValue = (
  createdAt: string,
  timezone: string,
  dueBy?: string,
  completedAt?: string,
) => {
  const createdDisplayValue = `Created ${moment(createdAt).format('D MMM YY')}`;
  const completedDisplayValue = completedAt
    ? `completed ${moment(completedAt).format('D MMM YY')}`
    : undefined;

  const dueByDisplayValue =
    dueBy && !completedAt
      ? formatDueBy({
          dueBy,
          showDistantDates: true,
          capitalized: false,
          timezone,
        })
      : undefined;

  return [createdDisplayValue, completedDisplayValue, dueByDisplayValue]
    .filter(Boolean)
    .join(', ');
};
