/** @jsxImportSource @emotion/react */
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import { useCallback, useMemo } from 'react';
import { FormListItemWrapped } from '../components/FormListItem/FormListItemWrapped.tsx';
import { SwitchAnswer } from '../components/SwitchAnswer/SwitchAnswer.tsx';
import { useHandleAnswerVisibility } from '../hooks/useHandleAnswerVisibility.ts';
import { formInstanceAnswerChange } from '../redux/form-instance/answer/actions.ts';
import { useFormsDispatch, useFormsSelector } from '../redux/store.ts';
import { FormAnswer, ISwitchAnswerLine } from '../types/types.ts';

export interface ISwitchAnswerFactoryContainerProps {
  canUpdateInstance: boolean;
  instanceId: string;
  isPdfExport: boolean;
  line: ISwitchAnswerLine;
}

export const SwitchAnswerFactoryContainer: React.FC<
  ISwitchAnswerFactoryContainerProps
> = ({ canUpdateInstance, instanceId, isPdfExport, line }) => {
  const dispatch = useFormsDispatch();

  const isVisible = useHandleAnswerVisibility({
    instanceId,
    questionKeys: [line.id],
    showOnAnswer: line.showOnAnswer,
    showOnReportingDateMonth: line.showOnReportingDateMonth,
    showOnSwitch: line.showOnSwitch,
  });

  const newAnswerId = useMemo(() => {
    if (!isVisible) return;
    return uuid.generate();
  }, [isVisible]);

  const answer = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    return instance?.answers?.[line.id];
  });

  const answerValue = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    return instance?.answers?.[line.id]?.value === 'true';
  });

  const error = useFormsSelector(
    (state) =>
      state.formInstance.instances[instanceId]?.questionErrors?.[line.id],
  );

  const updateAnswer = useCallback(
    (to: boolean) => {
      if (!isVisible) return;

      const toAnswer: FormAnswer = {
        id: answer?.id ?? newAnswerId,
        value: to.toString(),
        lineId: line.id,
        type: 'switchAnswer',
        group: line.group,
      };

      dispatch(
        formInstanceAnswerChange(
          instanceId,
          answer?.id
            ? {
                type: 'UPDATE',
                toAnswer,
                fromAnswer: answer,
              }
            : {
                type: 'CREATE',
                toAnswer,
                creationPreviouslyFailed: Boolean(error),
              },
          true,
        ),
      );
    },
    [
      answer,
      dispatch,
      error,
      instanceId,
      isVisible,
      line.group,
      line.id,
      newAnswerId,
    ],
  );

  const updateAnswerOnWrappedClicked = useCallback(() => {
    if (canUpdateInstance) updateAnswer(!answerValue);
  }, [answerValue, updateAnswer, canUpdateInstance]);

  return (
    <FormListItemWrapped
      bulletTop={14}
      highlightOnHover={line.highlightOnHover}
      id={line.id}
      isPdfExport={isPdfExport}
      isVisible={isVisible}
      key={line.id}
      onClick={updateAnswerOnWrappedClicked}
      parentId={line.parentId}
    >
      <SwitchAnswer
        error={error}
        id={line.id}
        isEnabled={canUpdateInstance}
        label={line.label}
        value={answerValue}
      />
    </FormListItemWrapped>
  );
};
