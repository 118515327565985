/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DistributionListType } from '@seeeverything/ui.forms/src/redux/distributionList/types.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import {
  MaterialList,
  MaterialListItemType,
} from '@seeeverything/ui.primitives/src/components/MaterialList/index.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { TenantLocale } from '@seeeverything/ui.util/src/redux/tenant/types.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';

export interface IPreviewListProps {
  locale: TenantLocale;
  type: DistributionListType;
  items?: MaterialListItemType[];
}

const DEFAULT_LIST_ITEMS = {
  Team: [
    {
      id: 'Demo',
      text: 'No Teams',
      icon: Icons.team,
      value: 'Demo',
      isClickable: false,
    },
  ],
  Person: [
    {
      id: 'Demo',
      text: 'No People',
      icon: Icons.face,
      value: 'Demo',
      isClickable: false,
    },
  ],
};

export const PreviewList = ({
  items = [],
  type,
  locale,
}: IPreviewListProps) => {
  const defaultItems: MaterialListItemType[] =
    type === 'Team' ? DEFAULT_LIST_ITEMS.Team : DEFAULT_LIST_ITEMS.Person;
  items = items.length === 0 ? defaultItems : items;

  const formLabel = locale?.label?.form.toLowerCase() ?? 'meeting';

  return (
    <div css={styles.base}>
      <div css={styles.header}>
        <div css={styles.title}>
          <Text size={48} color={color.format(-0.2)} weight={FontWeight.light}>
            {'Preview'}
          </Text>
        </div>
      </div>
      <div css={styles.body}>
        <Text size={18} color={color.format(-0.7)} weight={FontWeight.light}>
          {`Below is the list of results for this distribution list. Using this list in the scheduler will create a ${formLabel} for each of the ${
            type === 'Team' ? 'below teams' : 'below people'
          }.`}
        </Text>
        <MaterialList
          items={items}
          style={styles.selectionList}
          isSelectable={false}
        />
      </div>
    </div>
  );
};

const styles = {
  base: css({
    overflow: 'hidden',
    flex: '1 1 auto',
    minHeight: '600px',
  }),
  header: css({
    height: 76,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'stretch',
  }),
  title: css({
    margin: '20px 0 0px 34px',
  }),
  body: css({
    padding: '20px 33px 33px 34px',
  }),
  selectionList: {
    marginTop: '20px',
    maxHeight: '530px',
  },
};
