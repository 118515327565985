import { formsMutation } from '@se/data/forms/mutation/index.ts';
import { formsQuery } from '@se/data/forms/query/index.ts';
import {
  FormActionAnswerDropdown,
  FormActionAnswerText,
} from '@se/data/forms/types.ts';
import { showStatusBar } from '@seeeverything/ui.shell/src/redux/sheets/actions.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { StateObservable, combineEpics } from 'redux-observable';
import { concatAll, filter, from, mergeMap, of } from 'rxjs';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import * as formActionSlice from './formActionSlice.ts';
import { validateActionFields } from './validation.ts';

export const formActionEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  loadActionTemplateOnNewDraftEpic,
  loadActionEpic,
  createActionEpic,
  updateActionEpic,
  loadAuditEpic,
);

function loadActionTemplateOnNewDraftEpic(
  action$: StateObservable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(formActionSlice.newDraft.match),
    mergeMap(async () => {
      const actionLabel = state$.value.tenantState.tenant.locale.label.action;
      const module = state$.value.tenantState.tenant.module;
      const timezone = state$.value.tenantState.tenant.configuration.timezone;
      const response = await formsQuery.getFormActionTemplates(client, module);

      if (response.isSuccess === false)
        return from([
          showStatusBar(
            'ERROR',
            `Unable to show your new ${actionLabel} right now - please try again later.`,
          ),
          formActionSlice.hide(),
        ]);

      return of(
        formActionSlice.loadedActionTemplateForNewDraft({
          actionTemplate: response.data[0],
          module,
          timezone,
        }),
      );
    }),
    concatAll(),
  );
}

function loadAuditEpic(
  action$: StateObservable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(formActionSlice.loadHistory.match),
    mergeMap(async () => {
      const actionId = state$.value.formActionV2.id;

      const result = await formsQuery.getFormActionAudit(client, actionId);

      if (result.isSuccess === false)
        return of(formActionSlice.loadHistoryError({ actionId }));

      return of(
        formActionSlice.loadedHistory({
          actionId,
          entries: result.data,
        }),
      );
    }),
    concatAll(),
  );
}

function loadActionEpic(
  action$: StateObservable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(formActionSlice.load.match),
    mergeMap(async (action) => {
      const actionLabel = state$.value.tenantState.tenant.locale.label.action;
      const timezone = state$.value.tenantState.tenant.configuration.timezone;
      const actionId = action.payload.actionId;

      const result = await formsQuery.getFormAction(client, actionId);

      if (result.isSuccess === false)
        return from([
          showStatusBar(
            'ERROR',
            `Unable to load ${actionLabel} right now - please try again later.`,
          ),
          formActionSlice.hide(),
        ]);

      return of(
        formActionSlice.loaded({ actionId, formAction: result.data, timezone }),
      );
    }),
    concatAll(),
  );
}

function createActionEpic(
  action$: StateObservable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(formActionSlice.save.match),
    filter(() => !state$.value.formActionV2.existingAction),
    mergeMap(async (action) => {
      const actionId = action.payload.actionId;

      const draftChanges = state$.value.formActionV2.draftChanges;
      const draftAction = draftChanges.action;
      const source = state$.value.formActionV2.source;
      const openedFromFormInstanceId =
        source?.type === 'FORM' ? source.instanceId : undefined;
      const module = state$.value.tenantState.tenant.module;
      const formActionTemplate = state$.value.formActionV2.actionTemplate;

      const validationErrorActions = validateActionFields(
        module,
        draftChanges,
        undefined,
        formActionTemplate,
      );

      if (validationErrorActions.length > 0)
        return from([
          ...validationErrorActions,
          formActionSlice.validationFailed(),
        ]);

      const createResult = await formsMutation.createFormAction(client, {
        actionId,
        formInstanceId: openedFromFormInstanceId,
        assignedToPersonId: draftAction.assignedTo.id,
        answerAutomatedActionId: draftAction.answerAutomatedActionId,
        description: draftAction.description,
        dueBy: draftAction.dueBy,
        goalId: draftAction.goalId,
        issueId: draftAction.issue?.id,
        note: draftAction.note,
        actionTemplateId: formActionTemplate?.id,
      });

      if (
        createResult.isSuccess === false &&
        createResult.error === 'FORMS_ACTION_CONTAINS_PLACEHOLDER_TEXT'
      ) {
        return from([
          formActionSlice.descriptionError({
            error: 'Placeholder text needs to be updated.',
          }),
          formActionSlice.validationFailed(),
        ]);
      }

      if (!createResult.isSuccess)
        return of(
          formActionSlice.saved({
            actionId,
            isSuccess: false,
            source,
            operation: 'CREATE',
            instanceId: openedFromFormInstanceId,
          }),
        );

      const mutationsAddTextAnswers = draftChanges.answers
        ?.filter(
          (answer): answer is FormActionAnswerText => answer.type === 'Text',
        )
        .map((answer) =>
          formsMutation.updateFormActionAnswerText(
            client,
            actionId,
            answer.questionId,
            answer.value,
          ),
        );

      const mutationsAddDropdownAnswers = draftChanges.answers
        ?.filter(
          (answer): answer is FormActionAnswerDropdown =>
            answer.type === 'Dropdown',
        )
        .map((answer) =>
          formsMutation.updateFormActionAnswerDropdown(
            client,
            actionId,
            answer.questionId,
            answer.selectedOptionIds,
          ),
        );

      const actionAnswerMutations = [
        mutationsAddTextAnswers,
        mutationsAddDropdownAnswers,
      ]
        .flat()
        .filter(Boolean);

      const answerUpdatesSuccess = actionAnswerMutations.length
        ? (await Promise.all(actionAnswerMutations)).every(
            (result) => result.isSuccess,
          )
        : true;

      const allSuccess = answerUpdatesSuccess && createResult.isSuccess;

      const createdAction = createResult.isSuccess
        ? getUpdatedAction(actionId, source, draftChanges)
        : undefined;

      return of(
        formActionSlice.saved({
          actionId,
          isSuccess: allSuccess ? true : 'PARTIAL_SUCCESS',
          updated: createdAction,
          source,
          operation: 'CREATE',
          instanceId: openedFromFormInstanceId,
        }),
      );
    }),
    concatAll(),
  );
}

function updateActionEpic(
  action$: StateObservable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(formActionSlice.save.match),
    filter(
      (action) =>
        state$.value.formActionV2.existingAction &&
        action.payload.actionId ===
          state$.value.formActionV2.existingAction?.id,
    ),
    mergeMap(async (action) => {
      const actionId = action.payload.actionId;

      const existingAction = state$.value.formActionV2.existingAction;
      const draftChanges = state$.value.formActionV2.draftChanges;
      const draftAction = draftChanges.action;

      const source = state$.value.formActionV2.source;
      const formActionTemplate = state$.value.formActionV2.actionTemplate;

      const module = state$.value.tenantState.tenant.module;

      const failResponse = formActionSlice.saved({
        actionId,
        initial: existingAction,
        isSuccess: false,
        operation: 'UPDATE',
        source,
      });

      const validationErrorActions = validateActionFields(
        module,
        draftChanges,
        existingAction,
        formActionTemplate,
      );

      if (validationErrorActions.length > 0)
        return from([
          ...validationErrorActions,
          formActionSlice.validationFailed(),
        ]);

      const mutateAssignedTo =
        draftAction.assignedTo &&
        formsMutation.updateFormActionAssignedToPersonId(
          client,
          actionId,
          draftAction.assignedTo.id,
        );

      const mutateDescription =
        draftAction.description &&
        formsMutation.updateFormActionDescription(
          client,
          actionId,
          draftAction.description,
        );

      const mutateDueBy =
        draftAction.dueBy &&
        formsMutation.updateFormActionDueBy(
          client,
          actionId,
          draftAction.dueBy,
        );

      const mutateLinkedIssueId =
        Boolean(draftAction.issue === null || draftAction.issue?.id) &&
        formsMutation.updateFormActionIssueId(
          client,
          actionId,
          draftAction.issue ? draftAction.issue.id : null,
        );

      const mutateAddNote =
        draftAction.note?.trim() &&
        formsMutation.addFormActionNote(client, actionId, draftAction.note);

      const mutationsAddTextAnswers = draftChanges.answers
        ?.filter(
          (answer): answer is FormActionAnswerText => answer.type === 'Text',
        )
        .map((answer) =>
          formsMutation.updateFormActionAnswerText(
            client,
            actionId,
            answer.questionId,
            answer.value,
          ),
        );

      const mutationsAddDropdownAnswers = draftChanges.answers
        ?.filter(
          (answer): answer is FormActionAnswerDropdown =>
            answer.type === 'Dropdown',
        )
        .map((answer) =>
          formsMutation.updateFormActionAnswerDropdown(
            client,
            actionId,
            answer.questionId,
            answer.selectedOptionIds,
          ),
        );

      const actionFieldMutations = [
        mutateAssignedTo,
        mutateDescription,
        mutateDueBy,
        mutateLinkedIssueId,
        mutateAddNote,
        mutationsAddTextAnswers,
        mutationsAddDropdownAnswers,
      ]
        .flat()
        .filter(Boolean);

      const fieldUpdatesSuccess = (
        await Promise.all(actionFieldMutations)
      ).every((result) => result.isSuccess);

      if (!fieldUpdatesSuccess) return of(failResponse);

      if (draftAction.verificationNotes) {
        const verificationNotesUpdate =
          await formsMutation.updateFormActionVerificationNotes(
            client,
            actionId,
            draftAction.verificationNotes,
          );

        if (!verificationNotesUpdate.isSuccess) return of(failResponse);
      }

      if (draftAction.status && draftAction.status !== 'Overdue')
        return of(
          await updateFormActionStatus(
            client,
            actionId,
            draftChanges,
            existingAction,
            source,
          ),
        );

      if (draftAction.verificationStatus) {
        const verificationStatusUpdate =
          await formsMutation.updateFormActionVerificationStatus(
            client,
            actionId,
            draftAction.verificationStatus,
          );

        if (!verificationStatusUpdate.isSuccess) return of(failResponse);
      }

      return of(
        formActionSlice.saved({
          actionId,
          initial: existingAction,
          isSuccess: true,
          operation: 'UPDATE',
          instanceId: existingAction.instanceId,
          source,
          updated: getUpdatedAction(
            actionId,
            source,
            draftChanges,
            existingAction,
          ),
        }),
      );
    }),
    concatAll(),
  );
}

const getUpdatedAction = (
  actionId: string,
  source: formActionSlice.FormActionSource,
  draftChanges: formActionSlice.DraftFormActionChanges,
  existingAction?: formActionSlice.FormAction,
): formActionSlice.FormAction => {
  const draftAction = draftChanges.action;
  const openedFromFormInstanceId =
    source?.type === 'FORM' ? source.instanceId : undefined;

  if (!existingAction)
    return {
      id: actionId,
      assignedTo: draftAction.assignedTo,
      description: draftAction.description,
      dueBy: draftAction.dueBy,
      note: draftAction.note,
      status: 'Open',
      answerAutomatedActionId: draftAction.answerAutomatedActionId,
      goalId: draftAction.goalId,
      issue: draftAction.issue,
      instanceId: openedFromFormInstanceId,
      type: draftAction.type,
    };

  const computedStatusChange =
    draftAction.verificationStatus === 'Returned' ? 'Open' : undefined;

  return {
    id: actionId,
    answerAutomatedActionId: existingAction.answerAutomatedActionId,
    assignedTo: draftAction.assignedTo ?? existingAction.assignedTo,
    description: draftAction.description ?? existingAction.description,
    dueBy: draftAction.dueBy ?? existingAction.dueBy,
    note: draftAction.note ?? existingAction.note,
    status: computedStatusChange ?? draftAction.status ?? existingAction.status,
    goalId: existingAction.goalId,
    issue:
      draftAction.issue === null
        ? null
        : (draftAction.issue ?? existingAction.issue),
    instanceId: existingAction.instanceId,
    type: existingAction.type,
  };
};

const updateFormActionStatus = async (
  client: IGraphQLClient,
  actionId: string,
  draftChanges: formActionSlice.DraftFormActionChanges,
  existingAction: formActionSlice.FormAction,
  source: formActionSlice.FormActionSource,
) => {
  const draftAction = draftChanges.action;
  const openedFromFormInstanceId =
    source?.type === 'FORM' ? source.instanceId : undefined;
  let statusUpdateSuccess = true;

  if (draftAction.status === 'Cancelled')
    statusUpdateSuccess = (
      await formsMutation.cancelFormAction(
        client,
        actionId,
        openedFromFormInstanceId,
      )
    ).isSuccess;

  if (draftAction.status === 'Completed')
    statusUpdateSuccess = (
      await formsMutation.completeFormAction(
        client,
        actionId,
        openedFromFormInstanceId,
      )
    ).isSuccess;

  if (draftAction.status === 'Open')
    statusUpdateSuccess = (
      await formsMutation.revertFormAction(client, actionId)
    ).isSuccess;

  return formActionSlice.saved({
    actionId,
    initial: existingAction,
    isSuccess: statusUpdateSuccess,
    operation: 'UPDATE',
    source,
    instanceId: existingAction.instanceId,
    updated: statusUpdateSuccess
      ? getUpdatedAction(actionId, source, draftChanges, existingAction)
      : undefined,
  });
};
