import { DigitalContentDefinition } from './types/digitalContent.types.ts';
import { IDigitalContentLine } from '../types/types.ts';

export function parseDigitalContent(
  item: DigitalContentDefinition,
): IDigitalContentLine {
  return {
    id: 'digitalContent',
    type: 'digitalContent',
    showIndex: item.isEnabled ?? true,
    commentsEnabled: item.commentsEnabled ?? true,
    commentsGuidance: item.commentsGuidance ?? '',
    description: item.description ?? '',
    files: item.files ?? [],
    rejections: item.rejections,
    isEnabled: item.isEnabled ?? true,
    packId: item.packId,
    title: item.title ?? 'Digital Content',
    highlightOnHover: false,
  };
}
