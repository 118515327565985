import pluralize from 'pluralize';

/**
 * Pluralizes a word.
 */
export function plural(input?: string | null, total?: number) {
  return input ? pluralize(input, total) : input;
}

/**
 * Singularizes a word.
 */
export function singular(input?: string | null) {
  return input ? pluralize.singular(input) : input;
}
