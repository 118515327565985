import { DraftFormActionChanges, FormAction } from './formActionSlice.ts';
import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import * as formActionSlice from './formActionSlice.ts';
import {
  FormActionAnswerDropdown,
  FormActionAnswerText,
} from '@se/data/forms/types.ts';

export const validateActionFields = (
  module: ModuleType,
  draftChanges: DraftFormActionChanges,
  existingAction?: FormAction,
  formActionTemplate?: formActionSlice.FormActionTemplate,
) =>
  [
    getNoteError(module, draftChanges, existingAction),
    getDueByError(draftChanges, existingAction),
    getAssignedToError(draftChanges, existingAction),
    getDescriptionError(draftChanges, existingAction),
    getVerificationNotesError(draftChanges, existingAction),
    ...getActionTextAnswerErrors(
      draftChanges,
      existingAction,
      formActionTemplate,
    ),
    ...getActionDropdownAnswerErrors(
      draftChanges,
      existingAction,
      formActionTemplate,
    ),
  ].filter(Boolean);

const getNoteError = (
  module: ModuleType,
  draftChanges: DraftFormActionChanges,
  existingAction: FormAction,
) => {
  if (module !== 'compliance') return undefined;
  if (!['Completed', 'Cancelled'].includes(draftChanges.action.status))
    return undefined;

  const draftNote = draftChanges.action.note;
  const existingNote = existingAction?.note;

  if (draftNote !== undefined && draftNote.trim() === '')
    return formActionSlice.noteError({
      error: 'This field is required.',
    });

  const hasDraftNote = draftNote !== undefined && draftNote.trim() !== '';
  const hasExistingNote = Boolean(existingNote);

  return (
    !hasDraftNote &&
    !hasExistingNote &&
    formActionSlice.noteError({ error: 'This field is required.' })
  );
};

const getDueByError = (
  draftChanges: DraftFormActionChanges,
  existingAction: FormAction,
) => {
  const draftDueBy = draftChanges.action.dueBy;
  const existingDueBy = existingAction?.dueBy;

  const hasDraftDueBy = draftDueBy !== undefined && draftDueBy.trim() !== '';
  const hasExistingDueBy = Boolean(existingDueBy);

  return (
    !hasDraftDueBy &&
    !hasExistingDueBy &&
    formActionSlice.dueByError({ error: 'This field is required.' })
  );
};

const getAssignedToError = (
  draftChanges: DraftFormActionChanges,
  existingAction: FormAction,
) => {
  const draftAssignedTo = draftChanges.action.assignedTo;
  const existingAssignedTo = existingAction?.assignedTo;

  const hasDraftAssignedTo = Boolean(draftAssignedTo);
  const hasExistingAssignedTo = Boolean(existingAssignedTo);

  return (
    !hasDraftAssignedTo &&
    !hasExistingAssignedTo &&
    formActionSlice.assignedToError({ error: 'This field is required.' })
  );
};

const getDescriptionError = (
  draftChanges: DraftFormActionChanges,
  existingAction: FormAction,
) => {
  const draftDescription = draftChanges.action.description;
  const existingDescription = existingAction?.description;

  if (draftDescription !== undefined && draftDescription.trim() === '')
    return formActionSlice.descriptionError({
      error: 'This field is required.',
    });

  const hasDraftDescription = draftDescription !== undefined;
  const hasExistingDescription = Boolean(existingDescription);

  return (
    !hasDraftDescription &&
    !hasExistingDescription &&
    formActionSlice.descriptionError({ error: 'This field is required.' })
  );
};

const getVerificationNotesError = (
  draftChanges: DraftFormActionChanges,
  existingAction: FormAction,
) => {
  const updatedVerificationStatus = draftChanges.action.verificationStatus;
  if (!updatedVerificationStatus) return;
  if (updatedVerificationStatus === 'Unverified') return;

  const draftVerificationNotes = draftChanges.action.verificationNotes;
  const existingVerificationNotes = existingAction?.verificationNotes;

  if (
    draftVerificationNotes !== undefined &&
    draftVerificationNotes.trim() === ''
  )
    return formActionSlice.verificationNotesError({
      error: 'This field is required.',
    });

  const hasDraftVerificationNotes = draftVerificationNotes !== undefined;
  const hasExistingVerificationNotes = Boolean(existingVerificationNotes);

  return (
    !hasDraftVerificationNotes &&
    !hasExistingVerificationNotes &&
    formActionSlice.verificationNotesError({
      error: 'This field is required.',
    })
  );
};

const getActionTextAnswerErrors = (
  draftChanges: DraftFormActionChanges,
  existingAction: FormAction,
  formActionTemplate?: formActionSlice.FormActionTemplate,
) => {
  if (!formActionTemplate) return [];
  if (draftChanges.action.status !== 'Completed') return [];

  return formActionTemplate.questions
    .filter((question) =>
      Boolean(question.isRequired && question.type === 'Text'),
    )
    .map((question) => {
      const draftAnswer = draftChanges.answers.find(
        (a): a is FormActionAnswerText =>
          a.questionId === question.id && a.type === 'Text',
      );

      const existingAnswer = existingAction?.answers?.find(
        (a): a is FormActionAnswerText =>
          a.questionId === question.id && a.type === 'Text',
      );

      const draftAnswerValue = draftAnswer?.value;
      if (draftAnswerValue !== undefined && draftAnswerValue.trim() === '')
        return formActionSlice.answerError({
          questionId: question.id,
          error: 'This field is required.',
        });

      const hasDraftAnswerValue =
        draftAnswerValue !== undefined && draftAnswerValue.trim() !== '';
      const hasExistingAnswerValue = Boolean(existingAnswer?.value);

      return (
        !hasDraftAnswerValue &&
        !hasExistingAnswerValue &&
        formActionSlice.answerError({
          questionId: question.id,
          error: 'This field is required.',
        })
      );
    });
};

const getActionDropdownAnswerErrors = (
  draftChanges: DraftFormActionChanges,
  existingAction: FormAction,
  formActionTemplate?: formActionSlice.FormActionTemplate,
) => {
  if (!formActionTemplate) return [];
  if (draftChanges.action.status !== 'Completed') return [];

  return formActionTemplate.questions
    .filter((question) =>
      Boolean(question.isRequired && question.type === 'Dropdown'),
    )
    .map((question) => {
      const draftAnswer = draftChanges.answers.find(
        (a): a is FormActionAnswerDropdown =>
          a.questionId === question.id && a.type === 'Dropdown',
      );

      const existingAnswer = existingAction?.answers?.find(
        (a): a is FormActionAnswerDropdown =>
          a.questionId === question.id && a.type === 'Dropdown',
      );

      const draftAnswerSelectedOptionIds = draftAnswer?.selectedOptionIds;
      if (
        draftAnswerSelectedOptionIds !== undefined &&
        draftAnswerSelectedOptionIds.length === 0
      )
        return formActionSlice.answerError({
          questionId: question.id,
          error: 'This field is required.',
        });

      const hasDraftAnswerValue =
        draftAnswerSelectedOptionIds !== undefined &&
        draftAnswerSelectedOptionIds.length > 0;

      const hasExistingAnswerValue = Boolean(
        existingAnswer?.selectedOptionIds?.length,
      );

      return (
        !hasDraftAnswerValue &&
        !hasExistingAnswerValue &&
        formActionSlice.answerError({
          questionId: question.id,
          error: 'This field is required.',
        })
      );
    });
};
