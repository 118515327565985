import { Observable, mergeMap } from 'rxjs';
import { StateObservable, ofType } from 'redux-observable';
import { downloadFileInFrame } from '@seeeverything/ui.util/src/download/index.ts';
import { ReduxFormDigitalContentDownloadFile } from './types.ts';
import {
  digitalContentDownloadComplete,
  digitalContentDownloadError,
} from './actions.ts';
import { GlobalFormsState } from '../store.ts';

export function queryDownloadLinkEpic(
  action$: Observable<ReduxFormDigitalContentDownloadFile>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    ofType('ui.forms/digital-content/file/DOWNLOAD_FILE'),
    mergeMap(async ({ payload: { fileId } }) => {
      const url = state$.value.formDigitalContent.contentMeta?.files.find(
        (file) => file.id === fileId,
      )?.downloadLink;

      if (!url)
        return digitalContentDownloadError(
          fileId,
          'Unable to download the file. Please retry.',
        );

      downloadFileInFrame(url);
      return digitalContentDownloadComplete(fileId);
    }),
  );
}
