/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { makeStyles, Button as MaterialButton } from '@material-ui/core';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import React from 'react';
import { CommonStyles } from '../../common/commonStyles.ts';
import { CSSProperties } from '../../types/types.ts';
import { IIcon } from '../Icon/types.ts';
import { ITextProps, Text } from '../Text/Text.tsx';
import { Tooltip } from '../Tooltip/Tooltip.tsx';
import * as themes from './themes.ts';
import { ThemeType } from './themes.ts';

export interface ITextButtonProps extends ITextProps {
  // Material UI Props.
  variant?: 'text' | 'outlined' | 'contained';
  buttonSize?: 'small' | 'medium' | 'large';
  fullWidth?: boolean;
  // Custom Props.
  isEnabled?: boolean;
  theme?: ThemeType;
  textStyle?: SerializedStyles;
  icon?: IIcon;
  iconStyle?: CSSProperties;
  iconSize?: number;
  iconSide?: 'LEFT' | 'RIGHT';
  align?: 'left' | 'center' | 'right';
  highlightOnHover?: boolean;
  dataTest?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  transitionDuration?: string;
  textButtonStyle?: CSSProperties;
}

const useButtonStyles = (props: ITextButtonProps) => {
  const {
    textButtonStyle,
    align,
    iconStyle,
    highlightOnHover = true,
    color: propColor = COLORS.BLUE,
    transitionDuration = '150ms',
  } = props;
  return makeStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: align,
      color: color.create(propColor).alpha(0.4).css(), // Changes the ripple color.
      '&:hover': {
        background: highlightOnHover
          ? color.create(propColor).alpha(0.04).css()
          : 'transparent',
      },
      ...CommonStyles.MaterialCubicTransitions,
      transitionDuration,
      ...textButtonStyle,
    },
    endIcon: {
      ...iconStyle,
    },
    startIcon: {
      ...iconStyle,
    },
  });
};

/**
 * A button that is made of text.
 */
export const TextButton: React.FC<ITextButtonProps> = React.forwardRef(
  function TextButton(props, ref) {
    const {
      isEnabled = true,
      variant = 'text',
      buttonSize = 'small',
      uppercase = false,
      fullWidth,
      textStyle,
      iconStyle,
      children,
      onClick,
      onMouseEnter,
      onMouseLeave,
      tooltip,
      dataTest,
      ...restProps
    } = props;
    const theme = props.theme || 'DARK';
    const themeColor = themes.textColor(theme);
    const textColor = isEnabled ? themeColor.ENABLED : themeColor.DISABLED;
    const cursor = isEnabled ? 'pointer' : 'default';
    const classes = useButtonStyles(props)();
    const computedStyles = {
      icon: css({
        cursor,
        display: 'flex',
        margin: '0 auto',
      }),
      text: css({
        cursor,
      }),
    };

    const { icon: Icon, iconSide = 'LEFT', iconSize = 24 } = props;
    const iconThemeColor = themes.iconColor(theme);
    const iconColor = isEnabled
      ? iconThemeColor.ENABLED
      : iconThemeColor.DISABLED;

    const elIcon = Icon && (
      <Icon size={iconSize} fill={iconColor} style={iconStyle} />
    );
    const elLeftIcon = elIcon && iconSide === 'LEFT' && (
      <div data-test={dataTest} css={computedStyles.icon}>
        {elIcon}
      </div>
    );
    const elRightIcon = elIcon && iconSide === 'RIGHT' && (
      <div data-test={dataTest} css={computedStyles.icon}>
        {elIcon}
      </div>
    );

    const elButton = (
      <MaterialButton
        startIcon={elLeftIcon}
        endIcon={elRightIcon}
        disabled={!isEnabled}
        size={buttonSize}
        variant={variant}
        fullWidth={fullWidth}
        onClick={onClick}
        classes={{
          root: classes.root,
          endIcon: classes.endIcon,
          startIcon: classes.startIcon,
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        innerRef={ref}
      >
        <Text
          color={textColor}
          uppercase={uppercase}
          tooltipArrow={true}
          ellipsis={true}
          {...restProps}
          style={css(computedStyles.text, textStyle)}
        >
          {children}
        </Text>
      </MaterialButton>
    );

    return tooltip ? <Tooltip title={tooltip}>{elButton}</Tooltip> : elButton;
  },
);
