import { formsQuery } from '@se/data/forms/query/index.ts';
import { downloadFileInFrame } from '@seeeverything/ui.util/src/download/index.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { combineEpics, StateObservable } from 'redux-observable';
import { filter, mergeMap, Observable } from 'rxjs';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import * as formBulkUploadDownloadFailedRowsSlice from './formBulkUploadDownloadFailedRowsSlice.ts';

export const formBulkUploadDownloadFailedRowsEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(failedRowsDownloadLinkEpic);

function failedRowsDownloadLinkEpic(
  action$: Observable<ReduxAction>,
  _: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(formBulkUploadDownloadFailedRowsSlice.requestDownloadLink.match),
    mergeMap(async (action) => {
      try {
        const downloadLinkResponse =
          await formsQuery.getFormsBulkUploadJobHistoryFailedDownload(
            client,
            action.payload.jobId,
          );

        if (downloadLinkResponse.isSuccess) {
          downloadFileInFrame(downloadLinkResponse.data);
          return formBulkUploadDownloadFailedRowsSlice.downloadComplete({
            jobId: action.payload.jobId,
          });
        }

        return formBulkUploadDownloadFailedRowsSlice.downloadFailed({
          jobId: action.payload.jobId,
        });
      } catch (error) {
        log.error(
          new Error(
            `Error downloading bulk failures with job id '${action.payload.jobId}': ${error?.message}`,
          ),
        );
        return formBulkUploadDownloadFailedRowsSlice.downloadFailed({
          jobId: action.payload.jobId,
        });
      }
    }),
  );
}
