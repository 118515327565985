/** @jsxImportSource @emotion/react */
import { FormInstanceActionPlanType } from '@se/data/forms/types.ts';
import { useFormsSelector } from '../../../redux/store.ts';
import { StandaloneActions } from './StandaloneActions.tsx';

export type ActionPlanWithoutSectionsProps = {
  includeCategories: string[];
  instanceId: string;
  lineId: string;
  typeFilter?: FormInstanceActionPlanType;
};

export const ActionPlanWithoutSections: React.FC<
  ActionPlanWithoutSectionsProps
> = ({ lineId, instanceId, typeFilter }) => {
  const actions = useFormsSelector((state) => {
    const plan = state.formActionPlan.actionPlan[lineId].plan;
    return plan.hasSections === false ? plan.actions : [];
  });

  const canCreateInInstance = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    if (!instance) return false;
    if (!instance.permissions.edit) return false;

    if (instance.status.status === 'InProgress') return true;
    if (instance.status.status === 'Created') return true;

    return false;
  });

  return (
    <StandaloneActions
      instanceId={instanceId}
      isDefaultExpanded={false}
      actions={actions}
      canCreateActions={canCreateInInstance}
      typeFilter={typeFilter}
      categoryGrouping={false}
    />
  );
};
