import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { QueryResult, SimpleEntity } from '../../types.ts';
import { FormAutomatedAction } from '../types.ts';

type GetAutomatedActionsArgs = {
  templateId?: string;
  showAll?: boolean;
};

export const getAutomatedActions = async (
  client: IGraphQLClient,
  args: GetAutomatedActionsArgs,
): Promise<QueryResult<FormAutomatedAction[]>> => {
  try {
    const response = await client.query<{
      forms: {
        automatedActions: Array<{
          id: string;
          description: string;
          templateId: string;
          matchIssueLabel: string;
          matchIssueClassification: string | null;
          inCurrentDefinition: boolean;
          updatedAt: string;
          updatedBy: SimpleEntity;
        }>;
      };
    }>({
      query: gql`
        query AutomatedActions($templateId: ID!, $showAll: Boolean) {
          forms {
            automatedActions(templateId: $templateId, showAll: $showAll) {
              id
              description
              templateId
              matchIssueLabel
              matchIssueClassification
              inCurrentDefinition
              updatedAt
              updatedBy {
                id
                name
              }
            }
          }
        }
      `,
      variables: { templateId: args.templateId, showAll: args.showAll },
      fetchPolicy: 'network-only',
    });

    const automatedActions = response.data.forms.automatedActions;

    if (!automatedActions) {
      log.error(
        `Failed to retrieve automated actions for template ${args.templateId}`,
      );
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: automatedActions.map((a) => ({
        id: a.id,
        description: a.description,
        templateId: a.templateId,
        matchIssueLabel: a.matchIssueLabel,
        matchIssueClassification: a.matchIssueClassification ?? null,
        inCurrentDefinition: a.inCurrentDefinition,
        updatedAt: a.updatedAt,
        updatedBy: { id: a.updatedBy.id, name: a.updatedBy.name },
      })),
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query automated actions for ${args.templateId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
