/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from '@emotion/react';
import {
  FormControl,
  FormHelperText,
  InputProps as MaterialInputProps,
  TextField as MaterialTextField,
} from '@material-ui/core';
import { forwardRef, useCallback, useState } from 'react';
import { CommonStyles } from '../../common/commonStyles.ts';
import { useForceRenderOnce } from '../../hooks/useForceRenderOnce.ts';
import { MarkdownTextField } from '../MarkdownTextField/MarkdownTextField.tsx';
import { Tooltip } from '../Tooltip/Tooltip.tsx';

export type TextFieldChangeEventHandler = (to: string) => void;
export type TextFieldKeyDownEventHandler = (keyPressed: string) => void;

export interface ITextFieldProps {
  dataTest?: string;
  defaultValue?: string;
  error?: string;
  helperText?: string;
  id: string;
  inputLabelStyle?: React.CSSProperties;
  InputProps?: MaterialInputProps;
  isEnabled?: boolean;
  label?: string;
  maxRows?: number;
  minRows?: number;
  multiline?: boolean;
  onBlur?: () => void;
  onChange?: TextFieldChangeEventHandler;
  onKeyDown?: TextFieldKeyDownEventHandler;
  placeholder?: string;
  style?: SerializedStyles;
  tooltip?: string;
  value?: string;
  variant?: 'standard' | 'filled' | 'outlined';
}

/**
 * A text input field with dropdown capability.
 * See:
 *  https://material-ui.com/api/text-field/
 *  https://material-ui.com/components/text-fields/
 */
export const TextField = forwardRef(function TextField(
  {
    dataTest,
    defaultValue,
    error,
    helperText,
    id,
    inputLabelStyle,
    InputProps,
    isEnabled = true,
    label,
    maxRows,
    minRows,
    multiline = false,
    onBlur,
    onChange,
    onKeyDown,
    placeholder,
    style,
    tooltip,
    value = '',
    variant = 'standard',
  }: ITextFieldProps,
  ref,
) {
  const [isFocused, setIsFocused] = useState(false);
  const focus = useCallback(() => setIsFocused(true), []);
  const blur = useCallback(() => {
    setIsFocused(false);
    onBlur?.();
  }, [onBlur]);

  /**
   * Bug in Material UI TextField height calculation.
   * Multi-line and long text isn't calculated correctly by Material UI,
   * but fixed after a re-render.
   */
  useForceRenderOnce();

  const handleChange = (e: { target: { value: string } }) =>
    onChange?.(e.target.value);
  const handleKeyDown = (e: { key: string }) => onKeyDown?.(e.key);

  const elHint = (error || helperText) && (
    <FormHelperText error={Boolean(error)}>
      {error || helperText}
    </FormHelperText>
  );

  const ellipsisPlaceholder = isFocused || Boolean(value);

  const computedStyles = {
    base: css([
      {
        width: '100%',
        position: 'relative',
        display: 'inline-block',
      },
      error ? CommonStyles.AnimationShake : undefined,
      style,
    ]),
  };

  const elTextField = (
    <div css={computedStyles.base}>
      <FormControl fullWidth={true}>
        {isEnabled ? (
          <MaterialTextField
            autoComplete={'off'}
            data-test={dataTest}
            id={id}
            defaultValue={defaultValue}
            fullWidth={true}
            value={value}
            label={label}
            error={Boolean(error)}
            onBlur={blur}
            onFocus={focus}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            disabled={!isEnabled}
            multiline={multiline}
            minRows={minRows}
            maxRows={maxRows}
            placeholder={placeholder}
            variant={variant}
            InputProps={InputProps}
            InputLabelProps={{
              style: {
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: ellipsisPlaceholder ? '133%' : '100%',
                height: '100%',
                ...inputLabelStyle,
              },
            }}
            inputRef={ref}
          />
        ) : (
          <MarkdownTextField label={label} value={value} isEnabled={false} />
        )}
      </FormControl>
      {elHint}
    </div>
  );

  const tooltipLabel = tooltip || label;

  return tooltipLabel ? (
    <Tooltip title={tooltipLabel}>{elTextField}</Tooltip>
  ) : (
    elTextField
  );
});
