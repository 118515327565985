/** @jsxImportSource @emotion/react */
import React, { CSSProperties } from 'react';
import { List } from '@material-ui/core';
import MaterialListItem from './components/MaterialListItem.tsx';
import { MaterialListItemType, MaterialListItemClickEvent } from './types.ts';

export interface IMaterialListProps {
  // Material UI Props
  dense?: boolean;
  disablePadding?: boolean;
  emptyMessage?: string;
  style?: CSSProperties;
  items?: MaterialListItemType[];
  initialSelectedIndex?: number;
  isDeselectable?: boolean;
  isSelectable?: boolean;
  selectedId?: string;
  onScrolledToItem?: (id: string, index: number) => void;
  onListItemClick?: (e: MaterialListItemClickEvent) => void;
}

const EMPTY_LIST_ITEM = (message: string): MaterialListItemType => ({
  id: 'EMPTY_LIST_MESSAGE',
  value: 'EMPTY',
  isDimmed: true,
  text: message,
});

/**
 * Wrapper for a <List /> provided by material ui. Named <Material List /> as a distinction from our own
 * hand-rolled <List />
 *
 * @see https://material-ui.com/components/lists/
 * @see https://material-ui.com/api/list/
 */
const MaterialList = ({
  disablePadding = true,
  initialSelectedIndex = -1,
  isSelectable = true,
  isDeselectable = true,
  dense,
  items = [],
  style,
  onListItemClick,
  emptyMessage,
  onScrolledToItem,
  selectedId,
}: IMaterialListProps) => {
  const [selectedIndex, setSelectedIndex] =
    React.useState<number>(initialSelectedIndex);

  const handleListItemClick = React.useCallback(
    (e: MaterialListItemClickEvent) => {
      if (selectedIndex === e.index && isDeselectable) {
        // Deselects an item if it's clicked after already being selected
        setSelectedIndex(-1);
      } else if (isSelectable) {
        setSelectedIndex(e.index);
      }
      onListItemClick?.(e);
    },
    [isDeselectable, isSelectable, onListItemClick, selectedIndex],
  );

  const handleItemScrolled = React.useCallback(
    (id: string, index: number) => () => onScrolledToItem?.(id, index),
    [onScrolledToItem],
  );

  const elItems = React.useMemo(
    () =>
      items.map((item, i) => (
        <MaterialListItem
          key={`list-item-${item.id}-${i}`}
          item={item}
          index={i}
          onClick={handleListItemClick}
          isSelected={selectedIndex === i || item.id === selectedId}
          onEnterViewport={handleItemScrolled(item.id, i)}
        />
      )),
    [handleItemScrolled, handleListItemClick, items, selectedId, selectedIndex],
  );

  const elEmptyMessage = React.useMemo(
    () =>
      emptyMessage &&
      elItems.length === 0 && (
        <MaterialListItem
          key={'empty-list-item'}
          isSelected={false}
          index={0}
          item={EMPTY_LIST_ITEM(emptyMessage)}
        />
      ),
    [elItems.length, emptyMessage],
  );

  const listStyle = React.useMemo(
    () => ({ ...styles.base, ...style }),
    [style],
  );

  return (
    <List dense={dense} disablePadding={disablePadding} style={listStyle}>
      {elEmptyMessage || elItems}
    </List>
  );
};

const styles = {
  base: {
    overflow: 'auto',
  },
};

export default React.memo(MaterialList);
