import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import {
  FormAnswerAutomatedAction,
  FormIssueAnswerDefinition,
  ServerAutomatedAction,
} from '../types.ts';
import { mapAutomatedAction } from './automatedActionsUtil.ts';

type GetAnswerAutomatedActionsArgs = {
  instanceId: string;
  questionKeys: string[];
  issueAnswerDefinitions: Record<string, FormIssueAnswerDefinition[]>;
};

export const getAnswerAutomatedActions = async (
  client: IGraphQLClient,
  args: GetAnswerAutomatedActionsArgs,
): Promise<QueryResult<Record<string, FormAnswerAutomatedAction[]>>> => {
  try {
    const response = await client.query<{
      forms: {
        formInstance: {
          answers: {
            nodes: Array<{
              id: string;
              key: string;
              value: string;
              issue: { id: string };
              automatedActions: ServerAutomatedAction[];
            }>;
          };
        };
      };
    }>({
      query: gql`
        query FormInstanceAnswerAutomatedActions($instanceId: ID!) {
          forms {
            formInstance(id: $instanceId) {
              answers(
                orderBy: { fieldName: "createdAt", direction: Descending }
              ) {
                nodes {
                  id
                  key
                  value
                  issue {
                    id
                  }
                  automatedActions {
                    id
                    description
                    label
                    createdActionId
                    automatedAction {
                      matchIssueClassification
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: { instanceId: args.instanceId },
      fetchPolicy: 'network-only',
    });

    const formInstance = response.data.forms.formInstance;

    if (!formInstance) {
      log.error(
        `Failed to retrieve form instance answer automated actions for ${args.instanceId}`,
      );
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: (formInstance.answers?.nodes ?? []).reduce((acc, answer) => {
        if (!answer.automatedActions?.length) return acc;

        return {
          ...acc,
          [answer.id]: (answer.automatedActions ?? []).map((automatedAction) =>
            mapAutomatedAction({
              answerKey: answer.key,
              answerValue: answer.value,
              input: automatedAction,
              issueAnswerDefinitions: args.issueAnswerDefinitions,
              issueId: answer.issue.id,
              questionKeys: args.questionKeys,
            }),
          ),
        };
      }, {}),
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query form instance answer automated actions for ${args.instanceId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
