/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import { Score } from './Score.tsx';
import {
  FormResult,
  RemediationOutcome,
} from '../../redux/form-score/types.ts';
import {
  remediationChanged,
  remediationReasonChanged,
  formScoreResultChanged,
} from '../../redux/form-score/actions.ts';
import { useFormsDispatch, useFormsSelector } from '../../redux/store.ts';

export interface IScoreContainerProps {
  canMarkProvisional: boolean;
  canOverruleNotApplicable: boolean;
  canOverruleResult: boolean;
  canRemediateProvisional: boolean;
  failLabel: string;
  hasScoreLabels: boolean;
  instanceId: string;
  isPdfExport: boolean;
  isProvisionalEditable: boolean;
  isResultEditable: boolean;
  passLabel: string;
  showScoreCalculation: boolean;
  speechText: string;
}

export const ScoreContainer: React.FC<IScoreContainerProps> = ({
  canMarkProvisional,
  canOverruleNotApplicable,
  canOverruleResult,
  canRemediateProvisional,
  failLabel,
  hasScoreLabels,
  instanceId,
  isPdfExport,
  isProvisionalEditable,
  isResultEditable,
  passLabel,
  showScoreCalculation,
  speechText,
}) => {
  const dispatch = useFormsDispatch();

  const formScore = useFormsSelector((state) => state.formScore);

  const errors = useFormsSelector((state) => {
    const scoreErrors = state.formScore.errors;
    const instance = state.formInstance.instances[instanceId];

    return {
      ...scoreErrors,
      overriddenResult:
        instance?.questionErrors.scoreOverrule ?? scoreErrors?.overriddenResult,
    };
  });

  const handleRemediationChange = useCallback(
    (to: RemediationOutcome) => {
      dispatch(remediationChanged(instanceId, to));
    },
    [dispatch, instanceId],
  );

  const handleRemediationReasonChange = useCallback(
    (to: string) => {
      if (formScore?.remediation === 'Pending') return;
      dispatch(remediationReasonChanged(instanceId, to));
    },
    [dispatch, formScore?.remediation, instanceId],
  );

  const handleResultChange = useCallback(
    (to: FormResult) => {
      dispatch(formScoreResultChanged(instanceId, to));
    },
    [dispatch, instanceId],
  );

  const percentageScore =
    showScoreCalculation && formScore.score?.result !== FormResult.NotApplicable
      ? formScore.score?.percentageScore
      : undefined;

  return (
    <Score
      calculatedResult={formScore.score?.result}
      canMarkProvisional={canMarkProvisional}
      canOverruleNotApplicable={canOverruleNotApplicable}
      canOverruleResult={canOverruleResult}
      canRemediateProvisional={canRemediateProvisional}
      errors={errors}
      failLabel={failLabel}
      hasScoreLabels={hasScoreLabels}
      instanceId={instanceId}
      isPdfExport={isPdfExport}
      isProvisionalEditable={isProvisionalEditable}
      isResultEditable={isResultEditable}
      onRemediationChange={handleRemediationChange}
      onRemediationReasonChange={handleRemediationReasonChange}
      onResultChange={handleResultChange}
      overruledResult={formScore.overruledResult}
      passLabel={passLabel}
      percentageScore={percentageScore}
      remediation={formScore.remediation}
      remediationReason={formScore.remediationReason}
      speechText={speechText}
    />
  );
};
