import {
  FormScore,
  ReduxFormScoreRemediationChanged,
  ReduxFormScoreRemediationChangedError,
  ReduxFormScoreRemediationChangedSuccess,
  ReduxFormScoreRemediationReasonChanged,
  ReduxFormScoreRemediationReasonChangedError,
  ReduxFormScoreRemediationReasonChangedSuccess,
  ReduxFormScoreResultChanged,
  ReduxFormScoreResultSavedError,
  ReduxFormScoreResultSavedSuccess,
  ReduxFormScoreLoadedFromInstance,
  ReduxFormScoreScoreChanged,
  RemediationOutcome,
  FormResult,
} from './types.ts';

export const formScoreResultChanged = (
  instanceId: string,
  to: FormResult,
): ReduxFormScoreResultChanged => ({
  type: 'ui.forms/score/RESULT_CHANGED',
  payload: { instanceId, to },
});

export const formScoreResultSaveSuccess = (
  instanceId: string,
): ReduxFormScoreResultSavedSuccess => ({
  type: 'ui.forms/score/RESULT_SAVED_SUCCESS',
  payload: { instanceId },
});

export const resultChangedError = (
  instanceId: string,
): ReduxFormScoreResultSavedError => ({
  type: 'ui.forms/score/RESULT_SAVED_ERROR',
  payload: { instanceId },
});

export const remediationChanged = (
  instanceId: string,
  to: RemediationOutcome,
): ReduxFormScoreRemediationChanged => ({
  type: 'ui.forms/score/REMEDIATION_CHANGED',
  payload: { instanceId, to },
});

export const remediationChangedSuccess = (
  instanceId: string,
): ReduxFormScoreRemediationChangedSuccess => ({
  type: 'ui.forms/score/REMEDIATION_CHANGED_SUCCESS',
  payload: { instanceId },
});

export const remediationChangedError = (
  instanceId: string,
): ReduxFormScoreRemediationChangedError => ({
  type: 'ui.forms/score/REMEDIATION_CHANGED_ERROR',
  payload: { instanceId },
});

export const remediationReasonChanged = (
  instanceId: string,
  to: string,
): ReduxFormScoreRemediationReasonChanged => ({
  type: 'ui.forms/score/REMEDIATION_REASON_CHANGED',
  payload: { instanceId, to },
});

export const remediationReasonChangedSuccess = (
  instanceId: string,
): ReduxFormScoreRemediationReasonChangedSuccess => ({
  type: 'ui.forms/score/REMEDIATION_REASON_CHANGED_SUCCESS',
  payload: { instanceId },
});

export const remediationReasonChangedError = (
  instanceId: string,
): ReduxFormScoreRemediationReasonChangedError => ({
  type: 'ui.forms/score/REMEDIATION_REASON_CHANGED_ERROR',
  payload: { instanceId },
});

export const scoreLoadedFromInstance = (
  instanceId: string,
  overruledResult?: FormResult,
  remediation?: RemediationOutcome,
  remediationReason?: string,
): ReduxFormScoreLoadedFromInstance => ({
  type: 'ui.forms/score/LOADED_FROM_INSTANCE',
  payload: { instanceId, overruledResult, remediation, remediationReason },
});

export const scoreChanged = (
  instanceId: string,
  score?: FormScore,
): ReduxFormScoreScoreChanged => ({
  type: 'ui.forms/score/SCORE_CHANGED',
  payload: { instanceId, score },
});
