import {
  ReduxFormDigitalContentLoad,
  IContentPackMetaState,
  ReduxFormDigitalContentLoaded,
  ReduxFormDigitalContentLoadError,
  ReduxFormDigitalContentDownloadFile,
  ReduxFormDigitalContentDownloadComplete,
  ReduxFormDigitalContentDownloadError,
  ReduxFormDigitalContentStreamContentFile,
  ReduxFormDigitalContentStreamDismissPlayer,
  ReduxFormDigitalContentStreamRefreshPlayer,
  ReduxFormDigitalContentStreamPlayerError,
} from './types.ts';

export const digitalContentLoad = (
  packId: string,
  dashboardComponentId?: string,
): ReduxFormDigitalContentLoad => ({
  type: 'ui.forms/digital-content/LOAD',
  payload: { packId, dashboardComponentId },
});

export const digitalContentLoaded = (
  content: IContentPackMetaState,
  dashboardComponentId?: string,
): ReduxFormDigitalContentLoaded => ({
  type: 'ui.forms/digital-content/LOADED',
  payload: { content, dashboardComponentId },
});

export const digitalContentLoadError = (
  message?: string,
): ReduxFormDigitalContentLoadError => ({
  type: 'ui.forms/digital-content/LOAD_ERROR',
  payload: { message },
});

export const digitalContentDownloadFile = (
  packId: string,
  fileId: string,
): ReduxFormDigitalContentDownloadFile => ({
  type: 'ui.forms/digital-content/file/DOWNLOAD_FILE',
  payload: { packId, fileId },
});

export const digitalContentDownloadComplete = (
  fileId: string,
): ReduxFormDigitalContentDownloadComplete => ({
  type: 'ui.forms/digital-content/file/DOWNLOAD_COMPLETE',
  payload: { fileId },
});

export const digitalContentDownloadError = (
  fileId: string,
  message?: string,
): ReduxFormDigitalContentDownloadError => ({
  type: 'ui.forms/digital-content/file/DOWNLOAD_ERROR',
  payload: { fileId, message },
});

export const digitalContentStreamFile = (
  fileId: string,
): ReduxFormDigitalContentStreamContentFile => ({
  type: 'ui.forms/digital-content/stream/STREAM_FILE',
  payload: { fileId },
});

export const digitalContentStreamDismissPlayer =
  (): ReduxFormDigitalContentStreamDismissPlayer => ({
    type: 'ui.forms/digital-content/stream/DISMISS_PLAYER',
    payload: {},
  });

export const digitalContentStreamRefreshPlayer =
  (): ReduxFormDigitalContentStreamRefreshPlayer => ({
    type: 'ui.forms/digital-content/stream/REFRESH_PLAYER',
    payload: {},
  });

export const digitalContentStreamPlayerError = (
  error: string,
): ReduxFormDigitalContentStreamPlayerError => ({
  type: 'ui.forms/digital-content/stream/PLAYER_ERROR',
  payload: { error },
});
