/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import { usePreventDoubleClick } from '../../../hooks/usePreventDoubleClick.ts';
import { DropdownList } from '../../DropdownList/DropdownList.tsx';
import { Popup } from '../../Popup/Popup.tsx';
import {
  ISelectionListItem,
  SelectionListClickEvent,
} from '../../SelectionList/types.ts';
import { LabelButtonDropdownClickEventHandler } from '../types.ts';

export interface IToolDropdownProps {
  items: ISelectionListItem[];
  emptyMessage?: string;
  width?: string | number;
  minWidth?: string | number;
  margin?: number | string;
  onHide?: () => void;
  onClick?: LabelButtonDropdownClickEventHandler;
}

export const DropdownPopup: React.FC<IToolDropdownProps> = ({
  items,
  emptyMessage,
  width,
  minWidth,
  margin,
  onHide,
  onClick,
}) => {
  const handleClick = usePreventDoubleClick(
    useCallback(
      (e: SelectionListClickEvent) => onClick?.({ id: e.id }),
      [onClick],
    ),
  );

  return (
    <Popup onHide={onHide}>
      <DropdownList
        items={items}
        width={width}
        minWidth={minWidth}
        onClick={handleClick}
        margin={margin}
        emptyMessage={emptyMessage}
      />
    </Popup>
  );
};
