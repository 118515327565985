import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { ofType, StateObservable } from 'redux-observable';
import { concatAll, from, mergeMap, Observable } from 'rxjs';
import {
  FormResult,
  IFormScoreState,
  RemediationOutcome,
} from '../../form-score/types.ts';
import { getScoreState } from '../../form-score/util.calculateScore.ts';
import {
  getCalculatedScore,
  updateScore,
  UpdateScoreInputs,
} from '../../form-score/util.saveScore.ts';
import * as formInstanceAppealSlice from '../../formInstanceAppeal/formInstanceAppealSlice.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../../store.ts';
import { AppealResponse, ReduxFormInstanceServerSignoff } from '../types.ts';
import {
  generalError,
  loadPermissions,
  signoffSuccess,
  validationFailed,
} from './actions.ts';

/**
 * Issues a sign-off through GraphQL.
 */
export function signoffSendToServerEpic(
  action$: Observable<ReduxFormInstanceServerSignoff>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/instance/SERVER_SIGNOFF'),
    mergeMap(async ({ payload }) => {
      const { instanceId, personId, signature, appealResponse } = payload;

      const formScore = getScoreState(state$.value);
      const instance = state$.value.formInstance.instances[instanceId];

      if (formScore && instance.status.status === 'InProgress') {
        const inputs = getUpdateScoreInputs(formScore, instanceId);
        if (inputs) {
          const response = await updateScore(client, inputs);
          if (!response) {
            return from([validationFailed(instanceId)]);
          }
        }
      }

      try {
        const reviewerResponse =
          signature.type === 'REVIEWER' ? appealResponse : undefined;
        await signoff(client, instanceId, personId, reviewerResponse);
      } catch (err) {
        log.error('Error occurred during signoff operation', err);
        return from([generalError()]);
      }

      // Only reload appeal on subject sign-off of an appealed instance
      const reloadAppealActions =
        signature.type === 'SUBJECT' && state$.value.formInstanceAppeal?.request
          ? [formInstanceAppealSlice.reloadAppeal({ instanceId })]
          : [];

      return from([
        signoffSuccess(instanceId, personId, signature, appealResponse),
        loadPermissions(instanceId),
        ...reloadAppealActions,
      ]);
    }),
    concatAll(),
  );
}

const getUpdateScoreInputs = (
  scoreState: IFormScoreState,
  formInstanceId: string,
): UpdateScoreInputs | undefined => {
  if (!scoreState.score) {
    return undefined;
  }

  const { score, overruledResult, remediation } = scoreState;
  const {
    currentWeightPoints,
    result: calculatedResult,
    percentageScore,
  } = score;

  if (calculatedResult === FormResult.NotApplicable && !overruledResult) {
    return undefined;
  }

  const remediationOutcome = getRemediationResult(overruledResult, remediation);
  const outcome = (remediationOutcome ??
    calculatedResult) as UpdateScoreInputs['outcome'];

  const calculatedScore = getCalculatedScore(
    calculatedResult,
    percentageScore,
    overruledResult,
  );

  return {
    finalScore: calculatedScore,
    rawScore: calculatedScore,
    totalWeighting: currentWeightPoints,
    outcome,
    formInstanceId,
  };
};

const getRemediationResult = (
  overruledResult: FormResult,
  remediation: RemediationOutcome,
): FormResult => {
  if (overruledResult !== FormResult.Provisional || !remediation)
    return overruledResult;

  if (remediation === RemediationOutcome.Yes) return FormResult.Pass;
  if (remediation === RemediationOutcome.No) return FormResult.Fail;

  return FormResult.Provisional;
};

async function signoff(
  client: IGraphQLClient,
  instanceId: string,
  personId: string,
  appealResponse?: AppealResponse,
): Promise<void> {
  const mutation = gql`
    mutation AddSignoff(
      $instanceId: ID!
      $personId: ID!
      $appealCategory: String
      $appealOutcome: String
      $appealOutcomeReason: String
      $appealResponse: String
    ) {
      forms {
        signOffFormInstance(
          input: {
            formInstanceId: $instanceId
            personId: $personId
            appealCategory: $appealCategory
            appealOutcome: $appealOutcome
            appealOutcomeReason: $appealOutcomeReason
            appealResponse: $appealResponse
          }
        ) {
          ok
        }
      }
    }
  `;

  const response = await client.mutate<any>({
    mutation,
    variables: {
      instanceId,
      personId,
      appealCategory: appealResponse?.reason,
      appealOutcome: appealResponse?.outcome,
      appealOutcomeReason: appealResponse?.outcomeReason,
      appealResponse: appealResponse?.response,
    },
  });

  if (!response?.data?.forms?.signOffFormInstance?.ok) {
    throw new Error(`Server return not OK for instance: '${instanceId}'`);
  }
}
