import gql from 'graphql-tag';
import { from, Observable, filter, concatMap, mergeMap, concatAll } from 'rxjs';
import { StateObservable, ofType } from 'redux-observable';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import {
  ReduxFormInstanceAttendanceAllAttendedChanged,
  ReduxFormInstanceAttendeeUpdateAttendance,
} from './../types.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../../store.ts';
import { attendeeSaved, updateAttendeeAttendance } from './actions.ts';

export function updateAllAttendeesAttendanceEpic(
  action$: Observable<ReduxFormInstanceAttendanceAllAttendedChanged>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/instance/attendance/UPDATE_ALL_ATTENDANCE'),
    filter(({ payload }) => {
      const instanceId = payload.instanceId;
      const attendees =
        state$.value.formInstance.instances[instanceId]?.attendees;
      return Boolean(attendees?.length);
    }),
    concatMap(async ({ payload }) => {
      const { instanceId, isAttending, updateAttendeeIds } = payload;

      return from(
        await Promise.all(
          updateAttendeeIds.map((attendeeId) =>
            attendanceMutation(client, {
              instanceId,
              isAttending: isAttending,
              personId: attendeeId,
            }),
          ),
        ),
      );
    }),
    concatAll(),
  );
}

export function updateAttendeeAttendanceEpic(
  action$: Observable<ReduxFormInstanceAttendeeUpdateAttendance>,
  _: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/instance/attendance/UPDATE_ATTENDEE_ATTENDANCE'),
    filter(({ payload: { error } }) => !error),
    mergeMap(async (action) => {
      const { attendeeId, instanceId, isAttending } = action.payload;
      return await attendanceMutation(client, {
        instanceId,
        personId: attendeeId,
        isAttending,
      });
    }),
  );
}

const attendanceMutation = async (
  client: IGraphQLClient,
  inputs: IUpdateAttendeeAttendanceInputs,
) => {
  const { personId, instanceId, isAttending } = inputs;

  const errorAction = updateAttendeeAttendance(
    instanceId,
    personId,
    !isAttending,
    'Unable to update the attendee. Please retry.',
  );

  try {
    const result = await client.mutate<{
      forms: { updateInstanceAttendeeAttendance: { ok: boolean } };
    }>({
      mutation: mutationUpdateInstanceAttendeeAttendance,
      variables: {
        personId,
        instanceId,
        isAttending,
      },
    });

    if (
      result.errors ||
      !result.data?.forms?.updateInstanceAttendeeAttendance?.ok
    ) {
      return errorAction;
    }

    return attendeeSaved(instanceId, personId);
  } catch (err) {
    log.error(
      new Error(
        `An unexpected error occurred trying to update attendance for ${personId} against instance ${instanceId} ${err}`,
      ),
    );
    return errorAction;
  }
};

interface IUpdateAttendeeAttendanceInputs {
  personId: string;
  instanceId: string;
  isAttending: boolean;
}

const mutationUpdateInstanceAttendeeAttendance = gql`
  mutation UpdateInstanceAttendeeAttendance(
    $personId: ID!
    $instanceId: ID!
    $isAttending: Boolean!
  ) {
    forms {
      updateInstanceAttendeeAttendance(
        input: {
          personId: $personId
          instanceId: $instanceId
          isAttending: $isAttending
        }
      ) {
        ok
      }
    }
  }
`;
