/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color as colorUtil } from '@seeeverything/ui.util/src/color/index.ts';

export interface IMarkdownProps {
  cursor?: React.CSSProperties['cursor'];
  color?: string | number;
  selectable?: boolean;
  size?: string | number;
  style?: SerializedStyles;
  text?: string;
  tooltip?: string;
}

const styles = {
  base: css({
    overflowWrap: 'break-word',
    '& *': {
      marginBlockStart: 'auto',
      marginBlockEnd: 'auto',
    },
    '& div': {
      display: 'flex',
      flexDirection: 'column',
      gap: 15,
    },
  }),
};

/**
 * Wrapper around Text primitive with standard markdown styles.
 */
export const Markdown: React.FC<IMarkdownProps> = ({
  color = colorUtil.format(-0.8),
  cursor,
  selectable = true,
  size,
  style,
  text,
  tooltip,
}) => (
  <Text
    color={color}
    cursor={cursor}
    isMarkdown={true}
    selectable={selectable}
    size={size}
    style={css([styles.base, style])}
    tooltip={tooltip}
  >
    {text}
  </Text>
);
