/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { useFormsSelector } from '../redux/store.ts';
import { ShowOnAnswer } from '../types/types.ts';
import { checkShowOnAnswer } from './util.ts';

export type UseShowOnAnswerOptions = {
  showOnAnswer?: ShowOnAnswer;
  instanceId: string;
};

export const useShowOnAnswer = ({
  showOnAnswer,
  instanceId,
}: UseShowOnAnswerOptions) => {
  const answers = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.answers,
  );

  return useMemo(
    () => checkShowOnAnswer(answers, showOnAnswer),
    [answers, showOnAnswer],
  );
};
