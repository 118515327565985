import { TenantConfiguration } from '@seeeverything/ui.util/src/redux/tenant/types.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { BusinessUnit } from '../types.ts';

export const getBusinessUnits = (
  tenantConfig: TenantConfiguration,
): Record<string, BusinessUnit> =>
  Object.entries(tenantConfig.businessUnits).reduce(
    (acc, [businessUnit, configValue]) => ({
      ...acc,
      [businessUnit]: {
        key: businessUnit,
        label: configValue.label,
        icon: configValue.icon,
      },
    }),
    {},
  );

export const toBusinessUnit = (
  businessUnit: string,
  tenantConfig: TenantConfiguration,
): BusinessUnit => {
  const key = str.camelize(businessUnit);
  const bu = tenantConfig.businessUnits[key];

  return bu
    ? { key, label: bu.label, icon: bu.icon }
    : { key, label: businessUnit, icon: 'group' };
};
