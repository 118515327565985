import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';

export type GetPermissionsResponseDto = {
  canEdit: boolean;
  canEditRoles: boolean;
};

export const getPermissions = async (
  client: IGraphQLClient,
  personId: string,
): Promise<QueryResult<GetPermissionsResponseDto>> => {
  try {
    const response = await client.query<{
      orgHierarchy: {
        permissions: { canEdit: boolean };
        rolePermissions: { canEdit: boolean };
      };
    }>({
      query: gql`
        query Permissions($personId: ID!) {
          orgHierarchy {
            permissions(id: $personId) {
              canEdit
            }
            rolePermissions {
              canEdit
            }
          }
        }
      `,
      variables: { personId },
      fetchPolicy: 'network-only',
    });

    if (!response.data.orgHierarchy) {
      log.error(`Failed to get permissions for ${personId}`);
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: {
        canEdit: Boolean(response.data.orgHierarchy.permissions.canEdit),
        canEditRoles: Boolean(
          response.data.orgHierarchy.rolePermissions.canEdit,
        ),
      },
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query permissions - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
