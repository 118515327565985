/** @jsxImportSource @emotion/react */
import { createContext, ReactElement, useContext, useMemo } from 'react';
import { IGraphQLClient } from './types.ts';

const Context = createContext<{
  client?: IGraphQLClient;
  batchClient?: IGraphQLClient;
  uploadClient?: IGraphQLClient;
}>(null);

export interface IGraphQLProviderProps {
  children: ReactElement;
  batchClient: IGraphQLClient;
  client: IGraphQLClient;
  uploadClient: IGraphQLClient;
}
export const GraphQLProvider = ({
  children,
  batchClient,
  client,
  uploadClient,
}: IGraphQLProviderProps) => {
  const clients = useMemo(
    () => ({ client, batchClient, uploadClient }),
    [batchClient, client, uploadClient],
  );

  return <Context.Provider value={clients}>{children}</Context.Provider>;
};

/**
 * React hook for providing GraphQL clients for use in components.
 */
export const useGraphQL = () => useContext(Context);
