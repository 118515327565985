/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@seeeverything/ui.primitives/src/components/Accordion/index.ts';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/index.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import moment from 'moment';
import React from 'react';
import {
  IDigitalContentComment,
  IDigitalContentCommentAddHandler,
  IDigitalContentCommentDeleteHandler,
  IDigitalContentErrorClickHandler,
} from '../../types.ts';
import { CommentField } from '../CommentField/CommentField.tsx';
import { LinkControls } from './LinkControls.tsx';

export interface ICommentProps {
  comment?: IDigitalContentComment;
  onErrorClick?: IDigitalContentErrorClickHandler;
  onReply?: IDigitalContentCommentAddHandler;
  onDelete?: IDigitalContentCommentDeleteHandler;
  depth?: number;
  canReplyActive?: boolean;
}

/**
 * Digital content comment.
 */
export const Comment: React.FC<ICommentProps> = React.memo(
  function CommentView({
    depth = 0,
    canReplyActive = true,
    comment,
    onDelete,
    onReply,
    onErrorClick,
  }) {
    const [isShowingReplies, setIsShowingReplies] = React.useState(true);
    const [inputReply, setInputReply] = React.useState<string | undefined>(
      undefined,
    );

    if (!comment) {
      return null;
    }

    const {
      replies = [],
      canReply = true,
      canDelete = true,
      isDeleted = false,
      isSaving,
      id,
      body,
      commentedAt,
      commentedBy,
      team,
      error,
      parentId,
    } = comment;

    const hasReplies = replies.length > 0;

    const handleReplyClicked = () => setInputReply('');
    const handleDeleteClicked = () => onDelete?.(id);
    const handleReplyChanged = (to: string) => setInputReply(to);
    const handleDiscardClicked = () => setInputReply(undefined);
    const handleReplySubmit = () => {
      onReply?.(inputReply ?? '', parentId || id);
      setInputReply(undefined);
    };
    const handleShowRepliesClicked = () =>
      setIsShowingReplies((currIsShowing) => !currIsShowing);
    const handleErrorClicked = () => onErrorClick?.(id);
    const handleFieldBlur = () => {
      if (!inputReply) {
        setInputReply(undefined);
      }
    };

    const computedStyles = {
      base: css({
        margin: depth ? '8px 0' : '12px 0',
      }),
      body: css({
        margin: isDeleted ? '6px 0 0' : '6px 0 12px 0',
      }),
      repliesIcon: {
        transform: isShowingReplies ? 'rotate(180deg)' : 'none',
      },
    };

    const teamLabel = team ? ` from ${team}` : '';
    const elTitle = (
      <Text
        color={color.format(-0.6)}
        weight={FontWeight.bold}
        italic={isDeleted}
      >{`${commentedBy}${teamLabel} on ${moment(commentedAt).format(
        'D MMM YY',
      )}`}</Text>
    );

    const elBody = (
      <Text
        color={color.format(-0.6)}
        size={14}
        style={computedStyles.body}
        italic={isDeleted}
      >
        {body}
      </Text>
    );

    const elLinkControls = inputReply === undefined &&
      !error &&
      !isSaving &&
      !isDeleted && (
        <LinkControls
          canDelete={canDelete}
          canReply={canReply && canReplyActive}
          onDeleteClick={handleDeleteClicked}
          onReplyClick={handleReplyClicked}
        />
      );

    const elReplyField = inputReply !== undefined && (
      <CommentField
        value={inputReply}
        onChange={handleReplyChanged}
        onDiscard={handleDiscardClicked}
        label={`Reply to ${commentedBy}`}
        onSubmit={handleReplySubmit}
        submitLabel={'Reply'}
        focusOnMount={true}
        id={comment.id}
        onBlur={handleFieldBlur}
      />
    );

    const elSaving = isSaving && (
      <div css={styles.saving}>
        <Spinner size={20} />
        <Text
          italic={true}
          color={color.format(-0.6)}
          size={14}
          style={styles.savingText}
        >
          {'Saving...'}
        </Text>
      </div>
    );

    const elError = error && (
      <Button
        style={styles.error}
        fill={COLORS.ERROR_RED}
        onClick={handleErrorClicked}
      >
        <>
          <Icons.errorOutline fill={COLORS.ERROR_RED} size={20} />
          <Text
            color={COLORS.ERROR_RED}
            size={14}
            cursor={'pointer'}
            style={styles.errorText}
          >
            {error}
          </Text>
        </>
      </Button>
    );

    const elInner = (
      <>
        {elTitle}
        {elBody}
        {elReplyField || elLinkControls || elSaving || elError}
      </>
    );

    if (!hasReplies) {
      return (
        <div css={[styles.base, styles.noRepliesBase, computedStyles.base]}>
          {elInner}
        </div>
      );
    }

    const elReplies = replies.map((reply) => (
      <Comment
        key={reply.id}
        comment={reply}
        depth={depth + 1}
        onDelete={onDelete}
        onErrorClick={onErrorClick}
        onReply={onReply}
        canReplyActive={canReplyActive}
      />
    ));

    const elSummary = (
      <div css={styles.outer}>
        <div css={[styles.base, computedStyles.base]}>{elInner}</div>

        <Button
          style={styles.replyCountButton}
          onClick={handleShowRepliesClicked}
          fill={COLORS.BLUE}
        >
          <>
            <Text
              color={COLORS.BLUE}
              size={12}
              cursor={'pointer'}
              style={styles.replyText}
            >
              {replies.length === 1 ? '1 reply' : `${replies.length} replies`}
            </Text>
            <Icons.arrowDropDown style={computedStyles.repliesIcon} />
          </>
        </Button>
      </div>
    );

    return (
      <Accordion
        id={id}
        style={styles.accordionBase}
        hideDividers={true}
        elevation={0}
        variant={'outlined'}
        isExpanded={isShowingReplies}
        disableGutter={false}
      >
        <AccordionSummary
          style={styles.summaryBase}
          contentStyle={styles.summaryContent}
          disableRipple={true}
          role={'div'}
          expandIcon={null}
        >
          {elSummary}
        </AccordionSummary>
        <AccordionDetails style={styles.details}>{elReplies}</AccordionDetails>
      </Accordion>
    );
  },
  (prevProps, nextProps) =>
    prevProps.comment === nextProps.comment &&
    prevProps.canReplyActive === nextProps.canReplyActive,
);

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: 0,
    flex: '1 1 auto',
  }),
  noRepliesBase: css({ minHeight: 48 }),
  outer: css({
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
  }),
  accordionBase: {
    border: 'none',
    background: 'transparent',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  replyText: css({
    whiteSpace: 'nowrap',
  }),
  summaryContent: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'stretch',
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  summaryBase: {
    '&:hover': {
      background: 'transparent',
    },
    '&$expanded': {
      background: 'transparent',
      minHeight: 48,
      '&:hover': {
        background: 'transparent',
      },
    },
    userSelect: 'text' as const,
    cursor: 'auto',
    padding: 0,
  },
  details: {
    display: 'flex',
    background: color.format(-0.05),
    paddingLeft: 22,
    paddingBottom: 6,
    borderLeft: `solid 5px ${color.format(-0.2)}`,
    flexDirection: 'column' as const,
    alignItems: 'stretch',
  },
  replyCountButton: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 12,
    padding: '6px 1px 6px 8px',
    maxHeight: 28,
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-start',
    padding: 4,
    borderRadius: 3,
  },
  saving: css({
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-start',
    position: 'relative',
  }),
  savingText: css({
    marginLeft: 6,
    paddingBottom: 3,
  }),
  errorText: css({
    marginLeft: 4,
  }),
};
