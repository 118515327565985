export type DistributionListItemStatus = 'Active' | 'Inactive';

export type DistributionListTeamRuleDetail = {
  id: string;
  name: string;
  hierarchyLevel?: number;
  path?: string;
};

export enum DistributionListRuleType {
  ExcludeTeams = 'Exclude Teams',
  IncludeTeams = 'Include Teams',
  IncludeTeamsBelow = 'Teams Below',
  IncludePositionTitles = 'Include Position Titles',
  ExcludePositionTitles = 'Exclude Position Titles',
}

export type DistributionListType = 'Person' | 'Team';

export type DistributionListItem = {
  id: string;
  canEdit: boolean;
  canSave: boolean;
  created: string;
  lastUpdatedBy: string;
  lastUpdatedOn: string;
  listName: string;
  ruleExcludeTeams: DistributionListTeamRuleDetail[];
  ruleIncludeTeams: DistributionListTeamRuleDetail[];
  ruleIncludeTeamsBelow: DistributionListTeamRuleDetail[];
  ruleIncludePositionTitles: string[];
  ruleExcludePositionTitles: string[];
  ruleIncludePositionTitlesContaining: string[];
  ruleIncludePositionTitlesNotContaining: string[];
  status: DistributionListItemStatus;
  type: DistributionListType;
};
