/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useRef, useState } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { LabelButton } from '../../../LabelButton/LabelButton.tsx';

export type ActionEvent = {
  id: string;
  label: string;
  data?: any;
};
export type ActionEventHandler = (e: ActionEvent) => void;

export interface IActionBarButton {
  backgroundAlways?: boolean;
  dataTest?: string;
  id: string;
  isEnabled?: boolean;
  label: string;
  onAction?: ActionEventHandler;
}

export interface IActionButtonsProps {
  actions: IActionBarButton[];
  style?: SerializedStyles;
  buttonWidth?: string | number;
}

/**
 * A set of buttons for confirming or rejecting an action.
 */
export const ActionButtons: React.FC<IActionButtonsProps> = ({
  actions,
  style,
  buttonWidth,
}) => {
  const [maxButtonWidth, setMaxButtonWidth] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  const buttonRefs = useRef([]);
  const setButtonRefs = useCallback((element: HTMLDivElement) => {
    buttonRefs.current.push(element);
  }, []);

  useEffect(() => {
    const refs = buttonRefs.current;
    const toMaxButtonWidth = Math.max(...refs.map((ref) => ref.clientWidth));
    setMaxButtonWidth(toMaxButtonWidth);
    setIsLoaded(true);
  }, []);

  const handleActionClick = useCallback(
    ({ id, label, onAction }: IActionBarButton) =>
      () =>
        onAction?.({ id, label }),
    [],
  );

  return (
    <div css={style}>
      {actions.map((action) => (
        <div
          ref={setButtonRefs}
          data-test={action.dataTest}
          key={action.id}
          css={styles.buttonOuter}
        >
          <LabelButton
            backgroundAlways={action.backgroundAlways ?? true}
            onClick={handleActionClick(action)}
            label={action.label}
            isVisible={isLoaded}
            minWidth={maxButtonWidth}
            isEnabled={action.isEnabled}
            width={buttonWidth}
          />
        </div>
      ))}
    </div>
  );
};

const styles = {
  buttonOuter: css({
    cursor: 'pointer',
    display: 'inline-block',
    marginLeft: 5,
    ':first-of-type': {
      marginLeft: 0,
    },
  }),
};
