import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import { Person } from '../types.ts';

export type GetPeopleResponseDto = {
  pageInfo: {
    hasNextPage: boolean;
    currentPage: number;
  };
  people: Person[];
};

type ServerPeople = {
  pageInfo: {
    hasNextPage: boolean;
    currentPage: number;
  };
  nodes: Person[];
};

export type GetPeopleArgs = {
  pageNumber?: number;
  name?: string;
  includeInactive?: boolean;
};

export const getPeople = async (
  client: IGraphQLClient,
  args: GetPeopleArgs,
): Promise<QueryResult<GetPeopleResponseDto>> => {
  try {
    const response = await client.query<{
      orgHierarchy: { people: ServerPeople };
    }>({
      query: gql`
        query GetPeople(
          $name: String
          $pageNumber: Int
          $includeInactive: Boolean
        ) {
          orgHierarchy {
            people(
              name: $name
              pagination: { pageNumber: $pageNumber, size: 50 }
              includeInactive: $includeInactive
            ) {
              pageInfo {
                hasNextPage
                currentPage
              }
              nodes {
                id
                email
                firstName
                isActive
                lastName
                positionTitle
              }
            }
          }
        }
      `,
      variables: {
        name: args.name,
        pageNumber: args.pageNumber ?? 1,
        includeInactive: args.includeInactive,
      },
      fetchPolicy: 'network-only',
    });

    const people = response.data.orgHierarchy.people.nodes;
    if (!people) {
      log.error(`Failed to retrieve people`);
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: {
        people: people.map(
          (person): Person => ({
            id: person.id,
            email: person.email,
            firstName: person.firstName,
            isActive: person.isActive,
            lastName: person.lastName,
            positionTitle: person.positionTitle,
          }),
        ),
        pageInfo: {
          currentPage: response.data.orgHierarchy.people.pageInfo.currentPage,
          hasNextPage: response.data.orgHierarchy.people.pageInfo.hasNextPage,
        },
      },
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query people - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
