/** @jsxImportSource @emotion/react */
import { scrollSlice } from '@seeeverything/ui.util/src/redux/scroll/index.ts';
import { useUtilDispatch } from '@seeeverything/ui.util/src/redux/store.ts';
import { useEffect, useRef } from 'react';
import { IntersectionOptions } from 'react-intersection-observer';
import { INTERSECTION_OBSERVER_DEFAULTS } from '../common/constants.ts';
import { useViewport } from './useViewport.ts';

export interface IUseTrackInViewportOptions {
  dataId: string;
  parentDataId?: string;
  observerOptions?: IntersectionOptions;
}

/**
 * Hook for subscribing a component to changes in its visibility in regards to the browser's viewport.
 * This hook also dispatches an action to the global scroll state to track the visibility of the component.
 * The `forwardedRef` returned by the hook **MUST** be passed as a prop to the underlying DOM node that is being
 * monitored for viewport changes.
 */
export const useTrackInViewport = ({
  dataId,
  parentDataId,
  observerOptions,
}: IUseTrackInViewportOptions) => {
  const dispatch = useUtilDispatch();
  const initialized = useRef(false);
  const [forwardedRef, inViewport] = useViewport(
    observerOptions ?? INTERSECTION_OBSERVER_DEFAULTS,
  );

  useEffect(() => {
    if (inViewport) initialized.current = true;
  }, [inViewport]);

  useEffect(() => {
    if (!initialized.current) return;

    dispatch(
      scrollSlice.trackDataIdInView({ dataId, parentDataId, inViewport }),
    );
  }, [dataId, parentDataId, dispatch, inViewport, initialized]);

  useEffect(() => {
    if (!initialized.current) return;

    return () => {
      dispatch(
        scrollSlice.trackDataIdInView({
          dataId,
          parentDataId,
          inViewport: false,
        }),
      );
    };
  }, [dataId, dispatch, parentDataId]);

  return [forwardedRef, inViewport] as const;
};
